import { Component, OnInit } from '@angular/core';
import { NgbDateStruct, NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';
import { throttleTime } from 'rxjs/operators';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { Obra } from 'src/app/domain/obra/obra';
import { EnumService } from 'src/app/service/usefull/enum.service';
import { Estado } from 'src/app/domain/enum/estado';
import { Municipio } from 'src/app/domain/enum/municipio';
import { Regiao } from 'src/app/domain/enum/regiao';
import { InputAny, EnumOjectInputAny } from '../localidade/localidade.component';
import { Cotacao, EnumObraEndereco, EnumRecebimentoProduto, EnumTipoFrete, EnumFormaPagamento, EnumStatusCotacao } from 'src/app/domain/cotacao/cotacao';
import { CotacaoService } from 'src/app/service/suprimento/comprador/cotacao.service';
import { AlertService } from 'src/app/service/alert.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PrazoPagamento } from 'src/app/domain/auxiliar/prazo-pagamento';
import { PrazoPagamentoService } from 'src/app/service/auxiliar/prazo-pagamento.service';
import { Empresa } from 'src/app/domain/empresa/empresa';
import { EmpresaService } from 'src/app/service/configuracao/empresa.service';
import { EnderecoService } from 'src/app/service/configuracao/endereco.service';
import { Enderecos } from 'src/app/domain/empresa/Enderecos';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { VerificaService } from 'src/app/service/auxiliar/verifica.service';
import { RepositoryService } from 'src/app/service/repository.service';
import { ObraCotacaoAuditor, ObraCotacao } from 'src/app/domain/gestao/obra-cotacao';
declare var $: any;

@Component({
  selector: 'app-new-cotacao',
  templateUrl: './new-cotacao.component.html',
  styleUrls: ['./new-cotacao.component.css']
})
export class NewCotacaoComponent implements OnInit {
  _ha_estado = false;
  _ha_cidade = false;

  data_limite: NgbDateStruct;
  data_recebimento: NgbDateStruct;
  date: { year: number, month: number };

  public _cotacao: Cotacao;
  public _input_modal: Array<InputAny>;

  public _regioes: Regiao[];
  public _estados: Estado[];
  public _cidades: Municipio[];

  public _recebimento_produto: boolean;

  _list_prazo = new Array<PrazoPagamento>();

  cotacao_id = this.route.snapshot.params['id'];
  constructor(
    private enderecoService: EnderecoService,
    private calendar: NgbCalendar,
    private route: ActivatedRoute,
    private cotacaoService: CotacaoService,
    private enumService: EnumService,
    private confirmService: AlertConfirmService,
    private router: Router,
    private prazoPagamentoService: PrazoPagamentoService,
    private empresaServico: EmpresaService,
    private verificaService: VerificaService,
    private reposity: RepositoryService) {
    var date = new Date();
    this.confirmService.UnsetObservers();
    this.confirmService.confirmEmitter$.subscribe(c => { this.DeleteEndereco(c); });
  }


  ngOnInit() {
    this.GetEnderecos();
    this.selectToday();
    this._regioes = new Array<Regiao>();
    this._estados = new Array<Estado>();
    this._cidades = new Array<Municipio>();
    this._cotacao = new Cotacao();
    this._input_modal = new Array<InputAny>();
  }

  _enderecos = []
  GetEnderecos() {
    this.enderecoService.GetAll().subscribe(
      d => {
        if (!(d && d.length > 0)) {
          this.SetMessageEmpresa()
        } else {
          this._enderecos = d
        }
      },
      e => { this.SetMessageEmpresa() },
      () => { this.LoadEdit(); });
  }


  public _msg_titulo: string;
  public _msg_descricao: string;
  SetMessageEmpresa() {
    this._msg_titulo = '<label class="titulo-msg">Dados da Empresa Desatualizados&nbsp;&nbsp;<i class="fa fa-thumbs-down"></i></label>'
    this._msg_descricao = '<div class="text-center"><br />Os dados da empresa estão desatualizados ou incompleto para ter acesso a todas as ferramentas atualize-os.<br /><br /></div>'
    this.verificaService.AutalizarDadosEmpresa()
  }

  Insert() {
    if (!this._tempObra.id)
      throw ('Por favor selecione uma obra')

    this._cotacao.data_max_recebimento_orcamento = UseFullService.formatNgbDateStruct(this.data_limite)
    this._cotacao.data_recebimento_produto = UseFullService.formatNgbDateStruct(this.data_recebimento)

    this._cotacao.tipo_frete = this.getTipoFrete()

    if (this._fob) {
      this._cotacao.regiao = Regiao.GetRegioes(this._regioes)
      this._cotacao.estado = Estado.GetEstados(this._estados)
      this._cotacao.cidade = Municipio.GetMunicipios(this._cidades)
    } else {
      this._cotacao.regiao = ''
      this._cotacao.estado = ''
      this._cotacao.cidade = ''
    }

    this.VerificaDataRecebimento();
    Cotacao.Validar(this._cotacao);
    if (this.cotacao_id && this.cotacao_id > 0)
      this.cotacaoService.Edit(this._cotacao).subscribe(
        d => { },
        e => { },
        () => { this.router.navigateByUrl("/cotacao/produto/" + this._cotacao.id); }
      );
    else
      this.cotacaoService.Create(this._cotacao).subscribe(
        d => { this._cotacao.id = d.guid; },
        e => { },
        () => {
          this.router.navigateByUrl("/cotacao/produto/" + this._cotacao.id);
        }
      );
  }

  getTipoFrete(): number {
    if (this._cif && this._fob)
      return EnumTipoFrete.ciffob
    else if (this._cif)
      return EnumTipoFrete.cif
    else if (this._fob)
      return EnumTipoFrete.fob
    else
      throw ("Selecione o tipo de frete, para continuar")
  }

  data_atual = {}
  selectToday() {
    var data_atual = new Date();
    this.data_atual = UseFullService.formatNgbDate(data_atual.setDate(data_atual.getDate() + 1))

    this.data_limite = this.data_atual as NgbDate;
    this.data_recebimento = this.data_atual as NgbDate;
    this.ChangeDateLimite();
    this.ChangeDateRecebimento();
  }

  ChangeDateLimite() {
    setTimeout(() => {
      $('.date-limit').val(UseFullService.GetFormatDate(this.data_limite.day, this.data_limite.month, this.data_limite.year));
      var data_limite = UseFullService.formatDateUS(UseFullService.GetFormatDate(this.data_limite.day, this.data_limite.month, this.data_limite.year));
      var data_recebimento = UseFullService.formatDateUS(UseFullService.GetFormatDate(this.data_recebimento.day, this.data_recebimento.month, this.data_recebimento.year));
      if (new Date(data_limite) > new Date(data_recebimento)) {
        this.data_recebimento = UseFullService.formatNgbDate(data_limite + "T00:00:00");
        setTimeout(() => {
          $('.date-recebimento').val(UseFullService.GetFormatDate(this.data_recebimento.day, this.data_recebimento.month, this.data_recebimento.year));
        }, 10);
      }
    }, 10);
  }

  ChangeDateRecebimento() {
    setTimeout(() => {
      $('.date-recebimento').val(UseFullService.GetFormatDate(this.data_recebimento.day, this.data_recebimento.month, this.data_recebimento.year));
      this.VerificaDataRecebimento();
    }, 10);
  }

  VerificaDataRecebimento() {
    var data_limite = UseFullService.formatDateUS(UseFullService.GetFormatDate(this.data_limite.day, this.data_limite.month, this.data_limite.year));
    var data_recebimento = UseFullService.formatDateUS(UseFullService.GetFormatDate(this.data_recebimento.day, this.data_recebimento.month, this.data_recebimento.year));
    if (new Date(data_limite) > new Date(data_recebimento))
      throw "Data do recebimento não pode ser menor que a data do orçamento.";
  }

  _cif = false
  selectCIF() { this._cif = !this._cif }

  _fob = false
  selectFOB() {
    this._fob = !this._fob
    this._regioes = new Array<Regiao>();
    this._estados = new Array<Estado>();
    this._cidades = new Array<Municipio>();
    this._ha_estado = false
    this._ha_cidade = false
    setTimeout(() => {
      if (this._fob)
        $('.input-preco-km').focus()
    }, 1);
  }

  selectFormaPagamento(v: number, zerar: boolean = false) {
    if (!zerar)
      this._cotacao.periodo_pagamento = 0
    this._cotacao.forma_pagamento = v
    if (v == EnumFormaPagamento.prazo) {
      if (this._cotacao.meio_pagamento) {
        this.prazoPagamentoService.GetAll(this._cotacao.meio_pagamento).subscribe(
          d => { this._list_prazo = d; }
        );
      }
      return
    }
    this._list_prazo = new Array<PrazoPagamento>()
  }

  ChangeRecebimento(v: number) {
    this._cotacao.recebimento_produto = v;
  }

  GetRegiao(modal: boolean = true) {
    this.enumService.GetAll("Regiao").subscribe(
      d => {
        var data = d as Array<InputAny>;
        data.forEach(c => {
          c._selecionado = false,
            c.objeto = EnumOjectInputAny.regiao;
        });
        this._input_modal = data;
      },
      e => { },
      () => {
        if (this.cotacao_id && this.cotacao_id > 0 && !modal) {
          this._regioes = Regiao.GetRegiosSelecionadas(this._input_modal, this._cotacao.regiao);
          this._input_modal.forEach(c => {
            var data = this._regioes.filter(o => { return (o.Id == c.Id) });
            if (data && data.length > 0)
              c._selecionado = true
          })
          setTimeout(() => {
            this.SelecionarEstado(false);
            this._ha_estado = (this._regioes.length > 0);
          }, 100);
        }
        
      }
    );
  }

  SelecionarRegiao() { 
    this.GetRegiao(); 
    $('#pesquisa-localidade-modal').modal("show");
  }

  SelecionarEstado(modal: boolean = true) {
    this._input_modal = new Array<InputAny>();
    var qt_regioes = this._regioes.length;
    var x = 1;
    this._regioes.forEach(c => {
      this.enumService.GetAll("Estado", String(c.Id), "regiao").subscribe(
        d => {
          var data = d as Array<InputAny>;
          data.forEach(c => {
            c._selecionado = false,
              c.objeto = EnumOjectInputAny.estado;
            this._input_modal.push(c);
          });
        },
        e => { },
        () => {
          if (this.cotacao_id && this.cotacao_id > 0 && !modal && x == qt_regioes) {
            this._estados = Estado.GetEstadosSelecionados(this._input_modal, this._cotacao.estado);
            setTimeout(() => {
              this._ha_cidade = (this._estados.length > 0);
              this.SelecionarCidade(false);
            }, 500);
          }
          x++;
        }
      );
    });
    if (modal)
      $('#pesquisa-localidade-modal').modal("show");
  }

  SelecionarCidade(modal: boolean = true) {
    this._input_modal = new Array<InputAny>();
    this._estados.forEach(c => {
      this.enumService.GetAll("Municipio", c.Uf).subscribe(
        d => {
          var data = d as Array<InputAny>;
          data.forEach(c => {
            c._selecionado = false,
              c.objeto = EnumOjectInputAny.cidade;
            this._input_modal.push(c);
          });
        },
        e => { },
        () => {
          if (this.cotacao_id && this.cotacao_id > 0 && !modal) {
            this._cidades = Municipio.GetCidadesSelecionados(this._input_modal, this._cotacao.cidade);
          }
        }
      );
    });
    if (modal)
      $('#pesquisa-localidade-modal').modal("show");
  }

  ReceiverRespostaModal(data: any[]) {
    if (data.length < 1)
      return;
    switch (data[0][0].objeto) {
      case EnumOjectInputAny.regiao:
        this.SetRegioes(data[0]);
        break;
      case EnumOjectInputAny.estado:
        this.SetEstados(data[0]);
        break;
      case EnumOjectInputAny.cidade:
        this.SetCidades(data[0]);
        break;
    }

    $('#pesquisa-localidade-modal').modal("hide");
  }

  SetRegioes(data: Array<Regiao>) {
    this._regioes = new Array<Regiao>();
    this._estados = new Array<Estado>();
    this._cidades = new Array<Municipio>();
    data.forEach(c => {
      if (c._selecionado) {
        this._regioes.push(c as Regiao);
      }
    });
    this._ha_estado = (this._regioes.length > 0);
    this._ha_cidade = false;
  }

  SetEstados(data: Array<Estado>) {
    this._estados = new Array<Estado>();
    this._cidades = new Array<Municipio>();
    data.forEach(c => {
      if (c._selecionado) {
        this._estados.push(c as Estado);
      }
    });
    this._ha_cidade = (this._estados.length > 0);
  }

  SetCidades(data: Array<Municipio>) {
    this._cidades = new Array<Municipio>();
    data.forEach(c => {
      if (c._selecionado) {
        this._cidades.push(c as Municipio);
      }
    });
  }

  Reload() {
    window.location.reload();
  }

  LoadEdit() {
    if (this.cotacao_id && this.cotacao_id > 0) {
      this.cotacaoService.Get(this.cotacao_id).subscribe(
        d => { this._cotacao = d; },
        e => { },
        () => {
          if (this._cotacao.status == EnumStatusCotacao.enviada)
            this.router.navigateByUrl("/cotacao/view/" + this._cotacao.id);
          this.SetUpdate();
          this.showModalSelectObra()
        }
      );
    } else
      this.showModalSelectObra()

    $(document).ready(function () {
      setTimeout(() => {
        $('input[name="nome"]').focus()
      }, 10);
    });
  }

  SetUpdate() {
    setTimeout(() => {
      this.selectTipoFrete()
      this.ChangeRecebimento(this._cotacao.recebimento_produto);
      this.selectFormaPagamento(this._cotacao.forma_pagamento, true);
    }, 500);

    this.data_limite = UseFullService.formatNgbDate(this._cotacao.data_max_recebimento_orcamento.toString())
    this.data_recebimento = UseFullService.formatNgbDate(this._cotacao.data_recebimento_produto.toString())
    setTimeout(() => {
      $('.date-limit').val(UseFullService.formatDate(new Date(this._cotacao.data_max_recebimento_orcamento)));
      $('.date-recebimento').val(UseFullService.formatDate(new Date(this._cotacao.data_recebimento_produto)));
    }, 100);

    this.GetRegiao(false);
  }

  selectTipoFrete() {
    if (this._cotacao.tipo_frete == EnumTipoFrete.ciffob) {
      this._cif = true
      this._fob = true
    } else if (this._cotacao.tipo_frete == EnumTipoFrete.cif)
      this._cif = true
    else if (this._cotacao.tipo_frete == EnumTipoFrete.fob)
      this._fob = true
    else
      throw ('Ha uma inconsistência nos dados da cotação, o tipo de frete é obrigatório!')
  }

  NovoEndereco() {
    this._input_endereco = new Enderecos()
    $('#new-endereco-modal').modal("show");
  }

  ReceiverRespostaEnderecoModal(e: any) {
    this.GetEnderecos();
  }
  _input_endereco = new Enderecos()
  EditEndereco(endereco: Enderecos) {
    this._input_endereco = endereco
    $('#new-endereco-modal').modal("show");
  }

  ConfirmDeleteEndereco(endereco: Enderecos) {
    this._input_endereco = endereco;
    this.confirmService._code = "remove-endereco";
    this.confirmService._title = "Excluir Endereço";
    this.confirmService._message = "Cliquei em <b>SIM</b> para excluir endereço.";
    this.confirmService.Show();
  }

  DeleteEndereco(confirm: boolean = false) {
    if (this.confirmService._code != "remove-endereco" || confirm == false)
      return;
    this.enderecoService.Delete(this._input_endereco.id).subscribe(
      d => { this.GetEnderecos(); },
      e => { },
      () => { }
    );
  }

  toggleCalendar() {
    UseFullService.TraduzirCalendario();
  }

  /* select obra */
  obras = new Array<ObraCotacaoAuditor>();
  showModalSelectObra() {
    //UseFullService.ShowModal('#select-list-modal')
    this.reposity.path = "obra-cotacao/all"
    this.reposity.getAll().subscribe(
      d => {
        if (d && d.length > 0)
          this.obras = d;
      },
      e => { },
      () => {
        let x = 1;
        this.obras.forEach(c => {
          if (c.id == this._cotacao.obra_id) {
            this._tempObra = c
            this.selecionarObra(this._tempObra)
          }
          c._index = x++;
        });
      }
    );
  }

  _tempObra = new ObraCotacao()
  _tempEndereco = new Enderecos()
  selecionarObra(obraCotacao: ObraCotacao) {
    UseFullService.HideModal('#select-list-modal')
    this._tempObra = obraCotacao;
    this._cotacao.obra_id = this._tempObra.id
    this._cotacao.nome_obra = this._tempObra.nome
    this._cotacao.endereco_id = this._tempObra.endereco_id
    UseFullService.SetMask()
    this.getAllRelacaoEmpresas(this._tempObra.id)
    this._tempEndereco = this._enderecos.filter(o => { return (o.id == this._tempObra.endereco_id) })[0]
  }

  _obra_list_empresas = new Array<Empresa>();
  getAllRelacaoEmpresas(obra_id) {
    this._obra_list_empresas = new Array<Empresa>();
    this.reposity.path = "obra-cotacao/relacao-empresa/all"
    this.reposity.getAll('', '', '?obra_id=' + obra_id + '&detail=true').subscribe(
      d => {
        if (d && d.length > 0)
          this._obra_list_empresas = d
      }
    );
    UseFullService.SetMask()
  }

  removerObra() {
    this._tempObra = new ObraCotacao();
    this._cotacao.obra_id = 0
    this._cotacao.nome_obra = String()
    this._cotacao.endereco_id = 0
    this._obra_list_empresas = new Array<Empresa>();
  }
}