<div class="col-lg-12 wrapper-top-content">
    <div class="ibox-title">
        <h5>Dados Pessoais</h5>
    </div>

    <form #EmpresaForm="ngForm" appIdentityRevealed action="#">
        <div class="ibox-content form-empresa">




            <div class="form-group row" *ngIf="(this.userLoggedInService.person ==0)">
                <label class="col-sm-2 col-form-label"><b>Razão Social</b> *</label>
                <div class="col-sm-10">
                    <input type="text" id="razao_social" name="razao_social" [(ngModel)]="_empresa.razao_social" required minlength="4" class="form-control" placeholder="Razão Social" #razao_social="ngModel">

                    <div *ngIf="razao_social.invalid && (razao_social.dirty ||
                            razao_social.touched)" class="input-error-msg text-danger">
                        <div *ngIf="razao_social.errors.required">
                            Razão Social é obrigatório.
                        </div>
                        <div *ngIf="razao_social.errors.minlength">
                            Razão Social é obrigatório.
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row" *ngIf="(this.userLoggedInService.person == 0)">
                <label class="col-sm-2 col-form-label"><b>Nome Fantasia</b> *</label>
                <div class="col-sm-4">
                    <input type="text" required minlength="4" id="nome_fantasia" name="nome_fantasia" class="form-control" [(ngModel)]="_empresa.nome_fantasia" placeholder="Nome Fantasia" #nome_fantasia="ngModel">
                    <div *ngIf="nome_fantasia.invalid && (nome_fantasia.dirty || nome_fantasia.touched)" class="input-error-msg text-danger">
                        <div *ngIf="nome_fantasia.errors.required">
                            Nome Fantasia é obrigatório.
                        </div>
                        <div *ngIf="nome_fantasia.errors.minlength">
                            Nome Fantasia é obrigatório.
                        </div>
                    </div>
                </div>
                <label class="col-sm-2 col-form-label"><b>Inscrição Estadual</b></label>
                <div class="col-sm-4">
                    <input type="text" class="form-control" name="inscricao_estadual" id="number" #inscricao_estadual="ngModel" [(ngModel)]="_empresa.inscricao_estadual" placeholder="Inscrição Estadual">
                </div>
            </div>

            <div class="form-group row" *ngIf="(this.userLoggedInService.person == 0)">
                <label class="col-sm-2 col-form-label"><b>CNPJ</b> *</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control" id="cnpj" name="cnpj" [(ngModel)]="_empresa._cnpj" disabled>
                </div>
            </div>


            <div class="form-group row" *ngIf="(this.userLoggedInService.person == 1)">
                <label class="col-sm-2 col-form-label"><b>Nome</b> *</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control" value="{{userLoggedInService.name}}" disabled>
                </div>

                <label class="col-sm-1 col-form-label"><b>CPF</b> *</label>
                <div class="col-sm-2">
                    <input type="text" class="form-control" id="cpf" name="cpf" [(ngModel)]="_empresa._cpf" disabled>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2 col-form-label"><b>Logo</b></label>
                <div class="col-sm-4">
                    <div class="fileUpload btn btn-primary">
                        <span>+ Escolher Logo</span>
                        <input #imageInput type="file" name="logo" id="fileImage" class="upload" accept="image/*" placeholder="Inserir Logo" (change)="CarregarLogo(imageInput)">
                    </div>
                    <br />
                    <img id="image-logo" (click)="MostrarLogo()" />
                </div>


                <label class="col-sm-2 col-form-label" *ngIf="(this.userLoggedInService.person == 0)"><b>Contrato Social</b></label>
                <div class="col-sm-4" *ngIf="(this.userLoggedInService.person == 0)">

                    <div class="fileUpload btn btn-primary">
                        <span>+ Escolher Arquivo</span>
                        <input #fileInput type="file" name="file" id="file" accept="image/*,.pdf" class="upload" placeholder="Inserir Contrato Social" (change)="CarregarArquivos(fileInput)" multiple>
                    </div>
                    <div id="previow_file">

                    </div>
                </div>
            </div>
            <hr />

            <div class="form-group row">
                <label class="col-sm-2 col-form-label"><b>CEP</b> *</label>
                <div class="col-sm-3">
                    <input type="text" [(ngModel)]="_empresa.cep" id="cep" name="cep" required minlength="9" #cep="ngModel" (ngModelChange)="OnChangeCep()" class="form-control" placeholder="00000-000">

                    <div *ngIf="cep.invalid && (cep.dirty || cep.touched)" class="input-error-msg text-danger">
                        <div *ngIf="cep.errors.required || cep.errors.minlength">
                            CEP deve ter 8 caracteres.
                        </div>
                    </div>
                </div>
                <label class="col-sm-1"></label>
                <label class="col-sm-2 col-form-label"><b>Número</b> *</label>
                <div class="col-sm-3">
                    <input type="text" [(ngModel)]="_empresa.numero" id="number" name="numero" #numero="ngModel" required minlength="1" class="form-control" placeholder="Número">
                    <div *ngIf="numero.invalid && (cep.dirty || numero.touched)" class="input-error-msg text-danger">
                        <div *ngIf="numero.errors.required || numero.errors.minlength">
                            Numero é obrigatório.
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2 col-form-label"><b>Endereço</b> *</label>
                <div class="col-sm-10">
                    <input type="text" [(ngModel)]="_empresa.endereco" name="endereco" #endereco="ngModel" required minlength="1" class="form-control" placeholder="endereco">

                    <div *ngIf="endereco.invalid && (endereco.dirty || endereco.touched)" class="input-error-msg text-danger">
                        <div *ngIf="endereco.errors.required">
                            Endereço é obrigatório.
                        </div>
                        <div *ngIf="endereco.errors.minlength">
                            Endereço é obrigatório.
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2 col-form-label"><b>Bairro</b> *</label>
                <div class="col-sm-4">
                    <input class="form-control" [(ngModel)]="_empresa.bairro" name="bairro" #bairro="ngModel" required minlength="2" placeholder="Bairro">

                    <div *ngIf="bairro.invalid && (bairro.dirty ||
                            bairro.touched)" class="input-error-msg text-danger">
                        <div *ngIf="bairro.errors.required">
                            Bairro é obrigatório.
                        </div>
                        <div *ngIf="bairro.errors.minlength">
                            Bairro é obrigatório.
                        </div>
                    </div>
                </div>
                <label class="col-sm-2 col-form-label"><b>Complemento</b></label>
                <div class="col-sm-4">
                    <input class="form-control" name="complemento" [(ngModel)]="_empresa.complemento" placeholder="Complemento">
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2 col-form-label"><b>Estado</b> *</label>
                <div class="col-sm-3">
                    <select [(ngModel)]="_empresa.uf" name="thisuf" #thisuf="ngModel" required (ngModelChange)="OnChangeEstado()" class="form-control">
                            <option value="" selected>Selecione Estado</option>
                            <option *ngFor="let estado of _estados"
                                value="{{estado.Uf}}">{{estado.Nome}}</option>
                        </select>

                    <div *ngIf="thisuf.invalid && (thisuf.dirty ||
                            thisuf.touched)" class="input-error-msg text-danger">
                        <div *ngIf="thisuf.errors.required">
                            Estado é obrigatório.
                        </div>
                    </div>
                </div>
                <label class="col-sm-1"></label>
                <label class="col-sm-2 col-form-label"><b>Cidade</b> *</label>
                <div class="col-sm-3">
                    <select [(ngModel)]="_empresa.cidade" class="form-control" name="thiscidade" #thiscidade="ngModel" required>
                            <option value="" selected>Selecione Cidade</option>
                            <option *ngFor="let cidade of _municipios"
                                value="{{cidade.Codigo}}">{{cidade.Nome}}</option>
                        </select>

                    <div *ngIf="thiscidade.invalid && (thiscidade.dirty ||
                            thiscidade.touched)" class="input-error-msg text-danger">
                        <div *ngIf="thiscidade.errors.required">
                            Cidade é obrigatório.
                        </div>
                    </div>
                </div>
            </div>

            <br /><br />
            <div class="form-group row">
                <div class="text-right col-sm-12">
                    <button type="button" class="btn btn-default" (click)="reload()">Cancelar</button>
                    <button type="button" class="btn btn-primary" style="margin-left: 10px;" [disabled]="EmpresaForm.invalid" (click)="Insert()">Salvar</button>
                </div>
            </div>

        </div>


    </form>

</div>

<!-- <app-image-modal (resposta_produto_selecionado)="ReciverPesquisaProduto($event)"></app-image-modal> -->
<app-data-modal [_nome_arquivo]="_nome_arquivo" [_src_arquivo]="_src_arquivo"></app-data-modal>