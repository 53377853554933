<!-- Modal -->
<div class="modal" id="pesquisa-insumo-modal" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="false">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">

        <app-modal-cores-insumo [_input_nivel]="_input_nivel" (resposta_cores_selecionadas)="ReciverCoresSelecionadas($event)"></app-modal-cores-insumo>
        <div class="modal-backdrop show"></div>

        <div class="modal-content">

            <div class="modal-header">

                <h4>Lista de Produtos</h4>
                <div class="ibox-tools">
                    <div>
                        <label class="margin-right-20">
                            {{(_vinculado)?_suprimento.insumos.length + 'itens':''}}
                            <!--
                                <b *ngIf="_total_pesquisa >= 0">de</b>
                                {{(_suprimento.insumos.length > _total_pesquisa) ?
                                _suprimento.insumos.length : _total_pesquisa}}
                            -->
                        </label>
                        <label>
                            <div class="btn-group mr-2
                                pagination-position-grid">
                                <button type="button"
                                    *ngIf="_paginas[0].active_one"
                                    class="btn btn-secondary"
                                    (click)="GetPage(1)">1</button>
                            </div>
                        </label>
                        <label>
                            <div class="btn-group mr-2
                                pagination-position-grid"
                                role="group"
                                aria-label="First group">
                                <button type="button" *ngFor="let page
                                    of _paginas"
                                    class="btn btn-secondary
                                    {{page.active
                                    ?'active':''}}"
                                    (click)="GetPage(page.page)">{{(page.page)?page.page:1}}</button>
                            </div>
                        </label>
                        <label>
                            <div class="btn-group mr-2
                                pagination-position-grid">
                                <button type="button"
                                    *ngIf="_paginas[0].ultima_pagina> 0"
                                    class="btn btn-secondary"
                                    (click)="GetPage(_paginas[0].ultima_pagina)">{{_paginas[0].ultima_pagina}}</button>
                            </div>
                        </label>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 wrapper-top-content">
                <div class="row bottom-10">

                    <div class="col-sm-5 m-b-xs">
                        <div class="input-group"><input (ngModelChange)="OnChangeSearch()" placeholder="Pesquisar" [(ngModel)]="_buscar" type="text" class="form-control
                                form-control-sm">
                            <span class="input-group-append">
                                <button type="button" class="btn btn-sm
                                    btn-primary">Buscar</button>
                            </span>
                        </div>
                    </div>
                    <div class="col-sm-2 m-b-xs">

                        <select class="form-control-sm form-control
                            input-s-sm
                            inline select-category" [(ngModel)]="_categoria_id" (change)="OnChangeSearch(false)">
                            <option value="0">Informe a Categoria</option>
                            <option *ngFor="let categoria of _categorias
                                | filter :
                                'pai_id' : 0 : true"
                                value="{{categoria.id}}">{{categoria.descricao}}</option>
                        </select>

                    </div>
                    <div class="col-sm-5 text-right">

                    </div>
                </div>


                <div class="col-lg-12 row   " style="padding:5px
                    10px 10px 10px;
                    margin: 0;
                    margin-bottom: 15px;background:#f5f5f5;" *ngIf="_categoria_nivel || _categoria_virtual &&
                    _categoria_virtual.id> 0">

                    <div class="col-lg-12" style="padding:0 0 2px 0;
                        margin-bottom:
                        5px; border-bottom: 1px solid #ccc;">
                        <h4>Possíveis produtos para busca e cadastro:</h4>
                    </div>

                    <div class="col-md-2" style="padding: 2px 0px 0px
                        2px;" *ngIf="_categoria_virtual &&
                        _categoria_virtual.id> 0">
                        <b>Produto</b><br />

                        <select class="form-control-sm form-control
                            input-s-sm
                            inline select-niveis {{
                            _categoria_virtual._selecionado>
                            0 ?
                            'input-border-success':'input-border-error'
                            }}" [(ngModel)]="_categoria_virtual._selecionado" (change)="OnChangeSearchCategoria()">
                            <option value="undefined" selected>Selecione
                                Produto</option>
                            <option *ngFor="let categoria of
                                _categoria_virtual.categorias"
                                value="{{categoria.id}}">{{categoria.descricao}}</option>
                        </select>
                    </div>

                    <div class="col-md-2" style="padding: 2px 0px 0px
                        2px;" *ngFor="let nivel of _niveis" [hidden]="!nivel._ativo">
                        <div *ngIf="nivel._ativo">
                            <b>{{nivel.nome}}*</b><br />

                            <button class="btn btn-default
                                btn-select-color text-left
                                button-pesquisa {{nivel._selected> 0 ?
                                'input-border-success':'input-border-error'
                                }}" *ngIf="nivel.selecao ==
                                _enumComponetesSelecao.modal" (click)="SelectCores(nivel)">Selecione
                                {{nivel.nome}}</button>
                            <select class="form-control-sm form-control
                                input-s-sm
                                inline select-niveis {{nivel._selected>
                                0 ?
                                'input-border-success':'input-border-error'
                                }}" [(ngModel)]="nivel._selected" (change)="OnChangeSearchNivel(nivel)" *ngIf="nivel.selecao ==
                                _enumComponetesSelecao.select">
                                <option value="undefined" selected>Selecione
                                    {{nivel.nome}}</option>
                                <option *ngFor="let sub of nivel.nivel"
                                    [hidden]="!(sub._temp_id_vinculo>
                                    0)"
                                    value="{{sub.id}}">{{sub.descricao}}</option>
                            </select>
                        </div>
                    </div>
                    <!-- | filter : '_ativo' : true : true"
                    'input-border-error':'input-border-success'
                <div class="col-md-2 ml-auto">
                    <button>Salvar Insumo</button>
                </div>
            -->
                </div>
                <div class="table-responsive min-height-grid-insumo modal-max
                    div-table-fixed">
                    <table class="table table-striped table-hover
                        table-bordered">
                        <thead class="thead-dark thead-fixed">
                            <tr>
                                <th class="text-center th-checked">#</th>
                                <th class="th-nome-produto">Nome do Insumo</th>
                                <th class="text-center th-categoria">Categoria</th>
                            </tr>
                        </thead>
                        <tbody class="  " [hidden]="_carregando">
                            <tr *ngFor="let produto of
                                _suprimento.insumos | filter
                                : 'ha_niveis' : 0 : true">
                                <td class="text-center">
                                    <div class="btn-group">
                                        <label class="btn btn-check-table
                                            btn-default {{produto._selecionado ?
                                            'active': ''}}" (click)="ClickBondModal(produto)">
                                            <span class="glyphicon-check-mark fa fa-check"></span>
                                        </label>
                                    </div>
                                </td>
                                <td class="td-nome-produto">{{produto.nome}}</td>
                                <td class="text-center th-categoria">{{produto._categoria}}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div *ngIf="_ha_produto && !_categoria_nivel" class="mensagem-produto
                          ">
                        Nenhum produto encontrado, para pesquisa selecionada. Para solicitar Produtos não cadastrados envie e-mail para:<b> sac@paineldaengenharia.com.br</b>
                        <!--
                        <a href [routerLink]="['/suprimento/solicitacao']">
                            Solicitar cadastro de um novo insumo!</a> 
                        -->
                    </div>

                    <div *ngIf="_ha_produto && _categoria_nivel" class="mensagem-produto
                          ">
                        <span class="text-danger">Selecione todas as subcategorias para buscar,</span> caso não encontre o produto desejado. Você pode
                        <a href [routerLink]="['/suprimento/solicitacao']">
                            Solicitar cadastro de um novo produto ou
                            item de
                            categoria!</a>
                    </div>

                    <div [hidden]="!_carregando" class="carregando-produtos
                          ">
                        <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                        </div>
                        carregando...
                    </div>
                </div>


                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                    <button type="button" class="btn btn-primary   " data-dismiss="modal" *ngIf="!_save_active" (click)="ConcluirAdicao()">Adicionar Produtos</button>
                </div>

            </div>
        </div>
    </div>
</div>