import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from 'src/app/core/global-variable';
import { VinculoNivel } from 'src/app/domain/suprimento/vinculo-nivel';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class VinculoNivelService {
  
  private url = GlobalVariable.BASE_API_URL + "supplies/level/link/";
  constructor(private http: HttpClient) { }

  GetAll() {
    return this.http.get<VinculoNivel[]>(this.url + "getall").pipe(
      map(res => {
        return res;
      })
    );
  }

  GetAllNivel(nivel_id: number, nivel_item_id: number, niveis: string = "") {
    return this.http.get<VinculoNivel[]>(this.url + "getallnivel?nivel_id="+nivel_id+"&nivel_item_id="+nivel_item_id+"&niveis="+niveis).pipe(
      map(res => {
        return res;
      })
    );
  }

  Get(id: number) {
    return this.http.get<VinculoNivel>(this.url + "get?id="+id).pipe(
      map(res => {
        return res;
      })
    );
  }
  
  Create(vinculoNivel: VinculoNivel): Observable<any>{
    return this.http.post<any[]>(this.url+"create", vinculoNivel).pipe(
      map(res=> { return res; })
    );
  }
  
  Edit(vinculoNivel: VinculoNivel): Observable<any>{
    return this.http.put<any[]>(this.url+"edit", vinculoNivel).pipe(
      map(res=> { return res; })
    );
  }
  
  SetDelete(nivel_id: number, nivel_item_id: number, filho_nivel_id: number, filho_nivel_item_id: number): Observable<any> {    
    return this.http.delete(this.url+"delete?nivel_id="+nivel_id+"&nivel_item_id="+nivel_item_id+"&filho_nivel_id="+filho_nivel_id+"&filho_nivel_item_id="+filho_nivel_item_id).pipe(
      map(res=> { return res; })
    );
  }
  
  SetDeleteAll(nome: string): Observable<any> {    
    return this.http.delete(this.url+"deleteAll?nome="+nome).pipe(
      map(res=> { return res; })
    );
  }
}
