import { TypeUser } from '../domain/usuario/Usuario';
import { UserLoggedInService } from '../service/authenticate/user-logged-in.service';
import { EnumOperacoesFuncao } from '../domain/configuracao/funcao-sistema';

export class AuthRouter{

  public static url: string
  
  public static CheckRoutePermission(url: string, typeUser: TypeUser): boolean{
    
    this.url = url

    if(this.AccessAll(url))
      return true;

    if(typeUser == TypeUser.provider && !UserLoggedInService.TemPermissao(url, EnumOperacoesFuncao.Visualizar)){
      return false;
    }

    var result = true;
    this.GetJson().forEach(e => {
      var rota = e as RouterUser;
      if(parseInt(url.split('/').pop())){
        rota.route +="/"+url.split('/').pop();
      }
      if(rota.route == url){        
        result = false;
        rota.access.forEach(r=>{
          if(r == typeUser)
            result = true;
        });        
      }
    });
    return result;
  }

  private static AccessAll(url: string) {    
    var result = false;
    this.GetJson().forEach(e => {
      var rota = e as RouterUser;
      if(parseInt(url.split('/').pop())){
        rota.route +="/"+url.split('/').pop();
      }
      if(rota.route == url){        
        result = false;
        rota.access.forEach(r=>{
          if(r == TypeUser.all)
            result = true;
        });        
      }
    });
    return result;
  }
  
  private static GetJson() : any{    
    var routers = [
      //all
      { route: '/not-permission', access: [TypeUser.all]},
      { route: '/login', access: [TypeUser.all]},
      { route: '/login-site', access: [TypeUser.all]},
      { route: '/login/email/', access: [TypeUser.all]},
      { route: '/cadastre-se', access: [TypeUser.all]},
      { route: '/home/not-found', access: [TypeUser.all]},
      { route: '/home/not-permission', access: [TypeUser.all]},
      

      //configurações
      { route: '/configuracao', access: [TypeUser.administrator, TypeUser.provider]},
      { route: '/configuracao-user', access: [TypeUser.administrator, TypeUser.client]},
      { route: '/configuracao-adm', access: [TypeUser.administrator] },
      { route: '/configuracao-adm/admin/categoria-nivel', access: [TypeUser.administrator] },
      { route: '/configuracao-adm/admin/vincula-niveis', access: [TypeUser.administrator] },
      { route: '/configuracao-adm/admin/categoria-virtual', access: [TypeUser.administrator] },

      //suprimentos
      { route: '/suprimento', access: [TypeUser.administrator, TypeUser.provider] },
      { route: '/suprimento/gerenciar-solicitacoes', access: [TypeUser.administrator] },
      { route: '/suprimento/solicitacao', access: [TypeUser.administrator, TypeUser.provider] },
      { route: '/suprimento/insumo', access: [TypeUser.administrator, TypeUser.provider] },

      //cotação
      { route: '/cotacao', access: [TypeUser.administrator,  TypeUser.client] },
      { route: '/cotacao/nova-cotacao', access: [TypeUser.administrator,  TypeUser.client] },
      { route: '/cotacao/historico', access: [TypeUser.administrator,  TypeUser.client] },
      { route: '/cotacao/edit-cotacao', access: [TypeUser.administrator,  TypeUser.client] },
      { route: '/cotacao/view', access: [TypeUser.administrator,  TypeUser.client] },
      { route: '/cotacao/produto', access: [TypeUser.administrator, TypeUser.client] },
      { route: '/cotacao/abertas', access: [TypeUser.administrator,  TypeUser.provider] },
      { route: '/cotacao/historico-fornecedor', access: [TypeUser.administrator, TypeUser.provider] },

      //orcamento
      { route: '/orcamentos', access: [TypeUser.administrator,  TypeUser.client] },
      { route: '/orcamentos/cotacao-list', access: [TypeUser.administrator,  TypeUser.client] },
      { route: '/orcamentos/cotacao-view', access: [TypeUser.administrator,  TypeUser.client] },
      { route: '/orcamentos/comprador-view', access: [TypeUser.administrator,  TypeUser.client] },
      { route: '/orcamentos/enviados-manual', access: [TypeUser.administrator,  TypeUser.provider] },
      { route: '/orcamentos/enviados-automatico', access: [TypeUser.administrator,  TypeUser.provider] },
      { route: '/orcamentos/configuracoes', access: [TypeUser.administrator,  TypeUser.provider] },
      { route: '/orcamentos/edit', access: [TypeUser.administrator,  TypeUser.provider] },
      { route: '/orcamentos/edit/email', access: [TypeUser.administrator,  TypeUser.unregisteredProvider] },
      { route: '/orcamentos/view', access: [TypeUser.administrator,  TypeUser.provider] },
      { route: '/orcamentos/fornecedor/historico', access: [TypeUser.administrator,  TypeUser.provider] },

      //pagina Compras
      { route: '/compras', access: [TypeUser.administrator, TypeUser.client] },
      { route: '/compras/analise', access: [TypeUser.administrator, TypeUser.client] },
      { route: '/compras/realizadas', access: [TypeUser.administrator, TypeUser.client] },
      { route: '/compras/view', access: [TypeUser.administrator, TypeUser.client] },

      //pagina Vendas
      { route: '/vendas', access: [TypeUser.administrator, TypeUser.provider] },
      { route: '/vendas/analise', access: [TypeUser.administrator, TypeUser.provider] },
      { route: '/vendas/realizadas', access: [TypeUser.administrator, TypeUser.provider] },
      { route: '/vendas/recusadas', access: [TypeUser.administrator, TypeUser.provider] },
      { route: '/vendas/view', access: [TypeUser.administrator, TypeUser.provider] },

      //pagina entregas
      { route: '/entregas', access: [TypeUser.administrator, TypeUser.provider] },
      { route: '/entregas/realizadas', access: [TypeUser.administrator, TypeUser.provider] },
      { route: '/entregas/pendentes', access: [TypeUser.administrator, TypeUser.provider] },
      { route: '/entregas/show', access: [TypeUser.administrator, TypeUser.client] },

      //pagina inicial
      { route: '/home', access: [TypeUser.administrator, TypeUser.provider, TypeUser.client] },

      //usuario
      { route: '/user', access: [TypeUser.administrator, TypeUser.provider ] },
      { route: '/user/gerenciar-usuario', access: [TypeUser.administrator, TypeUser.provider, TypeUser.client ] },
      { route: '/user/gerenciar-acesso', access: [TypeUser.administrator ] },
      { route: '/user/minha-conta', access: [TypeUser.all ] },
      { route: '/cadastre-se', access: [TypeUser.unregisteredProvider ] }
    ];
    return routers;
  }
}

export class RouterUser{
  public route: string;
  public access: Array<TypeUser>;
}