import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Authenticate } from 'src/app/domain/authenticate';
import { GlobalVariable } from 'src/app/core/global-variable';
import { UserLoggedInService } from './user-logged-in.service';
import { Router } from '@angular/router';
import { TypeUser } from 'src/app/domain/usuario/Usuario';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private _url = GlobalVariable.BASE_API_URL + "login/authenticate";
  private _currentUser: Authenticate;
  public _typeUser: TypeUser;

  constructor(private http: HttpClient, private userLoggedInService: UserLoggedInService, private router: Router) {
    this.SetCurrentUser();
  }

  login(username: string, password: string, token = ''): Observable<Authenticate> {
    return this.http.post<Authenticate>(this._url, { login: username, password: password, token: token })
      .pipe(
        map(data => {
          if (data && data.token)
            localStorage.setItem('currentUser', JSON.stringify(data));
          return data as Authenticate;
        })
      );
  }

  loginSite(token: string): Observable<Authenticate> {
    return this.http.get<Authenticate>(GlobalVariable.BASE_API_URL + "login/ActiveAuthenticateSite?token=" + token)
      .pipe(
        map(data => {
          if (data && data.token)
            localStorage.setItem('currentUser', JSON.stringify(data));
          return data as Authenticate;
        })
      );
  }

  loginEmail(token: string): Observable<Authenticate> {
    return this.http.get<Authenticate>(GlobalVariable.BASE_API_URL + "login/ActiveAuthenticateEmail?token=" + token)
      .pipe(
        map(data => {
          if (data && data.token)
            localStorage.setItem('currentUser', JSON.stringify(data));
          return data as Authenticate;
        })
      );
  }

  loginToken(token: string): Observable<Authenticate> {
    return this.http.get<Authenticate>(GlobalVariable.BASE_API_URL + "login/ActiveAuthenticateToken?token=" + token)
      .pipe(
        map(data => {
          if (data && data.token)
            localStorage.setItem('currentUser', JSON.stringify(data));
          return data as Authenticate;
        })
      );
  }

  loginTokenCompra(token: string): Observable<Authenticate> {
    return this.http.get<Authenticate>(GlobalVariable.BASE_API_URL + "login/ActiveAuthenticateTokenCompra?token=" + token)
      .pipe(
        map(data => {
          if (data && data.token)
            localStorage.setItem('currentUser', JSON.stringify(data));
          return data as Authenticate;
        })
      );
  }

  RenewLogin(): Observable<Authenticate> {
    return this.http.get<Authenticate>(this._url)
      .pipe(
        map(data => {
          if (data && data.token)
            localStorage.setItem('currentUser', JSON.stringify(data));
          return data as Authenticate;
        })
      );
  }

  logout(): void {
    this._currentUser = null;
    localStorage.removeItem('currentUser');
    if (!GlobalVariable.BASE_LOGIN)
      window.location.href = "https://paineldaengenharia.com.br/login";
    else
      this.router.navigate(['/login']);
  }

  logoutEmail(): void {
    this._currentUser = null;
    localStorage.removeItem('currentUser');
  }

  isLoggedIn(): boolean {
    this.SetCurrentUser();
    if (this._currentUser && this._currentUser.token && this._currentUser.user && this._currentUser.user.id > 0) {
      this._typeUser = this._currentUser.user.type;
      return this.CheckTempLogin();
    } else
      return false;
  }

  SetCurrentUser(): void {
    try {
      this._currentUser = JSON.parse(localStorage.getItem('currentUser')) as Authenticate;
      this.userLoggedInService.SetCurrentUser()      
    } catch (error) {
      this.logoutEmail()
    }
  }

  CheckTempLogin(): boolean {
    var expires = new Date(this._currentUser.expires.trim().replace(/ /g,"T"));
    var hora_atual = new Date();
    if (expires > hora_atual) {
      hora_atual.setMinutes(hora_atual.getMinutes() + GlobalVariable.TIME_RENEW_ACCESS);
      setTimeout(() => {
        if (hora_atual > expires)
          this.RenewLogin().subscribe();
      }, 10);
      return true;
    } else
      return false;
  }

  GetUserId(): number {
    return this._currentUser.user.id;
  }
}
