import { ProdutoComprador } from '../suprimento/comprador/produto-comprador';
import { Orcamento } from './orcamento';

export class OrcamentoProduto {
  public constructor() {
    this.preco = "0";
    this.ipi = 0;
    this.icms = 0;
  }
  public id: number;
  public orcamento_id: number;
  public produto_comprador_id: number;
  public icms: number;
  public ipi: number;
  public preco: string;
  public preco_registrado: string;
  public preco_sugerido: string;
  public estoque: number;
  public marca_id: number;

  _marca: string;
  _preco_string: string;
  _ipi: number;
  _icms: number;
  _ipi_string: string;
  _icms_string: string;
  _preco: string;
  _preco_ipi: string;
  _preco_total: string;
  _preco_registrado: string;
  _preco_sugerido: string;

  static SetCalculos(model: OrcamentoProduto, quantidade: number, orcamento: Orcamento) {
    if (parseFloat(model.preco) <= 0 && parseFloat(model.preco_registrado) > 0) {
      model.preco = (parseFloat(model.preco_registrado) - (parseFloat(orcamento.desconto_linear) / 100) * parseFloat(model.preco_registrado)).toString();
      model._preco = model.preco;
    }
    model._preco_total = (parseFloat(model.preco) * quantidade).toLocaleString('pt-br', { minimumFractionDigits: 2 });
    if (!orcamento.ha_ipi && model.ipi > 0 && model._preco) 
      model._preco_ipi = ((model.ipi / 100) * (parseFloat(model.preco) * quantidade)).toLocaleString('pt-br', { minimumFractionDigits: 2 });
    else
      model._preco_ipi = '0,00';
  }

  static SetConfig(model: OrcamentoProduto, listProdutoComprador: ProdutoComprador[], orcamento: Orcamento) {
    if (!listProdutoComprador)
      return;
    function filtrar(obj) {
      return (obj.id == model.produto_comprador_id);
    }

    var data = listProdutoComprador.filter(filtrar);
    if (data && data.length > 0) {
      OrcamentoProduto.SetCalculos(model, data[0].quantidade, orcamento);
    }
  }
}


export enum EnumEstoqueProdutoOrcamento
{
    possui = 0,
    naoPossui = 1
}