export class VinculoNivel {

    public id: number;
    public nivel_id: number;
    public nivel_item_id: number;
    public filho_nivel_id: number;
    public filho_nivel_item_id: number;

    Valida(): boolean {
        return  (this.nivel_id > 0) && 
                (this.nivel_item_id > 0) &&
                (this.filho_nivel_id > 0) &&
                (this.filho_nivel_item_id > 0);
    }
}