
import { Component, OnInit, ComponentFactoryResolver, Input } from '@angular/core';
import { OrcamentoViewCtrl } from '../orcamento-ctrl-view';

@Component({
  selector: 'app-orcamento-comprador-view',
  templateUrl: './orcamento-comprador-view.component.html',
  styleUrls: ['./orcamento-comprador-view.component.css']
})
export class OrcamentoCompradorViewComponent extends OrcamentoViewCtrl implements OnInit {

  ngOnInit() {
    this.GetConfiguracoes();
    this.GetOrcamento(this.orcamento_id);
    this.getEstadoValidacaoOrcamento();
  }  
  ReceiverEntregaProduto(e){}
  
}