import { UseFullService } from 'src/app/service/usefull/usefull.service';

export class EntregaParcialComprador
{
    constructor(){
        this.quantidade = 0;
    }
    public id: number;
    public produto_comprador_id: number;
    public quantidade: number;
    public data_entrega: Date;
    public _data_entrega: string;
    public _index: number;
    public _quantidade: string;
    
    public static SetFormatDate(model: EntregaParcialComprador){
        model._data_entrega = UseFullService.formatDate(new Date(model.data_entrega));
    }
}