import { InteractiveTable } from '../common/interactiveTable'

export class EmailValidate extends InteractiveTable {
    public id: number
    public origem: number
    public origem_id: number
    public fonte_id: number
    public item_id: number
    public status: number
    public codigo: string
    public email: string
    public observacao: string
    public data: Date
    public log: string
    public _tipo: number
    public _observacao: string

    public static getStatus(status: number) {
        return EnumStatusEmailValidade[status]
    }
}

export class ProcessoEmailOrcmento extends EmailValidate {
    public nome_fantasia: string
    public razao_social: string
    public nome_responsavel: string
    public telefone: string
}

export enum EnumStatusEmailValidade {
    Pendente = 0,
    Aprovado = 1,
    Reprovado = 2
}