import { Injectable } from '@angular/core';
import { ProdutoCategoriaNivel } from 'src/app/domain/suprimento/produto-categoria-nivel';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class InsumoCTRL {

  constructor() { }
  
  LimparNiveisApartir(niveis: Array<ProdutoCategoriaNivel>, nivel: ProdutoCategoriaNivel) {
    niveis.forEach(e => {
      if (e.order > nivel.order) {
        e.nivel.forEach(c => {
          c._temp_id_vinculo = 0;
          c._selecionado = false;
        });
        e._selected = undefined;
        e._ativo = false;
      }
    });
  }
}