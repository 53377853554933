import { Component, OnInit } from '@angular/core';
import { ProdutoService } from 'src/app/service/suprimento/produto.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { ObraService } from 'src/app/service/obra/obra.service';
import { Obra } from 'src/app/domain/obra/obra';
import { RepositoryService } from 'src/app/service/repository.service';
import { ObraCotacao } from 'src/app/domain/gestao/obra-cotacao';
import { Router } from '@angular/router';

@Component({
  selector: 'app-relacao-obra-list',
  templateUrl: './relacao-obra-list.component.html',
  styleUrls: ['./relacao-obra-produto-marca-list.component.css'],
  providers: [ProdutoService]
})

export class RelacaoObraListComponent implements OnInit {
  _path = ''
  _carregando = false
  obras = new Array<ObraCotacao>();
  constructor(
    private userLoggedIn: UserLoggedInService,
    private repository: RepositoryService,
    private obraService: ObraService,
    private confirm: AlertConfirmService,
    private userLoggedService: UserLoggedInService,
    private router: Router) {
    this.userLoggedService.SetCurrentUser();
  }

  ngOnInit() {
    this.userLoggedIn.AllowedOperations();
    this.confirm.UnsetObservers();
    this.GetAll();
  }
  /*
    getAllObra() {
      this.obraService.GetAllWork().subscribe(
        d => { this.obras = d; console.log(d); }
      );
    }
  */

  GetAll() {
    this.repository.path = "obra-cotacao/all"
    this.repository.getAll().subscribe(
      d => {
        if (d && d.length > 0)
          this.obras = d;
      },
      e => { },
      () => {
        let x = 1;
        this.obras.forEach(c => { c._index = x++; });
      }
    );
  }

  ver(obraCotacao: ObraCotacao) {
    console.log(obraCotacao)
    this.router.navigateByUrl("/auxiliar/relacao/produto/obra/form/" + obraCotacao.id);
  }
}