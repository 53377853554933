import { Component, OnInit, ComponentFactoryResolver, EventEmitter, Output, Input } from '@angular/core';
import { InsumoDoFornecedor } from 'src/app/domain/suprimento/produto-fornecedor';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { ProdutoCategoriaNivel, TipoSelecao } from 'src/app/domain/suprimento/produto-categoria-nivel';
import { ProdutoService } from 'src/app/service/suprimento/produto.service';
import { ProdutoComprador } from 'src/app/domain/suprimento/comprador/produto-comprador';
import { InsumoComponentCtrl } from '../../insumo/insumo-component-ctrl';
import { RepositoryService } from 'src/app/service/repository.service';
import { CompradorCategoriaProduto, RelacaoObraProduto } from 'src/app/domain/suprimento/marca';
import { ProdutoUnidadeMedida } from 'src/app/domain/suprimento/produto-unidade-medida';

declare var $: any;

@Component({
  selector: 'app-grid-produto-obra-component',
  templateUrl: './grid-produto-obra.component.html',
  styleUrls: ['./grid-produto-obra.component.css'],
  providers: [ProdutoService]
})
export class GridProdutoObraComponent implements OnInit {

  @Output() _produtos_adicionados: EventEmitter<any> = new EventEmitter();
  @Input() _input_cotacao;
  @Input() _buscar_produto;
  @Input() _index;
  @Input() _obra_id;
  _list_categoria = new Array<CompradorCategoriaProduto>()
  active = false

  constructor(private context: RepositoryService, private httpProduto: ProdutoService) {

  }


  ngOnInit() {
    var _this = this
    setTimeout(() => {
      $('#_search_').focus();
      $('#_search_').on("input", function () {
        _this._buscar = _this._buscar_produto
        _this.active = true
        _this.SetListaUnidadeMedida();
      })
    }, 100)
  }

  _unidades_medida = new Array<ProdutoUnidadeMedida>();
  SetListaUnidadeMedida() {
    if (this._unidades_medida && this._unidades_medida.length > 0) {
      this.OnChangeSearch()
      return;
    }
    this.httpProduto.GetAllUnitsMeasured().subscribe(
      data => { this._unidades_medida = data; this.OnChangeSearch(); },
      error => { },
      () => {  }
    );
  }

  _categoriaId = 0
  _buscar = ''
  getAll() {
    this.context.path = "supplies/relacao-produto/getall/grid";
    this.context.getAll('&obraId=' + this._obra_id + '&like=' + this._buscar + '&categoriaId=' + this._categoriaId).subscribe(
      d => { this._list_categoria = d; }, e => { },
      () => { this.formatList() }
    )
  }

  tempList = null
  formatList() {
    if (!this.tempList)
      this.tempList = this._list_categoria;
    for (let i = 0; i < this._list_categoria.length; i++) {
      this._list_categoria[i]._index = i + 1
      let x = 1
      if (this._list_categoria[i]._listRelacaoObraProduto && this._list_categoria[i]._listRelacaoObraProduto.length > 0)
        this._list_categoria[i]._listRelacaoObraProduto.forEach(r => {
          r._index = x++; RelacaoObraProduto.format(r);
          r._unidade = ProdutoUnidadeMedida.Get(this._unidades_medida, r.unidade_id).nome;
        })
    }
  }

  OnChangeSearch() {
    this.getAll()
  }

  OnChangeSearchNivel(nivel: ProdutoCategoriaNivel) {
    throw new Error("Method not implemented.");
  }

  cancelar() {
    const produto = new ProdutoComprador()
    produto._index = this._index
    this.active = false
    this._produtos_adicionados.emit(produto);
  }

  rowClick(categoria, model) {
    this._produtos_adicionados.emit(model);
  }
}