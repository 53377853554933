import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/domain/usuario/Usuario';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';

@Component({
  selector: 'app-gerenciar-view-usuarios',
  templateUrl: './gerenciar-view-usuarios.component.html',
  styleUrls: ['./gerenciar-usuarios.component.css']
})
export class GerenciarViewUsuariosComponent implements OnInit {

  public code: "string";
  public _usuario: Usuario;
  _observacao = ""

  constructor(private confirmService: AlertConfirmService) {
      this.confirmService.UnsetObservers();
      //this.confirmService.confirmEmitter$.subscribe(c => this.Excluir(c))
  }

  ngOnInit() {
    //this.GetAll();
  }
/*
  GetAll() {
    this.prazoPagamentoService.GetAll().subscribe(
      d => { this._list_prazo_pagamento = d; },
      e => { },
      () => {
        this.Cancelar();
      }
    );
  }

  Adicionar() {
    this._prazo_pagamento.ativo = 1;
    this.prazoPagamentoService.Create(this._prazo_pagamento).subscribe(
      d => { },
      e => { },
      () => {
        this.GetAll();
      }
    );
  }

  Cancelar() {
    this._prazo_pagamento = new PrazoPagamento();
  }

  ActionEditar(data: PrazoPagamento) {
    this._prazo_pagamento = data;
  }

  Editar() {
    this.prazoPagamentoService.Edit(this._prazo_pagamento).subscribe(
      d => { },
      e => { },
      () => {
        this.GetAll();
      }
    );
  }

  ConfirmExcluir(data: PrazoPagamento) {
    this._prazo_pagamento = data;
    this.confirmService._code = this.code;
    this.confirmService._title = "Excluir Enum Prazo de Pagamento";
    this.confirmService._message = "Deseja a opção de prazo para pagamento " + data.descricao + "?";
    this.confirmService.Show();
  }

  Excluir(confirm: boolean = false) {
    if (this.confirmService._code != this.code || confirm == false)
      return;
    this.prazoPagamentoService.Delete(this._prazo_pagamento.id).subscribe(
      d => { this.GetAll(); },
      e => { },
      () => { }
    );
  }

  */
}

