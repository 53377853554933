import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from 'src/app/core/global-variable';
import { Orcamento, ListOrcamento } from 'src/app/domain/orcamento/orcamento';
import { Cotacao } from 'src/app/domain/cotacao/cotacao';
import { EnumStatusTransacao, ListCompraVenda, CompraVenda } from 'src/app/domain/transacao/compra-venda';

@Injectable({
  providedIn: 'root'
})
export class CompraVendaService {
      
  private url = GlobalVariable.BASE_API_URL + "transacao/compravenda/";
  constructor(private http: HttpClient) { }

  GetAllCnpjStatus(status: EnumStatusTransacao): Observable<Array<ListCompraVenda>> {
    return this.http.get<Array<ListCompraVenda>>(this.url + "getcnpjstatus?status="+status).pipe(
      map(res => {
        return res;
      })
    );
  }

  GetAllUserStatus(status: EnumStatusTransacao): Observable<Array<ListCompraVenda>>  {
    return this.http.get<ListCompraVenda[]>(this.url + "getuserstatus?status="+status).pipe(
      map(res => {
        return res;
      })
    );
  }

  Get(id: number) {
    return this.http.get<CompraVenda>(this.url + "get?id="+id).pipe(
      map(res => {
        return res;
      })
    );
  }
  GetByQuote(id: number) {
    return this.http.get<Orcamento>(this.url + "getbyquote?cotacao_id="+id).pipe(
      map(res => {
        return res;
      })
    );
  }
  GetByQuoteComprador(id: number) {
    return this.http.get<ListOrcamento[]>(this.url + "getallorcamentocotacao?cotacao_id="+id).pipe(
      map(res => {
        return res;
      })
    );
  }
  Create(data: Cotacao): Observable<any>{
    return this.http.post<any[]>(this.url+"create", data).pipe(
      map(res=> { return res; })
    );
  }
  
  Edit(data: CompraVenda): Observable<any>{
    return this.http.put<any[]>(this.url+"edit", data).pipe(
      map(res=> { return res; })
    );
  }
  
  Delete(id: number): Observable<any> {    
    return this.http.delete(this.url+"delete?id="+id).pipe(
      map(res=> { return res; })
    );
  }

  GetOrcamentosFinalizados(){    
    return this.http.get<ListOrcamento[]>(this.url + "getallorcamentocnpj").pipe(
      map(res => {
        return res;
      })
    );
  }
  GetOrcamentosStatus(status: number){    
    return this.http.get<ListOrcamento[]>(this.url + "getall/status?status="+status).pipe(
      map(res => {
        return res;
      })
    );
  }
}
