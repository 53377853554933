import { Component, OnInit } from '@angular/core';
import { PerfilUsuarioService } from 'src/app/service/configuracao/perfil-usuario.service';
import { UserComponent } from '../../user/user.component';
import { Usuario } from 'src/app/domain/usuario/Usuario';
import { FuncaoSistema, OperacoesFuncao, RestricaoUsuarioPerfilFuncao } from 'src/app/domain/configuracao/funcao-sistema';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';

@Component({
  selector: 'app-permissao-usuario',
  templateUrl: './permissao-usuario.component.html',
  styleUrls: ['./permissao-usuario.component.css']
})
export class PermissaoUsuarioComponent implements OnInit {

  _code = "app-permissao-usuario";
  public _funcao_sistema: FuncaoSistema;
  public _funcoes_sistema: Array<FuncaoSistema>;
  public _operacaoes: Array<OperacoesFuncao>;
  public _usuario: Usuario;

  constructor(private perfilUsuarioService: PerfilUsuarioService, private confirmService: AlertConfirmService) {
    this.confirmService.UnsetObservers();
    this.confirmService.confirmEmitter$.subscribe(c => this.RestaurarPadrao(c));
    this.perfilUsuarioService.eventEmitter$.subscribe(c => this.CarregarData(c));

    this._funcao_sistema = new FuncaoSistema();
    this._funcoes_sistema = new Array<FuncaoSistema>();
    this._operacaoes = new Array<OperacoesFuncao>();

    this._usuario = new Usuario();
  }

  ngOnInit() {

  }

  GetAll() {

  }

  CarregarData(usuario: Usuario) {
    this._usuario = usuario;
    this.perfilUsuarioService.GetAllFuncoesPerfil(usuario.tipo_usuario, usuario.id).subscribe(
      d => { this._funcoes_sistema = d; },
      e => { },
      () => { this.OrganizarOperacoes(); }
    );
  }

  OrganizarOperacoes() {
    this._funcoes_sistema.forEach(c => { FuncaoSistema.SetOperacoes(c); });
  }

  ChangeOperacao(e, data: FuncaoSistema, operacao_id: number) {
    var restricao = new RestricaoUsuarioPerfilFuncao();
    if (e.srcElement.checked) {
      if (data._restricao)
        restricao.operacao = data._restricao;
      else
        return;

      restricao.usuario_id = this._usuario.id;
      restricao.funcao_id = data.id;
      RestricaoUsuarioPerfilFuncao.RemoveOperacao(restricao, operacao_id);
      data._restricao = restricao.operacao;
      this.EditarRestricaoUsuarioPerfilFuncao(restricao);
    } else {
      if (data._restricao)
        restricao.operacao = data._restricao;
      RestricaoUsuarioPerfilFuncao.AddOperacao(restricao, operacao_id);
      restricao.usuario_id = this._usuario.id;
      restricao.funcao_id = data.id;
      data._restricao = restricao.operacao;
      this.EditarRestricaoUsuarioPerfilFuncao(restricao);
    }

  }

  EditarRestricaoUsuarioPerfilFuncao(restricao: RestricaoUsuarioPerfilFuncao) {
    this.perfilUsuarioService.EditRestricaoUsuarioPerfilFuncao(restricao).subscribe(
      d => { },
      e => { },
      () => { this.OrganizarOperacoes(); });
  }

  RestaurarPadrao(confirm: boolean): void {
    if (this.confirmService._code != this._code)
      return;
    this.perfilUsuarioService.RestaurarPadrao(this._usuario.id).subscribe(
      d => { },
      e => { },
      () => { this.CarregarData(this._usuario); }
    );
  }

  clickRestaurarPadrao(): void {
    this.confirmService._code = this._code;
    this.confirmService._title = "Restaurar Permissões de Usuário";
    this.confirmService._message = "Deseja restaurar as permissões do(a) usuário " + this._usuario.nome + "?";
    this.confirmService.Show();
  }
}
