<div class="modal fade   " id="global-confirm-secundario-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="min-height:inherit;" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <b class="modal-title" [innerHTML]="_titulo_confirm"></b>
                <button type="button" class="close" (click)="ConfirmEmit(false)" aria-label=" Close ">
                    <span aria-hidden="true ">&times;</span>
                </button>
            </div>


            <div class="modal-body modal-confirm " style="padding: 1rem; " [innerHTML]="_descricao_confirm "></div>
            <div class="modal-footer ">
                <button type="button " class="btn btn-secondary " (click)="ConfirmEmit(false) ">Não</button>
                <button type="button " class="btn btn-primary " (click)="ConfirmEmit(true) ">Sim</button>
            </div>
        </div>
    </div>
</div>