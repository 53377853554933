import { Usuario } from '../usuario/Usuario';
import { ProdutoCategoria } from './produto-categoria';

export class CategoriaVirtual
{
    public id: number;
    public nome: string;
    public filho_id: number;
    public _selecionado: number;
    public categorias: Array<ProdutoCategoria>;
}