import { Component, OnInit } from '@angular/core';
import { PrazoPagamento } from 'src/app/domain/auxiliar/prazo-pagamento';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { ProdutoCategoria, TypeEnumGeral } from 'src/app/domain/suprimento/produto-categoria';
import { RepositoryService } from 'src/app/service/repository.service';

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.css']
})
export class CategoriaComponent implements OnInit {

  code = 'app-categoria';
  _categoria = new ProdutoCategoria();
  _list_categoria = new Array<ProdutoCategoria>();

  constructor(private repository: RepositoryService, private confirmService: AlertConfirmService) {
      this.confirmService.UnsetObservers();
      this.confirmService.confirmEmitter$.subscribe(c => this.Excluir(c));
      this.Cancelar();
  }

  ngOnInit() {
    this.GetAll();
  }

  GetAll() {
    this.repository.path = 'enumgeral/getall';
    this.repository.getAll('_&tipo='+TypeEnumGeral.categoria).subscribe(
      d => { this._list_categoria = d; },
      e => { },
      () => {
        this.Cancelar();
        console.log(this._list_categoria)
      }
    );
  }

  Adicionar() {
    this._categoria.ativo = 1;
    this.repository.path = 'enumgeral/save';
    this._categoria.tipo = TypeEnumGeral.categoria;
    this.repository.post(this._categoria).subscribe(
      d => { },
      e => { },
      () => {
        this.GetAll();
      }
    );
  }

  Cancelar() {
    this._categoria = new ProdutoCategoria();
  }

  ActionEditar(data: ProdutoCategoria) {
    this._categoria = data;
  }

  Editar() {
    this.Adicionar();
  }

  ConfirmExcluir(data: ProdutoCategoria) {
    this._categoria = data;
    this.confirmService._code = this.code;
    this.confirmService._title = "Excluir Enum Categoria";
    this.confirmService._message = "Deseja excluir a categoria " + data.descricao + "?";
    this.confirmService.Show();
  }

  Excluir(confirm: boolean = false) {
    if (!(this.confirmService._code != this.code || confirm == false)){      
      this.repository.path = 'enumgeral/delete';
      this.repository.post(this._categoria).subscribe( d => { this.GetAll(); });
    }
  }
}
