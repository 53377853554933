<div class="col-lg-12 wrapper-top-content">
    <div class="ibox">
        <div class="ibox-title" style="padding-right:20px;">
            <h5>Orçamentos</h5>
            <div style="float: right;">
                <input placeholder="Pesquisar" [(ngModel)]="_buscar" type="text" class="form-control form-control-sm">
            </div>
        </div>
        <div class="ibox-content">
            <div class="table-responsive min-height-grid-insumo div-table-fixed
                margin-top-15">
                <!-- table-striped table-hover table-bordered -->
                <table class="table table-striped">
                    <thead class="thead-fixed">
                        <tr>
                            <th class="th-sequence text-center" rowspan="2"></th>
                            <th class="th-sequence text-center" rowspan="2">Nome Fantasia
                            </th>
                            <th class="th-sequence text-center" rowspan="2">Razão Social
                            </th>
                            <th class="th-sequence text-center" rowspan="2">Telefone</th>
                            <th class="th-sequence text-center" rowspan="2">Cidade</th>
                            <th class="th-sequence text-center" rowspan="2">Estado</th>
                            <th class="th-sequence text-center" rowspan="2">CNPJ</th>
                            <th class="th-sequence text-center" rowspan="2">Orcamento</th>
                            <th class="th-sequence text-center" rowspan="2">R$ SubTotal
                            </th>
                            <th class="th-sequence text-center" rowspan="2">R$ Total
                            </th>
                            <th class="th-sequence text-center" colspan="4">Usuário</th>
                            <th class="th-sequence text-center" rowspan="2">Email Envio
                            </th>
                        </tr>
                        <tr>
                            <th class="text-center th-data">Data Envio</th>
                            <th class="text-center th-data">Nome</th>
                            <th class="text-center th-data">Telefone</th>
                            <th class="text-center th-data">Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="" *ngFor="let cotacao of _orcamentosCotacao |
                            filter:_buscar">
                            <td class="text-center th-sequence">{{cotacao._index}}</td>
                            <td class="text-center th-sequence">{{cotacao.nome_fantasia}}</td>
                            <td class="text-center th-sequence">{{cotacao.razao_social}}</td>
                            <td class="text-center th-sequence">{{cotacao.telefone}}</td>
                            <td class="text-center th-sequence">{{cotacao.cidade}}</td>
                            <td class="text-center th-sequence">{{cotacao.uf}}</td>
                            <td class="text-center th-sequence">{{cotacao.cnpj}}</td>
                            <td class="text-center th-sequence">{{cotacao.codigo}}</td>
                            <td class="text-center th-sequence">{{cotacao.sub_total}}</td>
                            <td class="text-center th-sequence">{{cotacao.valor_total}}</td>
                            <td class="text-center th-sequence">{{cotacao.data_envio}}</td>
                            <td class="text-center th-sequence">{{cotacao.nome_usuario}}</td>
                            <td class="text-center th-sequence">{{cotacao.telefone_usuario}}</td>
                            <td class="text-center th-sequence">{{cotacao.email_usuario}}</td>
                            <td class="text-center th-sequence">{{cotacao.origem}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>


        </div>
    </div>
</div>


<!-- data-toggle="tooltip" data-placement="top" title=".." -->