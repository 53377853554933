import { Cotacao } from "../cotacao/cotacao";
import { Orcamento } from '../orcamento/orcamento';
import { Entrega } from '../entrega/entrega';
import { UseFullService } from 'src/app/service/usefull/usefull.service';

export class AlertData {
    public orcamentoAbertos: Array<Cotacao>;
    public vendasAbertas: Array<Cotacao>;
    public entregasAbertas: Array<Entrega>;
    public inclusaoUsuario: Array<Orcamento>;

    public _countOrcamentoAbertos: number
    public _countVendasAbertas: number
    public _countEntregasAbertas: number
    public _countInclusaoUsuario: number
    public _countTotal: number

    static SetData(model: AlertData) {
        try {
            if (model.inclusaoUsuario) {
                model._countInclusaoUsuario = model.inclusaoUsuario.length
            }
            if (model.entregasAbertas) {
                model._countEntregasAbertas = model.entregasAbertas.length
                model.entregasAbertas.forEach(e => { e._data_max_entrega = UseFullService.formatDateString(String(e.data_max_entrega)) });
            }
            if (model.vendasAbertas) {
                model._countVendasAbertas = model.vendasAbertas.length
                model.vendasAbertas.forEach(e => { e._data_criacao = UseFullService.formatDateString(String(e.data_criacao)) });
            }
            if (model.orcamentoAbertos) {
                model._countOrcamentoAbertos = model.orcamentoAbertos.length
                model.orcamentoAbertos.forEach(e => { e._data_max_recebimento_orcamento = UseFullService.formatDateString(String(e.data_max_recebimento_orcamento)) });
            }

            model._countTotal = (model._countEntregasAbertas?model._countEntregasAbertas:0) +
                                (model._countInclusaoUsuario?model._countInclusaoUsuario:0) +
                                (model._countOrcamentoAbertos?model._countOrcamentoAbertos:0) +
                                (model._countVendasAbertas?model._countVendasAbertas:0)
        } catch (error) {
            console.log(error)
        }
    }
}