import { Component, OnInit, ComponentFactoryResolver, Input } from '@angular/core';
import { ProdutoCategoria } from 'src/app/domain/suprimento/produto-categoria';
import { ProdutoUnidadeMedida } from 'src/app/domain/suprimento/produto-unidade-medida';
import { ProdutoCategoriaNivel, ComponetesSelecao, TipoSelecao } from 'src/app/domain/suprimento/produto-categoria-nivel';
import { Suprimento } from 'src/app/domain/suprimento/suprimento';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { ProdutoComprador } from 'src/app/domain/suprimento/comprador/produto-comprador';
import { ProdutoCompradorService } from 'src/app/service/suprimento/comprador/produto-comprador.service';
import { GlobalVariable } from 'src/app/core/global-variable';
import { Router, ActivatedRoute } from '@angular/router';
import { CotacaoService } from 'src/app/service/suprimento/comprador/cotacao.service';
import { Cotacao, EnumStatusCotacao } from 'src/app/domain/cotacao/cotacao';
import { EntregaParcialComprador } from 'src/app/domain/suprimento/comprador/entrega-parcial-comprador';
import { ProdutoService } from 'src/app/service/suprimento/produto.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { EmpresaService } from 'src/app/service/configuracao/empresa.service';
import { OrcamentoService } from 'src/app/service/orcamento/orcamento.service';
import { InsumoDoFornecedor } from 'src/app/domain/suprimento/produto-fornecedor';
import { EnderecoService } from 'src/app/service/configuracao/endereco.service';
import { Enderecos } from 'src/app/domain/empresa/Enderecos';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { Empresa } from 'src/app/domain/empresa/empresa';
import { CabecalhoCotacao } from 'src/app/service/commom/cabecalho-cotacao';

declare var $: any;
@Component({
  selector: 'app-cotacao-view',
  templateUrl: './cotacao-view.component.html',
  styleUrls: ['./cotacao-view.component.css']
})
export class CotacaoViewComponent implements OnInit {
  url = GlobalVariable.BASE_API_URL;
  _code = 'app-cotacao-view-code'
  public empresa: Empresa;
  constructor(
    private httpProduto: ProdutoService,
    private confirmService: AlertConfirmService,
    private produtoCompradorService: ProdutoCompradorService,
    private route: ActivatedRoute,
    private router: Router,
    private cotacaoService: CotacaoService,
    private userLoggedService: UserLoggedInService,
    private empresaService: EmpresaService,
    private orcamentoService: OrcamentoService,
    private enderecoService: EnderecoService) {
    this._produto_selecionado = new ProdutoComprador();
    this.confirmService.UnsetObservers();
    this.confirmService.confirmEmitter$.subscribe(c => { this.SolicitarOrcamento(c); });

    this._carregando = false;
    this._ha_produto = false;
    this._buscar = '';
    this._categoria_id = 0;
    this._suprimento = new Suprimento();
    this._suprimento.insumos = new Array<InsumoDoFornecedor>();
    this._input_nivel = new ProdutoCategoriaNivel();
    this._categorias = new Array<ProdutoCategoria>();
  }

  cabecalho = new CabecalhoCotacao(this.empresaService, this.userLoggedService);

  public _cotacao: Cotacao;
  _tempCotacao = new Cotacao();
  _input_cotacao = "";
  _list_produtos = new Array<ProdutoComprador>();
  _produto_selecionado: ProdutoComprador;
  public _input_nivel: ProdutoCategoriaNivel;
  public _suprimento: Suprimento;
  public _categorias: ProdutoCategoria[];
  public _unidade_medida: ProdutoUnidadeMedida[];
  public _ha_produto: boolean;
  public _carregando: boolean;
  count_nivel = 0;
  public _categoria_nivel: boolean;
  public _niveis: Array<ProdutoCategoriaNivel>;
  public _buscar: string;
  public _categoria_id: number;
  public _vinculado: boolean;
  public _loading_save: boolean;
  public _enumComponetesSelecao = ComponetesSelecao;
  cotacao_id = this.route.snapshot.params['id'];

  ngOnInit() {
    this._cotacao = new Cotacao()
    this.userLoggedService.SetCurrentUser()
    this._niveis = new Array<ProdutoCategoriaNivel>()
    this.SetListaUnidadeMedida()
    this.GetCotacao(this.cotacao_id)
  }

  GetCotacao(id: number) {
    this.cotacaoService.Get(id).subscribe(
      d => { this._cotacao = d; },
      e => { },
      () => {
        Cotacao.SetFormat(this._cotacao)
        this.GetEndereco(this._cotacao.endereco_id);
      }
    );
  }

  _endereco = new Enderecos()
  GetEndereco(endereco_id: number) {
    this.enderecoService.Get(endereco_id).subscribe(
      d => { this._endereco = d }
    )
  }

  GetAll(cotacao_id: number) {
    this.produtoCompradorService.GetAll(cotacao_id).subscribe(
      d => { this._list_produtos = d; },
      e => { },
      () => {
        this.CarregarDadosTrunk();
      }
    );
  }

  _ha_anexo = false;
  _boolQuantidade = true
  _boolUnidade = true
  SetConfig() {
    for (let i = 0; i < this._list_produtos.length; i++) {
      this._list_produtos[i]._index = i + 1;
      this._list_produtos[i]._index_impar_par = Number.isInteger((i + 1) / 2);
      this._list_produtos[i]._name_file = this._list_produtos[i].anexo;
      if (this._list_produtos[i].quantidade > 0)
        this._list_produtos[i]._quantidade = this._list_produtos[i].quantidade.toFixed(2).replace(".", ",");
      else
        this._boolQuantidade = false
      if (this._list_produtos[i].unidade_id > 0)
        this._list_produtos[i]._unidade_nome = ProdutoUnidadeMedida.Get(this._unidade_medida, this._list_produtos[i].unidade_id).nome;
      else
        this._boolUnidade = false
      if (this._list_produtos[i].anexo && this._list_produtos[i].anexo != 'null')
        this._ha_anexo = true;
    }
  }

  SetListaUnidadeMedida() {
    this.httpProduto.GetAllUnitsMeasured().subscribe(
      data => { this._unidade_medida = data; },
      error => { },
      () => {
        this.GetAll(this.cotacao_id);
      }
    );
  }

  SetFunctions() { UseFullService.SetMask(); }

  public _nome_arquivo: string;
  public _src_arquivo: string;
  MostrarFile(data: ProdutoComprador) {
    if (data._file) {
      var file = data._file;
      this._nome_arquivo = file.name;
      var _this = this;
      var reader = new FileReader();
      reader.onload = function (e) {
        var target = e.target as any;
        if (file.type == "application/pdf") {
          _this._src_arquivo = undefined;
          setTimeout(() => {
            $("#exibir-pdf").html("<iframe width='100%' height='" + ($('body').height() - 100) + "px' src='" + target.result + "'></iframe>");
          }, 10);
        } else
          _this._src_arquivo = target.result;
      };
      reader.readAsDataURL(file);
    } else
      this.CarregarDataModal(data);
    $('#data-modal').modal('show');
  }

  CarregarDataModal(data: ProdutoComprador) {
    this._nome_arquivo = "";
    this._src_arquivo = "";
    $("#exibir-pdf").html("");
    this._nome_arquivo = data.anexo;
    if (!data.anexo.match(".png") && !data.anexo.match(".jpg") && !data.anexo.match(".gif")) {
      this._src_arquivo = undefined;
      setTimeout(() => {
        $("#exibir-pdf").html("<iframe width='100%' height='" + ($('body').height() - 100) + "px' src='" + GlobalVariable.BASE_API_URL + "comprador/cotacao_" + data.cotacao_id + "/" + data.id + "_" + data.anexo + "'></iframe>");
      });
    } else
      this._src_arquivo = GlobalVariable.BASE_API_URL + "comprador/cotacao_" + data.cotacao_id + "/" + data.id + "_" + data.anexo;
  }

  _carregamento_concluido = false;
  CarregarDadosTrunk() {
    this._list_produtos.forEach(c => {
      this.produtoCompradorService.GetAllEntregaPacial(c.id).subscribe(
        d => { c.EntregasParcial = d; },
        e => { },
        () => {
          this.SetConfig();
          c._qt_entrega_parcial = c.EntregasParcial.length;
          var x = 1;
          c.EntregasParcial.forEach(c => {
            c._index = x++;
            EntregaParcialComprador.SetFormatDate(c);
          })
        }
      )
    })
    this._carregamento_concluido = true;
  }

  Reload() {
    this.router.navigateByUrl("/cotacao/edit-cotacao/" + this._cotacao.id);
  }

  ReloadProduto() {
    if (this._cotacao.status == EnumStatusCotacao.enviada)
      this.router.navigateByUrl("/cotacao/historico");
    else
      this.router.navigateByUrl("/cotacao/produto/" + this._cotacao.id);
  }

  ConfirmeSolicitacaoOrcamento(data: Cotacao) {
    this.confirmService._code = this._code
    this.confirmService._title = "Solicitação de Orçamento"
    this.confirmService._message_input = " <b>Deseja inserir seus fornecedores de confiança para esta cotação?</b>"
    this.confirmService._message = "Solicitar Orçamento?"
    this.confirmService.Show(true);
  }

  SolicitarOrcamento(confirm: boolean) {
    if (!confirm || this.confirmService._code != this._code) {
      this.confirmService.Reset()
      return;
    } else {
      this._cotacao._list_email = this.confirmService._list
      this.confirmService.Reset()
      this.orcamentoService.Create(this._cotacao).subscribe(
        d => { },
        e => { },
        () => {
        }
      );
      setTimeout(() => {
        this.SetModal();
        this._cotacao.status = EnumStatusCotacao.enviada
        Cotacao.SetFormat(this._cotacao);
        //this.ngOnInit();
      }, 200);
    }
  }

  _msg_titulo = ''
  _msg_descricao = ''
  SetModal() {
    this._msg_titulo = '<label class="">Pedido de Orçamento </label>'
    this._msg_descricao = '<div class="msg-nova-conta"><label class="titulo-msg"><b class="text-success" >COTAÇÃO EM PROCESSAMENTO&nbsp;&nbsp; <i class="fa fa-check"></i></b></label><br />'
    this._msg_descricao += '<div class="text-dark body-msg text-center" >A cotação está sendo enviada para todos os fornecedores, em breve, responderão sua solicitação.</div><BR />'
    this._msg_descricao += '<a href="' + GlobalVariable.HOST + '#/orcamentos/cotacao-list">Gerenciar Orçamentos</a></div><BR />'
    $('#message-modal').modal('show')
  }

  _marca_descricao = ''
  _tempProduto = new ProdutoComprador()
  showModalMarcas(produto: ProdutoComprador) {
    this._tempProduto = produto
    this._marca_descricao = produto.nome
    $('#marcas-modal').modal('show')
  }
}