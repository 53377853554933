<div class="col-lg-12 wrapper-top-content">
    <div class="ibox-title">
        <h5>Cadastro de Insumo</h5>
    </div>
    <div class="ibox-content form-categoria-nivel">
        <div class="row">
            <div class="col-sm-7 m-b-xs">
                <form #EmpresaForm="ngForm" appIdentityRevealed action="#">
                    <div class="form-empresa">
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label text-left"><b>Nome</b>
                                *</label>
                            <div class="col-sm-10">
                                <input type="text" [(ngModel)]="_produto.nome" name="nome" #nome="ngModel" required minlength="1" class="form-control" placeholder="Nome do Insumo">
                                <div *ngIf="nome.invalid && (nome.dirty
                                    || nome.touched)" class="input-error-msg
                                    text-danger">
                                    <div *ngIf="nome.errors.required ||
                                        nome.errors.minlength">
                                        Nome da obra é obrigatório.
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label text-left"><b>Categoria</b>
                                *</label>
                            <div class="col-sm-10">

                                <select class="form-control-sm form-control
                                    input-s-sm
                                    inline col-sm-4" [(ngModel)]="_produto.prod_categoria_id" name="categoria_id" #categoria_id="ngModel" required minlength="1">
                                    <option value="0">Selecione uma categoria</option>
                                    <option *ngFor="let categoria of
                                        _categorias"
                                        value="{{categoria.id}}">{{categoria.descricao}}</option>
                                </select>

                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label text-left"><b>Complemento</b></label>
                            <div class="col-sm-10">
                                <textarea class="form-control" placeholder="Complemtento" name="complemento" [(ngModel)]="_produto.prod_complemento" rows="5"></textarea>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label text-left"><b>Status</b></label>
                            <div class="col-sm-10" style="padding-top: 8px;">
                                <input type="checkbox" name="status" [(ngModel)]="_produto.prod_validado" /> Ativo
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label text-left"><b>Produto Niveis</b></label>
                            <div class="col-sm-10" style="padding-top: 8px;">
                                <input type="checkbox" name="ha_niveis" [(ngModel)]="_produto.ha_niveis" /> Produto Niveis
                            </div>
                        </div>

                        <br /><br />
                        <div class="form-group row">
                            <div class="text-right col-sm-12">
                                <button type="button" class="btn
                                        btn-default" (click)="voltar()">Voltar</button>
                                <button type="button" class="btn
                                        btn-primary" style="margin-left: 10px;" [disabled]="EmpresaForm.invalid" (click)="save()">Salvar</button>
                            </div>
                        </div>
                    </div>
                </form>


            </div>
            <!--
            <div class="col-sm-5">
                <div class="list-group">
                    <b>Lista de Prazos</b>
                </div>
                <hr />
                <div class="list-group">
                    <a *ngFor="let prazo_pagamento of _list_prazo_pagamento" class="dropdown-item padding-top-12">
                        {{prazo_pagamento.descricao}}
                        <label class="text-primary float-right
                            delete-categoria-nivel cursor-pointer"
                            (click)="ConfirmExcluir(prazo_pagamento)"><i
                                class="fa fa-times"></i></label>
                        <label class="text-primary float-right
                            cursor-pointer"
                            (click)="ActionEditar(prazo_pagamento)"><i
                                class="fa fa-edit"></i>&nbsp;&nbsp;</label>
                    </a>
                </div>
            </div>
        -->
        </div>
    </div>
</div>