import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Usuario } from 'src/app/domain/usuario/Usuario';
import { UserService } from 'src/app/service/user/user.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { PerfilUsuarioService } from 'src/app/service/configuracao/perfil-usuario.service';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
declare var $: any;
@Component({
  selector: 'app-usuarios-empresa',
  templateUrl: './usuarios-empresa.component.html',
  styleUrls: ['./usuarios-empresa.component.css']
})
export class UsuariosEmpresaComponent implements OnInit {

  @Output() resposta_usuario: EventEmitter<Usuario> = new EventEmitter();
  @Output() resposta_usuario_permissao: EventEmitter<boolean> = new EventEmitter();
  private _code = "app-usuarios-empresa";

  public usuarioSelecionado: Usuario = new Usuario();
  public usuarios: Usuario[];
  public usuario: Usuario;
  public usuario_id_selecionado: number;

  constructor(private userService: UserService, public userLoggedIn: UserLoggedInService, private confirmService: AlertConfirmService, private perfilUsuarioService: PerfilUsuarioService) {
    this.confirmService.UnsetObservers();
    this.confirmService.confirmEmitter$.subscribe(c => this.DeleteUser(c));
    userLoggedIn.AllowedOperations();
  }

  _buscar = ''

  ngOnInit() {
    this.GetUsersCNPJ();
  }

  GetUsersCNPJ(): void {
    this.userService.getAllCNPJ().subscribe(
      d => { this.usuarios = d; },
      e => { },
      () => { this.GetUsersAdministradoresCNPJ(); }
    );
  }
  GetUsersAdministradoresCNPJ(): void {
    this.userService.getAllAdiministradoresCNPJ().subscribe(
      d => { this.SetFunctions(d); },
      e => { },
      () => { UseFullService.SetMask(); }
    );
  }

  EditPermissionUser(e, usuario: Usuario): void {
    e.preventDefault();
    this.usuario_id_selecionado = usuario.id;
    this.perfilUsuarioService.EmitirEvento(usuario);
    this.resposta_usuario_permissao.emit(true);
  }

  EditUser(e, usuario: Usuario): void {
    e.preventDefault();
    usuario._load = true;
    this.usuario_id_selecionado = usuario.id;
    var cpf = usuario._cpf;
    if (!cpf.match("-"))
      usuario.cpf = cpf.substr(0, 3) + "." + cpf.substr(3, 3) + "." + cpf.substr(6, 3) + "-" + cpf.substr(9, 2);
    this.resposta_usuario.emit(usuario);
    this.resposta_usuario_permissao.emit(false);
  }

  DeleteUser(confirm: boolean) {
    if (this.confirmService._code != this._code)
      return;
    this.userService.SetDeleteUserProvider(this.usuario).subscribe(
      d => { this.GetUsersCNPJ(); },
      e => { },
      () => { }
    );
  }

  ClickDeleteUser(e, usuario: Usuario): void {
    e.preventDefault();
    this.usuario = usuario;
    this.confirmService._code = this._code;
    this.confirmService._title = "Exclusão de Usuário";
    this.confirmService._message = "Deseja excluir o usuário, " + usuario.nome + "?";
    this.confirmService.Show();
  }

  SetFunctions(usuarios: Usuario[]) {
    this.usuarios.forEach(c => {
      const getUsuarioId = (obj: Usuario) => { return obj.id == c.id }
      c._administrador = (usuarios.filter(getUsuarioId).length > 0)
    })
  }
}
