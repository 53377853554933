import { Component, OnInit } from '@angular/core';
import { EnderecoModalComponent } from 'src/app/core/page/endereco-modal/endereco-modal.component';
import { ObraCotacao, RelacaoObraEmpresa, RelacaoObraEmpresaAuditor, EnumStatusObraCotacao } from 'src/app/domain/gestao/obra-cotacao';
import { Empresa } from 'src/app/domain/empresa/empresa';
import { ContatoFinanceiro } from 'src/app/domain/empresa/contato-financeiro';
import { UseFullService } from 'src/app/service/usefull/usefull.service';

declare var $: any;
@Component({
  selector: 'app-obra-cotacao-relatorio',
  templateUrl: './obra-cotacao-relatorio.component.html',
  styleUrls: ['./obra-cotacao-relatorio.component.css']
})
export class ObraCotacaoRelatorioComponent extends EnderecoModalComponent implements OnInit {

  public code: 'app-obra-cotacao-view';
  obra = new ObraCotacao();

  _status = 0
  empresas = new Array<Empresa>();
  _empresa = new Empresa();
  empresaSelecionada = 0;
  relacao_empresa = new RelacaoObraEmpresa();
  empresas_relacionadas = new Array<Empresa>();
  responsaveis = new Array<ContatoFinanceiro>();

  id = this.route.snapshot.params['id'];

  ngOnInit() {
    if (this.id && this.id > 0) {
      this.get(this.id);
      this.GetAllRelacaoEmpresas();
      this.relacao_empresa.obra_id = this.id
      this.getEmpresaLoggedIn()
      this.obterStatusConfig(this.id);
    }
    this.alertConfirm.UnsetObservers();
    this.alertConfirm.confirmEmitter$.subscribe(c => { this.update(c); });
  }

  getEmpresaLoggedIn() {
    this.empresaService.GetLoggedIn().subscribe(
      d => { this._empresa = d }
    )
  }

  get(id) {
    this.repositoryService.path = "obra-cotacao/get"
    this.repositoryService.get(id).subscribe(
      d => {
        if (d && d.id > 0) {
          this.obra = d
          this.obra._inicio = UseFullService.formatDateString(this.obra.inicio)
          this.obra._fim = UseFullService.formatDateString(this.obra.fim)
          this._endereco = this.obra.endereco
          this.GetMunicipios(this._endereco.uf, false)
        }
      });
  }

  GetAllRelacaoEmpresas() {
    this.repositoryService.path = "obra-cotacao/relacao-empresa/all"
    this.repositoryService.getAll('', '', '?obra_id=' + this.id + '&detail=true').subscribe(
      d => {
        if (d && d.length > 0) {
          this.empresas_relacionadas = d;

          this.empresas_relacionadas.forEach(e => {
            e.cnpj = UseFullService.GetCnpjMasc(e.cnpj)
          });
        }
      }
    );
  }

  enviarEmaisValidacao() {
    this.repositoryService.path = "obra/emailvalidate/save"
    this.repositoryService.post(this.empresas_relacionadas).subscribe(d => { this.ngOnInit() });
  }

  obterStatusConfig(id) {
    this.repositoryService.path = "obra/emailvalidate/status"
    this.repositoryService.get(id).subscribe(d => { this._status = d });
  }

  voltar() { this.router.navigateByUrl('gestao/obra/list'); }

  editar() {
    if (this.obra.status == EnumStatusObraCotacao.simples) {
      this.router.navigateByUrl('gestao/obra/view/' + this.obra.id);
    } else {
      this.alertConfirm._code = "app-relatorio-obra-cotacao";
      this.alertConfirm._title = "Editar Vinculo de Auditoria";
      this.alertConfirm._message = "O processo atual será excluído, Clique em <b>SIM</b> para continuar.";
      this.alertConfirm.Show();
    }
  }

  update(confirm: boolean) {
    if (this.alertConfirm._code != "app-relatorio-obra-cotacao")
      return;
    this.repositoryService.path = "obra-cotacao/reset"
    this.repositoryService.post(this.obra).subscribe(
      d => { this.router.navigateByUrl('gestao/obra/view/' + this.obra.id); }
    );
  }
}
