import { Component, OnInit } from '@angular/core';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { EntregaService } from 'src/app/service/entrega/entrega.service';
import { Entrega } from 'src/app/domain/entrega/entrega';
import { Enderecos } from 'src/app/domain/empresa/Enderecos';
import { EnderecoService } from 'src/app/service/configuracao/endereco.service';
import { VerificaService } from 'src/app/service/auxiliar/verifica.service';

@Component({
  selector: 'app-entrega-comprador',
  templateUrl: './entrega-comprador.component.html',
  styleUrls: ['./entrega-list.component.css']
})
export class EntregaCompradorComponent implements OnInit {


  code = "app-entrega-list";
  public _entregas: Array<Entrega>;
  public _entrega: Entrega;

  hoveredDate: NgbDate;

  fromDate: NgbDate;
  toDate: NgbDate;
  constructor(
    private route: ActivatedRoute,
    private entregaService: EntregaService,
    private confirmService: AlertConfirmService,
    private userLoggedService: UserLoggedInService,
    private enderecoService: EnderecoService,
    private router: Router,
    private calendar: NgbCalendar,
    private verificaService: VerificaService) {
    this.confirmService.UnsetObservers();
    this.confirmService.confirmEmitter$.subscribe(c => { this.Editar(c); });
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 1);
    this.userLoggedService.SetCurrentUser();
    this._entregas = new Array<Entrega>();
    this._entrega = new Entrega();
  }


  _data = this.route.snapshot.params['data'];
  _titulo = "";
  _status = 0;
  ngOnInit() {
    this.userLoggedService.SetCurrentUser();
    this.VerificaUrl()
    this.SetPageConfig();
    setTimeout(() => { UseFullService.TraduzirCalendario(); }, 50);

  }
  VerificaUrl() {
    if (this._data) {
      this.fromDate = UseFullService.formatNgbDate(this._data)
      this.toDate = this.fromDate
    }
  }

  SetPageConfig() {
    switch (this.route.snapshot.routeConfig.path) {
      case "aguardando":
        this._titulo = "Pendentes";
        this._status = 0;
        break;
      case "recebidas":
        this._titulo = "Recebidas";
        this._status = 1;
        break;
    }
    setTimeout(() => { this.GetAll(); }, 50);
  }

  GetAll() {
    var inicio = UseFullService.formatNgbDateStructString(this.fromDate);
    var fim = (this.toDate) ? UseFullService.formatNgbDateStructString(this.toDate) : inicio;

    this.entregaService.GetAllUser(this._status, inicio, fim).subscribe(
      d => { this._entregas = d; },
      e => { },
      () => {

        if (this._entregas && this._entregas.length > 0) {
          var x = 1;
          this._entregas.forEach(c => {
            c._index = x++;
            Entrega.Format(c);
            c._endereco = new Enderecos()
            this.GetEndereco(c)
          });
        } else
          this._entregas = new Array<Entrega>();

      }
    );
  }


  GetEndereco(c: Entrega) {
    this.enderecoService.Get(c._endereco_id).subscribe(
      d => {
        if (d)
          c._endereco = d
        else
          this.SetMessageEmpresa()
      },
      e => { this.SetMessageEmpresa() }

    )
  }

  public _msg_titulo: string;
  public _msg_descricao: string;
  SetMessageEmpresa() {
    this._msg_titulo = '<label class="titulo-msg">Dados da Empresa Desatualizados&nbsp;&nbsp;<i class="fa fa-thumbs-down"></i></label>'
    this._msg_descricao = '<div class="text-center"><br />Os dados da empresa estão desatualizados ou incompleto para ter acesso a todas as ferramentas atualize-os.<br /><br /></div>'
    this.verificaService.AutalizarDadosEmpresa()
  }

  Ver(id: number) {
    setTimeout(() => {
      this.router.navigateByUrl("/compras/view/" + id);
    }, 50);
  }

  ConfirmarEntrega(model: Entrega) {
    this._entrega = model;
    this.confirmService._code = this.code;
    this.confirmService._title = "Confirma Recebimento";
    this.confirmService._message = "Deseja confirmar o recebimento da mercadoria referente a venda <b>" + model._codigo_orcamento + "</b>?";
    this.confirmService.Show();
  }

  Editar(confirm: boolean) {
    if (!this._entrega || !confirm || this.confirmService._code != this.code)
      return;

    this._entrega.status_recebimento = 1;
    this.entregaService.Edit(this._entrega).subscribe(
      d => { },
      e => { },
      () => { }
    );
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    setTimeout(() => { this.GetAll(); }, 100);
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

}