import { Component, OnInit } from '@angular/core';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { ProdutoCategoria, TypeEnumGeral, EnumGeral } from 'src/app/domain/suprimento/produto-categoria';
import { RepositoryService } from 'src/app/service/repository.service';

@Component({
  selector: 'app-cadastro-cor',
  templateUrl: './cadastro-cor.component.html',
  styleUrls: ['./cadastro-cor.component.css']
})
export class CadastroCorComponent implements OnInit {

  code = 'app-cor-nivel';
  _categoria = new EnumGeral();
  _list_categoria = new Array<EnumGeral>();

  constructor(private repository: RepositoryService, private confirmService: AlertConfirmService) {
      this.confirmService.UnsetObservers();
      this.confirmService.confirmEmitter$.subscribe(c => this.Excluir(c));
      this.Cancelar();
  }

  ngOnInit() {
    this.GetAll();
  }

  GetAll() {
    this.repository.path = 'enumgeral/getall';
    this.repository.getAll('_&tipo='+TypeEnumGeral.cor).subscribe(
      d => { this._list_categoria = d; },
      e => { },
      () => {
        this.Cancelar();
      }
    );
  }

  Adicionar() {
    this._categoria.ativo = 1;
    this.repository.path = 'enumgeral/save';
    this._categoria.tipo = TypeEnumGeral.cor;
    this.repository.post(this._categoria).subscribe(
      d => { },
      e => { },
      () => {
        this.GetAll();
      }
    );
  }

  Cancelar() {
    this._categoria = new ProdutoCategoria();
  }

  ActionEditar(data: ProdutoCategoria) {
    this._categoria = data;
  }

  Editar() {
    this.Adicionar();
  }

  ConfirmExcluir(data: ProdutoCategoria) {
    this._categoria = data;
    this.confirmService._code = this.code;
    this.confirmService._title = "Excluir Nivel";
    this.confirmService._message = "Deseja excluir o nivel " + data.descricao + "?";
    this.confirmService.Show();
  }

  Excluir(confirm: boolean = false) {
    if (!(this.confirmService._code != this.code || confirm == false)){      
      this.repository.path = 'enumgeral/delete';
      this.repository.post(this._categoria).subscribe( d => { this.GetAll(); });
    }
  }
}
