export class ExtratoFornecedor{
    public id: number
    public cnpj: string
    public nome_fantasia: string
    public razao_social: string
    public quantidade_venda: string
    public total: string
    public _index: number

    public venda_id: number
    public status: number
    public porcentagem: string
    public total_painel: string
    public data: Date

    
    public cotacao_nome: string
    public codigo: string
    public sub_total: string
    public valor_total: string
  _data: string
}


export enum EnumStatusFaturamento
{
    Aberto = 0,
    Pago = 1,
    Cancelado = 2,
    Gratuito = 3
}