import { Component, OnInit, ComponentFactoryResolver, Input } from '@angular/core';
import { OrcamentoFornecedorCtrl } from '../orcamento-forncedor-ctrl';
import { EnumTipoFrete } from 'src/app/domain/cotacao/cotacao';

declare var $: any;

@Component({
  selector: 'app-orcamento-edit',
  templateUrl: './orcamento-edit.component.html',
  styleUrls: ['./orcamento-edit.component.css']
})
export class OrcamentoEditComponent extends OrcamentoFornecedorCtrl implements OnInit {
}