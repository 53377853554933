
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from '../../../core/global-variable';
import { ProdutoComprador } from 'src/app/domain/suprimento/comprador/produto-comprador';
import { EntregaParcialComprador } from 'src/app/domain/suprimento/comprador/entrega-parcial-comprador';
import { ProdutoFornecedor } from 'src/app/domain/suprimento/produto-fornecedor';

@Injectable({
    providedIn: 'root'
})
export class ProdutoCompradorService {

    private url = GlobalVariable.BASE_API_URL + "supplies/buyer";
    private produto: ProdutoComprador[];
    constructor(private http: HttpClient) { }

    GetAll(cotacao_id: number): Observable<ProdutoComprador[]> {
        return this.http.get<ProdutoComprador[]>(this.url + "/getall?cotacao_id=" + cotacao_id).pipe(
            map(res => {
                return res;
            })
        );
    }

    Get(id: number): Observable<ProdutoComprador> {
        return this.http.get<ProdutoComprador>(this.url + "/get?id=" + id).pipe(
            map(res => {
                return res;
            })
        );
    }

    GetAllEntregaPacial(produto_id: number): Observable<EntregaParcialComprador[]> {
        return this.http.get<EntregaParcialComprador[]>(this.url + "/entregaparcial/getall?produto=" + produto_id).pipe(
            map(res => {
                return res;
            })
        );
    }

    SetSaveProduct(produtoComprador: ProdutoComprador): Observable<ProdutoComprador> {
        var url = this.url + "/create";
        return this.http.post<ProdutoComprador>(url, produtoComprador).pipe(
            map(res => { return res; })
        );
    }

    AddProductRange(produtosComprador: ProdutoComprador): Observable<any> {
        var url = this.url + "/createrange";
        return this.http.post<any>(url, produtosComprador).pipe(
            map(res => { return res; })
        );
    }

    SetEditProduct(produtoComprador: ProdutoComprador) {
        var url = this.url + "/edit";
        const formData = new FormData();
        formData.append('id', String(produtoComprador.id));
        formData.append('cotacao_id', String(produtoComprador.cotacao_id));
        formData.append('produto_id', String(produtoComprador.produto_id));
        formData.append('produto_obra_id', String(produtoComprador.produto_obra_id));
        formData.append('categoria_id', String(produtoComprador.categoria_id));
        formData.append('unidade_id', String(produtoComprador.unidade_id));
        formData.append('quantidade', String(produtoComprador.quantidade));
        formData.append('_quantidade', String(produtoComprador.quantidade));
        formData.append('nome', produtoComprador.nome);
        formData.append('anexo', produtoComprador.anexo);
        formData.append('origem', produtoComprador.origem.toString());
        formData.append('data_criacao', String(produtoComprador.data_criacao));
        formData.append('_name_file', produtoComprador._name_file);
        formData.append('_file', produtoComprador._file);
        formData.append('_entregaParcial', produtoComprador._entregaParcial);

        return this.http.put(url, formData).pipe(
            map(res => { return res; })
        );
    }

    Delete(id: number): Observable<any> {
        return this.http.delete<any>(this.url + "/delete?id=" + id).pipe(
            map(res => {
                return res;
            })
        );
    }

    GetProdutoFornecedor(produto_id: number, produto_origem: number): Observable<ProdutoFornecedor> {
        var resquest = GlobalVariable.BASE_API_URL + "suppliesProvider/budget/get?id="+produto_id+"&origem="+produto_origem;
        return this.http.get<ProdutoFornecedor>(resquest).pipe(map(r => { return r; }));
    }
}
