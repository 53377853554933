import { UseFullService } from 'src/app/service/usefull/usefull.service';

export class Dashboard{
    public quantidade_vendas: string;
    public faturamento: string;
    public solicitacao_orcamento: string;
    public orcamentos_enviados: string;
    public entregas_pendentes: string;
    public entregas_realizadas: string;
    public faturamento_analise: string;
    public quantidade_vendas_analise: string;
    public pedidos_orcamento: string;
    public orcamentos_recebidos: string;
    public compras_efetuadas: string;
    public compras_aprovadas: string;
    public entregas_recebidas: string;

    public _solicitacoes_abertas: string;

    static Formatar(model: Dashboard){
        model.quantidade_vendas = UseFullService.ToFixed(model.quantidade_vendas);
        model.faturamento = UseFullService.ToFixed(model.faturamento);
        model.solicitacao_orcamento = UseFullService.ToFixed(model.solicitacao_orcamento);
        model.orcamentos_enviados = UseFullService.ToFixed(model.orcamentos_enviados);
        model.entregas_pendentes = model.entregas_pendentes;
        model.entregas_realizadas = model.entregas_realizadas;
        var solicitacoes_abertas = parseInt(model.solicitacao_orcamento);
        model._solicitacoes_abertas = UseFullService.ToFixed(solicitacoes_abertas);
        model.faturamento_analise = UseFullService.ToFixed(model.faturamento_analise);
        model.quantidade_vendas_analise = UseFullService.ToFixed(model.quantidade_vendas_analise);
    }
}

export class FaturamentoDashboard
{
    public data: Date;
    public total_diario: string;
}