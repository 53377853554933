import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from '../../core/global-variable';

import { Produto } from '../../domain/suprimento/produto';
import { ProdutoCategoria } from 'src/app/domain/suprimento/produto-categoria';
import { ProdutoUnidadeMedida } from 'src/app/domain/suprimento/produto-unidade-medida';
import { ProdutoFornecedor, InsumoDoFornecedor } from 'src/app/domain/suprimento/produto-fornecedor';

@Injectable({
  providedIn: 'root'
})
export class ProdutoService {

  private url = GlobalVariable.BASE_API_URL + "supplies/";
  private produto: Produto[];
  constructor(private http: HttpClient) {}
  
  GetVinculos(id: number): Observable<Produto> {
    return this.http.get<Produto>(this.url + "GetVinculos?id="+id).pipe(
      map(res=>{
        return res;
      })
    );
  }

  get(id: number): Observable<Produto> {
    return this.http.get<Produto>(this.url + "get?id="+id).pipe(
      map(res=>{
        return res;
      })
    );
  }

  getProduto(id: number): Observable<Produto> {
    return this.http.get<Produto>(this.url + "get/prod?id="+id).pipe(
      map(res=>{
        return res;
      })
    );
  }


  getAll(buscar: string, categoria_id: number, vinculado: boolean, page: number = 1): Observable<Produto[]> {
    return this.http.get<Produto[]>(this.url + "getallproducts?limite=10&buscar="+buscar+"&categoria_id="+categoria_id+"&vinculado="+vinculado+"&page="+page).pipe(
      map(res=>{
        return res;
      })
    );
  }
  
  getAllCategories(): Observable<ProdutoCategoria[]> {
    return this.http.get<ProdutoCategoria[]>(this.url + "getAllcategories").pipe(
      map(res=>{
        return res;
      })
    );
  }

  SetProductProvider(productProvider: InsumoDoFornecedor, edit = false): Observable<InsumoDoFornecedor> {    
    if(productProvider.ProdutoFornecedor.id > 0)
      return this.SetEditProductProvider(productProvider);
    else
      return this.SetSaveProductProvider(productProvider);
  }

  SetSaveProductProvider(productProvider: InsumoDoFornecedor): Observable<InsumoDoFornecedor> {    
    var url = GlobalVariable.BASE_API_URL + "suppliesProvider/create";
    return this.http.post<InsumoDoFornecedor>(url, productProvider).pipe(
      map(res=> { return res; })
    );
  }

  SetEditProductProvider(productProvider: InsumoDoFornecedor): Observable<InsumoDoFornecedor> {    
    var url = GlobalVariable.BASE_API_URL + "suppliesProvider/edit";
    return this.http.put<InsumoDoFornecedor>(url, productProvider).pipe(
      map(res=> { return res; })
    );
  }

  GetAllUnitsMeasured(): Observable<ProdutoUnidadeMedida[]> {
    return this.http.get<ProdutoUnidadeMedida[]>(GlobalVariable.BASE_API_URL+"suppliesProvider/GetAllUnitsMeasured").pipe(
      map(r => { return r; })
    );
  }
  /*
  save(produto: Produto): Observable<Produto> {
    var url = GlobalVariable.BASE_API_URL + "supplies/salvar";
    return this.http.post<Produto>(url, produto).pipe(
      map(res=> { return res; })
    );
  }
*/
  
  save(produto: Produto) {
    return this.http.post<Produto>(this.url +  "set/save", produto).pipe(
      map(res => {
        return res;
      })
    );
  }
  
}
