import { Component, OnInit, ComponentFactoryResolver, EventEmitter, Output, Input } from '@angular/core';
import { InsumoDoFornecedor } from 'src/app/domain/suprimento/produto-fornecedor';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { ProdutoCategoriaNivel, TipoSelecao } from 'src/app/domain/suprimento/produto-categoria-nivel';
import { ProdutoService } from 'src/app/service/suprimento/produto.service';
import { ProdutoComprador } from 'src/app/domain/suprimento/comprador/produto-comprador';
import { InsumoComponentCtrl } from '../../insumo/insumo-component-ctrl';

declare var $: any;

@Component({
  selector: 'app-adicionar-produto-comprador',
  templateUrl: './adicionar-produto-comprador.component.html',
  styleUrls: ['./adicionar-produto-comprador.component.css'],
  providers: [ProdutoService]
})
export class AdicionarProdutoCompradorComponent  extends InsumoComponentCtrl implements OnInit {

  @Output() _produtos_adicionados: EventEmitter<Array<ProdutoComprador>> = new EventEmitter();
  @Input() _input_cotacao;

  public _input_nivel: ProdutoCategoriaNivel;
  _save_active: boolean
  ngOnInit() {
    var _this = this;
    $('#pesquisa-insumo-modal').on('show.bs.modal', function (e) {
      $(".modal-max").css("max-height", ($("body").height() - 320) + "px");
      $(".modal-max").css("min-height", ($("body").height() - 320) + "px");
      _this.list_produto_comprador = new Array<ProdutoComprador>();      
      _this._niveis = new Array<ProdutoCategoriaNivel>();
      _this.SetListaCatetorias();
      _this.SetListaUnidadeMedida();
    });
  }

  SelectCores(nivel: ProdutoCategoriaNivel) {
    this._input_nivel = nivel;
    this._input_nivel._multiple = (nivel.multiplo == TipoSelecao.Multipla);
    //$("#pesquisa-cor-modal").modal('show');
    $("#pesquisa-cor-modal").addClass('show');
    $("#pesquisa-cor-modal").show();
    $("#pesquisa-insumo-modal").addClass('modal-z-index');
  }
  
  ReciverCoresSelecionadas(nivel: ProdutoCategoriaNivel) {

    $("#pesquisa-insumo-modal").removeClass('modal-z-index');

    this._input_nivel._selected = 0;
    nivel.nivel.forEach(c => {
      if (c._selecionado)
        this._input_nivel._selected = c.id;
    });
    this.OnChangeSearchNivel(nivel);
  }

  /** bloco modal **/
  list_produto_comprador = new Array<ProdutoComprador>();
  ClickBondModal(produto: InsumoDoFornecedor) {
    produto._selecionado = !produto._selecionado;
    var ja_adicionado = ProdutoComprador.FiltrarPorNome(this.list_produto_comprador, produto.nome);
    setTimeout(() => {
      if (!ja_adicionado && produto._selecionado) {
        var _insumo = new ProdutoComprador();
        _insumo.produto_id = produto.id;
        _insumo.origem = produto.origem;
        _insumo.categoria_id = produto.categoria_id;
        _insumo.nome = produto.nome;
        _insumo._niveis = produto._niveis;
        _insumo._categoria = produto._categoria;
        _insumo.cotacao_id = this.route.snapshot.params['id'];;
        this.list_produto_comprador.push(_insumo);
      } else if (!produto._selecionado && ja_adicionado) {
        UseFullService.RemoveArray(this.list_produto_comprador, ja_adicionado);
      }
    }, 10);
  }

  ConcluirAdicao() {
    for (let i = 0; i < this.list_produto_comprador.length; i++) {
      this.list_produto_comprador[i]._index = i + 1;
    }
    this._produtos_adicionados.emit(this.list_produto_comprador);
  }
}