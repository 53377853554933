import { TypeUser } from '../usuario/Usuario';

export class MenuSideBar {
    public name;
    public text;
    public route;
    public type;
    public icon;
    public class;
    public subClass;
    public expanded;
    public autorizado;
    public access: [];
    public subMenu = [];

    static _listMenu = {
        list: [
            { text: 'Dashboard', route: '/dashboard', icon: 'home', access: [TypeUser.administrator, TypeUser.provider] },
            { text: 'Suprimentos', route: '/suprimento/insumo', icon: 'archive', access: [TypeUser.administrator, TypeUser.provider] },

            //client
            { text: 'Dashboard', route: '/dashboard/comprador', icon: 'home', access: [TypeUser.client] },
            { text: 'Nova cotação', route: '/cotacao/nova-cotacao', icon: 'plus', access: [TypeUser.client] },
            { text: 'Lista cotações', route: '/cotacao/historico', icon: 'list', access: [TypeUser.client] },
            { text: 'Orçamentos recebidos', route: '/orcamentos/cotacao-list', icon: 'clipboard', access: [TypeUser.client] },
            { text: 'Histórico de orçamentos', route: '/orcamentos/cotacao-historico-list', icon: 'book-open', access: [TypeUser.client] },
            { text: 'Pedidos em análise', route: '/compras/analise', icon: 'shopping-cart', access: [TypeUser.client] },
            { text: 'Pedidos aprovados', route: '/compras/realizadas', icon: 'dollar-sign', access: [TypeUser.client] },
            { text: 'Pedidos recusados', route: '/compras/recusadas', icon: 'slash', access: [TypeUser.client] },
            { text: 'Recebimentos pendentes', route: '/entregas/aguardando', icon: 'truck', access: [TypeUser.client] },
            { text: 'Entregas recebidas', route: '/entregas/recebidas', icon: 'send', access: [TypeUser.client] },
            { text: 'Empresas', route: '/gestao/faturamento/list', icon: 'briefcase', access: [TypeUser.client] },
            { text: 'Obras', route: '/gestao/obra/list', icon: 'package', access: [TypeUser.client] },

            //unregisteredProvider            
            { text: 'Dashboard', route: '/cadastre-se', icon: 'home', access: [TypeUser.unregisteredProvider] },
            { text: 'Cotações abertas', route: '/cadastre-se', icon: 'list', access: [TypeUser.unregisteredProvider] },
            { text: 'Histórico de cotações', route: '/cadastre-se', icon: 'book', access: [TypeUser.unregisteredProvider] },
            { text: 'Pedidos recebidos', route: '/orcamentos/email/edit', icon: 'clipboard', access: [TypeUser.unregisteredProvider,] },
            { text: 'Histórico de orçamentos', route: '/cadastre-se', icon: 'book-open', access: [TypeUser.unregisteredProvider,] },
            { text: 'Vendas em análise', route: '/cadastre-se', icon: 'shopping-cart', access: [TypeUser.unregisteredProvider] },
            { text: 'Vendas realizadas', route: '/cadastre-se', icon: 'dollar-sign', access: [TypeUser.unregisteredProvider] },
            { text: 'Vendas recusadas', route: '/cadastre-se', icon: 'slash', access: [TypeUser.unregisteredProvider] },
            { text: 'Entegas pendentes', route: '/cadastre-se', icon: 'truck', access: [TypeUser.unregisteredProvider] },
            { text: 'Entregas realizadas', route: '/cadastre-se', icon: 'send', access: [TypeUser.unregisteredProvider] },

            //provider            
            { text: 'Cotações abertas', route: '/cotacao/abertas', icon: 'navigation', access: [TypeUser.provider] },
            { text: 'Histórico de cotações', route: '/cotacao/historico-fornecedor', icon: 'book', access: [TypeUser.provider] },
            { text: 'Orçamentos enviados', route: '/orcamentos/enviados-manual', icon: 'clipboard', access: [TypeUser.provider] },
            { text: 'Orçamentos pré-venda', route: '/orcamentos/disputa', icon: 'wind', access: [TypeUser.provider] },
            { text: 'Histórico de orçamentos', route: '/orcamentos/fornecedor/historico', icon: 'book-open', access: [TypeUser.provider] },
            { text: 'Vendas em Análise', route: '/vendas/analise', icon: 'shopping-cart', access: [TypeUser.provider] },
            { text: 'Vendas realizadas', route: '/vendas/realizadas', icon: 'dollar-sign', access: [TypeUser.provider] },
            { text: 'Vendas recusadas', route: '/vendas/recusadas', icon: 'slash', access: [TypeUser.provider] },
            { text: 'Entregas pendentes', route: '/entregas/pendentes', icon: 'truck', access: [TypeUser.provider] },
            { text: 'Entregas realizadas', route: '/entregas/realizadas', icon: 'send', access: [TypeUser.provider] },

            //administrador
            { text: 'Cotações', route: '/gerenciamento/comprador/cotacaoes', icon: 'list', access: [TypeUser.administrator] },
            { text: 'Fornecedores', route: '/gerenciamento/extrato/fornecedores', icon: 'users', access: [TypeUser.administrator] },
            { text: 'Usuários Compradores', route: '/gerenciamento/comprador/usuarios', icon: 'user-check', access: [TypeUser.administrator] },
            { text: 'Lista de Insumos', route: 'auxiliar/insumo/list', icon: 'book', access: [TypeUser.administrator] },
            { text: 'Cadastro de Insumo', route: '/auxiliar/insumo/form', icon: 'plus-square', access: [TypeUser.administrator] },
            { text: 'Categorias', route: '/auxiliar/categoria/form', icon: 'copy', access: [TypeUser.administrator] },
            { text: 'Niveis', route: '/auxiliar/nivel/form', icon: 'zap', access: [TypeUser.administrator] },
            { text: 'Cores', route: '/auxiliar/cor/form', icon: 'columns', access: [TypeUser.administrator] },
            { text: 'Marcas', route: '/auxiliar/marca/form', icon: 'columns', access: [TypeUser.administrator] },

            //auditor
            { text: 'Obras', route: 'auditor/obra/list', icon: 'home', access: [TypeUser.auditor] },
            { text: 'Avaliações Pendentes', route: 'auditor/compras/list', icon: 'shopping-cart', access: [TypeUser.auditor] }

        ], selected: { text: 'Dashboard', route: '/dashboard', icon: 'menu', access: [] }
    }
}

export class MenuNavBar {
    public text: string;
    public route: string;
    public access: [];

    static _listMenuNav = [
        {
            text: 'Gerenciar Solicitações', route: '/suprimento/gerenciar-solicitacoes',
            access: [TypeUser.administrator]
        },
        {
            text: 'Solicitações', route: '/suprimento/solicitacao',
            access: [TypeUser.provider]
        },
        { text: 'Gerenciar Acessos', route: '/user/gerenciar-acesso', access: [TypeUser.administrator] },
        { text: 'Minha Conta', route: '/user/minha-conta', access: [TypeUser.administrator, TypeUser.provider, TypeUser.client] }
    ];

    static _listMenuNavConfig = [
        {
            text: 'Gerenciar Solicitações', route: '/suprimento/gerenciar-solicitacoes',
            access: [TypeUser.administrator]
        },
        { text: 'Dados Empresa', route: '/configuracao', access: [TypeUser.provider] },
        { text: 'Dados Empresa', route: '/configuracao/user', access: [TypeUser.client] },
        { text: 'Usuários', route: '/user/gerenciar-usuario', access: [TypeUser.client] },
        {
            text: 'Gerenciar Usuários', route: '/user/gerenciar-usuario',
            access: [
                TypeUser.administrator,
                TypeUser.provider
            ]
        },
        { text: 'Orçamento', route: '/orcamentos/configuracoes', access: [TypeUser.provider] },
        {
            text: 'Configurações', route: '/configuracao-adm', access: [TypeUser.administrator]
        },
        { text: 'Gerenciar Acessos', route: '/user/gerenciar-acesso', access: [TypeUser.administrator] },
    ];
}