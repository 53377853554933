import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from 'src/app/core/global-variable';
import { PerfilUsuario } from 'src/app/domain/configuracao/perfil-usuario';
import { RelacaoPerfilFuncao, FuncaoSistema, RestricaoUsuarioPerfilFuncao } from 'src/app/domain/configuracao/funcao-sistema';

@Injectable({
  providedIn: 'root'
})
export class PerfilUsuarioService {
  
  public eventEmitter$: EventEmitter<any>;

  private url = GlobalVariable.BASE_API_URL + "autorizacao/perfilusuario/";

  constructor(private http: HttpClient) { 
    this.eventEmitter$ = new EventEmitter();
  }

  EmitirEvento(data: any){    
    this.eventEmitter$.emit(data);
  }

  GetAll() {
    return this.http.get<PerfilUsuario[]>(this.url + "getall").pipe(
      map(res => {
        return res;
      })
    );
  }

  Get(id: number) {
    return this.http.get<PerfilUsuario>(this.url + "get?id="+id).pipe(
      map(res => {
        return res;
      })
    );
  }
  
  Create(data: PerfilUsuario): Observable<any>{
    return this.http.post<any[]>(this.url+"create", data).pipe(
      map(res=> { return res; })
    );
  }
  
  Edit(data: PerfilUsuario): Observable<any>{
    return this.http.put<any[]>(this.url+"edit", data).pipe(
      map(res=> { return res; })
    );
  }
  
  Delete(id: number): Observable<any> {    
    return this.http.delete(this.url+"delete?id="+id).pipe(
      map(res=> { return res; })
    );
  }

  //relações ------------------
  GetAllRelacoesPerfilFuncao(perfil_id: number) {
    return this.http.get<RelacaoPerfilFuncao[]>(this.url + "relacaofuncao/getall?perfil_id="+perfil_id).pipe(
      map(res => {
        return res;
      })
    );
  }

  //relações ------------------
  GetAllFuncoesPerfil(perfil_id: number, usuario_id: number) {
    return this.http.get<FuncaoSistema[]>(this.url + "funcao/getall?perfil_id="+perfil_id+"&usuario_id="+usuario_id).pipe(
      map(res => {
        return res;
      })
    );
  }
  
  EditRelacoesPerfilFuncao(data: RelacaoPerfilFuncao): Observable<any>{
    return this.http.put<any[]>(this.url+"relacaofuncao/edit", data).pipe(
      map(res=> { return res; })
    );
  }
  
  EditRestricaoUsuarioPerfilFuncao(data: RestricaoUsuarioPerfilFuncao): Observable<any>{
    return this.http.put<any[]>(this.url+"restricaousuariofuncao/edit", data).pipe(
      map(res=> { return res; })
    );
  }
  
  RestaurarPadrao(usuario_id: number) {
    return this.http.delete<any[]>(this.url+"restricaousuariofuncao/restaurarpadrao?usuario_id="+usuario_id).pipe(
      map(res=> { return res; })
    );
  }
  
}
