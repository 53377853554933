<div class="col-lg-12 wrapper-top-content">
    <div class="ibox">
        <div class="ibox-title" style="padding-right:20px;">
            <h5>Cotações Enviadas</h5>
            <div style="float: right;">
                Buscar: <input placeholder="Pesquisar" [(ngModel)]="_buscar" type="text"> &nbsp;&nbsp;&nbsp; Status: <select class="" [(ngModel)]="_status" (change)="OnChangeSearch()">
                    <option value="0">Abertas</option>
                    <option value="1">Enviadas</option>
                    <option value="2">Canceladas</option>
                    <option value="-1">Todas</option>
                </select>&nbsp;&nbsp;&nbsp; Periodo: <input type="date" [(ngModel)]="_inicio" (change)="OnChangeSearch()"> à
                <input type="date" [(ngModel)]="_fim" (change)="OnChangeSearch()">
            </div>
        </div>
        <div class="ibox-content">
            <div class="table-responsive min-height-grid-insumo div-table-fixed margin-top-15">
                <!-- table-striped table-hover table-bordered -->
                <table class="table table-striped">
                    <thead class="thead-fixed">
                        <tr>
                            <th class="th-sequence" rowspan="2"></th>
                            <th class="th-data text-center" colspan="3">Solicitações de Orçamentos</th>
                            <th class="th-nome text-center" rowspan="2">Nome</th>
                            <th class="text-center th-codigo" rowspan="2">Código</th>
                            <th class="text-center th-obra" rowspan="2">Obra</th>
                            <th class="text-center th-data" rowspan="2">Data Criação</th>
                            <th class="text-center th-data" rowspan="2">Validade Cotação</th>
                        </tr>
                        <tr>
                            <th class="text-center th-data">Email</th>
                            <th class="text-center th-data">Sistema</th>
                            <th class="text-center th-data">Recebidos</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="  " *ngFor="let cotacao of _cotacoes | filter:_buscar">
                            <td class="text-center th-sequence">{{cotacao._index}}</td>
                            <td class="text-center th-sequence">{{cotacao._qt_email}}</td>
                            <td class="text-center th-sequence  cursor-pointer" (click)="VerOrcamentos(cotacao)">{{cotacao._qt_solicitada}}</td>
                            <td class="text-center th-sequence">{{cotacao._qt_orcamento}}</td>
                            <td><label class="btn-link cursor-pointer" (click)="Ver(cotacao)">
                                    {{cotacao.nome}}
                                </label></td>
                            <td class="text-center th-codigo">{{cotacao.codigo}}</td>
                            <td class=" th-obra">{{cotacao.nome_obra}}</td>
                            <td class="text-center th-data">{{cotacao._data_criacao}}</td>
                            <td class="text-center th-data">{{cotacao._data_max_recebimento_orcamento}}</td>
                            <td class="text-center th-action-anexo">
                                <i class="fa fa-search btn-link cursor-pointer" (click)="Ver(cotacao)">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>


        </div>
    </div>
</div>


<!-- data-toggle="tooltip" data-placement="top" title=".." -->