import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-dashboard-cotacao',
  templateUrl: './dashboard-cotacao.component.html',
  styleUrls: ['./dashboard-cotacao.component.css']
})
export class DashboardCotacaoComponent implements OnInit {
  ngOnInit(): void {
  }



}
