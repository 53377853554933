export class Usuario{
    public id: number;
    public status: number;
    public tipo_pessoa: number;
    public tipo_usuario: number;
    public origem_cadastro: number;
    public data_criacao: Date;

    /// <summary>
    /// dados do cadastrante
    /// </summary>
    public nome: string;
    public email: string;
    public cpf: string;
    public telefone: string;
    public senha: string;
    public repita_senha: string;

    /// <summary>
    /// dados da empresa
    /// </summary>
    public cnpj: string;
    public inscricao_estadual: string;

    public _load: boolean = false;
    public _tipo_email: number; 
    
    public _cpf: string;
    public _administrador: boolean;
  _index: number;
  _data_criacao: string;
}

export enum TypeUser
{
    all = 0,
    administrator = 1,
    provider = 2,
    client = 3,
    unregisteredProvider = 4,
    auditor = 5,
    member = 6,
    reader = 7
}

export enum PersonType{
    juridica = 0,
    fisica = 1
}