import { Component, OnInit } from '@angular/core';
import { GlobalVariable } from '../../global-variable';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
declare var $ : any;
@Component({
  selector: 'app-cadastre-se',
  templateUrl: './cadastre-se.component.html',
  styleUrls: ['./cadastre-se.component.css']
})
export class CadastreSeComponent implements OnInit {

  constructor(private userLoggedService: UserLoggedInService) { }

  ngOnInit() {
    this.SetModal();    
    $('#message-modal').on('hide.bs.modal', function (e) {
      window.location.href = "orcamentos/email/edit"
    })    
  }

  _msg_titulo = ''
  _msg_descricao = ''
  SetModal() {
    this._msg_titulo = '<label class="titulo-msg">  <b class="text-" >Acesso Restrito &nbsp;<i class="fa fa-ban"></i></b></label>'
    this._msg_descricao = '<div class="text-dark body-msg text-center" >Efetue o cadastro da sua empresa. Para acessar todas as funcionalidades.</div>'
    this._msg_descricao += '<div class="msg-nova-conta"><a class="btn btn-success" target="black" href="https://www.paineldaengenharia.com.br/gestao/fornecedor/cadastro-fornecedor">Cadastrar</a><br />'
    this._msg_descricao += 'Caso possua uma conta, <a href="' + GlobalVariable.HOST + '#/login/email/' + this.userLoggedService.urlToken + '">clique aqui!</a></div>'
    $('#message-modal').modal('show')
  }

}
