<div class="col-lg-12 wrapper-top-content">
    <div class="ibox-title">
        <h5>Cadastro Prazo para Pagamento</h5>
    </div>

    <div class="ibox-content form-categoria-nivel">
        <div class="row">

            <div class="col-sm-5 m-b-xs">
                <Br />
                <div class="form-group">
                    <label>Descrição</label>
                    <div class="row">
                        <div class="col-sm-10">
                            <input type="text" class="form-control" placeholder="Digita à descrição" [(ngModel)]="_prazo_pagamento.descricao" />
                            <small id="emailHelp" class="form-text text-muted" required>Digite um descrição da condição de
                                pagamento.</small>
                        </div>

                        <div class="col-sm-6">
                            <select class="form-control" [(ngModel)]="_prazo_pagamento.meio_pagamento">
                                <option value="0" disabled>Selecionar</option>
                                <option value="1">Dinheiro</option>
                                <option value="2">Transferência Bancária</option>
                                <option value="3">Boleto Bancário</option>
                                <option value="4">Cartão Crédito</option>
                                <option value="5">Cartão BNDES</option>
                            </select>
                            <small id="emailHelp" class="form-text text-muted" required>Selecione o meio de pagamento.</small>
                            <br />
                        </div>

                        <div class="col-sm-10">
                            <button *ngIf="!(_prazo_pagamento.id> 0)" type="submit" class="btn btn-primary" (click)="Adicionar()">Adicionar</button>
                            <button *ngIf="(_prazo_pagamento.id> 0)" type="button" class="btn btn-default" (click)="Cancelar()">Cancelar</button>&nbsp;&nbsp;&nbsp;&nbsp;
                            <button *ngIf="(_prazo_pagamento.id> 0)" type="submit" class="btn btn-primary" (click)="Editar()">Editar</button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-sm-7">
                <div class="list-group">
                    <b>Lista de Prazos</b>
                </div>
                <hr />
                <div class="list-group">
                    <table class="table table-bordered">
                        <thead>
                            <th>Descrição</th>
                            <th class="width-200-px">Meio Pagamento</th>
                            <th class="width-80-px"></th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let prazo_pagamento of _list_prazo_pagamento">
                                <td>{{prazo_pagamento.descricao}}</td>
                                <td>{{prazo_pagamento._meio_pagamento}}</td>
                                <td class="text-center">
                                    <label class="cursor-pointer cursor-opacity" (click)="ActionEditar(prazo_pagamento)"><i class="fa
                                        fa-edit"></i>&nbsp;&nbsp;</label>
                                    <label class="cursor-pointer cursor-opacity" (click)="ConfirmExcluir(prazo_pagamento)"><i
                                            class="fa fa-times"></i></label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>