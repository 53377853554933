import { Enderecos } from '../empresa/Enderecos';
import { InteractiveTable } from '../common/interactiveTable';

export class ObraCotacao extends InteractiveTable{
    public id: number;
    public empresa_id: number;
    public nome: string;
    public endereco_id: number;
    public data: Date;
    public log: string;
    public endereco: Enderecos;
    public _endereco: string
    public inicio: string;
    public fim: string;
    public status: number;
    public _status_auditoria: number;
    _inicio: any;
    _fim: any;
}

export class RelacaoObraEmpresa{
    public id: number
    public obra_id: number
    public empresa_id: number
    public data: number
    public tipo: number;

    static _tipo = (x) => { return EnumCategoriaEmpresaObra[x] }
}

export class RelacaoObraEmpresaAuditor{
    public id: number
    public relacao_obra_empresa_id: number
    public responsavel_id: number
    public data: number
}

export class ObraCotacaoAuditor extends ObraCotacao
{
    public _tipo:string
    public _status:string

    public tipo:number
    public empresa_relacao_id:number
    public responsavel_id:number
    public empresa_relacao_razao_social:number
    public status_email: number;
}

export enum EnumCategoriaEmpresaObra {
    Faturante = 1,
    Fiscalizadora = 2
}

export enum EnumStatusObraCotacao
{
    simples = 0,
    auditatando = 1,
    auditada = 2
}
