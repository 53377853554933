<!-- Modal -->
<div class="modal fade" id="modal-crm-cliente" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable
        modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Informações do Cliente</h4>

                <div class="ibox-tools">
                    <div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span> 
                        </button>
                    </div>
                </div>

            </div>

            <div class="modal-body modal-confirm" style="padding: 1rem;">

                <div class="row">

                    <div class="col-sm-5 m-b-xs">
                        <Br />
                        <div class="form-group">
                            <label>Descrição</label>
                            <div class="row">
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" placeholder="Digita à descrição" [(ngModel)]="_observacao" />
                                    <small id="emailHelp" class="form-text text-muted" required>Digite um descrição da condição de pagamento.</small>
                                </div>

                                <div class="col-sm-10">
                                    <!--
                                    <button type="submit" class="btn btn-primary" (click)="Adicionar()">Adicionar</button>
                                    <button type="button" class="btn btn-default" (click)="Cancelar()">Cancelar</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <button type="submit" class="btn btn-primary" (click)="Editar()">Editar</button>-->
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-sm-7">
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>