<!-- Modal -->
<div class="modal fade" id="new-endereco-modal" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Cadastro Endereço</h4>
                <div class="ibox-tools">
                    <div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="body-data">
                <form #EmpresaForm="ngForm" appIdentityRevealed action="#">
                    <div class="ibox-content form-empresa">

                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label text-left"><b>CEP</b> *</label>
                            <div class="col-sm-3">
                                <input type="text" [(ngModel)]="_endereco.cep" id="cep" name="cep" required minlength="9" #cep="ngModel" (ngModelChange)="OnChangeCep()" class="form-control" placeholder="00000-000">

                                <div *ngIf="cep.invalid && (cep.dirty || cep.touched)" class="input-error-msg text-danger">
                                    <div *ngIf="cep.errors.required || cep.errors.minlength">
                                        CEP deve ter 8 caracteres.
                                    </div>
                                </div>
                            </div>
                            <label class="col-sm-1"></label>
                            <label class="col-sm-2 col-form-label text-left"><b>Número</b> *</label>
                            <div class="col-sm-3">
                                <input type="text" [(ngModel)]="_endereco.numero" id="number" name="numero" #numero="ngModel" required minlength="1" class="form-control" placeholder="Número">
                                <div *ngIf="numero.invalid && (cep.dirty || numero.touched)" class="input-error-msg text-danger">
                                    <div *ngIf="numero.errors.required || numero.errors.minlength">
                                        Número é obrigatório.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label text-left"><b>Endereço</b> *</label>
                            <div class="col-sm-10">
                                <input type="text" [(ngModel)]="_endereco.endereco" name="endereco" #endereco="ngModel" required minlength="1" class="form-control" placeholder="endereco">

                                <div *ngIf="endereco.invalid && (endereco.dirty || endereco.touched)" class="input-error-msg text-danger">
                                    <div *ngIf="endereco.errors.required">
                                        Endereço é obrigatório.
                                    </div>
                                    <div *ngIf="endereco.errors.minlength">
                                        Endereço é obrigatório.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label text-left"><b>Bairro</b> *</label>
                            <div class="col-sm-4">
                                <input class="form-control" [(ngModel)]="_endereco.bairro" name="bairro" #bairro="ngModel" required minlength="2" placeholder="Bairro">

                                <div *ngIf="bairro.invalid && (bairro.dirty ||
                                        bairro.touched)" class="input-error-msg text-danger">
                                    <div *ngIf="bairro.errors.required">
                                        Bairro é obrigatório.
                                    </div>
                                    <div *ngIf="bairro.errors.minlength">
                                        Bairro é obrigatório.
                                    </div>
                                </div>
                            </div>
                            <label class="col-sm-2 col-form-label text-left"><b>Complemento</b></label>
                            <div class="col-sm-4">
                                <input class="form-control" name="complemento" [(ngModel)]="_endereco.complemento" placeholder="Complemento">
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label text-left"><b>Estado</b> *</label>
                            <div class="col-sm-3">
                                <select [(ngModel)]="_endereco.uf" name="thisuf" #thisuf="ngModel" required (ngModelChange)="OnChangeEstado()" class="form-control">
                                        <option value="" selected>Selecione Estado</option>
                                        <option *ngFor="let estado of _estados"
                                            value="{{estado.Uf}}">{{estado.Nome}}</option>
                                    </select>

                                <div *ngIf="thisuf.invalid && (thisuf.dirty ||
                                        thisuf.touched)" class="input-error-msg text-danger">
                                    <div *ngIf="thisuf.errors.required">
                                        Estado é obrigatório.
                                    </div>
                                </div>
                            </div>
                            <label class="col-sm-1"></label>
                            <label class="col-sm-2 col-form-label text-left"><b>Cidade</b> *</label>
                            <div class="col-sm-3">
                                <select [(ngModel)]="_endereco.cidade" class="form-control" name="thiscidade" #thiscidade="ngModel" required>
                                        <option value="" selected>Selecione Cidade</option>
                                        <option *ngFor="let cidade of _municipios"
                                            value="{{cidade.Codigo}}">{{cidade.Nome}}</option>
                                    </select>

                                <div *ngIf="thiscidade.invalid && (thiscidade.dirty ||
                                        thiscidade.touched)" class="input-error-msg text-danger">
                                    <div *ngIf="thiscidade.errors.required">
                                        Cidade é obrigatório.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br /><br />
                        <div class="form-group row">
                            <div class="text-right col-sm-12">
                                <button type="button" class="btn btn-default" (click)="reload()">Cancelar</button>
                                <button type="button" class="btn btn-primary" style="margin-left: 10px;" [disabled]="EmpresaForm.invalid" (click)="Insert()">Salvar</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>