import { Component, OnInit, ComponentFactoryResolver, Input, EventEmitter, Output } from '@angular/core';
import { OrcamentoViewCtrl } from '../orcamento-ctrl-view';
import { ListOrcamento, EnumStatusOrcamento } from 'src/app/domain/orcamento/orcamento';

declare var $: any;

@Component({
  selector: 'app-orcamento-comprador-modal',
  templateUrl: './orcamento-comprador-modal.component.html',
  styleUrls: ['./orcamento-comprador-modal.component.css']
})
export class OrcamentoCompradorModalComponent extends OrcamentoViewCtrl implements OnInit {

  @Output() gridLoad: EventEmitter<boolean> = new EventEmitter();
  _titulo_confirm = ''
  _descricao_confirm = ''
  _id_model_pai = 'orcamento-veiw-modal'
  @Input() _list_orcamento: Array<ListOrcamento>;
  @Input() _grid_tipo_frete: string;
  _input_cotacao = "";
  _cotacao_respondida = false

  ngOnInit() {
    var _this = this;
    $('#orcamento-veiw-modal').on('show.bs.modal', function (e) {
      _this._cotacao_respondida = false
      _this._list_orcamento.forEach(e => {
        if (e.status == EnumStatusOrcamento.aprovado)
          _this._cotacao_respondida = true
      });

      $("#global-confirm-secundario-modal").removeClass('show')
      $("#global-confirm-secundario-modal").hide()
      $("#global-confirm-secundario-modal").modal('hide')
      $('#orcamento-veiw-modal').removeClass('modal-z-index')
      $(".modal-max").css("max-height", ($("body").height() - 320) + "px")
      
      _this._tipo_entrega = _this._grid_tipo_frete;
      _this.GetConfiguracoes();
      var model = ListOrcamento.GetSelected(_this._list_orcamento);
      if (model) {
        _this.GetOrcamento(model.id);
        _this.getEstadoValidacaoOrcamento(model.id);
      }
      _this.VerificarButtons()
    });
  }

  ConfirmeRrightEnd() {
    this._titulo_confirm = "Finalizar Compra"
    this._descricao_confirm = "Clique em <b>SIM</b> para finalizar a compra."

    $("#global-confirm-secundario-modal").addClass('show')
    $("#global-confirm-secundario-modal").show()
    $("#orcamento-veiw-modal").addClass('modal-z-index')
    $(".modal-z-index").scrollTop(100)
  }

  ReciverConfirm(confirm: boolean) {
    if (confirm)
      this.EndBudget()
  }

  EndBudget() {
    this._orcamento.status = EnumStatusOrcamento.aprovado
    this._orcamento._temp_frete = this._tipo_entrega;
    this.orcamentoService.Edit(this._orcamento).subscribe(
      d => { $("#orcamento-veiw-modal").modal("hide") },
      e => { },
      () => {
        $("#global-confirm-secundario-modal").removeClass('show')
        $("#global-confirm-secundario-modal").hide()
        $("#global-confirm-secundario-modal").modal('hide')
        $('#orcamento-veiw-modal').removeClass('modal-z-index')
        this.SetModalConfirmacaoCompra();
        this.gridLoad.emit(true);
      }
    )
  }

  VerDetalhada(orcamento_id: number) {
    $("#orcamento-veiw-modal").modal("hide")
    this.router.navigateByUrl("/orcamentos/comprador-view/" + orcamento_id + "/"+this._tipo_entrega);
  }

  GetLinePosition() {
    for (let i = 0; i < this._list_orcamento.length; i++) {
      const e = this._list_orcamento[i];
      if (e._selected)
        return i;
    }
  }

  _button_anterior = false
  _button_proximo = false
  VerificarButtons() {
    this._button_anterior = false
    this._button_proximo = false
    const row = this.GetLinePosition()
    if (row > 0)
      this._button_anterior = true
    if ((row + 1) < this._list_orcamento.length)
      this._button_proximo = true
  }

  SetRight() {
    var line = this.GetLinePosition();
    if (this._list_orcamento.length > (line + 1)) {
      this._list_orcamento.forEach(c => { c._selected = false; });
      this._list_orcamento[(line + 1)]._selected = true;
      const orcamento_id = this._list_orcamento[line + 1].id
      this.GetOrcamento(orcamento_id);
      this.getEstadoValidacaoOrcamento(orcamento_id);
      this.LeftRightView();
    }
  }
  SetLeft() {
    var line = this.GetLinePosition();
    if (line > 0 && this._list_orcamento[line - 1]) {
      this._list_orcamento.forEach(c => { c._selected = false; });
      this._list_orcamento[line - 1]._selected = true;
      const orcamento_id = this._list_orcamento[(line - 1)].id
      this.GetOrcamento(orcamento_id)
      this.getEstadoValidacaoOrcamento(orcamento_id)
      this.LeftRightView();
    }
  }

  _atualizando = false;
  LeftRightView() {
    this._atualizando = true
    setTimeout(() => { this._atualizando = false }, 100)
    this.VerificarButtons()
  }
}