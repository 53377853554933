import { Component, OnInit } from '@angular/core';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { Cotacao } from 'src/app/domain/cotacao/cotacao';
import { Router } from '@angular/router';
import { ListOrcamento } from 'src/app/domain/orcamento/orcamento';
import { RepositoryService } from 'src/app/service/repository.service';
import { Empresa } from 'src/app/domain/empresa/empresa';
import { ObraCotacao, ObraCotacaoAuditor, RelacaoObraEmpresa } from 'src/app/domain/gestao/obra-cotacao';
import { EmailValidate } from 'src/app/domain/auxiliar/EmailValidate';

@Component({
  selector: 'app-obra-cotacao-auditor-list',
  templateUrl: './obra-cotacao-auditor-list.component.html',
  styleUrls: ['./obra-cotacao-auditor-list.component.css']
})
export class ObraCotacaoAuditorListComponent implements OnInit {

  public code: string;
  obras = new Array<ObraCotacaoAuditor>();

  navs = [
    { active: 'active', descricao: 'Todas' },
    { active: '', descricao: 'Faturante' },
    { active: '', descricao: 'Fiscalizadora' },
  ]

  constructor(
    public reposity: RepositoryService,
    public confirmService: AlertConfirmService,
    public userLoggedService: UserLoggedInService,
    public router: Router) {
    this.userLoggedService.SetCurrentUser();
  }

  ngOnInit() {
    this.userLoggedService.SetCurrentUser();
    this.GetAll();
  }

  GetAll() {
    this.reposity.path = "obra-cotacao/auditor/all"
    this.reposity.getAll().subscribe(
      d => {
        if (d && d.length > 0)
          this.obras = d;
      },
      e => { },
      () => {
        let x = 1;
        this.obras.forEach(c => { 
          c._index = x++; 
          c._tipo = RelacaoObraEmpresa._tipo(c.tipo); 
          c._status = EmailValidate.getStatus(c.status_email);
        });
      }
    );
  }

  edit(obraCotacao: ObraCotacao) {
    this.router.navigateByUrl("/obra/auditor/relatorio/" + obraCotacao.id);
  }

  ver(obraCotacao: ObraCotacao) {
    this.router.navigateByUrl("/auditor/obra/relatorio/" + obraCotacao.id);
  }
}