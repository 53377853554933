<div class="col-lg-12 wrapper-top-content">
    <div class="ibox-title">
        <h5>Cadastro Itens Nivel</h5>
    </div>
    <div class="ibox-content    form-categoria-nivel">
        <div class="row">
            <div class="col-sm-5 m-b-xs">
                <Br />
                <div class="form-group">
                    <label>Descrição</label>
                    <div class="row">
                        <div class="col-sm-10">
                            <input type="text" class="form-control" placeholder="Digita à descrição" [(ngModel)]="_categoria.descricao" />
                            <small id="emailHelp" class="form-text text-muted" required>Digite uma descrição para adicionar item.</small>
                        </div>
                        <div class="col-sm-10">
                            <button *ngIf="!(_categoria.id > 0)" type="submit" class="btn btn-primary" (click)="Adicionar()">Adicionar</button>
                            <button *ngIf="(_categoria.id > 0)" type="button" class="btn btn-default" (click)="Cancelar()">Cancelar</button>&nbsp;&nbsp;&nbsp;&nbsp;
                            <button *ngIf="(_categoria.id > 0)" type="submit" class="btn btn-primary" (click)="Editar()">Editar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-7">
                <div class="list-group">
                    <b>Lista de Niveis</b>
                </div>
                <hr />
                <div class="list-group">
                    <a *ngFor="let categoria of _list_categoria" class="dropdown-item padding-top-12">
                        {{categoria.descricao}}
                        <label class="text-primary float-right delete-categoria-nivel cursor-pointer" (click)="ConfirmExcluir(categoria)"><i class="fa fa-times"></i></label>
                        <label class="text-primary float-right cursor-pointer" (click)="ActionEditar(categoria)"><i class="fa fa-edit"></i>&nbsp;&nbsp;</label>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>