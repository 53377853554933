<div class="col-lg-12" style="margin-top: 10px;">
    <div class="">
        <div class="ibox-title" style="background: none; padding-left:0;">
            <h5>CADASTRO DE COTAÇÃO</h5>
        </div>
        <div class="ibox-content padding-7-5" style="background: none;">
            <div class="row">
                <div class="col-lg-6 padding-7-5">

                    <div class="col-lg-12 block-title no-padding" style="padding: 10px;">
                        <i-feather name="edit" style="width: 18px; margin-right:
                            10px;"></i-feather>
                        <b>Digite o nome da cotação</b></div>
                    <div class="form-group col-lg-12 block-content">
                        <div class="block-div-content">
                            <input id="inputState" name="nome" placeholder="Ex.: Material elétrico subsolo" #nome="ngModel" required class="form-control" [(ngModel)]="_cotacao.nome" />
                        </div>
                    </div>


                    <div class="col-lg-12 block-title no-padding">
                        <i-feather name="list" style="width: 18px; margin-right:
                            10px;"></i-feather>
                        <b>Selecione uma obra</b>
                    </div>
                    <div class="form-group col-lg-12 block-content" style="padding: 0px;">
                        <div class="block-div-content">
                            <div class="table-responsive min-height-grid-insumo
                                div-table-fixed custom-scroll" style="max-height: 218px;">
                                <!-- table-striped table-hover table-bordered -->
                                <table class="table table-striped table-hover
                                    no-margins">
                                    <!--
                                    <thead class="thead-fixed">
                                        <tr>
                                            <th class="th-sequence"></th>
                                            <th class="th-nome">Obra</th>
                                            <th class="th-nome">Endereço</th>
                                        </tr>
                                    </thead>
                                -->
                                    <tbody>
                                        <tr *ngFor="let obra of obras | filter :
                                            '_status_auditoria' : 0 : true" class="cursor-pointer">
                                            <td class="text-center th-sequence">{{obra._index}}</td>
                                            <td>{{obra.nome}}</td>
                                            <td class="width-65-px">
                                                <div class="button-checked
                                                    {{(_tempObra.id ==
                                                    obra.id)?'active':''}}" (click)="selecionarObra(obra)">
                                                    <div></div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="obras.length <1">Nenhuma obra encontrada!
                            </div>
                        </div>


                        <!--
                        <div class="col-lg-8">
                            <div class="input-group">
                                <input id="inputState" [disabled]="(_tempObra.id> 0)" name="nome_obra" #nome="ngModel" class="form-control" [(ngModel)]="_cotacao.nome_obra" />
                                <button type="button" class="btn btn-default
                                    button-add-obra" (click)="showModalSelectObra()">Selecionar</button>
                                <br />
                                <label style="padding:5px 30px 5px 5px;" *ngIf="(_tempObra.id> 0)">
                                    <b>Endereço:</b>&nbsp;&nbsp;{{_tempObra._endereco}}
                                    <br />
                                </label>

                                <span class="button-remove-obra" [hidden]="!(_tempObra.id> 0)" data-toggle="tooltip" (click)="removerObra()" data-placement="bottom" title="Remover
                                    Obra!">
                                    <i class="fa fa-times"></i>
                                </span>
                            </div>

                            <div *ngFor="let r_emp of _obra_list_empresas">
                                <div class="{{(_obra_list_empresas[_obra_list_empresas.length
                                    - 1] != r_emp)?'grid-proccess':''}}">
                                    <div class="proccess-b"></div>

                                    <label class="margin-buttom-5
                                        margin-left-10">{{r_emp.nome_fantasia}}</label><br />
                                    <label class="margin-buttom-5
                                        margin-left-10">{{(r_emp.tipo_relacao
                                        == 1)?'FATURANTE':'FISCALIZADORA'}}</label>
                                    <div *ngFor="let r of
                                        r_emp.responsaveis" style="padding:
                                        5px; margin-left: 25px; font-size:
                                        12px;">
                                        <span class="margin-buttom-5"><b>Auditor:</b>&nbsp;&nbsp;{{r.nome}}</span>
                                    </div>
                                    <hr style="margin:5px 0px 0px 0px;" *ngIf="_obra_list_empresas[_obra_list_empresas.length
                                        - 1] != r_emp" />
                                    <br />
                                </div>
                            </div>
                        </div>
                        -->
                    </div>

                    <!-- Tipo Recebimento -->
                    <div class="col-lg-12 block-title no-padding">
                        <i-feather name="truck" style="width: 18px; margin-right:
                            10px;"></i-feather>
                        <b>Selecione o tipo de frete desejado</b>
                    </div>
                    <div class="form-group col-lg-12 block-content">
                        <div class="block-div-content row no-margins">
                            <div class="col-lg-4 no-padding
                                padding-border-grid">
                                <b>CIF</b>
                                <div style="float: right;">
                                    <div class="button-checked
                                        {{(_cif) ?
                                        'active':''}}" (click)="selectCIF()">
                                        <div></div>
                                    </div>
                                </div>
                                <div class="descricao-item descricao-hr">
                                    A mercadoria será entregue pelo fornecedor
                                </div>

                                <b>FOB</b>
                                <div style="float: right;">
                                    <div class="button-checked
                                        {{(_fob) ?
                                        'active':''}}" (click)="selectFOB()">
                                        <div></div>
                                    </div>
                                </div>

                                <div class="descricao-item">
                                    A mercadoria deverá ser retirada no estabelecimento do fornecedor
                                </div>


                                <div style="display: inline-flex; width:100%">
                                    <div class="div-preco-km">
                                        <b>Preço por R$/KM</b>

                                        <div class="descricao-item" style="margin-bottom:
                                            0;padding-bottom: 0;">
                                            Informe o preço de rodagem do seu carregamento
                                        </div>
                                    </div>
                                    <!--<input class="form-control form-control-sm
                                        input-preco-km" currencyMask [options]="{
                                        prefix: 'R$ ',
                                        thousands: '.', decimal: ',' }" [(ngModel)]="_cotacao.preco_por_km" type="text" [disabled]="!_fob" placeholder="0,00">-->
                                    <input class="form-control form-control-sm
                                        input-preco-km" currencyMask [(ngModel)]="_cotacao.preco_por_km" type="text" [disabled]="!_fob" placeholder="0,00">
                                </div>

                            </div>

                            <div class="col-lg-8 padding-right-0 regiao-estado-mobile">
                                <div class="row no-margins">
                                    <div class="col-lg-4 padding-right-0 margin-top-20-990">
                                        <button class="btn btn-default
                                            btn-select-color text-center
                                            text-ellipsis
                                            button-pesquisa width-100" (click)="SelecionarRegiao()" [disabled]="!_fob">Região</button>
                                        <div class="custom-scroll div-regiao-estado-cidade">
                                            <div *ngFor="let regiao of _regioes" class="badge badge-primary espaco-gadge">
                                                {{regiao.Nome}}
                                            </div>
                                        </div>
                                    </div>

                                    <!--*ngIf="_ha_estado"-->
                                    <div class="col-lg-4 padding-right-0 margin-top-20-990">
                                        <button class="btn btn-default
                                            btn-select-color text-center
                                            text-ellipsis
                                            button-pesquisa width-100" (click)="SelecionarEstado()" [disabled]="!_ha_estado">Estados</button>
                                        <div class="custom-scroll
                                            div-regiao-estado-cidade">
                                            <div *ngFor="let estado of _estados" class="badge
                                                badge-primary espaco-gadge">
                                                {{estado.Nome}}
                                            </div>
                                        </div>
                                    </div>
                                    <!--*ngIf="_ha_cidade"-->
                                    <div class="col-lg-4 padding-right-0 margin-top-20-990">
                                        <button class="btn btn-default
                                            btn-select-color text-center
                                            text-ellipsis
                                            button-pesquisa width-100" (click)="SelecionarCidade()" [disabled]="!_ha_cidade">Cidades</button>
                                        <div class="custom-scroll
                                            div-regiao-estado-cidade">
                                            <div *ngFor="let cidade of _cidades" class="badge
                                                badge-primary espaco-gadge
                                                text-ellipsis" style="max-width:
                                                100%;">
                                                {{cidade.Nome}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- end tipo frete -->

                </div>
                <div class="col-lg-6 padding-7-5">


                    <!-- START Data e forna de recebimento -->

                    <div class="col-lg-12 block-title no-padding">
                        <i-feather name="calendar" style="width: 18px; margin-right:
                            10px;"></i-feather>
                        <b>Data e forma de recebimento</b>
                    </div>


                    <div class="form-group col-lg-12 block-content">
                        <div class="block-div-content row no-margins">
                            <div class="col-lg-4 no-padding
                                padding-border-grid">

                                <b>Recebimento Total</b>
                                <div style="float: right;">
                                    <div class="button-checked
                                        {{(_cotacao.recebimento_produto == 1) ?
                                        'active':''}}" (click)="ChangeRecebimento(1)">
                                        <div></div>
                                    </div>
                                </div>
                                <div class="descricao-item descricao-hr">
                                    Informe se quer receber a mercadoria em uma única remessa, total.
                                    <br /><br />
                                </div>

                                <b>Recebimento Parcial</b>
                                <div style="float: right;">
                                    <div class="button-checked
                                        {{(_cotacao.recebimento_produto == 2) ?
                                        'active':''}}" (click)="ChangeRecebimento(2)">
                                        <div></div>
                                    </div>
                                </div>
                                <div class="descricao-item" style="margin-bottom:
                                    0;padding-bottom: 0;">
                                    Informe se quer receber a mercadoria parcialmente.
                                </div>
                            </div>

                            <div class="col-lg-8 padding-right-0
                                grid-recebimento-mobile">
                                <div class="float-left width-100" style="margin-top:0px">
                                    <div class="float-left" style="width:calc(100% - 150px);
                                        padding-top:0px;"><b>Validade da cotação</b>
                                        <div class="descricao-item">
                                            Informe a data limite em que deseja receber as propostas. Esta data também será a do fechamento da negociação.
                                        </div>
                                    </div>
                                    <div class="form-group width-140-px
                                        float-right">
                                        <div class="input-group">
                                            <input class="form-control
                                                date-limit" placeholder="dd/mm/yyyy" (ngModelChange)="ChangeDateLimite()" name="data_limite" [(ngModel)]="data_limite" ngbDatepicker #data_max_recebimento_orcamento="ngbDatepicker" [minDate]="data_atual">
                                            <div class="input-group-append">
                                                <button class="btn
                                                    btn-outline-secondary
                                                    calendar fa
                                                    fa-calendar-alt" (click)="data_max_recebimento_orcamento.toggle();
                                                    toggleCalendar();" type="button"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="float-left width-100">
                                    <div class="float-left" style="width:calc(100% - 150px);"><b>Data
                                            de recebimento
                                            da mercadoria</b>
                                        <div class="descricao-item" style="margin-bottom:
                                            0;padding-bottom: 0;">
                                            Informe a data que o fornecedor deverá entregar a mercadoria.
                                        </div>
                                    </div>
                                    <div class="form-group width-140-px
                                        float-right">
                                        <div class="input-group">
                                            <input class="form-control
                                                date-recebimento" placeholder="dd/mm/yyyy" (ngModelChange)="ChangeDateRecebimento()" name="teste" [(ngModel)]="data_recebimento" ngbDatepicker #dataRecebimento="ngbDatepicker" [minDate]="data_limite">
                                            <div class="input-group-append">
                                                <button class="btn
                                                    btn-outline-secondary
                                                    calendar fa
                                                    fa-calendar-alt" (click)="dataRecebimento.toggle();
                                                    toggleCalendar();" type="button"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- END -------- Data e forna de recebimento -->


                    <!-- START -------- Forma de pagamento -->

                    <div class="col-lg-12 block-title no-padding">
                        <i-feather name="dollar-sign" style="width: 18px; margin-right:
                            10px;"></i-feather>
                        <b>Formas de pagamento</b>
                    </div>


                    <div class="form-group col-lg-12 block-content">
                        <div class="block-div-content row no-margins">
                            <div class="col-lg-4 no-padding
                                padding-border-grid">

                                <b>Pagamento à vista</b>
                                <div style="float: right;">
                                    <div class="button-checked
                                        {{(_cotacao.forma_pagamento == 1) ?
                                        'active':''}}" (click)="selectFormaPagamento(1)">
                                        <div></div>
                                    </div>
                                </div>
                                <div class="descricao-item descricao-hr">
                                    Pagamento total ao credor em uma única vez.
                                </div>

                                <b>Pagamento à prazo</b>
                                <div style="float: right;">
                                    <div class="button-checked
                                        {{(_cotacao.forma_pagamento == 2) ?
                                        'active':''}}" (click)="selectFormaPagamento(2)">
                                        <div></div>
                                    </div>
                                </div>
                                <div class="descricao-item" style="margin-bottom: 0; padding-bottom:
                                    0;">
                                    Pagamento com prestações ao credor.
                                </div>
                            </div>

                            <div class="col-lg-8 padding-right-0
                                grid-pagamento-mobile">

                                <div class="float-left width-100" style="margin-top:0px">
                                    <div class="float-left" style="width:calc(100% - 190px);
                                        padding-top:0px;"><b>Meio de pagamento</b>
                                        <div class="descricao-item">
                                            Selecione um dos meios de pagamento para continuar.
                                        </div>
                                    </div>
                                    <div class="form-group width-140-px
                                        float-right">
                                        <select id="inputState" class="form-control" [(ngModel)]="_cotacao.meio_pagamento" (change)="selectFormaPagamento(_cotacao.forma_pagamento)" [disabled]="(_cotacao.forma_pagamento
                                        <1)">
                                            <option value="0" disabled>Selecionar</option>
                                            <option value="1"
                                                [hidden]="(_cotacao.forma_pagamento
                                                == 2)">Dinheiro</option>
                                            <option value="2"
                                                [hidden]="(_cotacao.forma_pagamento
                                                == 2)">Transferência
                                                Bancária</option>
                                            <option value="3">Boleto
                                                Bancário</option>
                                            <option value="4">Cartão Crédito</option>
                                            <option value="5">Cartão BNDES</option>
                                        </select>
                                    </div>
                                </div>

                                <!-- [hidden]="!_payment" -->
                                <div class="float-left width-100" style="margin-top:0px">
                                    <div class="float-left" style="width:calc(100% - 150px);
                                        padding-top:0px;"><b>Período de
                                            pagamento</b>
                                        <div class="descricao-item" style="margin-bottom:
                                            0;padding-bottom: 0;">
                                            Selecione um periodo de pagamento, para continuar. Sujeito análise de crédito.
                                        </div>
                                    </div>
                                    <div class="form-group width-140-px
                                        float-right">

                                        <select id="inputState" class="form-control" [(ngModel)]="_cotacao.periodo_pagamento" [disabled]="_list_prazo.length <1">
                                            <option value="0">Selecionar</option>
                                            <option *ngFor="let prazo of
                                                _list_prazo"
                                                value="{{prazo.id}}">{{prazo.descricao}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END -------- Data e forna de recebimento -->

                    <!-- START Data e forna de recebimento -->
                    <div class="col-lg-12 block-title no-padding">
                        <i-feather name="map-pin" style="width: 18px; margin-right:
                            10px;"></i-feather>
                        <b>Endereço da obra</b>
                    </div>

                    <div class="form-group col-lg-12 block-content
                        grid-endereco-mobile">
                        <div class="block-div-content row no-margins">

                            <div class="width-80-px col-form-label
                                width-120-px-mobile"><b>CEP</b></div>
                            <div class="width-180-px">
                                <input type="text" [(ngModel)]="_tempEndereco.cep" id="cep" class="form-control width-100-px" disabled>
                            </div>
                            <div class="dividir-mobile"></div>

                            <div class="width-120-px col-form-label
                                padding-left-input">
                                <b>Número</b> </div>
                            <div class="width-80-px">
                                <input type="text" [(ngModel)]="_tempEndereco.numero" class="form-control" disabled>
                            </div>

                            <div class="row no-margins col-lg-12 no-padding
                                margin-top-10">
                                <div class="width-80-px col-form-label
                                    width-120-px-mobile"><b>Endereço</b>
                                </div>
                                <div class="endereco-width">
                                    <input type="text" [(ngModel)]="_tempEndereco.endereco" #endereco="ngModel" class="form-control" disabled>
                                </div>
                            </div>

                            <div class="row no-margins col-lg-12 no-padding
                                margin-top-10">
                                <div class="width-80-px col-form-label
                                    width-120-px-mobile"><b>Bairro</b>
                                </div>
                                <div class="width-180-px">
                                    <input class="form-control" [(ngModel)]="_tempEndereco.bairro" disabled>
                                </div>
                                <div class="dividir-mobile"></div>
                                <div class="width-120-px col-form-label
                                    padding-left-input"><b>Complemento</b></div>
                                <div class="width-80-px">
                                    <input class="form-control" [(ngModel)]="_tempEndereco.complemento" disabled>
                                </div>
                            </div>

                            <div class="row no-margins col-lg-12 no-padding
                                margin-top-10">
                                <div class="width-80-px col-form-label
                                    width-120-px-mobile" width-120-px-mobile><b>Estado</b>
                                </div>
                                <div class="width-180-px">
                                    <input [(ngModel)]="_tempEndereco.uf" class="form-control width-60-px" disabled>
                                </div>
                                <div class="dividir-mobile"></div>
                                <div class="width-120-px col-form-label
                                    padding-left-input"><b>Cidade</b> </div>
                                <div class="width-160-px">
                                    <input [(ngModel)]="_tempEndereco.cidade_nome" class="form-control" disabled>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- END -------- Data e forna de recebimento -->


                    <!--
                    <div class="row no-margin">
                        <div class="form-group col-lg-6 row" *ngIf="!(_tempObra.id> 0)">
                            <label class="col-lg-4 col-form-label"><b>Endereço
                                    para Entrega</b></label>
                            <div class="col-lg-8 row-endereco">
                                <label *ngIf="!_enderecos"> Não há endereços
                                    disponivéis para obra selecionada.</label>
                                <ul class="list-group list-group-flush
                                    list-enderecos custom-scroll">
                                    <li class="list-group-item" *ngFor="let
                                        endereco of _enderecos">
                                        <label class="action-endereco">
                                            <i class="fa fa-edit"
                                                (click)="EditEndereco(endereco)"
                                                *ngIf="(endereco._origem ==
                                                0)"></i>&nbsp;&nbsp;
                                            <i class="fa fa-trash-alt"
                                                (click)="ConfirmDeleteEndereco(endereco)"
                                                *ngIf="(endereco._origem ==
                                                0)"></i>
                                        </label>
                                        <div class="custom-control
                                            custom-radio
                                            custom-control-inline">
                                            <input type="radio" id="radioEndecoEntrega{{endereco.id}}" value="{{endereco.id}}" name="radioEndecoEntrega" class="custom-control-input">
                                            <label class="custom-control-label" for="radioEndecoEntrega{{endereco.id}}">
                                                <b>Endereço:</b> {{
                                                endereco.endereco }},
                                                <b
                                                    *ngIf="(endereco.numero)">n°:
                                                </b> {{ endereco.numero }}
                                                {{ endereco.complemento }}<br/>
                                                    <b>Bairro:</b> {{
                                                    endereco.bairro }},
                                                    <b>Cidade:</b> {{
                                                    endereco.cidade_nome }},
                                                    <b>Estado:</b> {{
                                                    endereco.uf }}
                                                    <b>CEP: </b>{{
                                                    endereco.cep
                                                    }}
                                                </label>
                                        </div>
                                    </li>
                                </ul>

                                <div *ngIf="_msg_error_endereco" class="input-error-msg
                                        text-danger">
                                    <div>
                                        {{_msg_error_endereco}}
                                    </div>
                                </div>
                                <button class="btn btn-default
                                        button-new-endereco" (click)="NovoEndereco()">Cadastrar
                                        Endereço</button>
                            </div>
                        </div>

                    </div>
                    <hr />
                -->
                    <div class="text-right">
                        <button type="button" *ngIf="!cotacao_id" class="btn
                            btn-default btn-default-icon" (click)="Reload()">LIMPAR<i
                                class="fa fa-redo"></i></button>
                        <button type="button" *ngIf="cotacao_id" class="btn
                            btn-default btn-default-icon" (click)="Reload()">RESETAR<i
                                class="fa fa-redo"></i></button>
                        <button type="submit" class="btn btn-primary
                            btn-default-icon
                            margin-left-10" (click)="Insert()">SEGUINTE<i
                                class="fa fa-arrow-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-localidade [_input_modal]=[_input_modal] (_resposta_modal)="ReceiverRespostaModal($event)"></app-localidade>
    <app-endereco-modal [_input_endereco]="_input_endereco" (_endereco_modal)="ReceiverRespostaEnderecoModal($event)"></app-endereco-modal>
    <app-message-modal [_titulo]="_msg_titulo" [_descricao]="_msg_descricao"></app-message-modal>
    <!-- Modal -->
    <div class="modal fade" id="select-list-modal" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable
            modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Lista de Obras/Empresas
                    </h4>
                    <div class="ibox-tools">
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="modal-body modal-confirm" style="padding: 1rem;">
                    <div class="table-responsive min-height-grid-insumo
                        div-table-fixed margin-top-15">
                        <!-- table-striped table-hover table-bordered -->
                        <table class="table table-striped table-hover">
                            <thead class="thead-fixed">
                                <tr>
                                    <th class="th-sequence"></th>
                                    <th class="th-nome">Obra</th>
                                    <th class="th-nome">Endereço</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let obra of obras | filter :
                                    '_status_auditoria' : 0 : true" class="btn-link
                                    cursor-pointer" (click)="selecionarObra(obra)">
                                    <td class="text-center th-sequence">{{obra._index}}</td>
                                    <td>{{obra.nome}}</td>
                                    <td>{{obra._endereco}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div *ngIf="obras.length <1">Nenhuma obra encontrada!
                    </div>

                    <br />
                    <br />

                    <div class="row no-margin">
                        <div class="col-lg-6 text-left margin-top-20">
                        </div>
                        <div class="col-lg-6 text-right margin-top-20">
                            <button class="btn btn-default margin-right-10" data-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>