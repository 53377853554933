<div class="col-lg-12 wrapper-top-content">
    <div class="ibox">
        <div class="ibox-title" style="padding-right:20px;">
            <h5>Extrato Financeiro</h5>
            <div style="float: right;">

                Status: <select class="" [(ngModel)]="_status" (change)="OnChangeSearch()">
                    <option value="0">Aberto</option>
                    <option value="1">Pago</option>
                    <option value="2">Cancelado</option>
                    <option value="3">Gratuito</option>
                </select>&nbsp;&nbsp;&nbsp; Periodo: <input type="date" [(ngModel)]="_inicio" (change)="OnChangeSearch()"> à
                <input type="date" [(ngModel)]="_fim" (change)="OnChangeSearch()">
            </div>
        </div>

        <div class="ibox-content">

            <div *ngIf="_transacoes.length> 0">
                <strong>Empresa: </strong> &nbsp;&nbsp;{{_transacoes[0].nome_fantasia}}
                <br />
                <strong>CNPJ: </strong> &nbsp;&nbsp;{{_transacoes[0].cnpj}}
            </div>

            <div class="table-responsive min-height-grid-insumo div-table-fixed
                margin-top-15">
                <!-- table-striped table-hover table-bordered -->
                <table class="table table-striped">
                    <thead class="thead-fixed">
                        <tr>
                            <th class="th-sequence"></th>
                            <th class="th-nome">Nome</th>
                            <th class="text-center th-codigo">Codigo</th>
                            <th class="text-center th-codigo">Data</th>
                            <th class="text-right th-data">Subtotal R$</th>
                            <th class="text-right th-data">Total R$</th>
                            <th class="text-right th-data">Total Painel R$</th>
                            <th class="text-center th-action"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="" *ngFor="let model of _transacoes">
                            <td class="text-center th-sequence">{{model._index}}</td>
                            <td>{{model.cotacao_nome}}</td>
                            <td class="text-center th-codigo">{{model.codigo}}</td>
                            <td class="text-center th-codigo">{{model._data}}</td>
                            <td class="text-right th-data">{{model.sub_total}}</td>
                            <td class="text-right th-data">{{model.valor_total}}</td>
                            <td class="text-right th-data">{{model.total_painel}}</td>
                            <td class="text-center th-action-anexo">
                                <i class="fa fa-search btn-link cursor-pointer" (click)="Ver(model)">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div *ngIf="_transacoes.length <= 0">Nenhuma ordem de venda encontrada!
            </div>
            <br />
            <div class="row">
                <div class="col-sm-8">
                    <div *ngIf="_contatosFinanceiros.length> 0">
                        <h4>Contatos Financeiros:</h4>
                        <div *ngFor="let contato of _contatosFinanceiros" style="margin-bottom:20px">
                            <strong>Nome:</strong>&nbsp;&nbsp;{{contato.nome}}<br/>
                            <strong>Email:</strong>&nbsp;&nbsp;{{contato.email}}<br/>
                            <strong>Telefone:</strong>&nbsp;&nbsp;{{contato.telefone}}<br/>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 text-right">
                    <button type="submit" class="btn btn-default" (click)="RetornarParaLista()"><i class="fa
                                        fa-arrow-left"></i>&nbsp;&nbsp;Voltar</button>
                </div>
            </div>
        </div>
    </div>
</div>