import { Component, OnInit, ComponentFactoryResolver, EventEmitter, Output, Input } from '@angular/core';
import { InsumoDoFornecedor } from 'src/app/domain/suprimento/produto-fornecedor';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { ProdutoCategoriaNivel, TipoSelecao } from 'src/app/domain/suprimento/produto-categoria-nivel';
import { ProdutoService } from 'src/app/service/suprimento/produto.service';
import { ProdutoComprador } from 'src/app/domain/suprimento/comprador/produto-comprador';
import { InsumoComponentCtrl } from '../../insumo/insumo-component-ctrl';

declare var $: any;

@Component({
  selector: 'app-buscar-produto-component',
  templateUrl: './buscar-produto.component.html',
  styleUrls: ['./buscar-produto.component.css'],
  providers: [ProdutoService]
})
export class BuscarProdutoComponent extends InsumoComponentCtrl implements OnInit {

  @Output() _produtos_adicionados: EventEmitter<ProdutoComprador> = new EventEmitter();
  @Input() _input_cotacao;
  @Input() _buscar_produto;
  @Input() _index;
  @Input() _categoria_index;

  active = false

  public _input_nivel: ProdutoCategoriaNivel;

  ngOnInit() {
    this._niveis = new Array<ProdutoCategoriaNivel>();
    this.SetListaCatetorias(false)
    this.SetListaUnidadeMedida()
    var _this = this;
    setTimeout(() => {
      $('#_search_' + this._categoria_index + '_' + this._index).focus();
      $('#_search_' + this._categoria_index + '_' + this._index).on("input", function () {        
        _this._buscar = _this._buscar_produto
        if ($(this).val().length < 3)
          return;
        _this.active = true
        _this.OnChangeSearch()
      })
    }, 500);
  }

  SelectCores(nivel: ProdutoCategoriaNivel) {
    this._input_nivel = nivel;
    this._input_nivel._multiple = (nivel.multiplo == TipoSelecao.Multipla);
    $("#pesquisa-cor-modal").modal('show');
  }

  ReciverCoresSelecionadas(nivel: ProdutoCategoriaNivel) {
    $("#pesquisa-cor-modal").modal('hide');

    this._input_nivel._selected = 0;
    nivel.nivel.forEach(c => {
      if (c._selecionado)
        this._input_nivel._selected = c.id;
    });
    this.OnChangeSearchNivel(nivel);
  }
  cancelar() {
    const produto = new ProdutoComprador()
    produto._index = this._index
    produto._categoria_index = this._categoria_index
    this.active = false
    this._produtos_adicionados.emit(produto);
  }

  addRow(produto: ProdutoComprador) {
    produto._index = this._index
    produto._categoria_index = this._categoria_index
    this._produtos_adicionados.emit(produto);
  }
}