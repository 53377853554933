<div class="col-lg-12 wrapper-top-content">
    <div class="ibox-title">
        <h5>Gerênciar Categorias Virtuais</h5>
    </div>

    <div class="ibox-content    form-categoria-nivel">
        <div class="row">

            <div class="col-sm-6 m-b-xs">
                <b>Lista de Categorias Virtuais: </b> <br />
                <Br />
                <div class="form-group">
                    <label>Descrição</label>
                    <div class="row">
                        <div class="col-sm-8">
                            <input type="text" class="form-control" placeholder="Digita a descrição" [(ngModel)]="_text_categoria_pai" />
                            <small id="emailHelp" class="form-text text-muted">Digite
                                o nome pra cadastrar uma nova categoria pai.</small>
                        </div>

                        <div class="col-sm-4">
                            <button type="submit" class="btn btn-primary" (click)="AdicionarCategoriaPai()">Adicionar</button>
                        </div>
                    </div>
                </div>
                <Br />
                <div class="row">
                    <div class="col-sm-6 lista-categoria-virtual">
                        <div class="list-group">
                            <a *ngFor="let categoria of _categorias_virtuais" class="dropdown-item    padding-top-12 {{(_produto_categoria_virtual.id == categoria.id) ? 'active': ' '}}" (click)="SelectCategoriaVirtual(categoria)">{{categoria.nome}}
                            
                                <label class="text-primary float-right delete-categoria-nivel" *ngIf="(_produto_categoria_virtual.id == categoria.id)"
                                (click)=ClickRemoveAll(categoria)>x</label>
                            </a>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div>
                            <p class="    " *ngFor="let categoria of _produto_categoria_virtual.categorias">{{categoria.descricao}}</p>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-sm-6 m-b-xs">
                <div class="select-categorias">
                    <div class="form-check" *ngFor="let categoria of
                        _categorias| filter : 'origem' : 0 : true">
                        <input class="form-check-input" type="checkbox" value="categoria.id" [checked]="categoria._active" (change)="ChangeCategoria($event, categoria)" id="defaultCheck{{categoria.id}}">
                        <label class="form-check-label" for="defaultCheck{{categoria.id}}">
                            {{categoria.descricao}}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>