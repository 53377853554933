export class Pagination {

    Pagination(){
        this.ultima_pagina = 0;
        this.active_one = false;
    }

    public page: number;
    public active: boolean;
    public ultima_pagina: number;
    public active_one: boolean;

    public static SetPage(page: number, total: number, quantidade: number = 5): Pagination[] {
        var list_pagination = new Array<Pagination>();
        let for_page = (total > quantidade) ? quantidade : total;

        var active_on = false;
        let qt = page;
        for (let i = 0; i < for_page; i++) {
            
            let active = false;

            if(i == 0 && qt > 1)
                qt--;            
            else if(qt == page)
                active = true;

            if(!active_on)
                active_on = active && page > 2 ? true : false;
          
            var pagination = new Pagination();
            pagination.page = qt;
            pagination.active = active;
            list_pagination.push(pagination);   

            qt++;
            if(qt > total)
                break;
        }
        if(list_pagination.length < 1)
            list_pagination.push(new Pagination());

        list_pagination[0].active_one = active_on;
        list_pagination[0].ultima_pagina = (total > quantidade && (list_pagination[0].page + 4) < total) ? total : 0;

        return list_pagination;
    }
}