import { Component, OnInit } from '@angular/core';
import { CotacaoService } from 'src/app/service/suprimento/comprador/cotacao.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { Cotacao } from 'src/app/domain/cotacao/cotacao';
import { Router } from '@angular/router';
import { EnumStatusOrcamento, ListOrcamento, EnumTipoOrcamento, EnumStatusTipoOrcamento } from 'src/app/domain/orcamento/orcamento';

@Component({
  selector: 'app-orcamento-solicitacao-historico',
  templateUrl: './orcamento-solicitacao.component.html',
  styleUrls: ['./orcamento-solicitacao.component.css']
})
export class OrcamentoSolicitacaoHistoricoComponent implements OnInit {

  _titulo = "Histórico de Cotações";
  public _list_cotacao: Array<Cotacao>;
  public _cotacao: Cotacao;

  constructor(
    private cotacaoService: CotacaoService,
    private confirmService: AlertConfirmService,
    private userLoggedService: UserLoggedInService,
    private router: Router) {
    this.userLoggedService.SetCurrentUser();
    this._list_cotacao = new Array<Cotacao>();
  }

  ngOnInit() {
    this.GetAll();
  }

  GetAll() {
    this.cotacaoService.GetAllFornecedor(this.userLoggedService.id, EnumStatusOrcamento.enviado).subscribe(
      d => {
        if (d && d.length > 0)
          this._list_cotacao = d;
      },
      e => { },
      () => {
        var x = 1
        this._list_cotacao.forEach(c => {
          c._index = x++
          Cotacao.SetFormat(c)
          c._orcamento_status_nome = ListOrcamento.GetStatus(c._orcamento_status)
        });
      }
    );
  }

  Ver(cotacao: Cotacao) {
    if (cotacao._tipo == EnumTipoOrcamento.sugerido){
      if(cotacao._status_por_tipo_orcamento == EnumStatusTipoOrcamento.reenviado)
        this.router.navigateByUrl("/orcamentos/sugerido/view/" + cotacao._orcamento_id);
      else
        this.router.navigateByUrl("/orcamentos/sugerido/edit/" + cotacao._orcamento_id);
    }else
      this.router.navigateByUrl("/orcamentos/view/" + cotacao._orcamento_id);
  }





}
