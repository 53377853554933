import { Component, OnInit } from '@angular/core';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { ProdutoCategoria, TypeEnumGeral, EnumGeral } from 'src/app/domain/suprimento/produto-categoria';
import { RepositoryService } from 'src/app/service/repository.service';
import { ProdutoService } from 'src/app/service/suprimento/produto.service';
import { ActivatedRoute, Route } from '@angular/router';
import { Produto } from 'src/app/domain/suprimento/produto';

@Component({
  selector: 'app-cadastro-item-nivel',
  templateUrl: './cadastro-item-nivel.component.html',
  styleUrls: ['./cadastro-item-nivel.component.css']
})
export class CadastroItemNivelComponent implements OnInit {

  code = 'app-cadastro-item-nivel';
  _categoria = new EnumGeral();
  _list_categoria = new Array<EnumGeral>();

  _produto = new Produto();



  constructor(
    private httpProduto: ProdutoService,
    private repository: RepositoryService,
    private confirmService: AlertConfirmService,
    private route: ActivatedRoute
    ) {
    this.confirmService.UnsetObservers();
    this.confirmService.confirmEmitter$.subscribe(c => this.Excluir(c));
    this.Cancelar();
  }

  categoria_id = this.route.snapshot.params['categoria_id'];
  nivel_id = this.route.snapshot.params['nivel_id'];
  produto_id = this.route.snapshot.params['produto'];


  ngOnInit() {
    this.GetAll();
    this.getProduto(this.produto_id);
  }

  getProduto(id: any) {
    this.httpProduto.getProduto(id).subscribe(
      data => {
        if (data && data.id == id) {
          this._produto = data
          this._produto.prod_validado = (this._produto.prod_validado == "S")
        }
      },
      error => { },
      () => { }
    );
  }

  GetAll() {
    this.repository.path = 'enumgeral/getall';
    this.repository.getAll('_&tipo=' + this.nivel_id + '&objeto=' + this.categoria_id).subscribe(
      d => { this._list_categoria = d; },
      e => { },
      () => {
        this.Cancelar();
      }
    );
  }

  Adicionar() {
    this._categoria.ativo = 1;
    this.repository.path = 'enumgeral/save';
    this._categoria.tipo = this.nivel_id;
    this._categoria.item_id = this.categoria_id;
    this.repository.post(this._categoria).subscribe(
      d => { },
      e => { },
      () => {
        this.GetAll();
      }
    );
  }

  Cancelar() {
    this._categoria = new ProdutoCategoria();
  }

  ActionEditar(data: ProdutoCategoria) {
    this._categoria = data;
  }

  Editar() {
    this.Adicionar();
  }

  ConfirmExcluir(data: ProdutoCategoria) {
    this._categoria = data;
    this.confirmService._code = this.code;
    this.confirmService._title = "Excluir Nivel";
    this.confirmService._message = "Deseja excluir o nivel " + data.descricao + "?";
    this.confirmService.Show();
  }

  Excluir(confirm: boolean = false) {
    if (!(this.confirmService._code != this.code || confirm == false)) {
      this.repository.path = 'enumgeral/delete';
      this.repository.post(this._categoria).subscribe(d => { this.GetAll(); });
    }
  }
}
