import { Component, OnInit } from '@angular/core';
import { ProdutoService } from 'src/app/service/suprimento/produto.service';
import { InsumoComponentCtrl } from '../../suprimento/insumo/insumo-component-ctrl';
import { ProdutoCategoriaNivel } from 'src/app/domain/suprimento/produto-categoria-nivel';
import { Produto } from 'src/app/domain/suprimento/produto';

@Component({
  selector: 'app-produto-list',
  templateUrl: './produto-list.component.html',
  styleUrls: ['./produto-list.component.css'],
  providers: [ProdutoService]
})
export class ProdutoListComponent extends InsumoComponentCtrl implements OnInit {  
  ngOnInit() {    
    this.userLoggedService.AllowedOperations();
    this.confirmService.UnsetObservers();
    this.confirmService.confirmEmitter$.subscribe(c => { this.DeleteProductProvider(c); });
    this._niveis = new Array<ProdutoCategoriaNivel>();
    this.SetListaCatetorias();
    this.SetListaUnidadeMedida();    
  }
  edit(produto: any){ this.router.navigateByUrl('auxiliar/insumo/form/'+produto.id); }
}