import { Component, OnInit } from '@angular/core';
import { SolicitacaoService } from 'src/app/service/suprimento/solicitacao.service';
import { SolicitacaoProduto, SolicitacaoProdutoLog } from 'src/app/domain/suprimento/solicitacao-produto';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { StatusSolicitacaoProdutoEnum } from 'src/app/domain/suprimento/status-solicitacao-produto-enum';
import { Produto } from 'src/app/domain/suprimento/produto';
import { ProdutoService } from 'src/app/service/suprimento/produto.service';
import { empty } from 'rxjs';
import { parse } from 'url';
import { Pagination } from 'src/app/domain/common/pagination';
import { InsumoDoFornecedor } from 'src/app/domain/suprimento/produto-fornecedor';
import { SuprimentoService } from 'src/app/service/suprimento/suprimento-service';
declare var $: any;
@Component({
  selector: 'app-solicitacao-admin',
  templateUrl: './solicitacao-admin.component.html',
  styleUrls: ['./solicitacao-admin.component.css']
})
export class SolicitacaoAdminComponent implements OnInit {

  public _solicitacoes: SolicitacaoProduto[];
  public _solicitacao_selecionada: SolicitacaoProduto;
  public _produto: InsumoDoFornecedor;
  public _buscar: string;
  public _hidden: boolean;
  public _status_select: number;
  public _filter: number;
  
  public _count_grid: number;
  public _total_pesquisa: number;
  public _paginas: Pagination[];

  _status_solicitacao_enum = StatusSolicitacaoProdutoEnum;

  keys(): Array<string> {
    var keys = Object.keys(StatusSolicitacaoProdutoEnum);
    return keys.slice(keys.length / 2);
  }


  constructor(private solicitacaoService: SolicitacaoService, private produtoService: ProdutoService, private confirmService: AlertConfirmService, private suprimentoService: SuprimentoService) {
    this.confirmService.UnsetObservers();
    //this.confirmService.confirmEmitter$.subscribe(c => this.DeleteSolicitacao(c));
  }

  ngOnInit() {


    this.Clear();
    this._solicitacoes = new Array<SolicitacaoProduto>();
    this.GetAllSolicitacao(0);
    this.solicitacaoService.LoadingPage();
  }

  VerSolicitacao(solicitacao: SolicitacaoProduto): void {    
    this.Clear();

    if (solicitacao.item_id > 0) {
      this.suprimentoService.get(solicitacao.item_id).subscribe(
        d => { this._produto = d;  }
      );
    }

    setTimeout(() => {
      this._solicitacao_selecionada = solicitacao;
      this._status_select = solicitacao.status;
      this.SetLog(this._solicitacao_selecionada);
    }, 10);
  }

  SaveSolicitacao(): void {
    this._solicitacao_selecionada.status = this._status_select;

    if (this._status_select == StatusSolicitacaoProdutoEnum.Improcedente) {
      this._solicitacao_selecionada.item_id = 0;
    }

    this.solicitacaoService.SetSolicitationProvider(this._solicitacao_selecionada).subscribe(
      d => { },
      e => { this._solicitacao_selecionada.status = StatusSolicitacaoProdutoEnum.Aberto },
      () => { this.GetAllSolicitacao(0); }
    );
  }

  OnChangeSearch() {
    this.DesvicularItem(empty);
  }

  GetAllSolicitacao(filter: number, page: number = 1): void {
    this.Clear();
    this._buscar = "";
    this._filter = filter;
    this.solicitacaoService.GetAll(filter, page).subscribe(
      d => { this._solicitacoes = d; },
      e => { },
      () => {
        this._solicitacoes.forEach(c => {
          c._data_formatada = UseFullService.formatDateTime(new Date(c.data_criacao));
          c._status_descricao = UseFullService.OneToLowerCase(StatusSolicitacaoProdutoEnum[c.status]);
        });
        this.SetConfigProduto(page);
      }
    );
  }
  
  SetConfigProduto(page: number) {
    this._count_grid = this._solicitacoes.length;
    if (this._solicitacoes != null && this._solicitacoes.length > 0) {
      this._total_pesquisa = this._solicitacoes[0]._count_pesquisa;
      var count_page = UseFullService.CalcularPaginacao(100, this._total_pesquisa);
      this._paginas = Pagination.SetPage(page, count_page);
    }
  }
  
  GetPage(page: number): void{
    this.GetAllSolicitacao(this._filter, page);
  }

  OrdenarLog(log: SolicitacaoProdutoLog[]) {
    log.sort((a, b) => {
      var dateA = new Date(a.data).getTime();
      var dateB = new Date(b.data).getTime();
      return dateA < dateB ? 1 : -1;
    });
  }

  //organizar log para ser exibido.
  SetLog(c: SolicitacaoProduto) {

    if (c.log != null && c.log != '') {
      var data = c.log.split('|');
      c._log = new Array<SolicitacaoProdutoLog>();

      var data_count = data.length - 1;
      data.forEach((l, x) => {
        if (l != "") {
          var log = JSON.parse(l) as SolicitacaoProdutoLog;
          log.produto = new InsumoDoFornecedor();

          if (log.item_id > 0) {
            this.suprimentoService.get(log.item_id).subscribe(
              d => { log.produto = d; },
              e => { },
              () => {
                c._log.push(log);
              }
            );
          } else
            c._log.push(log);

          if (x == data_count) {
            setTimeout(() => {
              this.OrdenarLog(c._log);
            }, 500);
          }
        }
      });
    }
  }

  Clear(): void {
    this._solicitacao_selecionada = new SolicitacaoProduto();
    this._produto = new InsumoDoFornecedor();
  }

  ReciverPesquisaProduto(produto: InsumoDoFornecedor): void {
    this._solicitacao_selecionada.item_id = produto.id;
    this._produto = produto;
  }

  SugerirProduto(): void {
    $('#pesquisa-insumo-modal').modal('show');
  }

  DesvicularItem(e) {
    if (e != empty)
      e.preventDefault();
    this._produto = new InsumoDoFornecedor();
    this._solicitacao_selecionada.item_id = 0;
  }
}