<div class="col-lg-12 wrapper-top-content">
    <div class="ibox-title">
        <h5>Gerênciar Funcões do Sistema</h5>
    </div>

    <div class="ibox-content    form-categoria-nivel">
        <div class="row">

            <div class="col-sm-5 m-b-xs">
                <Br />
                <div class="form-group">
                    <label>Rota</label>
                    <div class="row">
                        <div class="col-sm-8">
                            <input type="text" class="form-control" placeholder="Digita à rota" required [(ngModel)]="_funcao_sistema.rota" />
                        </div>
                    </div>
                    <Br />
                    <label>Descrição</label>
                    <div class="row">
                        <div class="col-sm-8">
                            <textarea class="form-control" placeholder="Digita à descrição" [(ngModel)]="_funcao_sistema.descricao"></textarea>
                            <small id="emailHelp" class="form-text text-muted" required>Digite uma descrição para rota.</small>
                        </div>

                        <div class="col-sm-4">
                            <button type="submit" class="btn btn-primary" (click)="AdicionarFuncao()">Adicionar</button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-sm-7">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead class="thead-dark thead-fixed">
                            <tr>
                                <th class="text-center">Rota</th>
                                <th class="text-center">Descrição</th>
                                <th class="text-center">Operações</th>
                                <th class="text-center">#</th>
                            </tr>
                        </thead>
                        <tbody class="  " *ngFor="let funcao of
                            _funcoes_sistema">
                            <tr>
                                <td>
                                    <textarea type="text" class="form-control
                                        input-table" style="resize: none" rows="4" required [(ngModel)]="funcao.rota" (change)="Editar(funcao)"></textarea>
                                </td>
                                <td>
                                    <textarea type="text" class="form-control
                                        input-table" style="resize: none" rows="4" required [(ngModel)]="funcao.descricao" (change)="Editar(funcao)"></textarea>
                                </td>
                                <td>
                                    <div>

                                        <div class="form-check" *ngFor="let operacao of funcao._operacoes">
                                            <input class="form-check-input" type="checkbox" value="" [checked]="operacao.selected" (change)="ChangeNivel($event, funcao, operacao.id)" id="defaultCheck0
                                                padding-top-12">
                                            <label class="form-check-label
                                                dropdown-item
                                                  " for="defaultCheck0" (click)="SelectItem()">
                                                {{operacao.descricao}}
                                            </label>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <a href="#" (click)="ClickDelete($event, funcao)">x</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>