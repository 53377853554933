<div class="row border-bottom">
    <nav class="navbar navbar-fixed-top" role="navigation" style="margin-bottom:
        0">
        <div class="navbar-header">
            <img src="https://www.paineldaengenharia.com.br/public/imagens/logo_horizontal_branca_.svg" style="max-height: 60px; padding:5px;" />


            <div class="btn-group nav-btn-group">
                <span class="btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                    <i-feather class="menu-full-mobile"
                        name="menu"
                        style="margin:0px 10px;"></i-feather>
                    <i-feather class="menu-full"
                        name="{{_listMenu.selected.icon}}"
                        style="margin:0px 10px;"></i-feather>
                    <span class="menu-full">{{_listMenu.selected.text}}</span>
                <i-feather name="chevron-down" class="menu-full" style="width: 16px;"></i-feather>

                </span>
                <div class="dropdown-menu">
                    <a *ngFor="let menu of _listMenu.list" class="dropdown-item
                        {{menu.class}} nav-select" [hidden]="!menu.autorizado" href="" (click)="MenuClick($event, menu)">
                        <i-feather name="{{menu.icon}}" style="width: 19px;
                            height: 19px;"></i-feather> {{menu.text}}
                    </a>
                </div>
            </div>


            <!--
            <a class="navbar-minimalize minimalize-styl-2 btn btn-primary" href (click)="OnOfSideBar($event)"><i class="fa fa-bars"></i> </a>
        
            <form role="search" class="navbar-form-custom" action="search_results.html">
                <div class="form-group">
                    <input type="text" placeholder="Buscar..." class="form-control" name="top-search" id="top-search">
                </div>
            </form>
          -->
        </div>
        <ul class="nav navbar-top-links navbar-right">
            <li class="dropdown" *ngIf="userLoggedService.type != 4">
                <!--data-toggle="dropdown"-->
                <a class="dropdown-toggle count-info" (click)="ShowHideAlert()">
                    <i class="fa fa-bell"></i>&nbsp;&nbsp; <span class="label
                        label-danger">{{_alertData._countTotal}}</span>
                </a>
                <ul class="dropdown-menu dropdown-alerts
                    {{(_alertData._countTotal> 0 &&
                    _show_hide_alert)?'show':''}}">
                    <!-- Orcamento abertos -->
                    <li *ngIf="(_alertData._countOrcamentoAbertos> 0)">
                        <b class="text-info">{{(_alertData._countOrcamentoAbertos
                            > 1) ? 'Solicitações de Orçamentos': 'Solicitação de
                            Orçamento'}}:</b>
                        <span class="float-right text-info">{{_alertData._countOrcamentoAbertos}}</span>
                    </li>
                    <li *ngFor="let orcamento of _alertData.orcamentoAbertos">
                        <a [routerLink]="'/orcamentos/edit/'+orcamento._orcamento_id">
                            <div>
                                <i class="fa fa-table fa-fw"></i> &nbsp;&nbsp;&nbsp; {{orcamento.codigo}}
                                <span class="float-right">{{orcamento._data_max_recebimento_orcamento}}</span>
                            </div>
                        </a>
                    </li>
                    <li *ngIf="(_alertData._countOrcamentoAbertos> 0)" class="dropdown-divider"></li>
                    <!-- Venda em analise -->

                    <li *ngIf="(_alertData._countVendasAbertas> 0)">
                        <b class="text-success">{{(_alertData._countVendasAbertas
                            > 1) ? 'Solicitações de Vendas': 'Solicitação de
                            Venda'}}:</b>
                        <span class="float-right text-success">{{_alertData._countVendasAbertas}}</span>
                    </li>
                    <li *ngFor="let venda of _alertData.vendasAbertas">
                        <a [routerLink]="'/vendas/view-link/'+venda._compra_id">
                            <div>
                                <i class="fa fa-check-circle"></i> &nbsp;&nbsp;&nbsp; {{venda.codigo}}
                                <span class="float-right">{{venda.data_criacao}}</span>
                            </div>
                        </a>
                    </li>
                    <li *ngIf="(_alertData._countVendasAbertas> 0)" class="dropdown-divider"></li>

                    <!-- Entrega pendente -->
                    <li *ngIf="(_alertData._countEntregasAbertas> 0)">
                        <b class="text-primary">{{(_alertData._countEntregasAbertas
                            > 1) ? 'Entregas Pendentes': 'Entrega Pendente'}}:</b>
                        <span class="float-right text-primary">{{_alertData._countVendasAbertas}}</span>
                    </li>
                    <li *ngFor="let entrega of _alertData.entregasAbertas">
                        <a [routerLink]="'/entregas/pendentes/'+entrega.data_max_entrega">
                            <div>
                                <i class="fa fa-truck"></i> &nbsp;&nbsp;&nbsp; EP{{entrega.id}}
                                <span class="float-right">{{entrega._data_max_entrega}}</span>
                            </div>
                        </a>
                    </li>
                    <li *ngIf="(_alertData._countEntregasAbertas> 0)" class="dropdown-divider"></li>

                    <!-- Solicitacao de Acesso -->
                    <li *ngIf="(_alertData._countInclusaoUsuario> 0)">
                        <b class="text-warning">{{(_alertData._countInclusaoUsuario
                            > 1) ? 'Pedidos ': 'Pedido '}} de Acesso ao Painel:</b>
                        <span class="float-right text-warning">{{_alertData._countInclusaoUsuario}}</span>
                    </li>
                    <li *ngFor="let pedido of _alertData.inclusaoUsuario">

                        <div class="alert-inclusao-content">
                            <p><i class="fa fa-user"></i> &nbsp;&nbsp;&nbsp; PA{{pedido.id}}
                                <span class="float-right">{{pedido.data_criacao}}</span></p>
                            <p><b>Email:&nbsp;&nbsp;</b> {{pedido.origem}}</p>
                            <p><b>Observação:&nbsp;&nbsp;</b> {{pedido.observacao_solicitante}}
                            </p>
                            <p class="text-center">
                                <button class="btn btn-success btn-sm" (click)="ConfirmSolicitacaoUsuario(pedido.id)">
                                    <i class="fa fa-check"></i>&nbsp;&nbsp;Resolvida</button>&nbsp;&nbsp;
                                <button class="btn btn-success btn-sm" [routerLink]="'/user/gerenciar-usuario/'+pedido.origem">
                                    <i class="fa fa-user-plus"></i>&nbsp;&nbsp;Cadastrar</button>
                            </p>
                        </div>
                    </li>
                    <li *ngIf="_alertData._countInclusaoUsuario> 0" class="dropdown-divider"></li>
                    <li>
                        <div class="text-center link-block">
                            <button class="btn btn-link" (click)="ShowHideAlert()">
                                <i class="fa fa-times"></i> &nbsp;&nbsp;Fechar</button>
                        </div>
                    </li>
                </ul>
            </li>
            <li class="nav-item dropdown" *ngIf="userLoggedService.type != 4">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-cog rotacionar-item" style="font-size:16px"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-user" aria-labelledby="navbarDropdown">
                    <span class="dropdown-item-text" style="margin-top:10px">Configurações</span>
                    <a *ngFor="let menu of _menu_nav_bar_config" class="dropdown-item" [routerLink]="[menu.route]">{{menu.text}}</a>
                </div>
            </li>
            <li class="nav-item dropdown no-padding no-margins">



                <div class="btn-group nav-btn-group">
                    <span class="btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div class="text-center div-img-logo">
                            <img src="{{url}}{{userLoggedService.img}}"
                                *ngIf="userLoggedService.img">
                            <i-feather name="user"
                                *ngIf="!userLoggedService.img" style="width:
                                16px;"></i-feather>
                        </div>
                    </span>

                    <div class="dropdown-menu dropdown-menu-user">
                        <span class="dropdown-item-text">{{_user_nome}}</span>
                        <a *ngFor="let menu of _menu_nav_bar" class="dropdown-item" [routerLink]="[menu.route]">{{menu.text}}</a>
                        <a class="dropdown-item" href="#" (click)="LogOut()">Sair</a>
                    </div>
                </div>
            </li>
        </ul>
    </nav>
</div>