import { InsumoDoFornecedor } from './produto-fornecedor';

export class Suprimento{
    constructor() {
        this.insumos = new Array<InsumoDoFornecedor>();
    }
    public _count_grid: number;
    public _total_registro: number;
    public _count_pesquisa: number;

    public insumos: Array<InsumoDoFornecedor>;
}