import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class UseFullService {

  constructor() { }

  static action: string

  static formatDate(data: Date) {
    var dia = data.getDate().toString().padStart(2, '0');
    var mes = (data.getMonth() + 1).toString().padStart(2, '0');
    var ano = data.getFullYear();
    return dia + "/" + mes + "/" + ano;
  }

  static formatDateHora(data: Date) {
    var dia = data.getDate().toString().padStart(2, '0');
    var mes = (data.getMonth() + 1).toString().padStart(2, '0');
    var ano = data.getFullYear();
    var hr = data.getHours().toString().padStart(2, '0');
    var mm = data.getMinutes().toString().padStart(2, '0');
    var ss = data.getSeconds().toString().padStart(2, '0');
    return dia + "/" + mes + "/" + ano + " " + hr + ":" + mm + ":" + ss;
  }

  static formatDateString(dataString: string) {
    var data = new Date(dataString);
    var dia = data.getDate().toString().padStart(2, '0');
    var mes = (data.getMonth() + 1).toString().padStart(2, '0');
    var ano = data.getFullYear();
    return dia + "/" + mes + "/" + ano;
  }
  static formatDateDiaMes(data: Date) {
    var dia = data.getDate().toString().padStart(2, '0');
    var mes = (data.getMonth() + 1).toString().padStart(2, '0');
    var ano = data.getFullYear();
    return dia + "/" + mes;
  }

  static formatDateUS(data_br: string) {
    var data = data_br.trim().split('/');
    var dia = data[0].padStart(2, '0');
    var mes = data[1].padStart(2, '0');
    var ano = data[2];
    return ano + "-" + mes + "-" + dia;
  }

  static formatDateUsForm(data: Date) {
    var dia = data.getDate().toString().padStart(2, '0');
    var mes = (data.getMonth() + 1).toString().padStart(2, '0');
    var ano = data.getFullYear();
    return ano + "-" + mes + "-" + dia;
  }

  static GetDateUs(data: Date) {
    var dia = data.getDate().toString().padStart(2, '0');
    var mes = (data.getMonth() + 1).toString().padStart(2, '0');
    var ano = data.getFullYear();
    return new Date(ano + "-" + mes + "-" + dia + "T00:00:00");
  }

  static formatNgbDateStruct(data: NgbDateStruct): Date {
    var _data = this.formatDateUS(data.day + "/" + data.month + "/" + data.year);
    return new Date(_data);
  }

  static formatNgbDate(data: any): NgbDate {
    var newdata = new Date(data)
    return new NgbDate(newdata.getFullYear(), newdata.getMonth() + 1, newdata.getDate());
  }

  static formatNgbDateStructString(data: NgbDateStruct): string {
    return this.formatDateUS(data.day + "/" + data.month + "/" + data.year);
  }

  static formatDateTime(data: Date = new Date()) {
    var dia = data.getDate().toString().padStart(2, '0');
    var mes = (data.getMonth() + 1).toString().padStart(2, '0');
    var ano = data.getFullYear();
    var hh = data.getHours().toString().padStart(2, '0');
    var mm = data.getMinutes().toString().padStart(2, '0');;
    var ss = data.getSeconds().toString().padStart(2, '0');;
    return dia + "/" + mes + "/" + ano + " " + hh + ":" + mm + ":" + ss;
  }

  static formatDateTimeUS(data: Date = new Date()) {
    var dia = data.getDate().toString().padStart(2, '0');
    var mes = (data.getMonth() + 1).toString().padStart(2, '0');
    var ano = data.getFullYear();
    var hh = data.getHours().toString().padStart(2, '0');
    var mm = data.getMinutes().toString().padStart(2, '0');;
    var ss = data.getSeconds().toString().padStart(2, '0');;
    return ano + "-" + mes + "-" + dia + " " + hh + ":" + mm + ":" + ss;
  }

  static GetFormatDate(dia: number, mes: number, ano: number) {
    var dd = dia.toString().padStart(2, '0');
    var mm = mes.toString().padStart(2, '0');
    return dd + "/" + mm + "/" + ano;
  }

  static OneToLowerCase(text: string): string {
    return text.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
  }

  static CalcularPaginacao(parcial: number, total: number): number {
    var r = Math.ceil(total / parcial);
    return r;
  }

  static RemoveArray(list: any[], data: any) {
    var count = list.length;
    for (let i = 0; i < count; i++) {
      if (list[i] == data)
        list.splice(i, 1);
    }
  }

  static TraduzirCalendario() {
    $("option[aria-label='January']").text("Jan");
    $("option[aria-label='February']").text("Fev");
    $("option[aria-label='March']").text("Mar");
    $("option[aria-label='April']").text("Abr");
    $("option[aria-label='May']").text("Mai");
    $("option[aria-label='June']").text("jun");
    $("option[aria-label='July']").text("Jul");
    $("option[aria-label='August']").text("Ago");
    $("option[aria-label='September']").text("Set");
    $("option[aria-label='October']").text("Out");
    $("option[aria-label='November']").text("Nov");
    $("option[aria-label='December']").text("Dez");

    $(".ngb-dp-weekdays div").each(function (index) {
      var text = '';
      switch ($(this).text().trim()) {
        case 'Mo': text = 'Seg'; break;
        case 'Tu': text = 'Ter'; break;
        case 'We': text = 'Qua'; break;
        case 'Th': text = 'Qui'; break;
        case 'Fr': text = 'Sex'; break;
        case 'Sa': text = 'Sab'; break;
        case 'Su': text = 'Dom'; break;
      }
      $(this).text(text);
    });

    $('.ngb-dp-arrow').click(() => {
      atualizarGridData();
    });

    function atualizarGridData() {
      if ($($(".ngb-dp-week")[6]).text().trim() == '')
        $($(".ngb-dp-week")[6]).hide()
      else
        $($(".ngb-dp-week")[6]).show()
    }
    atualizarGridData();
  }

  static ToDecimalString(value): string {
    if (!value)
      return '0.00'
    return String(value).replace('.', '').replace('.', '').replace('.', '').replace(',', '.');
  }

  static ReplaceFloat(value: string): string {
    if (!value)
      return String();
    return value.replace('.', '').replace('.', '').replace('.', '').replace('.', '').replace(',', '.');
  }

  static ConvertFloat(value: string): any {
    return (typeof (value) == "number") ? value : parseFloat(this.ReplaceFloat(value));
  }

  static ToFixed(value): string {
    return parseFloat(value).toFixed(2).replace('.', ',');
  }

  static ToFixedReal(v: any) {
    return parseFloat(v).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }

  static ToFixedString(val: string): string {
    return parseFloat(String(val).replace(',', '.')).toFixed(2).replace('.', ',');
  }

  static IsEmail(email) {
    return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email)
  }

  static IsCNPJ(cnpj) {
    if (!cnpj)
      return false
    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj == '') return false;
    if (cnpj.length != 14)
      return false;

    if (cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999")
      return false;

    var tamanho = cnpj.length - 2
    var numeros = cnpj.substring(0, tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
      return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
      return false;

    return true;
  }

  static SetMask(time = 200) {
    setTimeout(() => {
      $(".tooltip.show").removeClass('show')
      $(function () {
        $('[data-toggle="tooltip"]').tooltip();
        $('.money').mask('#.##0,00', {reverse: true});
        /*
        $(".money").priceFormat({
          prefix: '',
          centsSeparator: ',',
          thousandsSeparator: '.'
        });
        */
      })
    }, time);
  }

  static ShowModal(element) { $(element).modal('show'); }
  static HideModal(element) { $(element).modal('hide'); }

  static GetCnpjMasc(cnpj: string) {
    if (!cnpj)
      return String()
    return cnpj.substring(0, 2) + "." + cnpj.substring(2, 5) + "." + cnpj.substring(5, 8) + "/" + cnpj.substring(8, 12) + "-" + cnpj.substring(12, 14);
  }

  static GetCpfMasc(cpf: string) {
    if (!cpf)
      return String()
    return cpf.substring(0, 3) + "." + cpf.substring(3, 6) + "." + cpf.substring(6, 9) + "-" + cpf.substring(9, 11);
  }

  static UrlEdit = window.location.href.match('edit') ? true : false;

  static GetListString(value: string): Array<string> {
    if (!value)
      return []
    var array = new Array<string>()
    value.split(':').forEach(c => {
      if (c)
        array.push(c)
    });
    return array
  }
  static GetListStringQT(value: string): number {
    return this.GetListString(value).length
  }
}