import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'src/app/service/user/user.service';
import { Usuario } from 'src/app/domain/usuario/Usuario';
import { Router } from '@angular/router';
import { UseFullService } from 'src/app/service/usefull/usefull.service';

@Component({
  selector: 'app-minha-conta',
  templateUrl: './minha-conta.component.html',
  styleUrls: ['./minha-conta.component.css']
})
export class MinhaContaComponent implements OnInit {

  @Input() _usuario: Usuario;
  public _title: string;
  public _hidden: boolean;
  public grid_use_detalhes: string;

  constructor(private userService: UserService, private router: Router) { }

  public usuario: Usuario;

  ngOnInit() {
    this.usuario = new Usuario();
    this._usuario = new Usuario();
    if(this.router.url.match(/gerenciar-acesso/)){      
      this._title = "Informações do Cadastro";
      this.grid_use_detalhes = "grid-user-detalhes";
    }else{  
      this._title = "Minha Conta";  
      this.GetUser();
    }
  }

  GetUser() {
    this.userService.getLoggedIn().subscribe(
      d => { this.usuario = d; },
      r => {},
      () => { 
        this.usuario.cpf = UseFullService.GetCpfMasc(this.usuario.cpf)
        this.usuario.cnpj = UseFullService.GetCnpjMasc(this.usuario.cnpj)
       }
    );
  }

}
