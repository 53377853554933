import { EspecificacaoProduto } from './produto';

export class ProdutoCategoriaNivel {

    constructor() {
        this.nivel = new Array<EspecificacaoProduto>();
    }

    public id: number;
    public produto_id: number;
    public categoria_id: number;
    public nome: string;
    public nivel_id: number;
    public pre_fixo: string;
    public order: number;
    public selecao: number;
    public multiplo: number;

    public nivel: Array<EspecificacaoProduto>;

    public _selected: number = 0;
    public _multiple: boolean;
    public _ativo: boolean;
    public _buscar: string;

    public static ObterPorOrdem(niveis: ProdutoCategoriaNivel[], order: number): ProdutoCategoriaNivel {
        function FiltrarPorOrdem(obj){
            return (obj.order == order);
        }
        var nivel = niveis.filter(FiltrarPorOrdem); 
        return nivel[0];
    }
}

//temporario
export class SubCategoria {
    public id: number;
    public nome: number;
    public pai_id: number;
    public origem: number;
}

//temporario
export enum OrigemSubCategoria {
    cagotegoria = 1,
    subcategoria = 2
}

//componetes de seleção
export enum ComponetesSelecao {
    select = 0,
    modal = 1
}

//componetes de seleção
export enum TipoSelecao {
    Simples = 0,
    Multipla = 1
}