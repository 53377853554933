import { Orcamento, EnumStatusOrcamento, EnumStatusTipoOrcamento, EnumTipoOrcamento } from 'src/app/domain/orcamento/orcamento';
import { Router } from '@angular/router';
import { Cotacao } from 'src/app/domain/cotacao/cotacao';
import { Injectable } from '@angular/core';
import { UseFullService } from '../usefull/usefull.service';
import { UserLoggedInService } from '../authenticate/user-logged-in.service';
import { TypeUser } from 'src/app/domain/usuario/Usuario';
declare var $: any
@Injectable({
    providedIn: 'root'
})
export class VerificaService {

    constructor(private router: Router, private userLoggedIn: UserLoggedInService) { }

    EstadoOrcamento(orcamento: Orcamento, cotacao: Cotacao) {
        var dataLimite = UseFullService.GetDateUs(new Date(String(cotacao.data_max_recebimento_orcamento)));
        var data = UseFullService.GetDateUs(new Date());
        cotacao._expirado = (dataLimite < data);

        if ((orcamento.status != EnumStatusOrcamento.aberto && orcamento.tipo == EnumTipoOrcamento.comum) || orcamento.status > EnumStatusOrcamento.enviado)
            this.router.navigateByUrl("/orcamentos/view/" + orcamento.id);
        else if ((orcamento.status_por_tipo == EnumStatusTipoOrcamento.reenviado || cotacao._expirado) && orcamento.tipo == EnumTipoOrcamento.sugerido)
            this.router.navigateByUrl("/orcamentos/sugerido/view/" + orcamento.id);
        else if (orcamento.status_por_tipo == EnumStatusTipoOrcamento.solicitado && !cotacao._expirado && orcamento.tipo == EnumTipoOrcamento.sugerido)
            this.router.navigateByUrl("/orcamentos/sugerido/edit/" + orcamento.id);
        else if (cotacao._expirado) {
            this.router.navigateByUrl("/orcamentos/view/" + orcamento.id);
        } else if (orcamento.status == EnumStatusOrcamento.aberto)
            this.router.navigateByUrl("/orcamentos/edit/" + orcamento.id);
    }

    AutalizarDadosEmpresa() {        
        $('#message-modal').modal('show')
        $('#message-modal').on('hide.bs.modal', () => {
            if (this.userLoggedIn.type == TypeUser.provider)
                this.router.navigateByUrl("/configuracao");
            else if (this.userLoggedIn.type == TypeUser.client)
                this.router.navigateByUrl("/configuracao/user");
            else
                this.router.navigateByUrl("/configuracao");
        })
    }
}