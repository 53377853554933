import { Component, OnInit, ComponentFactoryResolver, Input } from '@angular/core';
import { ProdutoCategoria } from 'src/app/domain/suprimento/produto-categoria';
import { ProdutoUnidadeMedida } from 'src/app/domain/suprimento/produto-unidade-medida';
import { InsumoDoFornecedor } from 'src/app/domain/suprimento/produto-fornecedor';
import { ProdutoCategoriaNivel, ComponetesSelecao, TipoSelecao } from 'src/app/domain/suprimento/produto-categoria-nivel';

import { Suprimento } from 'src/app/domain/suprimento/suprimento';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { ProdutoComprador } from 'src/app/domain/suprimento/comprador/produto-comprador';
import { ProdutoCompradorService } from 'src/app/service/suprimento/comprador/produto-comprador.service';
import { GlobalVariable } from 'src/app/core/global-variable';
import { Router, ActivatedRoute } from '@angular/router';
import { CotacaoService } from 'src/app/service/suprimento/comprador/cotacao.service';
import { Cotacao, EnumTipoFrete } from 'src/app/domain/cotacao/cotacao';
import { EntregaParcialComprador } from 'src/app/domain/suprimento/comprador/entrega-parcial-comprador';
import { ProdutoService } from 'src/app/service/suprimento/produto.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { EmpresaService } from 'src/app/service/configuracao/empresa.service';
import { OrcamentoService } from 'src/app/service/orcamento/orcamento.service';
import { Orcamento, OrcamentoAliquota, EnumStatusOrcamento } from 'src/app/domain/orcamento/orcamento';
import { OrcamentoProduto } from 'src/app/domain/orcamento/orcamento-produto';
import { OrcamentoProdutoService } from 'src/app/service/orcamento/orcamento-produto.service';
import { OrcamentoAliquotaService } from 'src/app/service/orcamento/orcamento-aliquota.service';
import { OrcamentoConfiguracaoService } from 'src/app/service/orcamento/orcamento-configuracao.service';
import { OrcamentoConfiguracao } from 'src/app/domain/orcamento/orcamento-configuracao';
import { Empresa } from 'src/app/domain/empresa/empresa';
import { UserService } from 'src/app/service/user/user.service';
import { Usuario } from 'src/app/domain/usuario/Usuario';
import { CompraVendaService } from 'src/app/service/transacao/compra-venda.service';
import { CompraVenda } from 'src/app/domain/transacao/compra-venda';
import { Enderecos } from 'src/app/domain/empresa/Enderecos';
import { EnderecoService } from 'src/app/service/configuracao/endereco.service';

declare var $: any;

@Component({
  selector: 'app-compra-view',
  templateUrl: './compra-view.component.html',
  styleUrls: ['./compra-view.component.css']
})
export class CompraViewComponent implements OnInit {


  _code = 'app-compra-view'
  url = GlobalVariable.BASE_API_URL;
  constructor(
    private configuracaoService: OrcamentoConfiguracaoService,
    private aliquotaService: OrcamentoAliquotaService,
    private orcamentoProdutoService: OrcamentoProdutoService,
    private orcamentoService: OrcamentoService,
    private httpProduto: ProdutoService,
    private confirmService: AlertConfirmService,
    private produtoCompradorService: ProdutoCompradorService,
    private route: ActivatedRoute,
    private router: Router,
    private cotacaoService: CotacaoService,
    private userLoggedService: UserLoggedInService,
    private empresaService: EmpresaService,
    private userService: UserService,
    private compraVendaService: CompraVendaService,
    private enderecoService: EnderecoService) {
    this._produto_selecionado = new ProdutoComprador();
    this.confirmService.UnsetObservers();

    this.confirmService.confirmEmitter$.subscribe(c => { this.EditBudget(c); });
    this._carregando = false;
    this._ha_produto = false;
    this._buscar = '';
    this._categoria_id = 0;
    this._suprimento = new Suprimento();
    this._suprimento.insumos = new Array<InsumoDoFornecedor>();
    this._input_nivel = new ProdutoCategoriaNivel();
    this._categorias = new Array<ProdutoCategoria>();
  }

  _input_cotacao = "";
  _list_produtos = new Array<ProdutoComprador>();
  _produto_selecionado: ProdutoComprador;
  public _input_nivel: ProdutoCategoriaNivel;
  public _suprimento: Suprimento;
  public _categorias: ProdutoCategoria[];
  public _unidade_medida: ProdutoUnidadeMedida[];
  public _ha_produto: boolean;
  public _carregando: boolean;
  count_nivel = 0;
  public _categoria_nivel: boolean;
  public _niveis: Array<ProdutoCategoriaNivel>;
  public _buscar: string;
  public _categoria_id: number;
  public _vinculado: boolean;
  public _loading_save: boolean;
  public _enumComponetesSelecao = ComponetesSelecao;
  _id = this.route.snapshot.params['id'];
  _orcamento = new Orcamento();
  _orcamento_produtos = new Array<OrcamentoProduto>();
  _aliquotas = new Array<OrcamentoAliquota>();
  _aliquota = new OrcamentoAliquota();
  _temp_aliquota = new OrcamentoAliquota();
  _configuracoes = new OrcamentoConfiguracao();
  _compra_venda = new CompraVenda();

  ngOnInit() {
    this.userLoggedService.SetCurrentUser();
    this.GetEmpresaLoggedIn();
    this.SetListaUnidadeMedida();    
  }
  
  ReceiverEntregaProduto(e){}

  carregarArquivoPropota(id, nome) {
    this._nome_arquivo = "";
    this._src_arquivo = "";
    $("#exibir-pdf").html("");
    const _src_arquivo = GlobalVariable.BASE_API_URL + "fornecedor/orcamento_" + id + "/" + nome;
    setTimeout(() => { $("#exibir-pdf").html("<iframe width='100%' height='" + ($('body').height() - 100) + "px' src='" + _src_arquivo + "'></iframe>"); });
    $('#data-modal').modal('show');
  }

  GetCompraVenda() {
    this.compraVendaService.Get(this._id).subscribe(
      d => {
        if (d && d != null)
          this._compra_venda = d;
      },
      e => { this.router.navigateByUrl("/compras/analise"); },
      () => {
        this.SetUser(this._compra_venda.user_compra_id, 1);
        this.GetOrcamento(this._compra_venda.orcamento_id);
      }
    );
  }

  GetConfiguracoes() {
    this.configuracaoService.GetFornecedor().subscribe(
      d => { this._configuracoes = d; }
    );
  }

  GetOrcamento(orcamento_id: number) {
    this.orcamentoService.Get(orcamento_id).subscribe(
      d => {
        this._orcamento = d;
      },
      e => { },
      () => {
        this.GetCotacao(this._orcamento.cotacao_id);
        this.GetAllProdutos(this._orcamento.cotacao_id);
        this.SetUser(this._orcamento.user_id);
      }
    );
  }

  GetOrcamentoProduto(cotacao_id: number) {
    this.orcamentoProdutoService.GetAll(cotacao_id).subscribe(
      d => { this._orcamento_produtos = d; },
      e => { },
      () => {
        ProdutoComprador.SetProdutoOrcamento(this._list_produtos, this._orcamento_produtos, this._orcamento);
        if (this._cotacao.tipo_frete == EnumTipoFrete.ciffob && 
          this._orcamento.codigo_processo_venda && 
          this._orcamento.codigo_processo_venda.match('fob') || this._orcamento.tipo_frete == 2)
          this.CarregarAliquotaFob(this._orcamento.id);
        else
          this.CarregarAliquota(this._orcamento.id);
      }
    );
  }

  _cotacao = new Cotacao();
  GetCotacao(id: number) {
    this.cotacaoService.Get(id).subscribe(
      d => { this._cotacao = d; },
      e => { },
      () => {
        this._cotacao._meio_pagamento = Cotacao.GetMeioPagamento(this._cotacao.meio_pagamento);
        Cotacao.SetFormat(this._cotacao);
        this.GetEndereco(this._cotacao.endereco_id);
      }
    );
  }

  _endereco = new Enderecos()
  GetEndereco(endereco_id: number) {
    this.enderecoService.Get(endereco_id).subscribe(
      d => { this._endereco = d }
    )
  }

  GetAllProdutos(cotacao_id: number) {
    this.produtoCompradorService.GetAll(cotacao_id).subscribe(
      d => {

        d.forEach(c => { c._orcamento_produto = new OrcamentoProduto() });
        this._list_produtos = d;
      },
      e => { },
      () => {
        this.SetConfig();
        this.GetOrcamentoProduto(this._orcamento.id);
        this.CarregarDadosTrunk();
      }
    );
  }

  _ha_anexo = false;
  SetConfig() {
    for (let i = 0; i < this._list_produtos.length; i++) {
      this._list_produtos[i]._index = i + 1;
      this._list_produtos[i]._index_impar_par = Number.isInteger((i + 1) / 2);
      this._list_produtos[i]._name_file = this._list_produtos[i].anexo;
      this._list_produtos[i]._unidade_nome = ProdutoUnidadeMedida.Get(this._unidade_medida, this._list_produtos[i].unidade_id).nome;
      this._list_produtos[i]._quantidade = this._list_produtos[i].quantidade.toFixed(2).replace(".", ",");
      if (this._list_produtos[i].anexo && this._list_produtos[i].anexo != 'null')
        this._ha_anexo = true;
    }


  }
  SetListaUnidadeMedida() {
    this.httpProduto.GetAllUnitsMeasured().subscribe(
      data => { this._unidade_medida = data; },
      error => { },
      () => {
        this.GetCompraVenda();
      }
    );
  }

  public _nome_arquivo: string;
  public _src_arquivo: string;
  MostrarFile(data: ProdutoComprador) {
    if (data._file) {
      var file = data._file;
      this._nome_arquivo = file.name;
      var _this = this;
      var reader = new FileReader();
      reader.onload = function (e) {
        var target = e.target as any;
        if (file.type == "application/pdf") {
          _this._src_arquivo = undefined;
          setTimeout(() => {
            $("#exibir-pdf").html("<iframe width='100%' height='" + ($('body').height() - 100) + "px' src='" + target.result + "'></iframe>");
          }, 10);
        } else
          _this._src_arquivo = target.result;
      };
      reader.readAsDataURL(file);
    } else
      this.CarregarDataModal(data);
    $('#data-modal').modal('show');
  }

  CarregarDataModal(data: ProdutoComprador) {
    this._nome_arquivo = "";
    this._src_arquivo = "";
    $("#exibir-pdf").html("");
    this._nome_arquivo = data.anexo;
    if (!data.anexo.match(".png") && !data.anexo.match(".jpg") && !data.anexo.match(".gif")) {
      this._src_arquivo = undefined;
      setTimeout(() => {
        $("#exibir-pdf").html("<iframe width='100%' height='" + ($('body').height() - 100) + "px' src='" + GlobalVariable.BASE_API_URL + "comprador/cotacao_" + data.cotacao_id + "/" + data.id + "_" + data.anexo + "'></iframe>");
      });
    } else
      this._src_arquivo = GlobalVariable.BASE_API_URL + "comprador/cotacao_" + data.cotacao_id + "/" + data.id + "_" + data.anexo;
  }

  _carregamento_concluido = false;
  CarregarDadosTrunk() {
    this._list_produtos.forEach(c => {
      this.produtoCompradorService.GetAllEntregaPacial(c.id).subscribe(
        d => { c.EntregasParcial = d; },
        e => { },
        () => {
          c._qt_entrega_parcial = c.EntregasParcial.length;
          var x = 1;
          c.EntregasParcial.forEach(c => {
            c._index = x++;
            EntregaParcialComprador.SetFormatDate(c);
          });
        }
      );
    });
    this._carregamento_concluido = true;
  }

  OpenModalTrunck(produto: ProdutoComprador) {
    if (produto.unidade_id < 1)
      throw "Selecione uma unidade!";
    if (produto.quantidade <= 0)
      throw "Preencha a quantidade!";

    this._produto_selecionado = produto;
    this._produto_selecionado._unidade_nome = ProdutoUnidadeMedida.Get(this._unidade_medida, produto.unidade_id).nome;

    $("#data-entraga-parcial-modal").modal("show");
  }

  CarregarAliquotaFob(orcamento_id: number) {
    this.aliquotaService.GetAllFOB(orcamento_id).subscribe(
      d => { this._aliquotas = d; },
      e => { },
      () => {
        OrcamentoAliquota.Set(this._aliquotas);
        setTimeout(() => {
          Orcamento.SetConfig(this._orcamento, this._orcamento_produtos, this._aliquotas);
        }, 10);
      }
    );
  }

  CarregarAliquota(orcamento_id: number) {
    this.aliquotaService.GetAll(orcamento_id).subscribe(
      d => { this._aliquotas = d; },
      e => { },
      () => {
        OrcamentoAliquota.Set(this._aliquotas);
        setTimeout(() => {
          Orcamento.SetConfig(this._orcamento, this._orcamento_produtos, this._aliquotas);
        }, 10);
      }
    );
  }

  ConfirmeEdit() {
    this.confirmService._code = this._code;
    this.confirmService._title = "Reabrir Orçamento";
    this.confirmService._message = "Você desea reabrir orçamento para edição? Clique em <b>SIM</b> para confirmar.";
    this.confirmService.Show();
  }

  EditBudget(confirm: boolean) {
    if (!this._orcamento || !confirm || this.confirmService._code != this._code)
      return;
    this._orcamento.status = EnumStatusOrcamento.aberto;
    this._orcamento.valor_total = this._orcamento._total.replace(',', '.');
    this._orcamento.sub_total = this._orcamento._subtotal.replace(',', '.');
    this.orcamentoService.Edit(this._orcamento).subscribe(
      d => { },
      e => { },
      () => { this.router.navigateByUrl("/orcamentos/edit/" + this._cotacao.id); }
    );
  }

  _empresa = new Empresa();
  GetEmpresaLoggedIn() {
    this.empresaService.GetLoggedIn().subscribe(
      d => {
        if (d != null)
          this._empresa = d;
      },
      e => { },
      () => { }
    );
  }

  _usuario_cotacao = new Usuario();
  _usuario_responsavel = new Usuario();
  SetUser(id: number, variavel: number = 0) {
    this.userService.get(id).subscribe(
      d => {
        if (d && d != null) {
          if (variavel === 0)
            this._usuario_responsavel = d;
          else if (variavel === 1)
            this._usuario_cotacao = d;
        }
      },
      e => { },
      () => { }
    );
  }

  ConfiguracoesEmpresa() {
    this.router.navigateByUrl("/configuracao")
  }
}