<div class="col-lg-12 wrapper-top-content">
    <div class="ibox">
        <div class="ibox-title" style="padding-right:20px;">
            <h5>Histórico de Cotações</h5>
            <div style="float: right;"> </div>
        </div>
        <div class="ibox-content">
            <div class="table-responsive min-height-grid-insumo div-table-fixed margin-top-15">
                <!-- table-striped table-hover table-bordered -->
                <table class="table table-striped">
                    <thead class="thead-fixed">
                        <tr>
                            <th class="th-sequence"></th>
                            <th class="th-nome">Nome</th>
                            <th class="text-center th-codigo">Código</th>
                            <th class="text-center th-obra">Obra</th>
                            <th class="text-center th-data">Data Criação</th>
                            <th class="text-center th-data">Validade Cotação</th>
                            <th class="text-center th-data">Status</th>
                            <th class="text-center th-action"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="  " *ngFor="let cotacao of _list_cotacao">
                            <td class="text-center th-sequence">{{cotacao._index}}</td>
                            <td><label class="btn-link cursor-pointer" (click)="Ver(cotacao)">
                                {{cotacao.nome}}
                            </label></td>
                            <td class="text-center th-codigo">{{cotacao.codigo}}</td>
                            <td class=" th-obra">{{cotacao.nome_obra}}</td>
                            <td class="text-center th-data">{{cotacao._data_criacao}}</td>
                            <td class="text-center th-data">{{cotacao._data_max_recebimento_orcamento}}</td>
                            <td class="text-center th-data">{{cotacao._status}}</td>
                            <td class="text-center th-action-anexo">
                                <i class="fa fa-search btn-link cursor-pointer" (click)="Ver(cotacao)">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                                <i class="fa fa-check" style="color:#08c65b" *ngIf="cotacao.status == 1">&nbsp;&nbsp;&nbsp;</i>
                                <i class="fa fa-times" style="color:red" *ngIf="cotacao.status == 2">&nbsp;&nbsp;&nbsp;</i>
                                <i class="far fa-clone btn-link cursor-pointer" (click)="ConfirmDuplicar(cotacao)">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                                <i class="fa fa-edit btn-link cursor-pointer" *ngIf="cotacao.status == 0" (click)="Editar(cotacao)">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                                <i class="fa fa-trash-alt btn-link cursor-pointer" *ngIf="(cotacao.status == 0)" (click)="ConfirmDelete(cotacao)"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>


        </div>
    </div>
</div>


<!-- data-toggle="tooltip" data-placement="top" title=".." -->