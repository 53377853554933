
import { Component, OnInit, ComponentFactoryResolver, Input } from '@angular/core';
import { OrcamentoViewCtrl } from '../orcamento-ctrl-view';

declare var $: any;

@Component({
  selector: 'app-orcamento-comprador-sugestao-view',
  templateUrl: './orcamento-comprador-sugestao-view.component.html',
  styleUrls: ['./orcamento-comprador-sugestao-view.component.css']
})
export class OrcamentoCompradorSugestaoViewComponent extends OrcamentoViewCtrl implements OnInit {
  
  ngOnInit() {
    this.GetConfiguracoes();
    this.GetOrcamento(this.orcamento_id);    
    this.getEstadoValidacaoOrcamento();
  }
  ReceiverEntregaProduto(e){

  }
}