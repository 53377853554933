<div class="col-lg-12 wrapper-top-content">
    <div class="ibox">
        <div class="ibox-title" style="padding-right:20px;">
            <h5>Compras</h5>
            <div style="float: right; margin-top:-4px;">
                <button *ngFor="let nav of navs" class="btn btn-tab-border
                    {{nav.active}}" (click)="verTab(nav)">{{nav.descricao}}</button>
            </div>
        </div>
        <div class="ibox-content">
            <div class="table-responsive min-height-grid-insumo div-table-fixed
                margin-top-15">
                <!-- Eu amo simplificar,   table-striped table-hover table-bordered -->
                <table class="table table-striped">
                    <thead class="thead-fixed">
                        <tr>
                            <th class="th-sequence"></th>
                            <th>Razão Social</th>
                            <th>Nome</th>
                            <th class="text-center th-data">Tipo</th>
                            <th class="text-center th-data">Status</th>
                            <th class="text-center th-action"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let empresa of empresas">
                            <td class="text-center th-sequence">{{empresa._index}}</td>
                            <td><label class="btn-link cursor-pointer" (click)="Ver(empresa)">{{empresa.razao_social}}</label></td>
                            <td>{{empresa.nome_fantasia}}</td>
                            <td class="text-center th-data">{{(empresa._tipo == 2)?'Fiscalização':'Faturamento'}}</td>
                            <td class="text-center th-data">{{(empresa.status == 0)?'Não Avaliado':(empresa.status == 1)?'Aprovado':'Reprovado'}}</td>
                            <td class="text-center th-action-anexo">
                                <i class="fa fa-search btn-link cursor-pointer" (click)="Ver(empresa)">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="empresas.length <= 0">Nenhuma empresa encontrada!</div>
        </div>
    </div>
</div>