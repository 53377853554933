<div style="padding-bottom: 20px; margin-top: 15px;" *ngIf="active">
    <div class="col-lg-12 row" style="padding:5px
        10px 10px 10px;
        margin: 0;background:#f5f5f5;" *ngIf="(_categoria_virtual &&
        _categoria_virtual.id> 0) || (_niveis && _niveis.length > 0)">

        <div class="col-md-2" style="padding: 2px 0px 0px
            2px;" *ngIf="_categoria_virtual &&
            _categoria_virtual.id> 0">
            <div style="margin-bottom: 5px;"><b>Produto</b></div>

            <select class="form-control-sm form-control
                input-s-sm
                inline select-niveis {{
                _categoria_virtual._selecionado>
                0 ?
                'input-border-success':'input-border-error'
                }}" [(ngModel)]="_categoria_virtual._selecionado" (change)="OnChangeSearchCategoria()">
                <option value="undefined" selected>Selecione
                    Produto</option>
                <option *ngFor="let categoria of
                    _categoria_virtual.categorias"
                    value="{{categoria.id}}">{{categoria.descricao}}</option>
            </select>
        </div>

        <div class="col-md-2" style="padding: 2px 0px 0px
            2px;" *ngFor="let nivel of _niveis" [hidden]="!nivel._ativo">
            <div *ngIf="nivel._ativo">
                <div style="margin-bottom: 5px;"><b>{{nivel.nome}}*</b></div>
                <button class="btn btn-default
                    btn-select-color text-left
                    button-pesquisa {{nivel._selected> 0 ?
                    'input-border-success':'input-border-error'
                    }}" *ngIf="nivel.selecao ==
                    _enumComponetesSelecao.modal" (click)="SelectCores(nivel)">Selecione
                    {{nivel.nome}}</button>
                <select class="form-control-sm form-control
                    input-s-sm
                    inline select-niveis {{nivel._selected>
                    0 ?
                    'input-border-success':'input-border-error'
                    }}" [(ngModel)]="nivel._selected" (change)="OnChangeSearchNivel(nivel)" *ngIf="nivel.selecao
                    ==
                    _enumComponetesSelecao.select">
                    <option value="undefined" selected>Selecione
                        {{nivel.nome}}</option>
                    <option *ngFor="let sub of nivel.nivel"
                        [hidden]="!(sub._temp_id_vinculo>
                        0)"
                        value="{{sub.id}}">{{sub.descricao}}</option>
                </select>
            </div>
        </div>
        <!-- | filter : '_ativo' : true : true"
                    'input-border-error':'input-border-success'
                <div class="col-md-2 ml-auto">
                    <button>Salvar Insumo</button>
                </div>
            -->
    </div>
    <div class="table-responsive min-height-grid-insumo modal-max
        div-table-fixed" style="max-height: 400px; overflow-y: auto;">
        <table class="table table-striped table-hover
            table-bordered">
            <thead class="thead-light thead-fixed">
                <tr>
                    <th class="th-nome-produto">Nome do Insumo</th>
                    <th class="text-center width-200-px">Categoria</th>
                </tr>
            </thead>
            <tbody class="" [hidden]="_carregando">
                <tr *ngFor="let produto of
                    _suprimento.insumos | filter
                    : 'ha_niveis' : 0 : true" (click)="addRow(produto)" class="cursor-pointer">
                    <td class="td-nome-produto">{{produto.nome}}</td>
                    <td class="text-center width-200-px">{{produto._categoria}}</td>
                </tr>
            </tbody>
        </table>

        <div *ngIf="_ha_produto && !_categoria_nivel" class="mensagem-produto">
            Nenhum produto encontrado, para pesquisa selecionada. Para solicitar Produtos não cadastrados envie e-mail para:<b>
                sac@paineldaengenharia.com.br</b>
            <!--
                        <a href [routerLink]="['/suprimento/solicitacao']">
                            Solicitar cadastro de um novo insumo!</a> 
                        -->
        </div>

        <div *ngIf="_ha_produto && _categoria_nivel" class="mensagem-produto">
            <span class="text-danger">Selecione todas as subcategorias para
                buscar,</span> caso não encontre o produto desejado. Você pode
            <a href [routerLink]="['/suprimento/solicitacao']">
                Solicitar cadastro de um novo produto ou
                item de
                categoria!</a>
        </div>

        <div [hidden]="!_carregando" class="carregando-produtos">
            <div class="spinner-border" role="status">
                <span class="sr-only"></span>
            </div>
            carregando...
        </div>



    </div>

    <div style="text-align: right; padding: 20px 0;">



        <div style="float: left;" *ngIf="_suprimento.insumos.length> 49">
            <label *ngIf="_paginas[0].active_one">
                <div class="btn-group pagination-position-grid margin-right-10">
                    <button type="button" class="btn btn-secondary"
                        (click)="GetPage(1)">1</button>
                </div>
            </label>
            <label>
                <div class="btn-group
                    pagination-position-grid"
                    role="group"
                    aria-label="First group">
                    <button type="button" *ngFor="let page of _paginas"
                        class="btn btn-secondary
                        {{page.active
                        ?'active':''}}"
                        (click)="GetPage(page.page)">{{(page.page)?page.page:1}}</button>
                </div>
            </label>
            <label>
                <div class="btn-group
                    pagination-position-grid margin-left-10">
                    <button type="button"
                        *ngIf="_paginas[0].ultima_pagina> 0"
                        class="btn btn-secondary"
                        (click)="GetPage(_paginas[0].ultima_pagina)">{{_paginas[0].ultima_pagina}}</button>
                </div>
            </label>
        </div>


        <button type="button" class="btn btn-secondary" (click)="cancelar()">Cancelar</button>
        <!--&nbsp;&nbsp;&nbsp;
        <button type="button" class="btn btn-primary" data-dismiss="modal" *ngIf="!_save_active" (click)="ConcluirAdicao()">Adicionar Produtos</button> -->
    </div>
</div>
<app-modal-cores-insumo [_input_nivel]="_input_nivel" (resposta_cores_selecionadas)="ReciverCoresSelecionadas($event)"></app-modal-cores-insumo>