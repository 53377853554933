import { Component, OnInit } from '@angular/core';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { UseFullService } from 'src/app/service/usefull/usefull.service';

declare var $: any
@Component({
  selector: 'app-alert-confirm',
  templateUrl: './alert-confirm.component.html',
  styleUrls: ['./alert-confirm.component.css']
})
export class AlertConfirmComponent implements OnInit {

  constructor(public confirmService: AlertConfirmService) { }

  error = ''

  ngOnInit() {
    $('#global-modal').on('show.bs.modal', function (e) {

    })
  }

  ConfirmEmit(confirm: boolean) {
    if (!confirm)
      return;
    else {
      this.addList()
      this.confirmService.ConfirmEmit(confirm);
    }
  }

  addList() {
    if (UseFullService.IsEmail(this.confirmService._input)) {
      this.confirmService._list.push(this.confirmService._input)
      this.confirmService._input = ''
    } else {
      this.error = 'E-mail inválido!'
      setTimeout(() => { this.error = '' }, 5000)
    }
  }

  removerItem(item: string) {
    const countItem = this.confirmService._list.length
    for (let i = 0; i < countItem; i++) {
      const element = this.confirmService._list[i];
      if (item == element) {
        this.confirmService._list.splice(i, 1)
        continue
      }
    }
  }
}
