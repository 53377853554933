import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from, empty } from 'rxjs';
import { map } from 'rxjs/operators';
import { Http } from '@angular/http';

declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class ViaCepService {

    private url = "https://viacep.com.br/ws/";
    constructor(private http: HttpClient, private simpleHttp: Http) { }

    Get(cep: string): Observable<ViaCep> {
           return this.simpleHttp.get(this.url + cep + "/json").pipe(
            map(res => res.json())
        );
    }
}

export class ViaCep {
    public cep: string;
    public logradouro: string;
    public complemento: string;
    public bairro: string;
    public localidade: string;
    public uf: string;
    public unidade: string;
    public ibge: string;
    public gia: string;
    public erro: boolean;
}