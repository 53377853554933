<div class="col-lg-12 wrapper-top-content">
    <div class="ibox-title">
        <h5>Gerênciar Categorias e Niveis</h5>
    </div>

    <div class="ibox-content    form-categoria-nivel">
        <div class="row">

            <div class="col-sm-12 m-b-xs">
                <label>Selecione um produto: </label> <br />
                <button type="button" class="btn btn-sm btn-info" (click)="SugerirProduto()">Selecionar Produto</button> ou
                <label>

                    <select class="form-control-sm form-control input-s-sm
                        inline" [(ngModel)]="_categoria_nivel.categoria_id"
                        (change)="OnChangeSearch()">
                        <option value="0">Informe a Categoria</option>
                        <option *ngFor="let categoria of _categorias"
                            value="{{categoria.id}}">{{categoria.descricao}}</option>
                    </select>
                </label>
                <br />
                <label>{{_produto.nome}}</label>
                <br />
                <br />
                <div *ngIf="(_produto.id> 0)">
                    <b>Categoria:</b>
                    <label class="margin-left-10">{{_produto._categoria}}</label>
                    <br />
                    <label class="margin-top-15"><b>Nivel:</b></label>

                </div>


            </div>
            <div class="col-sm-12 m-b-xs">
                <b>Lista de Niveis: </b> <br />
                <Br />
                <div class="row">

                    <div *ngFor="let nivel of _categoria_niveis" class="col-sm-3 lista-vinculo   " (mouseleave)="nivel._ativo = false" (mouseover)="nivel._ativo = true">
                        <b class="text-info">{{_niveis[nivel.nivel_id]}} -
                            {{nivel.nome}} </b>

                        <input type="text" class="form-control input-pesquisar" [(ngModel)]="nivel._buscar" placeholder="Pesquisar">

                        <div class="list-group grid-vinculo">
                            <a *ngFor="let item of nivel.nivel | filter :
                                nivel._buscar" [hidden]="(nivel.order> 1)" class="dropdown-item   
                                padding-top-12 {{(nivel._selected ==
                                item.id) ? 'active': ' '}}" (click)="SelectItem(nivel, item)">
                                {{item.descricao}}
                            </a>

                            <div class="form-check" *ngFor="let item of
                                nivel.nivel | filter : nivel._buscar" [hidden]="!(nivel.order> 1)">
                                <input class="form-check-input" type="checkbox" value="" [checked]="item._selecionado" (change)="ChangeNivel($event, nivel, item)" id="defaultCheck{{item.descricao}}
                                    padding-top-12">
                                <label class="form-check-label dropdown-item
                                       {{(nivel._selected ==
                                    item.id) ? 'active': ' '}}" for="defaultCheck{{item.descricao}}" (click)="SelectItem(nivel, item)">
                                    {{item.descricao}}
                                </label>
                            </div>

                        </div>

                        <label class="label-selecionar-tudo text-primary   Right" (click)="SelecionarTudoDoNivel(nivel)" *ngIf="nivel._ativo"><small>Vincular Tudo</small></label>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <app-modal-insumo-nivel (resposta_produto_selecionado)="ReciverPesquisaProduto($event)"></app-modal-insumo-nivel>