import { UseFullService } from 'src/app/service/usefull/usefull.service';

export class Entrega {
    public id: number;
    public compra_venda_id: number;
    public status: number;
    public status_recebimento: number;
    public data_criacao: Date;
    public data_max_entrega: Date;
    public data_entrega: Date;
    public entrega_parcial_id: number;
    
    public _data_criacao: string;
    public _data_max_entrega: string;
    public _data_entrega: string;

    public _index: number;
    public _cotacao_id: number;
    public _codigo_orcamento: string;
    public _nome_obra: string;
    public _valor_total: string;
    public _sub_total: string;
    public _tipo_frete: number;
    public _endereco_id: number;
    public _endereco: import("c:/projetos/painel-engenharia-front/src/app/domain/empresa/Enderecos").Enderecos;

    static Format(c: Entrega) {
        c._data_criacao = UseFullService.formatDate(new Date(c.data_criacao));
        c._data_max_entrega = UseFullService.formatDate(new Date(c.data_max_entrega));
        c._data_entrega = UseFullService.formatDate(new Date(c.data_entrega));
        c._sub_total = UseFullService.ToFixed(c._sub_total);
        c._valor_total = UseFullService.ToFixed(c._valor_total);
    }
}

export class CountEntrega {
    public pendentes: number;
    public realizadas: number;
}

export enum EnumStatusEntrega {
    Pendente = 0,
    Realizada = 1
}

export enum EnumStatusRecebimento {
    Pendente = 0,
    Realizado = 1
}