<div class="col-lg-12 wrapper-top-content">
    <div class="ibox">
        <div class="ibox-title">
            <h5>Produtos relacionados a Obra</h5>
            <div class="ibox-tools">
                <div>

                </div>
            </div>
        </div>
        <div class="ibox-content">
            <div class="row bottom-10">

                <div class="col-sm-2 m-b-xs">
                    <select class="form-control-sm form-control input-s-sm
                        inline select-category" [(ngModel)]="_categoria_id" (change)="OnChangeSearch()">
                        <option value="0">Selecione categorias</option>
                        <option *ngFor="let categoria of tempList |
                            filter :
                            'pai_id' : 0 : true"
                            value="{{categoria.id}}">{{categoria.descricao}}</option>
                    </select>
                </div>
                <div class="col-sm-5 m-b-xs">
                    <div class="input-group"><input placeholder="Pesquisar produtos" [(ngModel)]="_buscar" type="text" class="form-control
                            form-control-sm">
                        <span class="input-group-append">
                            <button type="button" class="btn btn-sm
                                btn-primary">Buscar</button>
                        </span>
                    </div>
                </div>
            </div>

            <div class="table-responsive min-height-grid-insumo
                div-table-fixed">
                <table class="table table-hover">
                    <thead class="thead-dark thead-fixed">
                        <tr>
                            <th class="text-center th-categoria">Código</th>
                            <th class="th-nome-produto">Nome do Insumo</th>
                            <th class="text-center th-categoria">Marca</th>
                            <th class="text-center th-categoria">Unidade</th>
                            <th class="text-center width-80-px">Qtd. Total</th>
                            <th class="text-right width-100-px">R$ Hist.</th>
                            <th class="text-right width-100-px">Crossdocking</th>
                            <th class="text-center">#</th>
                        </tr>
                    </thead>
                    <tbody class="table-td" *ngFor="let categoria of
                        _list_categoria">

                        <!--List Categorias -->
                        <tr (mouseenter)="mouseEnter(categoria)" (mouseleave)="mouseLeave(categoria)" class="row-subgrupo-sub {{categoria._selected ?
                            'tr-selected' : ''}}">
                            <td class="text-left width-120-px" style="padding-left:
                                15px;">{{categoria._index}}.0
                                <i class="fa fa-plus cursor-pointer btn-link
                                    margin-right-10" *ngIf="categoria._selected_hover &&
                                    !categoria._toSon" (click)="addRow(categoria,
                                    null)" style="float: right;"></i>
                                <i class="fa fa-minus cursor-pointer btn-link
                                    margin-right-10" *ngIf="categoria._selected_hover &&
                                    categoria._toSon" (click)="removeRow(categoria)" style="float:
                                    right;"></i>
                            </td>

                            <td class="td-nome-produto align-middle" colspan="6">
                                <input type="text" [(ngModel)]="categoria.descricao" style="width:calc(100% - 50px); display:
                                    inline; font-weight: 600;" class="form-control input-td" (change)="updateCategoria(categoria)" />
                            </td>
                            <td *ngIf="categoria.id" class="text-center
                                th-action-delete
                                width-80-px">
                                <button *ngIf="categoria._selected_hover &&
                                    !categoria._toSon" class="btn btn-sm-grid
                                    btn-link" (click)="removeCategoria(categoria)">
                                    <i class="fa fa-trash-alt"></i>
                                </button>
                            </td>
                        </tr>

                        <!--List Produtos de uma Categorias -->
                        <tr *ngFor="let model of categoria._listRelacaoObraProduto |
                            filter : _buscar" (click)="rowClick(categoria,
                            model)" (mouseenter)="mouseEnter(model)" (mouseleave)="mouseLeave(model)" class="{{model._selected ? 'tr-selected' : ''}}">
                            <td *ngIf="model.id" class="text-left width-120-px" style="padding-left: 15px;">{{categoria._index}}.{{model._index}}
                                <i class="fa fa-plus cursor-pointer btn-link
                                    margin-right-10" *ngIf="model._selected_hover &&
                                    !model._toSon" (click)="addRow(categoria,
                                    model)" style="float: right;"></i>
                                <i class="fa fa-minus cursor-pointer btn-link
                                    margin-right-10" *ngIf="model._selected_hover &&
                                    model._toSon" (click)="removeRow(model)" style="float: right;"></i>
                            </td>
                            <td *ngIf="model.id" class="td-nome-produto">{{model._produto.nome}}</td>

                            <td *ngIf="model.id" class="text-center
                                width-180-px">
                                {{model._marcas.length}} marca(s) &nbsp;&nbsp;
                                <button class="btn btn-default btn-sm" (click)="showModalMarcas(model)">+ Marca</button>
                            </td>
                            <td *ngIf="model.id" class="text-center
                                width-120-px">

                                <select class="form-control-sm form-control
                                    text-center input-s-sm inline" (ngModelChange)="update(model)" [(ngModel)]="model.unidade_id">
                                    <option value="0" disabled
                                        style="color:#ccc;">Unidade</option>
                                    <option *ngFor="let unidade of
                                        _unidade_medida" value="{{unidade.id}}">{{unidade.nome}}</option>
                                </select>
                            </td>
                            <td *ngIf="model.id" class="text-center
                                width-80-px">
                                <input type="text" pattern="[0-9]+$" placeholder="0,00" [(ngModel)]="model.previsto_total" (change)="update(model)" class="form-control
                                    input-td text-center" />
                            </td>
                            <!--currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"-->
                            <td *ngIf="model.id" class="text-right
                                width-100-px">
                                <input type="text" pattern="[0-9]+$" placeholder="0,00" [(ngModel)]="model.valor" (change)="update(model)" class="form-control input-td text-right" />
                            </td>
                            <td *ngIf="model.id" class="text-center
                                width-100-px">
                                <input type="text" pattern="[0-9]+$" placeholder="-" [(ngModel)]="model.crossdocking" (change)="update(model)" class="form-control input-td text-center" />
                            </td>
                            <td *ngIf="model.id" class="text-center
                                th-action-delete
                                width-80-px">
                                <button class="btn btn-sm-grid btn-link" (click)="remove(categoria, model)">
                                    <i class="fa fa-trash-alt"></i>
                                </button>
                            </td>


                            <!-- busca produto ou categorias -->
                            <td *ngIf="!model.id" class="text-center
                                width-120-px td-icon align-top" style="padding-top: 8px;">
                                <i class="fa fa-list-alt {{(model._type
                                <1)?'active':''}}" (click)="insertProduto(model)"></i>
                            </td>
                            <td *ngIf="!model.id" class="td-nome-produto
                                {{model._type}}" colspan="5">
                                <input type="text" placeholder="{{model._textInputItemTipo}}" id="_search_{{categoria._index}}_{{model._index}}" [(ngModel)]="_buscar_produto[categoria._index+'_'+model._index]" class="form-control input-td" />
                                <!-- grid produtos -->
                                <div *ngIf="!model.type" style="margin: 0 auto; max-width: calc(100%)">
                                    <app-buscar-produto-component [_index]="model._index" [_categoria_index]="categoria._index" [_buscar_produto]="_buscar_produto[categoria._index+'_'+model._index]" [_input_cotacao]="_input_cotacao" (_produtos_adicionados)="ReceiverProdutos($event)"></app-buscar-produto-component>
                                </div>
                            </td>
                            <td *ngIf="!model.id" colspan="4"></td>
                        </tr>
                    </tbody>


                    <tbody>
                        <tr>
                            <td class="text-center width-120-px td-icon">
                                <i class="fa fa-list {{(_model._type == 1) ?
                                    'active' : ''}}" (click)="insertCategoria(_model)"></i>
                                <span style="color:#c5dce7">&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
                                <i class="fa fa-list-alt {{(!_model._type) ?
                                    'active' : ''}}" (click)="insertProduto(_model)"></i>
                            </td>
                            <td class="td-nome-produto">
                                <input type="text" placeholder="{{_model._textInputItemTipo}}" id="_search_{{_model._categoria_index}}_{{_model._index}}" [(ngModel)]="_buscar_produto[_model._categoria_index+'_'+_model._index]" style="width:calc(100% - 50px); display:
                                    inline;" class="form-control input-td" (ngModelChange)="seachCategoria(_model,
                                    _buscar_produto[_model._categoria_index+'_'+_model._index])" (keydown)="onKeydownCategoria($event, _buscar_produto[_model._categoria_index+'_'+_model._index])" />
                                <button *ngIf="_model._type" class="btn
                                    btn-primary" (click)="createCategoria(_buscar_produto[_model._categoria_index+'_'+_model._index])">Salvar</button>
                            </td>
                            <td colspan="6"></td>
                        </tr>
                        <tr style="padding:0" *ngIf="_model._type <1">
                            <td colspan="8" style="padding:0">
                                <div style="margin: 0 auto; max-width: calc(100% - 200px)">
                                    <app-buscar-produto-component [_index]="_model._index" [_categoria_index]="_model._categoria_index" [_buscar_produto]="_buscar_produto[_model._categoria_index+'_'+_model._index]" [_input_cotacao]="_input_cotacao" (_produtos_adicionados)="ReceiverProdutos($event)"></app-buscar-produto-component>
                                </div>
                            </td>
                        </tr>
                        <!--
                        <tr *ngIf="_model._type == 1">
                            <td></td>
                            <td style="padding:0">
                                <h4>Escolha uma categoria para continuar:</h4>
                                <table class="table table-bordered
                                    table-hover table-striped">
                                    <thead class="thead-light">
                                        <th>Descrição</th>
                                    </thead>
                                    <tbody>
                                        <tr class="cursor-pointer" *ngFor="let
                                            iCategoria of listCategoria" (click)="addCategoria()">
                                            <td>{{iCategoria.descricao}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td colspan="6"></td>
                        </tr>
                    -->
                    </tbody>


                    <!--
                    <tbody class="table-td" *ngFor="let model of listModel">


                        <tr *ngIf="model.id> 0" (click)="rowClick(model)" (mouseenter)="mouseEnter(model)" (mouseleave)="mouseLeave(model)" class="{{model._selected ? 'tr-selected' : ''}}">
                            <td class="text-left width-120-px" style="padding-left: 15px;">{{model.id}}
                                <i class="fa fa-plus cursor-pointer btn-link
                                    margin-right-10" *ngIf="model._selected_hover &&
                                    !model._toSon" (click)="add(model)" style="float: right;"></i>
                                <i class="fa fa-minus cursor-pointer btn-link
                                    margin-right-10" *ngIf="model._selected_hover &&
                                    model._toSon" (click)="removeRow(model)" style="float: right;"></i>
                            </td>
                            <td class="td-nome-produto">{{model._produto.nome}}</td>
                            <td class="text-center width-180-px">
                                {{model._marcas.length}} marca(s) &nbsp;&nbsp;
                                <button class="btn btn-default btn-sm" (click)="showModalMarcas(model)">+ Marca</button>
                            </td>
                            <td class="text-center width-120-px">

                                <select class="form-control-sm form-control
                                    text-center input-s-sm inline" (ngModelChange)="update(model)" [(ngModel)]="model.unidade_id">
                                    <option value="0" disabled
                                        style="color:#ccc;">Unidade</option>
                                    <option *ngFor="let unidade of
                                        _unidade_medida" value="{{unidade.id}}">{{unidade.nome}}</option>
                                </select>
                            </td>
                            <td class="text-center width-80-px">
                                <input type="text" pattern="[0-9]+$" placeholder="0,00" [(ngModel)]="model.previsto_total" (change)="update(model)" class="form-control
                                    input-td text-center" />
                            </td>
                            <td class="text-right width-100-px">
                                <input type="text" pattern="[0-9]+$" placeholder="0,00" [(ngModel)]="model.valor" placeholder="0,00" (change)="update(model)" class="form-control input-td text-right" />
                            </td>
                            <td class="text-right width-100-px">
                                <input type="text" pattern="[0-9]+$" placeholder="0,00" [(ngModel)]="model.Crossdocking" placeholder="0,00" (change)="update(model)" class="form-control input-td text-right" />
                            </td>
                            <td class="text-center th-action-delete
                                width-80-px">
                                <button class="btn btn-sm-grid btn-link" (click)="remove(model)">
                                    <i class="fa fa-trash-alt"></i>
                                </button>
                            </td>
                        </tr>

                        <tr *ngIf="!model.id && !model._produto_categoria">
                            <td class="text-center width-120-px td-icon">

                                <i class="fa fa-list {{(model._type == 1) ?
                                    'active' : ''}}" (click)="insertCategoria(model)"></i>
                                <span style="color:#c5dce7">&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
                                <i class="fa fa-list-alt {{(model._type
                                <1)?'active':''}}" (click)="insertProduto(model)"></i>
                            </td>
                            <td class="td-nome-produto {{model._type}}" colspan="5">
                                <input type="text" placeholder="{{model._textInputItemTipo}}" id="_search_{{model._index}}" [(ngModel)]="_buscar_produto[model._index]" class="form-control input-td" />

                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr *ngIf="!model.id && model._produto_categoria" class="row-subgrupo-sub">
                            <td class="text-center width-120-px"></td>
                            <td class="td-nome-produto align-middle" colspan="7">
                                {{model._produto_categoria}}
                            </td>
                        </tr>
                        <tr *ngIf="!model.id && !model.type">
                            <td colspan="7" style="padding:0">
                                <div style="margin: 0 auto; max-width: calc(100%
                                    - 200px)">
                                    <app-buscar-produto-component [_index]="model._index" [_buscar_produto]="_buscar_produto[model._index]" [_input_cotacao]="_input_cotacao" (_produtos_adicionados)="ReceiverProdutos($event)"></app-buscar-produto-component>
                                </div>
                            </td>
                        </tr>
                    </tbody>

                    

                -->
                </table>
                <div *ngIf="false" class="mensagem-produto">
                    Produto não encontrado, para pesquisa selecionada.
                </div>
                <div *ngIf="false" class="mensagem-produto">
                    <span class="text-danger">Selecione todas as subcategorias
                        para buscar,</span> caso não encontre o produto desejado.
                </div>
                <div *ngIf="false" class="carregando-produtos">
                    <div class="spinner-border" role="status">
                        <span class="sr-only"></span>
                    </div>
                    carregando...
                </div>
            </div>
        </div>
    </div>
</div>

<app-modal-multiselect [_input]="_input" [_multiple]="true" (_return)="receiverData($event)"></app-modal-multiselect>
<!-- <app-adicionar-produto-comprador [_input_cotacao]="_input_cotacao" (_produtos_adicionados)="ReceiverProdutos($event)"></app-adicionar-produto-comprador> -->