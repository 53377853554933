import { InteractiveTable } from '../common/interactiveTable'

export class ContatoFinanceiro extends InteractiveTable
{
    public id: number
    public empresa_id: number
    public nome: string
    public email: string
    public telefone: string
    public _auditor: number
    public _sub_relacao_id: number
}