<div class="row">

    <div class="col-logo">

        <div class="  Right">
            <div class="text-center div-img-logo">
                <img src="{{url}}{{userLoggedService.img ? userLoggedService.img : cabecalho.dirlogo}}" class="config-img" *ngIf="userLoggedService.img || cabecalho.logo">
            </div>
        </div>
    </div>
    <div class="row table-button-0 cabecalho-orcamento cabecalho-cotacao">

        <div class="col-sm-6" style="padding:0;">
            <table class="table table-cabecalho-fornecedor">
                <tbody>
                    <tr>
                        <td class="text-left th-width" colspan="2"><b>Faturante:</b>&nbsp;&nbsp;{{cabecalho.c_razao_social}}
                        </td>
                    </tr>
                    <tr>
                        <td class="text-left th-width" colspan="2"><b>CNPJ/CPF:</b>&nbsp;&nbsp; {{cabecalho.c_cpnjcpf}}
                        </td>
                    </tr>
                    <tr>
                        <td v>
                            <div style="width: 50px; float: left;">
                                <b>Obra:</b>
                            </div>
                            <div style="width: calc(100% - 50px);
                                float: right;">{{cabecalho.nome_obra}}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-sm-6 text-right" style="padding:0;">
            <table class="table table-cabecalho-fornecedor">
                <tbody>
                    <tr>
                        <td class="text-left th-width" colspan="2"><b>Frete:</b>&nbsp;&nbsp; {{cabecalho._tipo_frete}}
                        </td>
                    </tr>
                    <tr>
                        <td class="text-left th-width" colspan="2">
                            <b>Forma de Pagamento:</b>&nbsp;&nbsp;{{cabecalho._meio_pagamento}} - {{cabecalho.forma_pagamento == 2 ? cabecalho.prazo_pagamento : 'à vista' }}
                        </td>
                        <!--
                        <td class="text-left th-width" *ngIf="cabecalho.tipo_frete != 1">
                            <b>Data Orçamento:</b>&nbsp;&nbsp;{{cabecalho._data_max_recebimento_orcamento}}
                        </td>
                        -->
                    </tr>
                    <tr>
                        <!--<td class="text-left th-width"><b>Data Solitação:</b>&nbsp;&nbsp;{{_cotacao._data_criacao}}</td>-->
                        <td class="text-left th-width"><b>Data Orçamento:</b>&nbsp;&nbsp;{{cabecalho._data_max_recebimento_orcamento}}</td>
                        <td class="text-left th-width"><b>Entrega Produto:</b>&nbsp;&nbsp;{{cabecalho._data_recebimento_produto}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="col-sm-12 div-endereco">
            <div class="label-endereco">
                <b>Endereço de entrega:</b>
            </div>
            <div class="label-detalhe-endereco">
                <label> {{cabecalho.endereco}} </label>
            </div>
        </div>
    </div>
</div>