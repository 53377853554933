import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from '../../core/global-variable';
import { Obra } from 'src/app/domain/obra/obra';

@Injectable({
  providedIn: 'root'
})
export class ObraService {

  private url = GlobalVariable.BASE_API_URL + "works/";
  constructor(private http: HttpClient) { }

  GetAllWork(): Observable<Obra[]> {
    return this.http.get<Obra[]>(this.url + "getAll").pipe(
      map(r => { return r; })
    );
  }

  Get(id: number): Observable<Obra> {
    return this.http.get<Obra>(this.url + "get?id=" + id).pipe(
      map(r => { return r; })
    );
  }
}
