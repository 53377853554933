import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from 'src/app/core/global-variable';
import { OrcamentoProduto } from 'src/app/domain/orcamento/orcamento-produto';

@Injectable({
  providedIn: 'root'
})
export class OrcamentoProdutoService {

  private url = GlobalVariable.BASE_API_URL + "orcamento/produto/";
  constructor(private http: HttpClient) { }

  GetAll(orcamento_id: number) : Observable<OrcamentoProduto[]> {
    return this.http.get<OrcamentoProduto[]>(this.url + "getall?orcamento_id="+orcamento_id).pipe(
      map(res => {
        return res;
      })
    );
  }

  Get(id: number) {
    return this.http.get<OrcamentoProduto>(this.url + "get?id="+id).pipe(
      map(res => {
        return res;
      })
    );
  }
  
  Create(data: OrcamentoProduto): Observable<any>{
    return this.http.post<any[]>(this.url+"create", data).pipe(
      map(res=> { return res; })
    );
  }
  
  Edit(data: OrcamentoProduto): Observable<any>{
    return this.http.put<any[]>(this.url+"edit", data).pipe(
      map(res=> { return res; })
    );
  }
  
  Delete(id: number): Observable<any> {    
    return this.http.delete(this.url+"delete?id="+id).pipe(
      map(res=> { return res; })
    );
  }
}
