import { ContatoFinanceiro } from './contato-financeiro';
import { InteractiveTable } from '../common/interactiveTable';
import { EmailValidate } from '../auxiliar/EmailValidate';

export class Empresa extends InteractiveTable {
    public id: number;
    public empresa_id: number;
    public arquivos_contrato_social: string;
    public razao_social: string;
    public nome_fantasia: string;
    public inscricao_estadual: string;
    public cnpj: string;
    public cpf: string;
    public logo: string;
    public categoria: number;
    public telefone: string;

    public cep: string;
    public endereco: string;
    public numero: number;
    public complemento: string;
    public bairro: string;
    public cidade: string;
    public uf: string;
    public data: Date;
    public log: string;

    public _categoria: string;
    public _cidade: string;
    public _img: File;
    public _files: File[];
    _cnpj: string;
    _cpf: string;

    _nome_usuario: string
    _tipo_usuario: number

    obra_id: string
    tipo_relacao: number
    relacao_id: number

    list_contatos_financeiro: Array<ContatoFinanceiro>
    
    responsaveis: Array<EmailValidate>;

    public static getCategoriaName(id){
        return EnumCategoriaEmpresa[id]
    }

    public static getActive(list: Empresa[]): Empresa{
        const active = (o: Empresa) => { return (o._active) }
        const model = list.filter(active)
        return (model.length > 0) ? model[0] : null
    }
}

export enum EnumCategoriaEmpresa {
    Revendedor = 0,
    Representante = 1,
    Industria = 2,
    Industria_Revendedor = 3,
    Faturante = 4,
    Fiscalizadora = 5
}