import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-temporary-message',
  templateUrl: './temporary-message.component.html',
  styleUrls: ['./temporary-message.component.css']
})
export class TemporaryMessageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
