import { Component, OnInit } from '@angular/core';
import { CotacaoService } from 'src/app/service/suprimento/comprador/cotacao.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { Cotacao } from 'src/app/domain/cotacao/cotacao';
import { Router } from '@angular/router';
import { Orcamento, ListOrcamento } from 'src/app/domain/orcamento/orcamento';

@Component({
  selector: 'app-orcamento-comprador-cotacao-historico-list',
  templateUrl: './orcamento-comprador-cotacao-historico-list.component.html',
  styleUrls: ['./orcamento-comprador-cotacao-list.component.css']
})
export class OrcamentoCompradorCotacaoHistoricoListComponent implements OnInit {

  public code: string;
  public _list_orcamento: Array<ListOrcamento>;
  public _cotacoes: Array<Cotacao>;
  public _cotacao: Cotacao;

  constructor(
    private cotacaoService: CotacaoService,
    private confirmService: AlertConfirmService,
    private userLoggedService: UserLoggedInService,
    private router: Router) {
    this.userLoggedService.SetCurrentUser();
    this._cotacoes = new Array<Cotacao>();
    this._cotacao = new Cotacao();
  }

  ngOnInit() {
    this.userLoggedService.SetCurrentUser();
    this.GetAll();
  }

  GetAll() {
    this.cotacaoService.GetAllCotacaoOrcada(this.userLoggedService.id, true).subscribe(
      d => {
        if (d && d.length > 0)
          this._cotacoes = d;
      },
      e => { },
      () => {
        var x = 1;
        this._cotacoes.forEach(c => {
          c._index = x++;
          Cotacao.SetFormat(c);
        });
      }
    );
  }

  Ver(cotacao: Cotacao) {
    this.router.navigateByUrl("/orcamentos/cotacao-view/" + cotacao.id);
  }
}