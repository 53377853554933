import { ProdutoFornecedor } from './produto-fornecedor';
import { ProdutoCategoriaNivel } from './produto-categoria-nivel';

export class Produto {
    public id: number;
    public emrpesa_id: number;
    public nome: string;
    public prod_complemento: string;
    public ha_niveis: number;
    public prod_categoria_id: number;
    public CategoriaNome: string;
    public prod_situacao: string;
    public prod_especie_id: number;
    
    public ProdutoFornecedor: ProdutoFornecedor;
    public CategoriasNiveis: Array<ProdutoCategoriaNivel>;

    public prod_classific1_id: number;
    public prod_classific2_id: number;
    public prod_classific3_id: number;
    public prod_classific4_id: number;
    public prod_classific5_id: number;
    public prod_classific6_id: number;

    public _count_grid: number;
    public _total_registro: number;
    public _count_pesquisa: number;

    public _selecionado: boolean;
    public prod_validado: any;
    public _categoria: string;
}

export class EspecificacaoProduto {
    public descricao: string;
    public id: number;
    public item_id: number;
    public tipo: number;
    public filhos: Array<EspecificacaoProduto>;

    public _selecionado: boolean;
    public _temp_id_vinculo: number;

    public static FiltrarPorId(especificacoes: Array<EspecificacaoProduto>, id: number): EspecificacaoProduto {
        function filtrar(obj) {
            return ('id' in obj && obj.id == id);
        }
        var data = especificacoes.filter(filtrar);
        return (data.length > 0) ? data[0] : new EspecificacaoProduto();
    }
}

export class NomeProduto {
    public nome: string = "";
    public n_1: string = "";
    public n_2: string = "";
    public n_3: string = "";
    public n_4: string = "";
    public n_5: string = "";
    public n_6: string = "";
    public n_7: string = "";

    public categoria_id: number;
    public categoria_nome: string;

    public Get(): string {
        return this.nome + " " + this.n_1 + " " + this.n_2 + " " + this.n_3 + " " + this.n_4 + " " + this.n_5 + " " + this.n_6 + " " + this.n_7;
    }
}