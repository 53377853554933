import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Produto } from 'src/app/domain/suprimento/produto';
import { ProdutoCategoria } from 'src/app/domain/suprimento/produto-categoria';
import { ProdutoService } from 'src/app/service/suprimento/produto.service';
import { SuprimentoService } from 'src/app/service/suprimento/suprimento-service';

declare var $: any;
@Component({
  selector: 'app-modal-insumo-nivel',
  templateUrl: './modal-insumo-nivel.component.html',
  styleUrls: ['./modal-insumo-nivel.component.css']
})
export class ModalInsumoNivelComponent implements OnInit {

  @Output() resposta_produto_selecionado: EventEmitter<Produto> = new EventEmitter();

  _produtos = new Array<Produto>();
  _produto_selecionado = new Produto();
  _categorias = new Array<ProdutoCategoria>();

  _ha_produto = false;
  _carregando = false;
  _save_active = false;
  _buscar = '';

  constructor(private httpProduto: ProdutoService,
    private httpSuprimentos: SuprimentoService) {
  
  }

  ngOnInit() {
    this.SetListaCatetorias();
    this.init();
  }

  SelectItem() {
    this.resposta_produto_selecionado.emit(this._produto_selecionado);
  }

  ClickBond(produto: Produto) {
    this._produtos.forEach(c => { c._selecionado = false; });
    produto._selecionado = !produto._selecionado;
    this._produto_selecionado = (produto._selecionado) ? produto : new Produto;
  }

  SetListaCatetorias() {
    this.httpProduto.getAllCategories().subscribe(
      data => {
        this._categorias = data;
        this.SetListaProdutos();
      });
  }

  SetListaProdutos() {
    this._carregando = true;
    this._ha_produto = false;
    this.httpSuprimentos.getAllNiveis().subscribe(
      data => { this._produtos = data; },
      error => { },
      () => { this.SetFunctions(); }
    );
  }

  SetFunctions() {
    this._ha_produto = this._produtos.length < 1;
    this._carregando = false;
    this._produtos.forEach(c => { c._categoria = ProdutoCategoria.GetName(this._categorias, c.prod_categoria_id); });
  }

  Show(): void {
    $('#pesquisa-insumo-modal').modal('show');
  }

  close(): void {
    $('#pesquisa-insumo-modal').modal('hide');
  }

  init(): void {
    $('#pesquisa-insumo-modal').on('show.bs.modal', function (e) {
      $(".modal-max").css("max-height", ($("body").height() - 220) + "px");
    });
  }
}