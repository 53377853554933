import { ExpectedConditions } from 'protractor';
import { AlertService } from 'src/app/service/alert.service';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { InputAny } from 'src/app/modules/cotacao/localidade/localidade.component';
import { Orcamento } from '../orcamento/orcamento';

export class Cotacao {
    public list_email: string;
    public _qt_email: number;

    constructor() {
        this._meio_pagamento = '';
        this.meio_pagamento = 0;
        this.forma_pagamento = 0;
        this.periodo_pagamento = 0;
        this.recebimento_produto = 0;
    }
    public id: number;
    public status: number;
    public nome: string;
    public codigo: string;
    public usuario_id: number;
    public tipo_frete: number;
    public nome_obra: string;
    public endereco_id: number;
    public obra_id: number;
    public preco_por_km: string;
    public data_max_recebimento_orcamento: Date;
    public forma_pagamento: number;
    public meio_pagamento: number;
    public periodo_pagamento: number;
    public recebimento_produto: number;
    public data_recebimento_produto: Date;
    public regiao: string;
    public estado: string;
    public cidade: string;
    public data_criacao: Date;
    public _meio_pagamento: string;
    public _status: string;
    public _index: number;
    public _qt_orcamento: number;

    public _data_criacao: string;
    public _data_recebimento_produto: string;
    public _data_max_recebimento_orcamento: string;

    public _list_email: Array<string>


    public _qt_solicitada: number
    public _user_nome: string
    public _user_email: string
    public _user_telefone: string

    public _compra_id: string
    public _orcamento_id: number
    public _orcamento_status: number
    public _orcamento_status_nome: string
    public _tipo: number;
    public _status_por_tipo_orcamento: number;
    _expirado = false;

    public static Validade(model: Cotacao) {
        model._expirado = (UseFullService.GetDateUs(new Date(model.data_max_recebimento_orcamento)) < UseFullService.GetDateUs(new Date()))
    }
    public static SetFormat(model: Cotacao) {
        model._data_criacao = UseFullService.formatDate(new Date(model.data_criacao));
        model._data_max_recebimento_orcamento = UseFullService.formatDate(new Date(model.data_max_recebimento_orcamento));
        model._data_recebimento_produto = UseFullService.formatDate(new Date(model.data_recebimento_produto));
        if (UseFullService.GetDateUs(new Date(model.data_max_recebimento_orcamento)) < UseFullService.GetDateUs(new Date()) && model.status == EnumStatusCotacao.aberta)
            model._expirado = true
        model._status = this.GetStatus(model.status, model._expirado)
    }

    static GetStatus(status: number, expirado = false): string {
        switch (status) {
            case EnumStatusCotacao.aberta:
                return (expirado) ? "Expirado" : "Aguardando Envio"; break;
            case EnumStatusCotacao.enviada: return "Enviada"; break;
            case EnumStatusCotacao.cancelada: return "Cancelada"; break;
            default:
                return null
        }
    }

    static Validar(cotacao: Cotacao) {
        if (!cotacao.nome)
            throw "Por favor digite um nome para cotação";
        if (cotacao.nome.length < 2)
            throw "Nome deve ter no minimo 2 digitos";
        if (cotacao.recebimento_produto < 1)
            throw "Selecione uma forma de recebiemento do produto";
        if (cotacao.forma_pagamento < 1)
            throw "Selecione uma forma de pagamento";
        if (cotacao.meio_pagamento < 1)
            throw "Selecione um meio de pagamento";
        if (cotacao.forma_pagamento == EnumFormaPagamento.prazo && cotacao.periodo_pagamento < 1)
            throw "Selecione um perido para pagamento";
    }

    static GetMeioPagamento(id: number): string {
        var meios_pagamento = this.GetAllMeioPagamento();
        return meios_pagamento[id];
    }

    static GetAllMeioPagamento(): Array<string> {
        var meios_pagamento = new Array<string>();
        meios_pagamento[1] = "Dinheiro";
        meios_pagamento[2] = "Transferência";
        meios_pagamento[3] = "Boleto Bancário";
        meios_pagamento[4] = "Cartão de Crédito/BNDES";
        return meios_pagamento;
    }

    static GetIdStringMeioPagamento(list: any[]): string {
        if (!list)
            return "";
        var data = ":";
        list.forEach(c => { data += c.id + ":"; });
        return data;
    }

    static GetMeioPagamentoSelecionados(meios_pagamento: InputAny[], list: string): InputAny[] {
        var list_ids = list.split(':');
        function filtrar(obj) {
            for (let i = 0; i < list_ids.length; i++) {
                if (parseInt(list_ids[i]) == obj.id)
                    return true;
            }
            return false;
        }
        var data = meios_pagamento.filter(filtrar);
        return data;
    }
}

export enum EnumFormaPagamento {
    vista = 1,
    prazo = 2
}

export enum EnumObraEndereco {
    obra = 1,
    entrega = 2,
    cobranca = 3
}

export enum EnumRecebimentoProduto {
    total = 1,
    parcial = 2
}

export enum EnumTipoFrete {
    cif = 1,
    fob = 2,
    ciffob = 3
}

export enum EnumMeioPagamento {
    dinheiro = 1,
    transferencia = 2,
    boleto_bancario = 3,
    cartao_credito = 4,
    cartao_bndes = 5
}

export enum EnumStatusCotacao {
    aberta = 0,
    enviada = 1,
    cancelada = 2
}


export class OrcamentoCotacao {
    public nome_fantasia: string
    public razao_social: string
    public telefone: string
    public cidade: string
    public uf: string
    public cnpj: string
    public codigo: string
    public data_envio: string
    public valor_total: string
    public sub_total: string
    public origem: string
    public nome_usuario: string
    public telefone_usuario: string
    public email_usuario: string
    public _index: number
}