<div style="max-width: 200px; padding: 5px 0;">
    <select class="form-control-sm form-control input-s-sm
            inline select-category" [(ngModel)]="_categoriaId" (change)="OnChangeSearch()">
            <option value="0">Selecione categorias</option>
            <option *ngFor="let categoria of tempList"
                value="{{categoria.id}}">{{categoria.descricao}}</option>
        </select>
</div>

<div class="table-responsive">
    <table class="table table-hover table-bordered">
        <thead class="thead-dark">
            <tr>
                <th class="text-center th-categoria">Código</th>
                <th class="th-nome-produto">Nome do Insumo</th>
                <th class="text-center th-categoria">Marca</th>
                <th class="text-center th-categoria">Unidade</th>
                <th class="text-center width-100-px">Qtd. Total</th>
                <th class="text-right width-100-px">R$ Hist.</th>
                <th class="text-right width-100-px">Crossdocking</th>
            </tr>
        </thead>
        <tbody class="table-td" *ngFor="let categoria of _list_categoria">
            <!--List Categorias -->
            <tr class="row-subgrupo-sub">
                <td class="text-left width-120-px" style="padding-left: 15px;">{{categoria._index}}.0
                </td>
                <td class="td-nome-produto align-middle" colspan="6">
                    {{categoria.descricao}}
                </td>
            </tr>

            <!--List Produtos de uma Categorias -->
            <tr *ngFor="let model of categoria._listRelacaoObraProduto" (click)="rowClick(categoria, model)">
                <td class="text-left width-120-px" style="padding-left: 15px;">{{categoria._index}}.{{model._index}}</td>
                <td class="td-nome-produto">{{model._nome}}</td>
                <td class="text-center width-180-px">{{model._marcas.length}}</td>
                <td class="text-center width-120-px">{{model._unidade}}</td>
                <td class="text-center width-80-px">{{model.previsto_total}}</td>
                <td class="text-right width-100-px">{{model.valor}}</td>
                <td class="text-center width-100-px">{{model.crossdocking}}</td>
            </tr>
        </tbody>

    </table>
    <div *ngIf="false" class="mensagem-produto">
        Produto não encontrado, para pesquisa selecionada.
    </div>
    <div *ngIf="false" class="mensagem-produto">
        <span class="text-danger">Selecione todas as subcategorias
            para buscar,</span> caso não encontre o produto desejado.
    </div>
    <div *ngIf="false" class="carregando-produtos">
        <div class="spinner-border" role="status">
            <span class="sr-only"></span>
        </div>
        carregando...
    </div>
</div>