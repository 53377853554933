
import { Component, OnInit } from '@angular/core';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { Router } from '@angular/router';
import { Orcamento, ListOrcamento, EnumStatusOrcamento } from 'src/app/domain/orcamento/orcamento';
import { OrcamentoService } from 'src/app/service/orcamento/orcamento.service';

@Component({
  selector: 'app-orcamento-fornecedor-list-enviados',
  templateUrl: './orcamento-fornecedor-list-enviados.component.html',
  styleUrls: ['./orcamento-fornecedor-list.component.css']
})
export class OrcamentoFornecedorListEnviadosComponent implements OnInit {

  public code: string;
  public _list_orcamento: Array<ListOrcamento>;
  public _orcamentos: Array<ListOrcamento>;
  public _orcamento: ListOrcamento;

  constructor(
    private orcamentoService: OrcamentoService,
    private userLoggedService: UserLoggedInService,
    private router: Router) {
    this.userLoggedService.SetCurrentUser();
    this._orcamentos = new Array<ListOrcamento>();
    this._orcamento = new ListOrcamento();
  }

  ngOnInit() {
    this.userLoggedService.SetCurrentUser();
    this.GetAll();
  }

  GetAll() {
    this.orcamentoService.GetOrcamentosStatus(EnumStatusOrcamento.enviado).subscribe(
      d => {
        if (d && d.length > 0)
          this._orcamentos = d;
      },
      e => { },
      () => {
        var x = 1;
        this._orcamentos.forEach(c => {
          c._index = x++;
          ListOrcamento.SetConfig(c);
        });
      }
    );
  }

  Ver(orcamento: Orcamento) {
    this.router.navigateByUrl("/orcamentos/view/" + orcamento.id);
  }

}