import { Component, OnInit } from '@angular/core';
import { EnderecoModalComponent } from 'src/app/core/page/endereco-modal/endereco-modal.component';
import { ObraCotacao, RelacaoObraEmpresa, RelacaoObraEmpresaAuditor } from 'src/app/domain/gestao/obra-cotacao';
import { Empresa } from 'src/app/domain/empresa/empresa';
import { ContatoFinanceiro } from 'src/app/domain/empresa/contato-financeiro';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { EnumStatusEmailValidade, EmailValidate } from 'src/app/domain/auxiliar/EmailValidate';

declare var $: any;
@Component({
  selector: 'app-obra-cotacao-auditor-relatorio',
  templateUrl: './obra-cotacao-auditor-relatorio.component.html',
  styleUrls: ['./obra-cotacao-auditor-relatorio.component.css']
})
export class ObraCotacaoAuditorRelatorioComponent extends EnderecoModalComponent implements OnInit {

  public code: 'app-obra-cotacao-auditor';
  obra = new ObraCotacao();

  empresas = new Array<Empresa>();
  _empresa = new Empresa();
  empresaSelecionada = 0;
  relacao_empresa = new RelacaoObraEmpresa();
  empresas_relacionadas = new Array<Empresa>();
  responsaveis = new Array<ContatoFinanceiro>();
  _observacao = String()

  id = this.route.snapshot.params['id'];
  email_validacao: EmailValidate;

  ngOnInit() {
    if (this.id && this.id > 0) {
      this.get(this.id);
      this.GetAllRelacaoEmpresas();
      this.relacao_empresa.obra_id = this.id
      this.getEmpresaPorObra(this.id)
    }
    this.alertConfirm.UnsetObservers();
    this.alertConfirm.confirmEmitter$.subscribe(c => this.reprovar(c));
  }
  
  getEmpresaPorObra(id: number) {
    this.empresaService.GetEmpresaPorObra(id).subscribe(
      d => { this._empresa = d }
    )
  }

  get(id) {
    this.repositoryService.path = "obra-cotacao/get"
    this.repositoryService.get(id).subscribe(
      d => {
        if (d && d.id > 0) {
          this.obra = d
          this.obra._inicio = UseFullService.formatDateString(this.obra.inicio)
          this.obra._fim = UseFullService.formatDateString(this.obra.fim)
          this._endereco = this.obra.endereco
        }
      });
  }

  GetAllRelacaoEmpresas() {
    this.repositoryService.path = "obra-cotacao/relacao-empresa/all"
    this.repositoryService.getAll('', '', '?obra_id=' + this.id + '&detail=true').subscribe(
      d => {
        if (d && d.length > 0){
          this.empresas_relacionadas = d;
          this.empresas_relacionadas.forEach(e => {            
            e.cnpj = UseFullService.GetCnpjMasc(e.cnpj)
            e.responsaveis.forEach(c => {
              if(c.email == this.userLoggedInService.login)
                this.email_validacao = c
            });
          });
        }
      }
    );
  }

  _concordo = false;
  checkedConcordo() {
    this._concordo = !this._concordo;
  }

  update() {
    this._observacao = this.email_validacao._observacao
    UseFullService.ShowModal("#confirm-config-modal");
  }

  enviarEmaisValidacao() {
    this.repositoryService.path = "obra/emailvalidate/save"
    this.repositoryService.post(this.empresas_relacionadas).subscribe(
      d => {
        if (d && d.length > 0)
          console.log(d);
      }
    );
  }

  save(status: EnumStatusEmailValidade.Pendente) {

    if (status === EnumStatusEmailValidade.Pendente) {
      UseFullService.ShowModal("#confirm-config-modal");
    } else if (status === EnumStatusEmailValidade.Reprovado) {
      this.clickTrash();
    } else if(!this._concordo){
      throw ("Antes de aprovar você deve concordas com as configurações!")
    } else {
      this.repositoryService.path = "obra/emailvalidate/get"
      this.repositoryService.get(this.id).subscribe(
        d => {
          if (d && d.id > 0) {
            this.repositoryService.path = "obra/emailvalidate/save"
            d.status = EnumStatusEmailValidade.Aprovado
            d.observacao = this._observacao
            this.repositoryService.put(d).subscribe(
              d => { UseFullService.HideModal("#confirm-config-modal"); this.ngOnInit(); }
            );
          }
        });
    }
  }


  clickTrash() {
    this.alertConfirm._code = this.code;
    this.alertConfirm._title = "Reprovar Configurações";
    this.alertConfirm._message = "Deseja reprovar as configurações do comprador para essa obra ?";
    this.alertConfirm.Show();
  }

  reprovar(confirm: boolean = false) {
    if (this.alertConfirm._code != this.code || confirm == false)
      return;
    this.repositoryService.path = "obra/emailvalidate/get"
    this.repositoryService.get(this.id).subscribe(
      d => {
        if (d && d.id > 0) {
          this.repositoryService.path = "obra/emailvalidate/save"
          d.status = EnumStatusEmailValidade.Reprovado
          d.observacao = this._observacao
          this.repositoryService.put(d).subscribe(
            d => {
              UseFullService.HideModal("#confirm-config-modal");
              window.location.reload();
            }
          );
        }
      });
  }
}
