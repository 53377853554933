import { Component, OnInit, ComponentFactoryResolver, Input } from '@angular/core';
import { OrcamentoFornecedorCtrl } from '../orcamento-forncedor-ctrl';
import { OrcamentoSugerido, EnumStatusOrcamento, EnumStatusTipoOrcamento } from 'src/app/domain/orcamento/orcamento';
import { UseFullService } from 'src/app/service/usefull/usefull.service';

declare var $: any;

@Component({
  selector: 'app-orcamento-sugerido-edit',
  templateUrl: './orcamento-edit-sugerido.component.html',
  styleUrls: ['./orcamento-edit-sugerido.component.css']
})
export class OrcamentoEditSugeridoComponent extends OrcamentoFornecedorCtrl implements OnInit {

  _produto_historico = new Array<OrcamentoSugerido>()

  ngOnInit() {
    this.ObterHistorico()
    super.ngOnInit()
  }

  ReceiverEntregaProduto(e){
    
  }

  ObterHistorico() {
    this.orcamentoService.GetSugerido(this.orcamento_id).subscribe(
      d => {
        if (d && d.length > 0)
          this._produto_historico = d
      },
      e => { },
      () => {
        this._produto_historico.forEach(c => OrcamentoSugerido.Config(c));
        this.SetListProdutos()
      }
    );
  }

  SetListProdutos() {
    if (this._list_produtos && this._list_produtos.length > 0 && this.GetLoadingOrcamento()) {

      this._list_produtos.forEach(c => {
        var sugerido = OrcamentoSugerido.ObterPorId(this._produto_historico, c.id);
        c._historico_valor_orcado = sugerido.valor_orcado
        c._valor_sugerido = sugerido.valor_sugerido
      });

      UseFullService.SetMask();
    } else
      setTimeout(() => { this.SetListProdutos() }, 500);

  }

  ReenviarOrcamento() {
    this._orcamento.status_por_tipo = EnumStatusTipoOrcamento.reenviado;
    this.ConfirmeRrightEnd();
  }

}