<div class="col-lg-12 wrapper-top-content">
    <div class="ibox">
        <div class="ibox-title">
            <h5>Lista de Insumos</h5>
            <div class="ibox-tools">
                <div>
                    <label class="margin-right-20">
                        {{(_vinculado)?_suprimento.insumos.length + '
                        itens':''}}
                        <!--
                        <b *ngIf="_total_pesquisa >= 0">de</b>
                        {{(_suprimento.insumos.length > _total_pesquisa) ?
                        _suprimento.insumos.length : _total_pesquisa}}-->
                    </label>
                    <label>
                        <div class="btn-group mr-2 pagination-position-grid">
                            <button type="button" *ngIf="_paginas[0].active_one"
                                class="btn btn-secondary"
                                (click)="GetPage(1)">1</button>
                        </div>
                    </label>
                    <label>
                        <div class="btn-group mr-2 pagination-position-grid"
                            role="group"
                            aria-label="First group">
                            <button type="button" *ngFor="let page of _paginas"
                                class="btn btn-secondary {{page.active
                                ?'active':''}}"
                                (click)="GetPage(page.page)">{{(page.page)?page.page:1}}</button>
                        </div>
                    </label>
                    <label>
                        <div class="btn-group mr-2 pagination-position-grid">
                            <button type="button"
                                *ngIf="_paginas[0].ultima_pagina> 0"
                                class="btn btn-secondary"
                                (click)="GetPage(_paginas[0].ultima_pagina)">{{_paginas[0].ultima_pagina}}</button>
                        </div>
                    </label>
                </div>
            </div>
        </div>
        <div class="ibox-content">
            <div class="row bottom-10">

                <div class="col-sm-5 m-b-xs">
                    <div class="input-group"><input (ngModelChange)="OnChangeSearch()" placeholder="Pesquisar" [(ngModel)]="_buscar" type="text" class="form-control
                            form-control-sm">
                        <span class="input-group-append">
                            <button type="button" class="btn btn-sm
                                btn-primary">Buscar</button>
                        </span>
                    </div>
                </div>
                <div class="col-sm-2 m-b-xs">

                    <select class="form-control-sm form-control input-s-sm
                        inline select-category" [(ngModel)]="_categoria_id" (change)="OnChangeSearch(false)">
                        <option value="0">Informe a Categoria</option>
                        <option *ngFor="let categoria of _categorias | filter :
                            'pai_id' : 0 : true"
                            value="{{categoria.id}}">{{categoria.descricao}}</option>
                    </select>

                </div>
                <div class="col-sm-5 text-right">

                    <div class="btn-group btn-group-toggle" data-toggle="buttons">

                        <label class="btn btn-white" (click)="SearchBond(true)">
                            <input type="radio" name="options"
                                autocomplete="off"> Insumos Registrados
                        </label>
                        <label class="btn btn-white" (click)="SearchBond(false)">
                            <input type="radio" name="options"
                                autocomplete="off">+ Adicionar Insumos
                        </label>
                    </div>

                </div>
            </div>


            <div class="col-lg-12 row   " style="padding:5px
                10px 10px 10px;
                margin: 0;
                margin-bottom: 15px;background:#f5f5f5;" *ngIf="_categoria_nivel || _categoria_virtual &&
                _categoria_virtual.id> 0">

                <div class="col-lg-12" style="padding:0 0 2px 0; margin-bottom:
                    5px; border-bottom: 1px solid #ccc;">
                    <h4>Possíveis produtos para busca e cadastro:</h4>
                </div>

                <div class="col-md-2" style="padding: 2px 0px 0px 2px;" *ngIf="_categoria_virtual && _categoria_virtual.id> 0">
                    <b>Produto</b><br />

                    <select class="form-control-sm form-control input-s-sm
                        inline select-niveis {{ _categoria_virtual._selecionado>
                        0 ?
                        'input-border-success':'input-border-error' }}" [(ngModel)]="_categoria_virtual._selecionado" (change)="OnChangeSearchCategoria()">
                        <option value="undefined" selected>Selecione Produto</option>
                        <option *ngFor="let categoria of
                            _categoria_virtual.categorias"
                            value="{{categoria.id}}">{{categoria.descricao}}</option>
                    </select>
                </div>

                <div class="col-md-2" style="padding: 2px 0px 0px 2px;" *ngFor="let nivel of _niveis" [hidden]="!nivel._ativo">
                    <div *ngIf="nivel._ativo">
                        <b>{{nivel.nome}}*</b><br />

                        <button class="btn btn-default btn-select-color text-left 
                            button-pesquisa {{nivel._selected> 0 ?
                            'input-border-success':'input-border-error' }}" *ngIf="nivel.selecao == _enumComponetesSelecao.modal" (click)="SelectCores(nivel)">Selecione {{nivel.nome}}</button>
                        <select class="form-control-sm form-control input-s-sm
                            inline select-niveis {{nivel._selected> 0 ?
                            'input-border-success':'input-border-error' }}" [(ngModel)]="nivel._selected" (change)="OnChangeSearchNivel(nivel)" *ngIf="nivel.selecao == _enumComponetesSelecao.select">
                            <option value="undefined" selected>Selecione
                                {{nivel.nome}}</option>
                            <option *ngFor="let sub of nivel.nivel" [hidden]="!(sub._temp_id_vinculo > 0)"
                                value="{{sub.id}}">{{sub.descricao}}</option>
                        </select>
                    </div>
                </div>
                <!-- | filter : '_ativo' : true : true"
                    'input-border-error':'input-border-success'
                <div class="col-md-2 ml-auto">
                    <button>Salvar Insumo</button>
                </div>
            -->
            </div>
            <div class="table-responsive min-height-grid-insumo
                div-table-fixed">
                <table class="table table-striped table-hover table-bordered">
                    <thead class="thead-dark thead-fixed">
                        <tr>
                            <th class="text-center"></th>
                            <th class="th-nome-produto">Nome do Insumo</th>
                            <th class="text-center th-categoria">Categoria </th>
                            <th class="text-center th-categoria">Marca</th>
                            <th class="text-center th-input-code">SKU</th>
                            <th class="text-center th-select-unity column-unidade">Unidade</th>
                            <th class="text-center th-input-price">Preço</th>
                            <th class="text-center th-input-stock" data-toggle="tooltip" data-placement="top" title="Campo estoque deve ter apenas
                                números
                                e virugula.">Estoque</th>
                            <th class="text-center th-input-crossdocking" data-toggle="tooltip" data-placement="top" title="Tempo de entrega do produto pelo
                                fornecedor. (dias)">CrossDocking</th>
                            <!-- <th class="text-center">Vinculado</th> -->
                            <th class="text-center width-th-action th-action" colspan="2" *ngIf="userLoggedService.Editar || userLoggedService.Excluir">#</th>
                        </tr>
                    </thead>
                    <!-- | filter : 'ha_niveis' : 0 : true -->
                    <tbody class="  " [hidden]="_carregando">
                        <tr *ngFor="let produto of _suprimento.insumos " (mouseenter)="mouseEnter(produto)" (mouseleave)="mouseLeave(produto)" [hidden]="(produto.ha_niveis >= 1 || (_vinculado && produto.ProdutoFornecedor.marca_id < 1 && !produto.ProdutoFornecedor.preco))">
                            <td class="text-center width-60-px">
                                {{produto._virtual_index}}
                            </td>
                            <td class="td-nome-produto">
                                {{produto.nome}}
                            </td>
                            <td class="text-center th-categoria">{{produto._categoria}}</td>
                            <td class="text-center width-100-px" style="padding:2px;">{{(produto.ProdutoFornecedor._marca) ? produto.ProdutoFornecedor._marca : '-'}}
                                <br /><span class="badge badge-secondary cursor-pointer cursor-opacity" *ngIf="produto._selected_hover" (click)="gerenciarMarca(produto)">+ Marca</span>
                            </td>
                            <td class="text-center th-input-code">
                                <input class="form-control input-code" type="text" (ngModelChange)="OnChangeAll(produto.ProdutoFornecedor)" [(ngModel)]="produto.ProdutoFornecedor.codigo_opcional" placeholder="000">
                            </td>
                            <td class="text-left th-select-unity">
                                <select class="form-control-sm form-control  {{(!produto.ProdutoFornecedor.unidade_id) ? 'select-of':''}}  
                                    input-s-sm inline select-unity" (ngModelChange)="OnChangeAll(produto.ProdutoFornecedor)" [(ngModel)]="produto.ProdutoFornecedor.unidade_id">
                                    <option value="undefined" disabled style="color: #ccc;">Unidade</option>
                                    <option *ngFor="let unidade of _unidade_medida" value="{{unidade.id}}">{{unidade.nome}}</option>
                                </select>
                            </td>
                            <td class="text-left th-input-price">
                                <!--readonly-->
                                <input class="form-control input-price" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" (ngModelChange)="OnChangeAll(produto.ProdutoFornecedor)" [(ngModel)]="produto.ProdutoFornecedor.preco" type="text" placeholder="0,00">
                            </td>
                            <td class="text-center th-input-stock">
                                <input class="form-control input-stock" data-toggle="tooltip" data-placement="top" title="Campo estoque deve ter apenas
                                    números
                                    e virgula." type="number" pattern="[0-9]+$" (ngModelChange)="OnChangeAll(produto.ProdutoFornecedor)" [(ngModel)]="produto.ProdutoFornecedor.estoque" placeholder="0">
                            </td>
                            <td class="text-center th-input-crossdocking">
                                <input class="form-control input-crossdocking" data-toggle="tooltip" data-placement="left" title="Tempo de entrega do produto pelo
                                    fornecedor. (dias)" type="number" pattern="[0-9]+$" (ngModelChange)="OnChangeAll(produto.ProdutoFornecedor)" [(ngModel)]="produto.ProdutoFornecedor.cross_docking" placeholder="0">
                            </td>
                            <!--
                                <td class="text-center">
                                    <div class="btn-group" data-toggle="buttons">
                                    <label class="btn btn-check-table btn-default {{produto.ProdutoFornecedor.vinculado ? 'active' : ''}}"
                                        aria-pressed="true" (click)="ClickBond(produto.ProdutoFornecedor)">
                                        <span class="glyphicon glyphicon-ok"></span>
                                    </label>
                                    </div>
                                </td>
                            -->
                            <td class="text-center th-action-salvar" *ngIf="userLoggedService.Editar">
                                <button class="btn btn-sm-grid btn-primary
                                    {{produto.ProdutoFornecedor._status ? '':
                                    'disabled' }}" (click)="SaveProductProvider(produto)">
                                    <span class=""
                                        [hidden]="produto.ProdutoFornecedor._loading">Salvar</span>
                                    <div class="spinner-border"
                                        role="status"
                                        [hidden]="!produto.ProdutoFornecedor._loading">
                                        <span class="sr-only"></span>
                                    </div>
                                </button>
                            </td>
                            <td class="text-center th-action-delete" *ngIf="userLoggedService.Excluir">
                                <button class="btn btn-sm-grid btn-link
                                    {{produto.ProdutoFornecedor.id> 0 ? '':
                                    'disabled' }}" (click)="ConfirmDeleteProductProvider(produto)">
                                    <i class="fa fa-trash-alt"></i>
                                </button>
                            </td>

                            <!--
                            <td *ngIf="produto.CategoriasNiveis.length> 0"
                                colspan="8" class="text-left" style="padding: 2px;">
                                <div class="row">
                                    <div class="col-lg-5">{{produto.Nome}}</div>
                                    <div class="col-lg-4">{{produto.CategoriaNome}}</div>
                                    <div class="col-lg-3 text-right">
                                        <button
                                            class="btn btn-primary"><i class="fa
                                                fa-plus"></i>Inserir Insumo</button>
                                    </div>
                                </div>
                            </td>
                            -->
                        </tr>

                    </tbody>
                </table>

                <div *ngIf="_ha_produto && !_categoria_nivel" class="mensagem-produto">
                    Nenhum produto encontrado, para pesquisa selecionada.
                    <a href [routerLink]="['/suprimento/solicitacao']">
                        Solicitar cadastro de um novo insumo!</a>
                </div>

                <div *ngIf="_ha_produto && _categoria_nivel" class="mensagem-produto">
                    <span class="text-danger">Selecione todas as subcategorias
                        para buscar,</span> caso não encontre o produto desejado, <a href [routerLink]="['/suprimento/solicitacao']">
                        solicite o cadastro de um novo produto!</a>
                </div>

                <div [hidden]="!_carregando" class="carregando-produtos">
                    <div class="spinner-border" role="status">
                        <span class="sr-only"></span>
                    </div>
                    carregando...
                </div>
            </div>

        </div>
    </div>
</div>

<app-modal-cores-insumo [_input_nivel]="_input_nivel" (resposta_cores_selecionadas)="ReciverCoresSelecionadas($event)"></app-modal-cores-insumo>
<app-modal-multiselect [_input]="_input" [_multiple]="true" (_return)="receiverData($event)"></app-modal-multiselect>