export class ProdutoUnidadeMedida
{
    public id: number;
    public nome: string;
    public descricao: string;

    public static Get(unidades: Array<ProdutoUnidadeMedida>, id: number): ProdutoUnidadeMedida{
        function FiltrarPorId(obj){
            return (obj.id == id)
        }
        var data = unidades.filter(FiltrarPorId);
        return data[0];
    }
}