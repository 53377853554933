import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from '../../../core/global-variable';
import { Cotacao } from 'src/app/domain/cotacao/cotacao';
import { Orcamento } from 'src/app/domain/orcamento/orcamento';

@Injectable({
    providedIn: 'root'
})
export class CotacaoService {

    private url = GlobalVariable.BASE_API_URL + "cotacao/";
    constructor(private http: HttpClient) { }

    GetAll(usuario_id: number): Observable<Cotacao[]> {
        return this.http.get<Cotacao[]>(this.url + "getall?usuario_id=" + usuario_id).pipe(
            map(res => {
                return res;
            })
        );
    }

    GetAllCotacaoOrcada(usuario_id: number, historico = false): Observable<Cotacao[]> {
        return this.http.get<Cotacao[]>(this.url + "getallorcada?usuario_id=" + usuario_id+"&historico="+historico).pipe(
            map(res => {
                return res;
            })
        );
    }

    Get(id: number): Observable<Cotacao> {
        return this.http.get<Cotacao>(this.url + "get?id=" + id).pipe(
            map(res => {
                return res;
            })
        );
    }

    GetOrcamentoAprovado(id: number): Observable<Orcamento> {
        return this.http.get<Orcamento>(this.url + "get/orcamento/aprovado?cotacao_id=" + id).pipe(
            map(res => {
                return res;
            })
        );
    }

    Create(cotacao: Cotacao): Observable<any> {
        var url = this.url + "create";
        return this.http.post<any>(url, cotacao).pipe(
            map(res => { return res; })
        );
    }

    Duplicar(cotacao: Cotacao): Observable<any> {
        var url = this.url + "duplicar";
        return this.http.post<any>(url, cotacao).pipe(
            map(res => { return res; })
        );
    }

    Edit(cotacao: Cotacao) {
        var url = this.url + "edit";
        return this.http.put<any>(url, cotacao).pipe(
            map(res => { return res; })
        );
    }

    EditPreco(cotacao: Cotacao) {
        var url = this.url + "edit-preco";
        return this.http.put(url, cotacao).pipe(
            map(res => { return res; })
        );
    }

    Cancelar(cotacao: Cotacao) {
        var url = this.url + "cancel";
        return this.http.put(url, cotacao).pipe(
            map(res => { return res; })
        );
    }

    Delete(id: number): Observable<any> {
        return this.http.delete<any>(this.url + "delete?id=" + id).pipe(
            map(res => {
                return res;
            })
        );
    }

    GetAllFornecedor(id: number, status: number): Observable<Cotacao[]> {
        return this.http.get<Cotacao[]>(this.url + "GetAllFornecedor?usuario_id=" + id+"&status="+status).pipe(
            map(res => {
                return res;
            })
        );
    }
}
