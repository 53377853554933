import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from '../../core/global-variable';
import { Http } from '@angular/http';
import { Empresa } from 'src/app/domain/empresa/empresa';
import { UseFullService } from '../usefull/usefull.service';
import { UserLoggedInService } from '../authenticate/user-logged-in.service';
import { TypeUser } from 'src/app/domain/usuario/Usuario';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  private url = GlobalVariable.BASE_API_URL + "empresa/";
  constructor(private userLoggeIn: UserLoggedInService, private http: HttpClient, private simpleHttp: Http) { }

  Insert(_empresa: Empresa) {
    const formData = new FormData();
    formData.append('id', String(_empresa.id));
    formData.append('empresa_id', String(_empresa.empresa_id));
    formData.append('arquivos_contrato_social', (_empresa.arquivos_contrato_social != undefined) ? _empresa.arquivos_contrato_social : "");
    formData.append('razao_social', _empresa.razao_social);
    formData.append('nome_fantasia', _empresa.nome_fantasia);
    formData.append('inscricao_estadual', _empresa.inscricao_estadual != undefined ? _empresa.inscricao_estadual : "");
    formData.append('cnpj', _empresa.cnpj);
    formData.append('categoria', String(_empresa.categoria));
    formData.append('cep', _empresa.cep);
    formData.append('endereco', _empresa.endereco);
    formData.append('numero', String(_empresa.numero));
    formData.append('complemento', _empresa.complemento != undefined ? _empresa.complemento : "");
    formData.append('bairro', _empresa.bairro);
    formData.append('cidade', _empresa.cidade);
    formData.append('uf', _empresa.uf);
    formData.append('img', _empresa._img);
    formData.append('log', _empresa.log != undefined ? _empresa.log : "");
    formData.append('logo', _empresa.logo);
    formData.append('cpf', _empresa.cpf);
    formData.append('telefone', _empresa.telefone);

    if (_empresa.list_contatos_financeiro && _empresa.list_contatos_financeiro.length > 0) {
      _empresa.list_contatos_financeiro.forEach(c => {
        if (!UseFullService.IsEmail(c.email))
          throw ("E-mail do contato financeiro é inválido!")
      });
      formData.append('_list_contatos_financeiro', JSON.stringify(_empresa.list_contatos_financeiro));
    }

    for (let file of _empresa._files)
      formData.append('files', file);

    return this.http.post(this.url + 'create', formData).pipe(
      map(r => { return r; })
    );
  }

  Get(id: number): Observable<Empresa> {
    return this.http.get<Empresa>(this.url + "get?id=" + id).pipe(
      map(r => { return r; })
    );
  }

  GetLoggedIn(): Observable<Empresa> {
    return this.http.get<Empresa>(this.url + "getcnpj").pipe(
      map(r => { return r; })
    );
  }

  GetEmpresaPorObra(id: number): Observable<Empresa> {
    return this.http.get<Empresa>(this.url + "get/obraid?id="+id).pipe(
      map(r => { return r; })
    );
  }

  GetPorUserId(user_compra_id: number): Observable<Empresa> {
    return this.http.get<Empresa>(this.url + "get/userid/?userid=" + user_compra_id).pipe(
      map(r => { return r; })
    );
  }
}
