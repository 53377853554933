import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from, empty } from 'rxjs';
import { map } from 'rxjs/operators';

import { SolicitacaoProduto } from 'src/app/domain/suprimento/solicitacao-produto';
import { GlobalVariable } from 'src/app/core/global-variable';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class EnumService {
  
  private url = GlobalVariable.BASE_API_URL + "enum/";
  constructor(private http: HttpClient) { }

  GetAll(objeto: string, chave: string = "", por: string = ""): Observable<any[]> {
    return this.http.get<any[]>(this.url + "getall?objeto="+objeto+"&chave="+chave+"&por="+por).pipe(
      map(res => {
        return res;
      })
    );
  }

  GeralGetAll(objeto: string, chave: string = ""): Observable<any> {
    return this.http.get<any>(this.url + "getall?objeto="+objeto+"&chave="+chave).pipe(
      map(res => {
        return res;
      })
    );
  }
}