import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule, AppRoutingProviders } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterPipe} from './core/filter.pipe';
import { AngularResizedEventModule } from 'angular-resize-event';
import { ChartModule } from 'angular-highcharts';
import { NgxCurrencyModule } from "ngx-currency";



import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NzTableModule } from 'ng-zorro-antd/table'


import { AppComponent } from './app.component';

import { HTTPListener, HTTPStatus } from './core/Interceptor/loader-interceptor';
import { HeaderComponent } from './core/header/header.component';
import { NavBarComponent } from './core/page/nav-bar/nav-bar.component';
import { HomeComponent } from './core/page/home/home.component';
import { SideBarComponent } from './core/page/side-bar/side-bar.component';
import { FooterComponent } from './core/page/footer/footer.component';
import { CustomHttpInterceptor } from './core/Interceptor/custom-http-interceptor';
import { BreadcrumbComponent } from './core/page/breadcrumb/breadcrumb.component';
import { NotFoundComponent } from './core/page/not-found/not-found.component';

import { UserComponent } from './modules/user/user.component';
import { InsumoComponent } from './modules/suprimento/insumo/insumo.component';
import { LoginComponent } from './modules/user/login/login.component';
import { MessageComponent } from './core/page/message/message.component';
import { LoadPageComponent } from './core/page/load-page/load-page.component';
import { NewCotacaoComponent } from './modules/cotacao/new-cotacao/new-cotacao.component';
import { AlertComponent } from './core/page/alert/alert.component';
import { NovoUsuarioComponent } from './modules/user/novo-usuario/novo-usuario.component';
import { MinhaContaComponent } from './modules/user/minha-conta/minha-conta.component';
import { UsuariosEmpresaComponent } from './modules/user/usuarios-empresa/usuarios-empresa.component';
import { AlertConfirmComponent } from './core/page/alert-confirm/alert-confirm.component';
import { GerenciamentoAcessoComponent } from './modules/user/gerenciamento-acesso/gerenciamento-acesso.component';
import { SolicitacaoComponent } from './modules/suprimento/solicitacao/solicitacao.component';
import { SolicitacaoAdminComponent } from './modules/suprimento/solicitacao-admin/solicitacao-admin.component';
import { PesquisaComponent } from './modules/suprimento/pesquisa/pesquisa.component';
import { LoginSiteComponent } from './modules/user/login-site/login-site.component';
import { ConfiguracaoComponent } from './modules/configuracao/configuracao/configuracao.component';
import { EmpresaFormComponent } from './modules/configuracao/empresa-form/empresa-form.component';
import { ImageModalComponent } from './core/page/data-modal/data-modal.component';
import { ConfiguracaoAdminComponent } from './modules/configuracao/configuracao-admin/configuracao-admin.component';
import { CategoriaNivelComponent } from './modules/configuracao/configuracao-admin/categoria-nivel/categoria-nivel.component';
import { ModalCoresInsumoComponent } from './modules/suprimento/insumo/adicionar-insumo/modal-cores-insumo.component';
import { NotPermissionComponent } from './core/page/not-permission/not-permission.component';
import { TemporaryMessageComponent } from './core/page/temporary-message/temporary-message.component';
import { CategoriaVirtualComponent } from './modules/configuracao/configuracao-admin/categoria-virtual/categoria-virtual.component';
import { VinculoNivelComponent } from './modules/configuracao/configuracao-admin/vinculo-nivel/vinculo-nivel.component';
import { FuncaoSistemaComponent } from './modules/configuracao/configuracao-admin/funcao-sistema/funcao-sistema.component';
import { PerfilUsuarioComponent } from './modules/configuracao/configuracao-admin/perfil-usuario/perfil-usuario.component';
import { PermissaoUsuarioComponent } from './modules/configuracao/permissao-usuario/permissao-usuario.component';
import { InsumoCompradorComponent } from './modules/suprimento/comprador/insumo-comprador/insumo-comprador.component';
import { AdicionarProdutoCompradorComponent } from './modules/suprimento/comprador/adicionar-produto-comprador/adicionar-produto-comprador.component';
import { EntregaParcialCompradorComponent } from './modules/suprimento/comprador/entrega-parcial-comprador/entrega-parcial-comprador.component';
import { LocalidadeComponent } from './modules/cotacao/localidade/localidade.component';
import { GlobalErrorHandler } from './core/Interceptor/ErrorHandler';
import { CotacaoViewComponent } from './modules/cotacao/cotacao-view/cotacao-view.component';
import { PrazoPagamentoComponent } from './modules/auxiliar/prazo-pagamento/prazo-pagamento.component';
import { CotacaoListComponent } from './modules/cotacao/cotacao-list/cotacao-list.component';
import { OrcamentoSolicitacaoComponent } from './modules/orcamento/fornecedor/orcamento-solicitacao/orcamento-solicitacao.component';
import { OrcamentoEditComponent } from './modules/orcamento/fornecedor/orcamento-edit/orcamento-edit.component';
import { EntregaParcialViewComponent } from './modules/suprimento/comprador/entrega-parcial-view/entrega-parcial-view.component';
import { OrcamentoCompradorViewComponent } from './modules/orcamento/comprador/orcamento-comprador-view/orcamento-comprador-view.component';
import { OrcamentoFornecedorViewComponent } from './modules/orcamento/fornecedor/orcamento-fornecedor-view/orcamento-fornecedor-view.component';
import { OrcamentoCompradorCotacaoListComponent } from './modules/orcamento/comprador/orcamento-comprador-cotacao-list/orcamento-comprador-cotacao-list.component';
import { OrcamentoConfiguracaoComponent } from './modules/orcamento/fornecedor/orcamento-configuracao/orcamento-configuracao.component';
import { OrcamentoCompradorGridComponent } from './modules/orcamento/comprador/orcamento-comprador-grid/orcamento-comprador-grid.component';
import { OrcamentoCompradorModalComponent } from './modules/orcamento/comprador/orcamento-comprador-modal/orcamento-comprador-modal.component';
import { OrcamentoSolicitacaoHistoricoComponent } from './modules/orcamento/fornecedor/orcamento-solicitacao/orcamento-solicitacao-historico.component';
import { OrcamentoFornecedorListComponent } from './modules/orcamento/fornecedor/orcamento-fornecedor-list/orcamento-fornecedor-list.component';
import { OrcamentoFornecedorListEnviadosComponent } from './modules/orcamento/fornecedor/orcamento-fornecedor-list/orcamento-fornecedor-list-enviados.component';
import { CompraListComponent } from './modules/transacao/comprador/compra-list/compra-list.component';
import { VendaListComponent } from './modules/transacao/fornecedor/venda-list/venda-list.component';
import { CompraViewComponent } from './modules/transacao/comprador/compra-view/compra-view.component';
import { VendaViewComponent } from './modules/transacao/fornecedor/venda-view/venda-view.component';
import { EntregaListComponent } from './modules/entrega/entrega-list/entrega-list.component';
import { DashboardComponent } from './modules/dashboard/dashboard/dashboard.component';
import { DashboardCotacaoComponent } from './modules/dashboard/dashboard-cotacao/dashboard-cotacao.component';
import { DashboardOrcamentoComponent } from './modules/dashboard/dashboard-orcamento/dashboard-orcamento.component';
import { DashboardVendaComponent } from './modules/dashboard/dashboard-venda/dashboard-venda.component';
import { DashboardEntregaComponent } from './modules/dashboard/dashboard-entrega/dashboard-entrega.component';
import { LoginFornecedorEmailComponent } from './modules/user/login/login-forncedor-email/login-fornecedor-email.component';
import { OrcamentoEmailEditComponent } from './modules/orcamento/fornecedor/orcamento-email-edit/orcamento-email-edit.component';
import { OrcamentoEmailViewComponent } from './modules/orcamento/fornecedor/orcamento-email-view/orcamento-email-view.component';
import { MessageModalComponent } from './core/page/message-modal/message-modal.component';
import { AlertConfirmSecundarioComponent } from './core/page/alert-confirm-secundario/alert-confirm-secundario.component';
import { DadosClientComponent } from './modules/configuracao/dados-client/dados-client.component';
import { EntregaCompradorComponent } from './modules/entrega/entrega-list/entrega-comprador.component';
import { EnderecoModalComponent } from './core/page/endereco-modal/endereco-modal.component';
import { OrcamentoCompradorSugestaoViewComponent } from './modules/orcamento/comprador/orcamento-comprador-sugestao-view/orcamento-comprador-sugestao-view.component';
import { OrcamentoFornecedorDisputaListComponent } from './modules/orcamento/fornecedor/orcamento-fornecedor-disputa-list/orcamento-fornecedor-disputa-list.component';
import { OrcamentoEditSugeridoComponent } from './modules/orcamento/fornecedor/orcamento-edit-sugerido/orcamento-edit-sugerido.component';
import { OrcamentoViewSugeridoComponent } from './modules/orcamento/fornecedor/orcamento-edit-sugerido/orcamento-view-sugerido.component';
import { OrcamentoCompradorCotacaoHistoricoListComponent } from './modules/orcamento/comprador/orcamento-comprador-cotacao-list/orcamento-comprador-cotacao-historico-list.component';
import { CadastreSeComponent } from './core/page/cadastre-se/cadastre-se.component';
import { DashboardCompradorComponent } from './modules/dashboard/dashboard-comprador/dashboard-comprador.component';
import { ExtratoFinanceiroForncedorComponent } from './modules/gerenciamento/fornecedor/extrato-financeiro/extrato-financeiro-fornecedor.component';
import { CotacoesComponent } from './modules/gerenciamento/comprador/cotacoes/cotacoes.component';
import { GerenciamentoCotacaoViewComponent } from './modules/gerenciamento/comprador/cotacoes/cotacao-view/gerenciamento-cotacao-view.component';
import { ViewExtratoFinanceiroComponent } from './modules/gerenciamento/fornecedor/view-extrato-financeiro/view-extrato-financeiro.component';
import { SolicitacaoSistemaComponent } from './modules/gerenciamento/fornecedor/solicitacao-sistema/solicitacao-sistema.component';
import { GerenciarUsuariosComponent } from './modules/gerenciamento/comprador/gerenciar-usuarios/gerenciar-usuarios.component';
import { GerenciarViewUsuariosComponent } from './modules/gerenciamento/comprador/gerenciar-usuarios/gerenciar-view-usuarios.component';
import { EmpresaFaturanteFormComponent } from './modules/gestao-cotacao/empresa-faturante-form.component';
import { EmpresaListComponent } from './modules/gestao-cotacao/empresa-list.component';
import { ObraCotacaoFormComponent } from './modules/gestao-cotacao/obra/obra-cotacao-form.component';
import { ObraCotacaoListComponent } from './modules/gestao-cotacao/obra/obra-cotacao-list.component';
import { ObraCotacaoViewComponent } from './modules/gestao-cotacao/obra/obra-cotacao-view.component';
import { CabecalhoOrcamentoComponent } from './modules/auxiliar/cabecalho/cabecalho-orcamento.component';;
import { CabecalhoCotacaoComponent } from './modules/auxiliar/cabecalho/cabecalho-cotacao.component';
import { ObraCotacaoRelatorioComponent } from './modules/gestao-cotacao/obra/obra-cotacao-relatorio.component';
import { LoginAuditorComponent } from './modules/user/login/login-auditor.component';
import { ObraCotacaoAuditorRelatorioComponent } from './modules/gestao-cotacao/obra/auditor/obra-cotacao-auditor-relatorio.component';
import { ObraCotacaoAuditorListComponent } from './modules/gestao-cotacao/obra/auditor/obra-cotacao-auditor-list.component';
import { AuditorCompraListComponent } from './modules/gestao-cotacao/compras/auditor-compra-list.component';
import { OrcamentoAuditorViewComponent } from './modules/gestao-cotacao/compras/orcamento-auditor-view.component';
import { AuditorViewProcessoComponent } from './modules/gestao-cotacao/auditoria-view/auditor-view-processo.component';
import { ProdutoListComponent } from './modules/auxiliar/produto/produto-list.component';
import { ProdutoFormComponent } from './modules/auxiliar/produto/produto-form.component';
import { ModalInsumoNivelComponent } from './modules/suprimento/pesquisa/modal-insumo-nivel.component';
import { CategoriaComponent } from './modules/auxiliar/categoria/categoria.component';
import { CadastroNivelComponent } from './modules/auxiliar/cadastro-nivel/cadastro-nivel.component';
import { CadastroItemNivelComponent } from './modules/auxiliar/cadastro-item-nivel/cadastro-item-nivel.component';
import { CadastroCorComponent } from './modules/auxiliar/cadastro-cor/cadastro-cor.component';
import { IconsModule } from './icons/icons.module';
import { CadastroMarcaComponent } from './modules/auxiliar/cadastro-marca/cadastro-marca.component';
import { RelacaoObraProdutoMarcaListComponent } from './modules/auxiliar/relacao-obra-produto-marca/relacao-obra-produto-marca-list.component';
import { RelacaoObraListComponent } from './modules/auxiliar/relacao-obra-produto-marca/relacao-obra-list.component';
import { ModalMultiSelectComponent } from './core/page/modal-multiselect/modal-multiselect.component';
import { BuscarProdutoComponent } from './modules/suprimento/comprador/adicionar-produto-comprador/buscar-produto.component';
import { GridProdutoObraComponent } from './modules/suprimento/comprador/insumo-comprador/grid-produto-obra.component';
import { OrcamentoCompradorViewListComponent } from './modules/orcamento/comprador/orcamento-comprador-grid/orcamento-comprador-view-list.component';



const RxJS_Services = [HTTPListener, HTTPStatus];

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    NgbModule,
    AngularResizedEventModule,
    ReactiveFormsModule,
    ChartModule,
    IconsModule,

    NgxCurrencyModule,
    
    BrowserAnimationsModule,
    DragDropModule,
    ScrollingModule,
    NzTableModule
    
  ],

  declarations: [
    AppComponent,
    HeaderComponent,
    NavBarComponent,
    HomeComponent,
    SideBarComponent,
    FooterComponent,
    UserComponent,
    BreadcrumbComponent,
    NotFoundComponent,
    InsumoComponent,
    LoginComponent,
    MessageComponent,
    LoadPageComponent,
    NewCotacaoComponent,
    AlertComponent,
    NovoUsuarioComponent,
    MinhaContaComponent,
    UsuariosEmpresaComponent,
    AlertConfirmComponent,
    GerenciamentoAcessoComponent,
    FilterPipe,
    SolicitacaoComponent,
    SolicitacaoAdminComponent,
    PesquisaComponent,
    LoginSiteComponent,
    ConfiguracaoComponent,
    EmpresaFormComponent,
    ImageModalComponent,
    ConfiguracaoAdminComponent,
    CategoriaNivelComponent,
    ModalCoresInsumoComponent,
    NotPermissionComponent,
    TemporaryMessageComponent,
    CategoriaVirtualComponent,
    VinculoNivelComponent,
    FuncaoSistemaComponent,
    PerfilUsuarioComponent,
    PermissaoUsuarioComponent,
    InsumoCompradorComponent,
    AdicionarProdutoCompradorComponent,
    EntregaParcialCompradorComponent,
    LocalidadeComponent,
    CotacaoViewComponent,
    PrazoPagamentoComponent,
    CotacaoListComponent,
    OrcamentoConfiguracaoComponent,
    OrcamentoSolicitacaoComponent,
    OrcamentoEditComponent,
    EntregaParcialViewComponent,
    OrcamentoCompradorViewComponent,
    OrcamentoFornecedorViewComponent,
    OrcamentoCompradorCotacaoListComponent,
    OrcamentoCompradorGridComponent,
    OrcamentoCompradorModalComponent,
    OrcamentoSolicitacaoHistoricoComponent,
    OrcamentoFornecedorListComponent,
    OrcamentoFornecedorListEnviadosComponent,
    CompraListComponent,
    VendaListComponent,
    CompraViewComponent,
    VendaViewComponent,
    EntregaListComponent,
    DashboardComponent,
    DashboardCotacaoComponent,
    DashboardOrcamentoComponent,
    DashboardVendaComponent,
    DashboardEntregaComponent,
    LoginFornecedorEmailComponent,
    OrcamentoEmailEditComponent,
    OrcamentoEmailViewComponent,
    MessageModalComponent,
    AlertConfirmSecundarioComponent,
    DadosClientComponent,
    EntregaCompradorComponent,
    EnderecoModalComponent,
    OrcamentoCompradorSugestaoViewComponent,
    OrcamentoFornecedorDisputaListComponent,
    OrcamentoEditSugeridoComponent,
    OrcamentoViewSugeridoComponent,
    OrcamentoCompradorCotacaoHistoricoListComponent,
    CadastreSeComponent,
    DashboardCompradorComponent,
    ExtratoFinanceiroForncedorComponent,
    CotacoesComponent,
    GerenciamentoCotacaoViewComponent,
    ViewExtratoFinanceiroComponent,
    SolicitacaoSistemaComponent,
    GerenciarUsuariosComponent,
    GerenciarViewUsuariosComponent,
    EmpresaFaturanteFormComponent,
    EmpresaListComponent,
    ObraCotacaoFormComponent,
    ObraCotacaoListComponent,
    ObraCotacaoViewComponent,
    CabecalhoOrcamentoComponent,
    CabecalhoCotacaoComponent,
    ObraCotacaoRelatorioComponent,
    LoginAuditorComponent,
    ObraCotacaoAuditorRelatorioComponent,
    ObraCotacaoAuditorListComponent,
    AuditorCompraListComponent,
    OrcamentoAuditorViewComponent,
    AuditorViewProcessoComponent,
    ProdutoListComponent,
    ProdutoFormComponent,
    ModalInsumoNivelComponent,
    CategoriaComponent,
    CadastroNivelComponent,
    CadastroItemNivelComponent,
    CadastroCorComponent,
    CadastroMarcaComponent,
    RelacaoObraProdutoMarcaListComponent,
    RelacaoObraListComponent,
    ModalMultiSelectComponent,
    BuscarProdutoComponent,
    GridProdutoObraComponent,
    OrcamentoCompradorViewListComponent
  ],

  providers: [ 
    RxJS_Services,
    /*{ provide: AppRoutingProviders, useClass: AuthenticationService },*/ 
    { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true } , 
    { provide: HTTP_INTERCEPTORS, useClass: HTTPListener, multi: true },
    { provide: ErrorHandler,  useClass: GlobalErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }