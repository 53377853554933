import { Component, OnInit } from '@angular/core';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { Cotacao } from 'src/app/domain/cotacao/cotacao';
import { Router } from '@angular/router';
import { Orcamento, ListOrcamento } from 'src/app/domain/orcamento/orcamento';
import { RepositoryService } from 'src/app/service/repository.service';
import { Empresa } from 'src/app/domain/empresa/empresa';
import { EmailValidate, EnumStatusEmailValidade, ProcessoEmailOrcmento } from 'src/app/domain/auxiliar/EmailValidate';

@Component({
  selector: 'app-auditor-compra-list',
  templateUrl: './auditor-compra-list.component.html',
  styleUrls: ['./auditor-compra-list.component.css']
})
export class AuditorCompraListComponent implements OnInit {

  public code: string;
  public empresas: Array<ProcessoEmailOrcmento>;
  public _empresa: ProcessoEmailOrcmento;
  
  navs = [
    { active: 'active', descricao: 'Aguardando', status: EnumStatusEmailValidade.Pendente },
    { active: '', descricao: 'Aprovado', status: EnumStatusEmailValidade.Aprovado },
    { active: '', descricao: 'Reprovado', status: EnumStatusEmailValidade.Reprovado }
  ]

  constructor(
    public reposity: RepositoryService,
    public confirmService: AlertConfirmService,
    public userLoggedService: UserLoggedInService,
    public router: Router) {
    this.userLoggedService.SetCurrentUser();
    this.empresas = new Array<ProcessoEmailOrcmento>();
    this._empresa = new ProcessoEmailOrcmento();
  }

  ngOnInit() {
    this.userLoggedService.SetCurrentUser();
    this.GetAll(EnumStatusEmailValidade.Pendente);
  }

  GetAll(status) {    
    this.empresas = new Array<ProcessoEmailOrcmento>();
    this.reposity.path = "obra/emailvalidate/get/allorcamento"
    this.reposity.getAll("&status="+status).subscribe(
      d => {
        if (d && d.length > 0)
          this.empresas = d;
      },
      e => { },
      () => {
        let x = 1;
        this.empresas.forEach(c => { c._index = x++; });
      }
    );
  }

  Ver(data: EmailValidate) {
    this.router.navigateByUrl("auditor/compras/orcamento/" + data.origem_id);
  }

  verTab(item){
    this.navs.forEach(c=>c.active = '')
    item.active = 'active'
    this.GetAll(item.status)
  }

}