<div class="content-login">
    <div class="middle-box text-center loginscreen   " style="border: 1px solid #ddd">

        <div class="row">

            <div class="col-sm-12 div-logo">
                <img alt="image" class="" src="./assets/images/logo_vertical.svg" />
            </div>
            <div class="col-sm-6 div-no-login text-left">
                <b>Não possui uma conta:</b>
                <button type="submit" class="btn btn-primary block
                        full-width m-b acessar" (click)="LogNoInto()">Acessar Sem Conta</button>

                <div class="text-left esqueceu-senha">
                    <a href="https://paineldaengenharia.com.br/fornecedor/cadastro-fornecedor">Cadastre-se, Grátis</a>
                </div>
            </div>
            <div class="col-sm-6 div-login text-left">
                <b>Possui uma conta:</b>
                <form action="#" style="margin-top: 20px;">

                    <div class="form-group form-component">
                        <label for="nome">E-mail*</label>
                        <input type="email" name="email" class="form-control" [(ngModel)]="_login" >
                    </div>
                    <div class="form-group form-component">
                        <label for="nome">Senha*</label>
                        <input type="password" name="password" class="form-control" [(ngModel)]="_password" >
                        <a href="https://paineldaengenharia.com.br/login" style="margin-top:5px;">Esqueceu a senha?</a>
                    </div>

                    <button type="submit" class="btn button button-transparent full-width m-b acessar" (click)="LogInto()">ENTRAR&nbsp;&nbsp;
                                
                            <svg aria-hidden="true" style="width:10px;" focusable="false" data-prefix="fas" data-icon="arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-right fa-w-14 fa-2x">
                                    <path fill="currentColor" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z" class=""></path>
                                </svg>
                            </button> 

                </form>
            </div>

            <div class="col-sm-12 div-footer">

                <p class="m-t"> <small>© 2019 | www.paineldaengenharia.com.br</small>
                </p>
            </div>
        </div>


    </div>

</div>