<div class="col-lg-12 row">


    <div class="col-lg-7 wrapper-top-content padding-right-none">
        <div class="ibox-title">
            <h5>Insumos Solicitados</h5>
            <div class="ibox-tools">
                <div>
                    <label class="margin-right-20">
                        {{_count_grid}}
                        <b *ngIf="_total_pesquisa >= 0">de</b>
                        {{_total_pesquisa}}
                    </label>
                    <label>
                        <div class="btn-group mr-2 pagination-position-grid"
                            role="group"
                            aria-label="First group">
                            <button type="button" *ngFor="let page of
                                _paginas"
                                class="btn btn-secondary {{page.active
                                ?'active':''}}"
                                (click)="GetPage(page.page)">{{page.page}}</button>
                        </div>
                    </label>
                </div>
            </div>

        </div>
        <div class="ibox-content   ">

            <div class="row bottom-10">

                <div class="col-sm-6 m-b-xs">
                    <div class="input-group">
                        <input placeholder="Pesquisar" [(ngModel)]="_buscar" (ngModelChange)="OnChangeSearch()" type="text" class="form-control form-control-sm">
                        <span class="input-group-append">
                            <button type="button" class="btn btn-sm
                                btn-primary">Buscar</button>
                        </span>
                    </div>
                </div>

                <div class="text-right text-right col-sm-6 m-b-xs">
                    <div class="btn-group btn-group-toggle">
                        <label class="btn btn btn-white {{_filter == 0 ?
                            'active':''}}" (click)="GetAllSolicitacao(0)">
                            <input type="radio" name="options"
                                autocomplete="off" checked="true"> Abertos
                        </label>
                        <label class="btn btn-white {{_filter> 0 && _filter < 4 ?
                                'active':''}}" (click)="GetAllSolicitacao(1)">
                                <input type="radio" name="options"
                                    autocomplete="off"> Respondidos
                            </label>
                        <label class="btn btn-white {{_filter == 4 ?
                                'active':''}}" (click)="GetAllSolicitacao(4)">
                                <input type="radio" name="options"
                                    autocomplete="off"> Finalizados
                            </label>
                    </div>
                </div>


            </div>

            <!-- Lista de solicitações -->
            <div class="table-responsive">
                <table class="table table-striped table-hover
                        table-bordered">
                    <thead class="thead-dark">
                        <tr>
                            <th>Especificação</th>
                            <th class="text-center">Marca</th>
                            <th class="text-center">Data</th>
                            <th class="text-center" colspan="2">Estado</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let solicitacao of _solicitacoes |
                                filter:_buscar" (click)="VerSolicitacao(solicitacao)" class="{{solicitacao.id ==
                                _solicitacao_selecionada.id ? 'table-info' :
                                ''}}">
                            <td>{{solicitacao.especificacao}}</td>
                            <td class="text-center">{{solicitacao.marca}}</td>
                            <td class="text-center">{{solicitacao._data_formatada}}</td>
                            <td class="text-center">{{solicitacao._status_descricao}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>


    <!-- Cadastro Solicitação Insumo -->
    <div class="col-lg-5 wrapper-top-content grid-right-detalhes animated
            fadeIn margin-bottom-40" [hidden]="_hidden">
        <div>
            <object [hidden]="!(_solicitacao_selecionada.id > 0)">
                    <div class="ibox-title   ">
                        <h5>Descrição Solicitação</h5>
                    </div>
                    <div class="ibox-content   ">

                        <div class="form-group row">
                            <label class="col-sm-3"><b>Especificação:</b></label>
                            <div class="col-sm-8">{{_solicitacao_selecionada.especificacao}}</div>
                            <label class="col-sm-3"><b>Marca:</b></label>
                            <div class="col-sm-8">{{_solicitacao_selecionada.marca}}</div>
                            <label class="col-sm-3"><b>Data:</b></label>
                            <div class="col-sm-8">{{_solicitacao_selecionada.data_criacao}}</div>
                            <label class="col-sm-3"><b>Status:</b></label>
                            <div class="col-sm-4">

                                <select
                                    [disabled]="_solicitacao_selecionada.status>
                                    0"
                                    class="form-control-sm form-control
                                    input-s-sm
                                    inline" [(ngModel)]="_status_select"
                                    (change)="OnChangeSearch()">
                                    
                                    <option *ngFor="let status of keys()"
                                        [hidden]="(_solicitacao_selecionada.status < 1) && (_status_solicitacao_enum[status] == 4)"
                                        value="{{_status_solicitacao_enum[status]}}">{{status}}</option>
                                </select>

                            </div>
                            <div class="col-sm-4"></div>


                            <label class="col-sm-3   
                                margin-top-10"
                                *ngIf="_status_select == 3"><b>Motivo:</b></label>
                            <div class="col-sm-8    margin-top-10"
                                *ngIf="_status_select == 3">

                                <textarea
                                    [disabled]="_solicitacao_selecionada.status>
                                    0"
                                    placeholder="Descrição do motivo"
                                    [(ngModel)]="_solicitacao_selecionada.detalhe"
                                    class="form-control form-control-sm"></textarea>
                            </div>


                            <label *ngIf="_produto.id> 0" class="  
                                col-sm-3 margin-top-20"><b>Produto:</b></label>
                            <div *ngIf="_produto.id> 0" class="  
                                col-sm-8
                                margin-top-20" >
                                {{_produto.nome}}
                                <div *ngIf="_solicitacao_selecionada.status ==
                                    0">
                                    <br />
                                    <br />
                                    <a href (click)="DesvicularItem($event)">Desvincular
                                        Produto.</a>
                                </div>
                            </div>

                            <div class="col-sm-6 margin-top-20   "
                                *ngIf="_solicitacao_selecionada.status == 0 &&
                                _status_select != 0">
                                <button type="button" class="btn btn-sm
                                    btn-primary"
                                    (click)="SaveSolicitacao()">Atualizar</button>
                            </div>
                            <div class="col-sm-6 margin-top-20 text-right
                                animated
                                fadeIn" *ngIf="_solicitacao_selecionada.status
                                == 0
                                &&
                                _status_select != 3 && _status_select != 0">
                                <button type="button" class="btn btn-sm
                                    btn-default"
                                    (click)="SugerirProduto()">Sugerir Item</button>
                            </div>

                        </div>

                    </div>

                    <div class="ibox-title   "
                        *ngIf="_solicitacao_selecionada.log &&
                        _solicitacao_selecionada.log != ''">
                        Histórico
                    </div>
                    <div class="ibox-content    text-11"
                        *ngFor="let log of _solicitacao_selecionada._log">

                        <div class="form-group row">
                            <div class="col-sm-3" [hidden]="log.especificacao ==
                                null"><b>Especificação:</b></div>
                            <div class="col-sm-8" [hidden]="log.especificacao ==
                                null">{{log.especificacao}}</div>
                            <div class="col-sm-3" [hidden]="log.marca == null"><b>Marca:</b></div>
                            <div class="col-sm-8" [hidden]="log.marca == null">{{log.marca}}</div>
                            <div class="col-sm-3"><b>Data:</b></div>
                            <div class="col-sm-8">{{log.data}}</div>
                            <div class="col-sm-3"><b>Status:</b></div>
                            <div class="col-sm-8">{{_status_solicitacao_enum[log.status]}}</div>
                            <div class="col-sm-3" [hidden]="(log.produto.nome ==
                                null) && (log.produto.nome != '')"><b>Produto:</b></div>
                            <div class="col-sm-8" [hidden]="(log.produto.nome ==
                                null) && (log.produto.Nome != '')">{{log.produto.nome}}</div>
                            <div class="col-sm-3" [hidden]="log.status != 3"><b>Motivo:</b></div>
                            <div class="col-sm-8" [hidden]="log.status != 3">{{log.detalhe}}</div>
                        </div>

                    </div>
                    <div class="grid-2"></div>
                </object>

        </div>
    </div>

</div>

<app-pesquisa (resposta_produto_selecionado)="ReciverPesquisaProduto($event)"></app-pesquisa>