import { Component, OnInit } from '@angular/core';
import { PrazoPagamento } from 'src/app/domain/auxiliar/prazo-pagamento';
import { PrazoPagamentoService } from 'src/app/service/auxiliar/prazo-pagamento.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { getMaxListeners } from 'cluster';
import { EnumMeioPagamento } from 'src/app/domain/cotacao/cotacao';

@Component({
  selector: 'app-prazo-pagamento',
  templateUrl: './prazo-pagamento.component.html',
  styleUrls: ['./prazo-pagamento.component.css']
})
export class PrazoPagamentoComponent implements OnInit {
  public code: string;
  public _prazo_pagamento: PrazoPagamento;
  public _list_prazo_pagamento: PrazoPagamento[];

  constructor(private prazoPagamentoService: PrazoPagamentoService,
    private confirmService: AlertConfirmService) {
      this.confirmService.UnsetObservers();
      this.confirmService.confirmEmitter$.subscribe(c => this.Excluir(c));
      this.Cancelar();
      this._list_prazo_pagamento = new Array<PrazoPagamento>();
      this.code = 'app-prazo-pagamento';
  }

  ngOnInit() {
    this.GetAll();
  }

  GetAll() {
    this.prazoPagamentoService.GetAll().subscribe(
      d => { this._list_prazo_pagamento = d; },
      e => { },
      () => {
        this._list_prazo_pagamento.forEach(c=>{
          c._meio_pagamento = EnumMeioPagamento[c.meio_pagamento]
        })
        this.Cancelar();
      }
    );
  }

  Adicionar() {
    this._prazo_pagamento.ativo = 1;
    this.prazoPagamentoService.Create(this._prazo_pagamento).subscribe(
      d => { },
      e => { },
      () => {
        this.GetAll();
      }
    );
  }

  Cancelar() {
    this._prazo_pagamento = new PrazoPagamento();
  }

  ActionEditar(data: PrazoPagamento) {
    this._prazo_pagamento = data;
  }

  Editar() {
    this.prazoPagamentoService.Edit(this._prazo_pagamento).subscribe(
      d => { },
      e => { },
      () => {
        this.GetAll();
      }
    );
  }

  ConfirmExcluir(data: PrazoPagamento) {
    this._prazo_pagamento = data;
    this.confirmService._code = this.code;
    this.confirmService._title = "Excluir Enum Prazo de Pagamento";
    this.confirmService._message = "Deseja a opção de prazo para pagamento " + data.descricao + "?";
    this.confirmService.Show();
  }

  Excluir(confirm: boolean = false) {
    if (this.confirmService._code != this.code || confirm == false)
      return;
    this.prazoPagamentoService.Delete(this._prazo_pagamento.id).subscribe(
      d => { this.GetAll(); },
      e => { },
      () => { }
    );
  }
}
