<!--
<div class="col-lg-12 wrapper-top-content">
    <div class="ibox">
        <div class="ibox-title">
            <h5>Resumo de Atividades</h5>
        </div>
        <div class="ibox-content">

        </div>

    </div>
</div>
-->

<div class="col-lg-12 wrapper-top-content">
    <div class="row">
        <div class="col-lg-3">
            <div class="ibox">
                <div class="ibox-title">
                    <b class="">Faturamento</b>
                </div>
                <div class="ibox-content" style="min-height: 74px;">
                    <div class="indicador-duplo text-center">
                        Em Análise
                        <h4 class="text-center text-danger">R$ {{_dashboard.faturamento_analise}}
                        </h4>
                    </div>
                    <div class="indicador-duplo border-left text-center">
                        Realizados
                        <h4 class="text-dark">R$ {{_dashboard.faturamento}}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 padding-left-0">
            <div class="ibox">
                <div class="ibox-title">
                    <b>Vendas</b>
                </div>
                <div class="ibox-content" style="min-height: 74px;">
                    <div class="indicador-duplo text-center">
                        Em Análise
                        <h4 class="text-center text-danger">{{_dashboard.quantidade_vendas_analise}}</h4>
                    </div>
                    <div class="indicador-duplo border-left text-center">
                        Realizadas
                        <h4 class="text-dark">{{_dashboard.quantidade_vendas}}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 padding-left-0">
            <div class="ibox">
                <div class="ibox-title">
                    <b>Orçamentos</b>
                </div>
                <div class="ibox-content" style="min-height: 74px;">
                    <div class="indicador-duplo text-center">
                        Abertos
                        <h4 class="text-center text-danger">{{_dashboard._solicitacoes_abertas}}</h4>
                    </div>
                    <div class="indicador-duplo border-left text-center">
                        Enviados
                        <h4 class="text-dark">{{_dashboard.orcamentos_enviados}}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 padding-left-0">
            <div class="ibox">
                <div class="ibox-title">
                    <b class="">Entregas</b>
                </div>
                <div class="ibox-content" style="min-height: 74px;">
                    <div class="indicador-duplo text-center">
                        Pendentes
                        <h4 class="text-center text-danger">{{_dashboard.entregas_pendentes}}</h4>
                    </div>
                    <div class="indicador-duplo border-left text-center">
                        Realizadas
                        <h4 class="text-dark">{{_dashboard.entregas_realizadas}}</h4>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12">
            <label class="fa fa-calendar-alt label-calendar"></label>
            <ngb-datepicker class="datapicker-calendar" #dp [(ngModel)]="model" (navigate)="date= $event.next"></ngb-datepicker>
            <div [chart]="chart" class="chart-faturamento" (click)="dp.toggle()" style="max-width: 100%;
                height: 300px;"></div>
            <button style="display: none;" id="atualizarChat"></button>
        </div>

        <div class="col-sm-12">
            <br />
            <div class="col-sm-4">
                <!--
                <h3 class="no-margins text-navy">Informações</h3>

                <table class="table m-t-sm">
                    <tbody>
                        <tr>
                            <td>
                                <strong>0</strong> Solicitações em aberto

                            </td>

                        </tr>
                        <tr>
                            <td>
                                <strong>0</strong> Produtos Cadastrados
                            </td>
                        </tr>
                    </tbody>
                </table>
            -->


            </div>
        </div>
        <div class="col-sm-12">
            <app-dashboard-orcamento></app-dashboard-orcamento>
        </div>
        <div class="col-sm-12">
            <app-dashboard-entrega></app-dashboard-entrega>
        </div>
    </div>
</div>