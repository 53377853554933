import { OrcamentoConfiguracaoService } from 'src/app/service/orcamento/orcamento-configuracao.service';
import { OrcamentoAliquotaService } from 'src/app/service/orcamento/orcamento-aliquota.service';
import { OrcamentoProdutoService } from 'src/app/service/orcamento/orcamento-produto.service';
import { OrcamentoService } from 'src/app/service/orcamento/orcamento.service';
import { ProdutoService } from 'src/app/service/suprimento/produto.service';
import { ProdutoCompradorService } from 'src/app/service/suprimento/comprador/produto-comprador.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CotacaoService } from 'src/app/service/suprimento/comprador/cotacao.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { EnderecoService } from 'src/app/service/configuracao/endereco.service';
import { ProdutoComprador } from 'src/app/domain/suprimento/comprador/produto-comprador';
import { Suprimento } from 'src/app/domain/suprimento/suprimento';
import { ProdutoUnidadeMedida } from 'src/app/domain/suprimento/produto-unidade-medida';
import { Orcamento, OrcamentoAliquota, EnumStatusOrcamento } from 'src/app/domain/orcamento/orcamento';
import { OrcamentoProduto } from 'src/app/domain/orcamento/orcamento-produto';
import { OrcamentoConfiguracao } from 'src/app/domain/orcamento/orcamento-configuracao';
import { EntregaParcialComprador } from 'src/app/domain/suprimento/comprador/entrega-parcial-comprador';
import { GlobalVariable } from 'src/app/core/global-variable';
import { InsumoDoFornecedor } from 'src/app/domain/suprimento/produto-fornecedor';
import { Cotacao, EnumTipoFrete } from 'src/app/domain/cotacao/cotacao';
import { Enderecos } from 'src/app/domain/empresa/Enderecos';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { EmpresaService } from 'src/app/service/configuracao/empresa.service';
import { CabecalhoCotacao } from 'src/app/service/commom/cabecalho-cotacao';
import { RepositoryService } from 'src/app/service/repository.service';
import { ProcessoEmailOrcmento } from 'src/app/domain/auxiliar/EmailValidate';
import { Directive } from "@angular/core";
declare var $: any;
@Directive()
export class OrcamentoViewCtrl {
  _code = "app-orcamento-comprador-view";
  _tipo_entrega = this.route.snapshot.params['frete'];

  constructor(
    public configuracaoService: OrcamentoConfiguracaoService,
    public aliquotaService: OrcamentoAliquotaService,
    public orcamentoProdutoService: OrcamentoProdutoService,
    public orcamentoService: OrcamentoService,
    public httpProduto: ProdutoService,
    public produtoCompradorService: ProdutoCompradorService,
    public route: ActivatedRoute,
    public router: Router,
    public cotacaoService: CotacaoService,
    public userLoggedService: UserLoggedInService,
    public confirmService: AlertConfirmService,
    public enderecoService: EnderecoService,
    public empresaService: EmpresaService,
    public repository: RepositoryService
  ) {
    this.confirmService.UnsetObservers();
    this.confirmService.confirmEmitter$.subscribe(c => { this.EndBudget(c); });
    this._produto_selecionado = new ProdutoComprador();

    this._carregando = false;
    this._ha_produto = false;
    this._buscar = '';
    this._suprimento = new Suprimento();
    this._suprimento.insumos = new Array<InsumoDoFornecedor>();
  }


  cabecalho = new CabecalhoCotacao(this.empresaService, this.userLoggedService);
  _input_cotacao = "";
  _list_produtos = new Array<ProdutoComprador>();
  _produto_selecionado: ProdutoComprador;
  public _suprimento: Suprimento;
  public _unidade_medida: ProdutoUnidadeMedida[];
  public _ha_produto: boolean;
  public _carregando: boolean;
  count_nivel = 0;
  public _buscar: string;
  public _vinculado: boolean;
  public _loading_save: boolean;
  orcamento_id = this.route.snapshot.params['id'];
  _orcamento = new Orcamento();
  _orcamento_produtos = new Array<OrcamentoProduto>();
  _aliquotas = new Array<OrcamentoAliquota>();
  _aliquota = new OrcamentoAliquota();
  _temp_aliquota = new OrcamentoAliquota();
  _configuracoes = new OrcamentoConfiguracao();

  GetConfiguracoes() {
    this.configuracaoService.GetFornecedor().subscribe(
      d => { this._configuracoes = d; },
      e => { },
      () => { }
    );
  }

  GetOrcamento(orcamento_id: number) {
    this.orcamentoService.Get(orcamento_id).subscribe(
      d => {
        this._orcamento = d;
      },
      e => { },
      () => {
        this.SetListaUnidadeMedida();
        this.viewProcessoCompra()
      }
    );
  }

  viewProcessoCompra() {
    if(this._orcamento.codigo_processo_venda)
      this._orcamento._showProcesso = !!(this._orcamento.codigo_processo_venda.match(this._tipo_entrega))
  }

  GetOrcamentoProduto(cotacao_id: number) {
    this.orcamentoProdutoService.GetAll(cotacao_id).subscribe(
      d => { this._orcamento_produtos = d; },
      e => { },
      () => {
        ProdutoComprador.SetProdutoOrcamento(this._list_produtos, this._orcamento_produtos, this._orcamento);
        this.HaIPIICMS();
        if (!this._tipo_entrega) {
          if (this._cotacao.tipo_frete == EnumTipoFrete.ciffob && this._orcamento.codigo_processo_venda)
            this._tipo_entrega = this._orcamento.codigo_processo_venda.split(':')[1]
        }
        if (this._cotacao.tipo_frete == EnumTipoFrete.ciffob && !this._orcamento.fob && this._tipo_entrega == 'fob')
          this.CarregarAliquotaFob(this._orcamento.id);
        else
          this.CarregarAliquota(this._orcamento.id);
        this.somarFrete()
      }
    );
  }

  somarFrete() {
    if (this._orcamento.distancia_km && this._orcamento.distancia_unidade && this._tipo_entrega == 'fob') {
      const distancia = (this._orcamento.distancia_unidade == 'm') ? '1' : this._orcamento.distancia_km;
      let valor_frete = parseFloat(distancia) * parseFloat(this._cotacao.preco_por_km);
      this._orcamento.preco_frete_fob = UseFullService.ToFixedReal(valor_frete);
      const valor_total = (this._cotacao.tipo_frete == 3) ? this._orcamento.valor_total_fob : this._orcamento.valor_total;
      this._orcamento.preco_frete_total_fob = UseFullService.ToFixedReal(valor_frete + parseFloat(valor_total));
    }
  }

  public boolIcms: boolean
  public boolIpi: boolean
  HaIPIICMS() {
    this._orcamento_produtos.forEach(c => {
      if (c.icms > 0)
        this.boolIcms = true
      if (c.ipi > 0)
        this.boolIpi = true
    });
  }

  _cotacao = new Cotacao();
  GetCotacao(id: number) {
    this.cotacaoService.Get(id).subscribe(
      d => { this._cotacao = d; },
      e => { },
      () => {
        this._cotacao._meio_pagamento = Cotacao.GetMeioPagamento(this._cotacao.meio_pagamento);
        Cotacao.SetFormat(this._cotacao);
        this.GetEndereco(this._cotacao.endereco_id);
      });
  }

  _endereco = new Enderecos()
  GetEndereco(endereco_id: number) {
    this.enderecoService.Get(endereco_id).subscribe(
      d => { this._endereco = d }
    )
  }

  GetAllProdutos(cotacao_id: number) {
    this.produtoCompradorService.GetAll(cotacao_id).subscribe(
      d => {
        d.forEach(c => { c._orcamento_produto = new OrcamentoProduto() });
        this._list_produtos = d;
      },
      e => { },
      () => {
        this.SetConfig();        
        this.GetOrcamentoProduto(this._orcamento.id);
        if (!this._carregamento_concluido && this._unidade_medida && this._unidade_medida.length > 0)
          this.CarregarDadosTrunk();
      }
    );
  }

  _ha_anexo = false
  SetConfig() {
    for (let i = 0; i < this._list_produtos.length; i++) {
      this._list_produtos[i]._index = i + 1;
      this._list_produtos[i]._index_impar_par = Number.isInteger((i + 1) / 2);
      this._list_produtos[i]._name_file = this._list_produtos[i].anexo;
      this._list_produtos[i]._unidade_nome = ProdutoUnidadeMedida.Get(this._unidade_medida, this._list_produtos[i].unidade_id).nome;
      this._list_produtos[i]._quantidade = this._list_produtos[i].quantidade.toFixed(2).replace(".", ",");
      if (this._list_produtos[i].anexo && this._list_produtos[i].anexo != 'null')
        this._ha_anexo = true;
    }
  }

  carregarArquivoPropota(id, nome) {
    this._nome_arquivo = "";
    this._src_arquivo = "";
    $("#exibir-pdf").html("");
    const _src_arquivo = GlobalVariable.BASE_API_URL + "fornecedor/orcamento_" + id + "/" + nome;
    setTimeout(() => { $("#exibir-pdf").html("<iframe width='100%' height='" + ($('body').height() - 100) + "px' src='" + _src_arquivo + "'></iframe>"); });
    $('#data-modal').modal('show');
  }

  SetListaUnidadeMedida() {
    this.httpProduto.GetAllUnitsMeasured().subscribe(
      data => { this._unidade_medida = data; },
      error => { },
      () => {
        this.GetCotacao(this._orcamento.cotacao_id);
        this.GetAllProdutos(this._orcamento.cotacao_id);
      }
    );
  }

  SetFunctions() { UseFullService.SetMask(); }

  public _nome_arquivo: string;
  public _src_arquivo: string;
  MostrarFile(data: ProdutoComprador) {
    if (data._file) {
      var file = data._file;
      this._nome_arquivo = file.name;
      var _this = this;
      var reader = new FileReader();
      reader.onload = function (e) {
        var target = e.target as any;
        if (file.type == "application/pdf") {
          _this._src_arquivo = undefined;
          setTimeout(() => {
            $("#exibir-pdf").html("<iframe width='100%' height='" + ($('body').height() - 100) + "px' src='" + target.result + "'></iframe>");
          }, 10);
        } else
          _this._src_arquivo = target.result;
      };
      reader.readAsDataURL(file);
    } else
      this.CarregarDataModal(data);
    $('#data-modal').modal('show');
  }

  CarregarDataModal(data: ProdutoComprador) {
    this._nome_arquivo = "";
    this._src_arquivo = "";
    $("#exibir-pdf").html("");
    this._nome_arquivo = data.anexo;
    if (!data.anexo.match(".png") && !data.anexo.match(".jpg") && !data.anexo.match(".gif")) {
      this._src_arquivo = undefined;
      setTimeout(() => {
        $("#exibir-pdf").html("<iframe width='100%' height='" + ($('body').height() - 100) + "px' src='" + GlobalVariable.BASE_API_URL + "comprador/cotacao_" + data.cotacao_id + "/" + data.id + "_" + data.anexo + "'></iframe>");
      });
    } else
      this._src_arquivo = GlobalVariable.BASE_API_URL + "comprador/cotacao_" + data.cotacao_id + "/" + data.id + "_" + data.anexo;
  }

  _carregamento_concluido = false;
  CarregarDadosTrunk() {
    this._list_produtos.forEach(c => {
      this.produtoCompradorService.GetAllEntregaPacial(c.id).subscribe(
        d => { c.EntregasParcial = d; },
        e => { },
        () => {
          c._qt_entrega_parcial = c.EntregasParcial.length;
          var x = 1;
          c.EntregasParcial.forEach(c => {
            c._index = x++;
            EntregaParcialComprador.SetFormatDate(c);
          });
        }
      );
    });
    this._carregamento_concluido = true;
  }

  OpenModalTrunck(produto: ProdutoComprador) {
    if (produto.unidade_id < 1)
      throw "Selecione uma unidade!";
    if (produto.quantidade <= 0)
      throw "Preencha a quantidade!";

    this._produto_selecionado = produto;
    this._produto_selecionado._unidade_nome = ProdutoUnidadeMedida.Get(this._unidade_medida, produto.unidade_id).nome;

    $("#data-entraga-parcial-modal").modal("show");
  }


  CarregarAliquota(orcamento_id: number) {
    this.aliquotaService.GetAll(orcamento_id).subscribe(
      d => { this._aliquotas = d; },
      e => { },
      () => {
        OrcamentoAliquota.Set(this._aliquotas);
        setTimeout(() => {
          Orcamento.SetConfig(this._orcamento, this._orcamento_produtos, this._aliquotas);
        }, 500);
      }
    );
  }

  CarregarAliquotaFob(orcamento_id: number) {
    this.aliquotaService.GetAllFOB(orcamento_id).subscribe(
      d => { this._aliquotas = d; },
      e => { },
      () => {
        OrcamentoAliquota.Set(this._aliquotas);
        setTimeout(() => { Orcamento.SetConfig(this._orcamento, this._orcamento_produtos, this._aliquotas); }, 500);
      }
    );
  }

  ConfirmeRrightEnd() {
    if (!this._li_termos)
      throw ("Leia os termos para enviar o orçamento!");
    this.confirmService._code = this._code;
    this.confirmService._title = "Finalizar Compra";
    this.confirmService._message = "Clique em <b>SIM</b> para finalizar a compra.";
    this.confirmService.Show();
  }

  EndBudget(confirm: boolean) {
    if (!this._temp_aliquota || !confirm || this.confirmService._code != this._code)
      return;
    this._orcamento.status = EnumStatusOrcamento.aprovado;
    this._orcamento._temp_frete = this._tipo_entrega;
    this.orcamentoService.Edit(this._orcamento).subscribe(
      d => { },
      e => { },
      () => { this.SetModalConfirmacaoCompra(); }
    );
  }

  _li_termos = false;
  ClickLiTermos() {
    this._li_termos = !this._li_termos;
  }

  _msg_titulo = ''
  _msg_descricao = ''
  SetModalConfirmacaoCompra() {
    this._msg_titulo = '<label class="">Processo de Compra </label>'
    this._msg_descricao = '<div class="msg-nova-conta"><label class="titulo-msg"><b class="text-success" >COMPRA EFETUADA COM SUCESSO&nbsp;&nbsp; <i class="fa fa-check"></i></b></label><br />'
    this._msg_descricao += '<div class="text-dark body-msg text-center" >Em breve o fornecedor responderá seu pedido.</div><BR />'
    this._msg_descricao += '<a href="' + GlobalVariable.HOST + '#/compras/analise">Ir para compras!</a></div><BR />'
    $('#message-modal').modal('show')
  }


  listProcesso = new Array<ProcessoEmailOrcmento>()
  getProcessoOrcamento(id = this.orcamento_id) {
    this.repository.path = "obra/emailvalidate/getProcessoOrcamento"
    this.repository.get(id).subscribe(
      d => {
        if (d && d.length > 0)
          this.listProcesso = d
      });
  }

  getEstadoValidacaoOrcamento(id = this.orcamento_id) {
    this.repository.path = "obra/emailvalidate/get/estadoorcamento"
    this.repository.get(id).subscribe(
      d => {
        if (d) { this.getProcessoOrcamento(id) }
      });
  }
}