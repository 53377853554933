<div class="row">

    <div class="col-logo">

        <div class="Right">

            <div class="sua-logo" *ngIf="!userLoggedService.img">
                <label>sua logo</label>
            </div>
            <div class="text-center div-img-logo" *ngIf="userLoggedService.img">
                <img src="{{url}}{{userLoggedService.img}}" class="config-img" *ngIf="userLoggedService.img">
            </div>
        </div>
    </div>


    <div class="row table-button-0 cabecalho-orcamento">
        <div class="col-sm-3 text-right" style="padding:0;">
            <table class="table table-cabecalho-fornecedor">
                <tbody>
                    <tr>
                        <td class="text-left th-width"><b>Fornecedor:</b>&nbsp;&nbsp;{{cabecalho.f_razao_social}}</td>
                        <!--<td class="text-left th-width"><b>Preço por KM:</b>&nbsp;&nbsp;{{_cotacao.preco_por_km}}</td>-->
                    </tr>
                    <tr>
                        <td class="text-left th-width"><b>CNPJ:</b>&nbsp;&nbsp;{{cabecalho.f_cnpj}}</td>
                    </tr>
                    <tr>
                        <td class="text-left th-width"><b>Vendedor:</b>&nbsp;&nbsp;{{cabecalho.f_responsavel}}</td>
                    </tr>
                    <tr>
                        <td class="text-left th-width"><b>Telefone:</b>&nbsp;&nbsp;{{cabecalho.f_telefone}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="col-sm-4" style="padding:0;">
            <table class="table table-cabecalho-fornecedor">
                <tbody>
                    <tr>
                        <td class="text-left th-width" colspan="2"><b>Faturante:</b>&nbsp;&nbsp;{{cabecalho.c_razao_social}}
                        </td>
                    </tr>
                    <tr>
                        <td class="text-left th-width" colspan="2"><b>CNPJ/CPF:</b>&nbsp;&nbsp; {{cabecalho.c_cpnjcpf}}
                        </td>
                    </tr>
                    <tr *ngIf="cabecalho.inscricao_estadual">
                        <td class="text-left th-width" colspan="2"><b>Inscrição estadual:</b>&nbsp;&nbsp; {{cabecalho.inscricao_estadual}}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style="width: 50px; float: left;">
                                <b>Obra:</b>
                            </div>
                            <div style="width: calc(100% - 50px);
                                float: right;">{{cabecalho.nome_obra}}
                            </div>
                        </td>
                    </tr>

                    <tr *ngIf="!cabecalho.inscricao_estadual">
                        <td class="text-left th-width" colspan="2"><b>Forma
                                de Pagamento:</b>&nbsp;&nbsp;{{cabecalho._meio_pagamento}} - {{cabecalho.forma_pagamento == 2 ? cabecalho.prazo_pagamento : 'à vista' }}
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
        <div class="col-sm-5 text-right" style="padding:0;">
            <table class="table table-cabecalho-fornecedor">
                <tbody>
                    <tr *ngIf="cabecalho.inscricao_estadual">
                        <td class="text-left th-width" colspan="2"><b>Forma
                                de Pagamento:</b>&nbsp;&nbsp;{{cabecalho._meio_pagamento}} - {{cabecalho.forma_pagamento == 2 ? cabecalho.prazo_pagamento : 'à vista' }}
                        </td>
                    </tr>
                    <tr *ngIf="cabecalho.tipo_frete != 2">
                        <td class="text-left th-width" colspan="2">
                            <div>
                                <div class="label-endereco">
                                    <b>Endereço de entrega:</b>
                                </div>
                                <div class="label-detalhe-endereco">
                                    <label>
                                        {{cabecalho.endereco}}
                                    </label>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="text-left th-width"><b>Frete:</b>&nbsp;&nbsp; {{cabecalho._tipo_frete}}
                        </td>
                        <td class="text-left th-width" *ngIf="cabecalho.tipo_frete != 1">
                            <!--<b>Data Orçamento:</b>&nbsp;&nbsp;{{cabecalho._data_max_recebimento_orcamento}} -->
                        </td>
                    </tr>
                    <tr>
                        <td class="text-left th-width" *ngIf="(cabecalho.tipo_frete != 2)"><b>Validade
                                Proposta:</b>&nbsp;&nbsp;{{cabecalho._data_max_recebimento_orcamento}}</td>
                        <td class="text-left th-width"><b>Entrega Produto:</b>&nbsp;&nbsp;{{cabecalho._data_recebimento_produto}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>