export class Regiao {
    constructor() {
        this._selecionado = false;
    }
    public Id: number;
    public Nome: string;

    public _selecionado: boolean;

    static GetRegioes(regioes: Array<Regiao>): string {
        if(!regioes)
            return "";
        var regiao = ":";
        regioes.forEach(c => { regiao += c.Id + ":"; });
        return regiao;
    }

    static GetRegiosSelecionadas(regioes: Array<Regiao>, list: string): Array<Regiao> {
        var list_ids = list.split(':');

        function filtrar(obj) {
            for (let i = 0; i < list_ids.length; i++) {
                if (parseInt(list_ids[i]) == obj.Id)
                    return true;
            }
            return false;
        }
        var data = regioes.filter(filtrar);
        return data;
    }
}