<div class="col-lg-12 wrapper-top-content">
    <div class="ibox">
        <div class="ibox-title" style="padding-right:20px;">
            <h5>Orçamentos Enviados</h5>
            <div style="float: right;"> </div>
        </div>
        <div class="ibox-content">
            <div class="table-responsive min-height-grid-insumo div-table-fixed margin-top-15">
                <!-- table-striped table-hover table-bordered -->
                <table class="table table-striped">
                    <thead class="thead-fixed">
                        <tr>
                            <th class="th-sequence"></th>
                            <th class="th-nome">Nome</th>
                            <th class="text-center th-codigo">Código</th>
                            <th class="text-center th-data">Validade cotação</th>
                            <th class="text-center th-data">Data Envio</th>
                            <th class="text-right th-data">Subtotal R$</th>
                            <th class="text-center th-action"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="  " *ngFor="let orcamento of _orcamentos">
                            <td class="text-center th-sequence">{{orcamento._index}}</td>
                            <td><label class="btn-link cursor-pointer" (click)="Ver(cotacao)">{{orcamento.cotacao_nome}}</label></td>
                            <td class="text-center th-codigo">{{orcamento.codigo}}</td>
                            <td class="text-center th-data">{{orcamento.data_max_recebimento_orcamento}}</td>
                            <td class="text-center th-data">{{orcamento.data_envio}}</td>
                            <td class="text-right th-data">{{orcamento.sub_total}}</td>
                            <td class="text-center th-action-anexo">
                                <i class="fa fa-search btn-link cursor-pointer" (click)="Ver(orcamento)">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="_orcamentos.length <= 0">Nenhum orçamento encontrado!</div>
        </div>
    </div>
</div>