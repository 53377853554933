
import { Component, OnInit, ComponentFactoryResolver, Input } from '@angular/core';
import { OrcamentoViewCtrl } from '../../orcamento/comprador/orcamento-ctrl-view';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { EnumStatusEmailValidade, EmailValidate } from 'src/app/domain/auxiliar/EmailValidate';
import { EnumStatusOrcamento } from 'src/app/domain/orcamento/orcamento';

@Component({
  selector: 'app-orcamento-auditor-view',
  templateUrl: './orcamento-auditor-view.component.html',
  styleUrls: ['./orcamento-auditor-view.component.css']
})
export class OrcamentoAuditorViewComponent extends OrcamentoViewCtrl implements OnInit {

  _observacao = String()

  ngOnInit() {
    this.GetConfiguracoes();
    this.GetOrcamento(this.orcamento_id);


    this.confirmService.UnsetObservers();
    this.confirmService.confirmEmitter$.subscribe(c => { this.reprovar(c); });
  }

  _concordo = false;
  checkedConcordo() {
    this._concordo = !this._concordo;
  }

  update() {
    UseFullService.ShowModal("#confirm-config-modal");
  }
  ReceiverEntregaProduto(a){
    
  }

  aprovar() {
    if (!this._concordo)
      throw ("Leia os termos para aprovar compra!");
    else {
      this.repository.path = 'obra/emailvalidate/orcamento/save'
      let model = new EmailValidate()
      model.origem_id = this.orcamento_id
      model.status = EnumStatusEmailValidade.Aprovado
      model.observacao = this._observacao;
      this.repository.post(model).subscribe(
        d => {
          UseFullService.HideModal("#confirm-config-modal"); 
          this.atualizarOrcamento()
        }, e => { throw ("Ocorreu um erro, atualize a pagina e tente novamente!") }
      );
    }
  }
  
  atualizarOrcamento() {
    this._orcamento.status = EnumStatusOrcamento.aprovado;
    this.orcamentoService.Edit(this._orcamento).subscribe(
      d => { },
      e => { },
      () => {  //window.location.reload();
       }
    );
  }

  clickTrash() {
    this.confirmService._code = this._code;
    this.confirmService._title = "Reprovar Configurações";
    this.confirmService._message = "Deseja reprovar as configurações do comprador para essa obra ?";
    this.confirmService.Show();
  }

  reprovar(confirm: boolean = false) {
    if (this.confirmService._code != this._code || confirm == false)
      return;
    this.repository.path = 'obra/emailvalidate/orcamento/save'
    let model = new EmailValidate()
    model.origem_id = this.orcamento_id
    model.status = EnumStatusEmailValidade.Reprovado
    model.observacao = this._observacao;
    this.repository.post(model).subscribe(
      d => {
        UseFullService.HideModal("#confirm-config-modal");
        window.location.reload();
      }, e => { throw ("Ocorreu um erro, atualize a pagina e tente novamente!") }
    );
  }
}