import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from 'src/app/core/global-variable';
import { AlertData } from 'src/app/domain/auxiliar/AlertData';

@Injectable({
  providedIn: 'root'
})
export class AlertDataService {
  
  private url = GlobalVariable.BASE_API_URL + "alert/";
  constructor(private http: HttpClient) { }

  GetAll() {
    return this.http.get<AlertData>(this.url+'all').pipe(
      map(res => {
        return res;
      })
    );
  }
  
  ConfirmSolicitacaoUsuarioViaOrcamento(id: number) {
    return this.http.get(this.url + "solicitacao/via/orcamento?id="+id).pipe(
      map(res => {
        return res;
      })
    );
  }

}
