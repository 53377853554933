<div class="col-lg-12 wrapper-top-content">
    <div class="ibox">
        <div class="ibox-title">
            <h5>Configuração de Recebimento das Cotações</h5>
        </div>
        <div class="ibox-content">
            <div class="row">
                <div class="col-lg-12">

                    <br />
                    <div class="row row-padding-top">
                        <div class="form-group col-lg-6 row">
                            <label for="inputEmail4" class="col-sm-3
                                col-form-label"><b>Receber Por:</b></label>
                            <div class="col-sm-8">

                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" name="recebimento" value="2" id="recebimento2" (click)="SelectRecebimento(2)" class="custom-control-input">
                                    <label class="custom-control-label" for="recebimento2">Produto</label>
                                </div>

                                <div class="custom-control
                                    custom-radio
                                    custom-control-inline">
                                    <input type="radio" name="recebimento" value="1" id="recebimento1" checked (click)="SelectRecebimento(1)" class="custom-control-input">
                                    <label class="custom-control-label" for="recebimento1">Categoria</label>
                                </div>

                                <div class="separacao-button-categoria animated
                                    fadeInLeft" *ngIf="(_receber_por == 1)">
                                    <button class="btn btn-default
                                        btn-select-color text-left
                                        button-pesquisa" (click)="SelecionarCategoria()">Selecione
                                        as Categorias</button><br />
                                </div>

                                <div *ngFor="let categoria of this._categorias" class="badge
                                    badge-primary espaco-gadge animated
                                    fadeInLeft" [hidden]="!(_receber_por == 1)">
                                    {{categoria.descricao}}
                                </div>
                                <div class="espaco-gadge" [hidden]="(_receber_por
                                    == 1)">
                                    Você possui <b>{{_quantidade_produto}}</b> produtos,
                                    <a href [routerLink]="['/suprimento/insumo']">
                                        inserir mais produtos?</a>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-lg-6 row" style="max-height:
                            250px;">
                            <label for="inputEmail4" class="col-sm-4
                                col-form-label"><b>Formas de Pagamento: *</b></label>
                            <div class="col-sm-5">
                                <button class="btn btn-default
                                    btn-select-color text-left
                                    button-pesquisa" (click)="SelecionarFormaPagamento()">Selecione
                                    as Formas de Pagamento</button><br />
                                <div *ngFor="let forma_pagamento of
                                    _formas_pagamento" class="badge
                                    badge-primary espaco-gadge">
                                    {{forma_pagamento.descricao}}
                                </div>
                            </div>
                            <div class="separacao-button-modal"></div>
                            <label for="inputEmail4" class="col-sm-4
                                col-form-label"><b>Prazos para Pagamento: *</b></label>
                            <div class="col-sm-5">
                                <button class="btn btn-default
                                    btn-select-color text-left
                                    button-pesquisa" (click)="SelecionarPrazoPagamento()">Selecione
                                    os prazos de Pagamento</button><br />
                                <div style="max-height: 210px; overflow:auto;">
                                    <div *ngFor="let prazo_pagamento of _prazos_pagamento" class="badge
                                        badge-primary espaco-gadge">
                                        {{prazo_pagamento.descricao}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 row">
                            <label for="inputEmail4" class="col-sm-3
                                col-form-label"><b>ICMS %:</b></label>
                            <div class="col-sm-8">
                                <label>
                                    <input class="form-control input-icms-ipi"
                                        type="number"
                                        [(ngModel)]="_orcamentoConfiguracao.icms"
                                        placeholder="0">
                                </label>
                            </div><label for="inputEmail4" class="col-sm-3
                                col-form-label"><b>IPI %:</b></label>
                            <div class="col-sm-8">
                                <label>
                                    <input class="form-control input-icms-ipi"
                                        type="number"
                                        [(ngModel)]="_orcamentoConfiguracao.ipi"
                                        placeholder="0">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <hr />
                </div>

                <div class="col-lg-12">
                    Regiões de entrega cotações por localidades:
                    <br /><br />
                    <div class="row row-padding-top">
                        <div class="form-group col-lg-4 row">
                            <label for="inputEmail4" class="col-sm-4
                                col-form-label"><b>Região *</b></label>
                            <div class="col-sm-5">

                                <button class="btn btn-default
                                    btn-select-color text-left
                                    button-pesquisa" (click)="SelecionarRegiao()">Selecione
                                    Região</button><br />
                                <div *ngFor="let regiao of _regioes" class="badge
                                    badge-primary espaco-gadge">
                                    {{regiao.Nome}}
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-4 row" *ngIf="_ha_estado">
                            <label for="inputEmail4" class="col-sm-3
                                col-form-label"><b>Estado</b></label>
                            <div class="col-sm-5">

                                <button class="btn btn-default
                                    btn-select-color text-left
                                    button-pesquisa" (click)="SelecionarEstado()">Selecione
                                    Estado</button>
                                <div *ngFor="let estado of _estados" class="badge
                                    badge-primary espaco-gadge">
                                    {{estado.Nome}}
                                </div>

                            </div>
                        </div>

                        <div class="form-group col-lg-4 row" *ngIf="_ha_cidade">
                            <label for="inputEmail4" class="col-sm-4
                                col-form-label"><b>Cidade</b></label>
                            <div class="col-sm-5">
                                <button class="btn btn-default
                                    btn-select-color text-left
                                    button-pesquisa" (click)="SelecionarCidade()">Selecione
                                    Cidade</button>
                                <div *ngFor="let cidade of _cidades" class="badge
                                    badge-primary espaco-gadge">
                                    {{cidade.Nome}}
                                </div>
                            </div>
                        </div>
                    </div>


                </div>


                <br /><br />
                <div class="col-sm-6">
                </div>
                <div class="col-sm-6 text-right">
                    <button type="button" *ngIf="!cotacao_id" class="btn
                        btn-default Right" (click)="Reload()">Cancelar</button>
                    <button type="submit" class="btn btn-primary
                        margin-left-10" *ngIf="userLoggedIn.Editar" (click)="Save()"><i class="fa
                            fa-check"></i>&nbsp;&nbsp;Salvar</button>
                </div>

            </div>
        </div>
    </div>
</div>


<app-localidade [_input_modal]=[_input_modal] (_resposta_modal)="ReceiverRespostaModal($event)">
</app-localidade>