<div class="col-lg-12 wrapper-top-content">
    <div class="ibox">
        <div class="ibox-title" style="padding-right:20px;">
            <h5>Extrato Financeiro</h5>
            <div style="float: right;">
                Buscar: <input placeholder="Pesquisar" [(ngModel)]="_buscar" type="text"> &nbsp;&nbsp;&nbsp;Status: <select class="" [(ngModel)]="_status" (change)="OnChangeSearch()">
                    <option value="0">Aberto</option>
                    <option value="1">Pago</option>
                    <option value="2">Cancelado</option>
                    <option value="3">Gratuito</option>
                </select>&nbsp;&nbsp;&nbsp; Periodo: <input type="date" [(ngModel)]="_inicio" (change)="OnChangeSearch()"> à
                <input type="date" [(ngModel)]="_fim" (change)="OnChangeSearch()">
            </div>
        </div>
        <div class="ibox-content">
            <div class="table-responsive min-height-grid-insumo div-table-fixed margin-top-15">
                <!-- table-striped table-hover table-bordered -->
                <table class="table table-striped">
                    <thead class="thead-fixed">
                        <tr>
                            <th class="th-sequence"></th>
                            <th class="th-nome">Nome Fantasia</th>
                            <th class="text-center th-codigo">Cnpj</th>
                            <th class="text-center th-sequence">Qt</th>
                            <th class="text-center th-data">Total Painel R$</th>
                            <th class="text-center th-action"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="  " *ngFor="let model of _transacoes | filter:_buscar">
                            <td class="text-center th-sequence">{{model._index}}</td>
                            <td>{{model.nome_fantasia}}</td>
                            <td class="text-center th-codigo">{{model.cnpj}}</td>
                            <td class="text-center th-data">{{model.quantidade_venda}}</td>
                            <td class="text-right th-data">{{model.total}}</td>
                            <td class="text-center th-action-anexo">
                                <i class="fa fa-search btn-link cursor-pointer" (click)="Ver(model)">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div *ngIf="_transacoes.length <= 0">Nenhuma ordem de venda encontrada!</div>
        </div>
    </div>
</div>