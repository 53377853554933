import { Component, OnInit } from '@angular/core';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { ProdutoCategoria, TypeEnumGeral, EnumGeral } from 'src/app/domain/suprimento/produto-categoria';
import { RepositoryService } from 'src/app/service/repository.service';
import { Marca } from 'src/app/domain/suprimento/marca';

@Component({
  selector: 'app-cadastro-marca',
  templateUrl: './cadastro-marca.component.html',
  styleUrls: ['./cadastro-marca.component.css']
})
export class CadastroMarcaComponent implements OnInit {

  code = 'app-cadastro-marca';
  _marca = new Marca();
  _list_marca = new Array<Marca>();

  constructor(private repository: RepositoryService, private confirmService: AlertConfirmService) {
      this.confirmService.UnsetObservers();
      this.confirmService.confirmEmitter$.subscribe(c => this.Excluir(c));
      this.Cancelar();
  }

  ngOnInit() {
    this.GetAll();
  }

  GetAll() {
    this.repository.path = 'supplies/marca/getall';
    this.repository.getAll('').subscribe(
      d => { this._list_marca = d; },
      e => { },
      () => {
        this.Cancelar();
      }
    );
  }

  Adicionar() {
    this.repository.path = 'supplies/marca/create';
    this.repository.post(this._marca).subscribe(
      d => { },
      e => { },
      () => {
        this.GetAll();
      }
    );
  }

  Cancelar() {
    this._marca = new Marca();
  }

  ActionEditar(data: Marca) {
    this._marca = data;
  }

  Editar() {
    this.repository.path = 'supplies/marca/edit';
    this.repository.put(this._marca).subscribe(
      d => { },
      e => { },
      () => {
        this.GetAll();
      }
    );
  }

  ConfirmExcluir(data: Marca) {
    this._marca = data;
    this.confirmService._code = this.code;
    this.confirmService._title = "Excluir Marca";
    this.confirmService._message = "Deseja excluir a marca " + data.descricao + "?";
    this.confirmService.Show();
  }

  Excluir(confirm: boolean = false) {
    if (!(this.confirmService._code != this.code || confirm == false)){      
      this.repository.path = 'supplies/marca/delete';
      this.repository.delete(this._marca.id).subscribe( d => { this.GetAll(); });
    }
  }
}
