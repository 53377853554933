<div class="col-lg-12 wrapper-top-content">
    <div class="ibox-title">
        <h5>Gerenciar permissões do usuário <span class="text-primary">{{_usuario.nome}}</span></h5>
    </div>
    <div class="ibox-content">

        <!--
        <div class="">
            <b>Desconto Maximo %:</b>&nbsp;&nbsp;
            <span>
                <input class="form-control input-desconto-maximo ng-pristine ng-valid ng-touched" placeholder="0" style="display: inherit;" type="number">
            </span>
        </div>
        -->

        <div class="table-responsive margin-top-20">
            <table class="table table-striped table-hover table-bordered">
                <thead class="thead-dark">
                    <tr>
                        <th class="text-center">Recurso</th>
                        <th class="text-center th-operacao">Visualizar</th>
                        <th class="text-center th-operacao">Inserir</th>
                        <th class="text-center th-operacao">Editar</th>
                        <th class="text-center th-operacao">Excluir</th>
                        <th class="text-center th-operacao">Enviar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let funcao of _funcoes_sistema">
                        <td>{{funcao.descricao}}</td>

                        <td class="text-center">
                            <input class="" type="checkbox" *ngIf="funcao._operacoes[0].selected" [checked]="funcao._operacoes[0].checked" (change)="ChangeOperacao($event, funcao,
                                funcao._operacoes[0].id)">
                        </td>
                        <td class="text-center">
                            <input class="" type="checkbox" *ngIf="funcao._operacoes[1].selected" [checked]="funcao._operacoes[1].checked" (change)="ChangeOperacao($event, funcao,
                                funcao._operacoes[1].id)">
                        </td>
                        <td class="text-center">
                            <input class="" type="checkbox" *ngIf="funcao._operacoes[2].selected" [checked]="funcao._operacoes[2].checked" (change)="ChangeOperacao($event, funcao,
                                funcao._operacoes[2].id)">
                        </td>
                        <td class="text-center">
                            <input class="" type="checkbox" *ngIf="funcao._operacoes[3].selected" [checked]="funcao._operacoes[3].checked" (change)="ChangeOperacao($event, funcao,
                                funcao._operacoes[3].id)">
                        </td>
                        <td class="text-center">
                            <input class="" type="checkbox" *ngIf="funcao._operacoes[4].selected" [checked]="funcao._operacoes[4].checked" (change)="ChangeOperacao($event, funcao,
                                funcao._operacoes[4].id)">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="text-right">
            <button class="btn btn-sm btn-link" (click)="clickRestaurarPadrao()">Restaurar Padrão</button>
        </div>
    </div>
</div>