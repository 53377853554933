import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from 'src/app/core/global-variable';
import { PrazoPagamento } from 'src/app/domain/auxiliar/prazo-pagamento';
import { EnumMeioPagamento } from 'src/app/domain/cotacao/cotacao';

@Injectable({
  providedIn: 'root'
})
export class PrazoPagamentoService {
  
  private url = GlobalVariable.BASE_API_URL + "prazo/pagamento/";
  constructor(private http: HttpClient) { }

  GetAll(meioPagamento: EnumMeioPagamento = 0) {
    return this.http.get<PrazoPagamento[]>(this.url + "getall?meioPagamento="+meioPagamento).pipe(
      map(res => {
        return res;
      })
    );
  }

  Get(id: number) {
    return this.http.get<PrazoPagamento>(this.url + "get?id="+id).pipe(
      map(res => {
        return res;
      })
    );
  }
  
  Create(data: PrazoPagamento): Observable<any>{
    return this.http.post<any[]>(this.url+"create", data).pipe(
      map(res=> { return res; })
    );
  }
  
  Edit(data: PrazoPagamento): Observable<any>{
    return this.http.put<any[]>(this.url+"edit", data).pipe(
      map(res=> { return res; })
    );
  }
  
  Delete(id: number): Observable<any> {    
    return this.http.delete(this.url+"delete?id="+id).pipe(
      map(res=> { return res; })
    );
  }
}
