import { Component, OnInit } from '@angular/core';
import { CotacaoService } from 'src/app/service/suprimento/comprador/cotacao.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { Cotacao, EnumTipoFrete } from 'src/app/domain/cotacao/cotacao';
import { Router, ActivatedRoute } from '@angular/router';
import { OrcamentoService } from 'src/app/service/orcamento/orcamento.service';
import { Orcamento, ListOrcamento, EnumStatusOrcamento } from 'src/app/domain/orcamento/orcamento';
import { FilterPipe } from 'src/app/core/filter.pipe';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { RepositoryService } from 'src/app/service/repository.service';
import { ProdutoService } from 'src/app/service/suprimento/produto.service';
import { OrcamentoAliquotaService } from 'src/app/service/orcamento/orcamento-aliquota.service';
import { OrcamentoProdutoService } from 'src/app/service/orcamento/orcamento-produto.service';
import { ProdutoCompradorService } from 'src/app/service/suprimento/comprador/produto-comprador.service';
import { ProdutoComprador } from 'src/app/domain/suprimento/comprador/produto-comprador';
import { OrcamentoProduto } from 'src/app/domain/orcamento/orcamento-produto';
import { GlobalVariable } from 'src/app/core/global-variable';


declare var $: any;
@Component({
  selector: 'app-orcamento-comprador-view-list',
  templateUrl: './orcamento-comprador-view-list.component.html',
  styleUrls: ['./orcamento-comprador-grid.component.css']
})
export class OrcamentoCompradorViewListComponent implements OnInit {
  public code: string;
  public _list_orcamento: Array<ListOrcamento>;
  public _orcamento: Orcamento;
  public _cotacao: Cotacao;
  public _cotacao_id: number;
  _orcamento_aprovado = new Orcamento();

  _list_orcamento_cif = new Array<ListOrcamento>();
  _list_orcamento_fob = new Array<ListOrcamento>();

  _orcamento_sugerido_cif = new ListOrcamento();
  _orcamento_sugerido_fob = new ListOrcamento();
  constructor(
    private _context: RepositoryService,
    private cotacaoService: CotacaoService,
    private confirmService: AlertConfirmService,
    private userLoggedService: UserLoggedInService,
    private orcamentoService: OrcamentoService,

    public aliquotaService: OrcamentoAliquotaService,
    public orcamentoProdutoService: OrcamentoProdutoService,
    public httpProduto: ProdutoService,
    public produtoCompradorService: ProdutoCompradorService,

    private router: Router,
    private route: ActivatedRoute) {
    this.userLoggedService.SetCurrentUser();
    this._list_orcamento = new Array<ListOrcamento>();
  }


  ngOnInit() {
    this._cotacao = new Cotacao()
    this._cotacao_id = this.route.snapshot.params['id']
    this.GetCotacao()
  }

  ReceiverReload(data: any) {
    this.ngOnInit();
  }

  OnChangePrecoPorKm() {

    console.log(this._cotacao.preco_por_km)
    setTimeout(() => { this.cotacaoService.EditPreco(this._cotacao).subscribe(d => { this.ngOnInit(); }); }, 300);
  }

  columns_orcamento = []
  GetAll() {
    this.orcamentoService.GetByQuoteComprador(this._cotacao_id).subscribe(
      d => { if (d && d.length > 0) this._list_orcamento = d; },
      e => { },
      () => {
        this._list_orcamento_fob = FilterPipe.getFilter(this._list_orcamento, '_temp_frete:fob', '2|3:0', true, true);
        this._list_orcamento_cif = FilterPipe.getFilter(this._list_orcamento, '_temp_frete:cif', '1|3:0', true, true);
        let x = 1

        this._list_orcamento_fob.forEach(c => {
          c._index = x++
          c._status_fob = (c.codigo_processo_venda && c.codigo_processo_venda.match(':cif')) ? 'Cancelado' : (c.status == EnumStatusOrcamento.aprovado) ? 'Aprovado' : 'Aberto';
          ListOrcamento.SetConfig(c)

          //if (c.distancia_km && c.distancia_unidade) {
            const valor_frete = parseFloat(c.distancia_km) * UseFullService.ConvertFloat(this._cotacao.preco_por_km);
            c.preco_frete_fob = UseFullService.ToFixedReal(valor_frete);
            const valor_total = (this._cotacao.tipo_frete == 3) ? c.valor_total_fob : c.valor_total;
            c.preco_frete_total_fob = UseFullService.ToFixedReal(valor_frete + UseFullService.ConvertFloat(valor_total));
          //}
        })
        let xx = 1
        this._list_orcamento_cif.forEach(c => {
          c._index = xx++
          c._status_cif = (c.codigo_processo_venda && c.codigo_processo_venda.match(':fob')) ?
            'Cancelado' : (c.status == EnumStatusOrcamento.aprovado) ? 'Aprovado' : 'Aberto';
          ListOrcamento.SetConfig(c)
        })

        this._list_orcamento.sort(function (a, b) {
          var _a = UseFullService.ConvertFloat(a.preco_frete_total_fob)
          var _b = UseFullService.ConvertFloat(b.preco_frete_total_fob)
          return (_a > _b) ? 1 : (_a < _b) ? - 1 : 0
        })
        this._list_orcamento.sort(function (a, b) {
          var _a = a._estoque
          var _b = b._estoque
          return (_a > _b) ? 1 : (_a < _b) ? - 1 : 0
        });
        this.GetAllProdutos(this._cotacao_id);
      }
    )
  }


  produtos: any //{ produto -> orcamento -> valores }
  GetAllProdutos(cotacao_id: number) {
    this.produtoCompradorService.GetAll(cotacao_id).subscribe(
      d => {
        this.produtos = d;
        let x = 1
        this.produtos.forEach(c => { c._index = x++ });
      },
      e => { },
      async () => {
        await new Promise(async (res, rej) => {
          for (let i = 0; i < this._list_orcamento.length; i++) {
            this._list_orcamento[i]._produtos = new Array<ProdutoComprador>()
            this._list_orcamento[i]._impar = !(((i + 1) % 2) == 0);
            this.produtos.forEach(p => {
              var produto = new ProdutoComprador();
              produto._orcamento_produto = new OrcamentoProduto()
              produto.id = p.id
              produto.quantidade = p.quantidade
              this._list_orcamento[i]._produtos.push(produto)
            });
            await new Promise(async (resolve, reject) => {
              await this.GetOrcamentoProduto(this._list_orcamento[i], resolve, reject);
            });
            if (i == (this._list_orcamento.length - 1)) {
              res()
            }
          }
        });
      }
    );
  }

  orcamento_produtos: any
  GetOrcamentoProduto(orcamento: any, resolve, reject) {
    this.orcamentoProdutoService.GetAll(orcamento.id).subscribe(
      d => { this.orcamento_produtos = d; },
      e => { reject(e) },
      () => {
        ProdutoComprador.SetProdutoOrcamento(orcamento._produtos, this.orcamento_produtos, orcamento);
        resolve(this.orcamento_produtos);
      }
    );
  }


  GetByQuoteSugested() {
    if (this._cotacao.tipo_frete === EnumTipoFrete.ciffob || this._cotacao.tipo_frete === EnumTipoFrete.cif) {
      this.orcamentoService.GetByQuoteCompradorSuggested(this._cotacao_id, EnumTipoFrete.cif).subscribe(
        d => { if (d) this._orcamento_sugerido_cif = d },
        e => { },
        () => { ListOrcamento.SetConfig(this._orcamento_sugerido_cif) })
    }

    if (this._cotacao.tipo_frete === EnumTipoFrete.ciffob || this._cotacao.tipo_frete === EnumTipoFrete.fob) {
      this.orcamentoService.GetByQuoteCompradorSuggested(this._cotacao_id, EnumTipoFrete.fob).subscribe(
        d => { if (d) this._orcamento_sugerido_fob = d },
        e => { },
        () => { ListOrcamento.SetConfig(this._orcamento_sugerido_fob) })
    }
  }

  GetOrcamentoAprovado() {
    this.cotacaoService.GetOrcamentoAprovado(this._cotacao_id).subscribe(
      d => {
        if (d && d.id > 0)
          this._orcamento_aprovado = d
        else
          this._orcamento_aprovado = new Orcamento()
        this.GetAll()
        if (this._cotacao.tipo_frete == EnumTipoFrete.ciffob) {
          this.MelhorOrcamento(EnumTipoFrete.cif);
          this.MelhorOrcamento(EnumTipoFrete.fob);
        } else
          this.MelhorOrcamento();
      }
    );
  }

  GetCotacao() {
    this.cotacaoService.Get(this._cotacao_id).subscribe(
      d => {
        if (!d) { throw ("Cotação não encontrada, atualize a pagina e tente novamente!") }
        this._cotacao = d;
      },
      e => { },
      () => {
        Cotacao.SetFormat(this._cotacao);
        Cotacao.Validade(this._cotacao);
        this.GetOrcamentoAprovado();
        UseFullService.SetMask()
        this.GetByQuoteSugested()
      }
    );
  }

  public _grid_tipo_frete = ''
  Ver(orcamento: ListOrcamento, frete: string) {
    if (((frete == 'cif' && orcamento.cif > 0) || (frete == 'cif' && this._cotacao.tipo_frete == 2)) ||
      ((frete == 'fob' && orcamento.fob > 0) || (frete == 'fob' && this._cotacao.tipo_frete == 1)))
      return;

    this._list_orcamento.forEach(c => { c._selected = false; });
    orcamento._selected = true;
    this._grid_tipo_frete = frete;
    setTimeout(() => { $('#orcamento-veiw-modal').modal('show'); }, 10);
  }

  VerSugerido(orcamento: ListOrcamento, tipo_frete) {
    if (orcamento.id > 0)
      this.router.navigateByUrl("/orcamentos/comprador-sugestao-view/" + orcamento.id + '/' + tipo_frete);
  }

  listGrid = []
  listGridCif = []
  listGridFob = []
  _cif_fob_modal = ''
  _precoTotalModal = '0,00'
  MelhorOrcamento(tipoFrete = 0) {
    this._context.path = 'orcamento/produto/get/nogociado/itens'
    this._context.getAll('&id=' + this._cotacao_id + '&tipoFrete=' + tipoFrete).subscribe(
      d => {
        this.listGrid = d;
        let total = 0;
        this.listGrid.forEach(c => {
          let valor = c._preco * c.quantidade;
          c.preco = UseFullService.ToFixedReal(c._preco)
          c.precoTotal = UseFullService.ToFixedReal(valor)
          c._quantidade = UseFullService.ToFixedReal(c.quantidade)
          total += valor
        });
        this._cif_fob_modal = (tipoFrete == 2) ? 'FOB' : 'CIF'
        this._precoTotalModal = UseFullService.ToFixedReal(total)
      })
  }

  public _nome_arquivo: any;
  public _src_arquivo: any;
  carregarArquivoPropota(o: any, nome) {
    if (nome == null)
      return;
    this._nome_arquivo = "";
    this._src_arquivo = "";
    $("#exibir-pdf").html("");
    const _src_arquivo = GlobalVariable.BASE_API_URL + "fornecedor/orcamento_" + o.id + "/" + nome;
    setTimeout(() => { $("#exibir-pdf").html("<iframe width='100%' height='" + ($('body').height() - 100) + "px' src='" + _src_arquivo + "'></iframe>"); });
    $('#data-modal').modal('show');
  }

  realizarBoot(frete) {
    this._context.path = '/sugerir/orcamento'
    this._context.getAll('&cotacaoId=' + this._cotacao_id + '&frete=' + frete).subscribe(
      d => { location.reload() })
  }

}
