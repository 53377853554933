<div class="modal fade" id="global-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <b class="modal-title">{{confirmService._title}}</b>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body modal-confirm" style="padding: 1rem; padding-bottom: 0px;" *ngIf="confirmService._message_input">
                <label [innerHTML]="confirmService._message_input"></label>
                <div class="form-inline">
                    <input class='form-control' style="width: calc(100% - 47px);" [(ngModel)]="confirmService._input" placeholder='Digite os emails de seus fornecedores!'>
                    <button type="submit" (click)="addList()" style="height: 32px; margin-left:7px;" class="btn btn-default my-1"><i class="fa fa-plus"></i></button>
                    <span class="error-email   " *ngIf="error && error != ''">{{error}}</span>
                    <div class="list-email" *ngFor="let email of confirmService._list">
                        {{email}}
                        <i class="fa fa-trash-alt" (click)="removerItem(email)"></i>
                    </div>
                </div>
            </div>

            <div class="modal-body modal-confirm" style="padding: 1rem; padding-bottom: 0px;" *ngIf="confirmService._message_input_geral">
                <label [innerHTML]="confirmService._message_input_geral"></label>
                <div class="form-inline">
                    <input class='form-control input-geral' style="width: calc(100% - 47px);" [(ngModel)]="confirmService._input" placeholder="{{confirmService._placeholder}}">
                    <span class="error-email   " *ngIf="confirmService._error && confirmService._error != ''">{{confirmService._error}}</span>
                </div>
            </div>
            <div class="modal-body modal-confirm" style="padding: 1rem; padding-bottom: 0px;" *ngIf="confirmService._message_input_secundario">
                <label [innerHTML]="confirmService._message_input_secundario"></label>
                <div class="form-inline">
                    <input class='form-control input-secundario' style="width: calc(100% - 47px);" [(ngModel)]="confirmService._input_secundario" placeholder="{{confirmService._placeholder_input_secundario}}">
                </div>
            </div>

            <div class="modal-body modal-confirm" style="padding: 1rem;" [innerHTML]="confirmService._message"></div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="ConfirmEmit(false)">Não</button>
                <button type="button" class="btn btn-primary" (click)="ConfirmEmit(true)">Sim</button>
            </div>
        </div>
    </div>
</div>