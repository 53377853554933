<!-- Modal -->
<div class="modal fade" id="data-modal" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{_nome_arquivo}}</h4>

                <div class="ibox-tools">
                    <div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>

            </div>

            <div class="body-data">
                <img *ngIf="_src_arquivo" class="" src="{{_src_arquivo}}" />
                <div id="exibir-pdf" class="" *ngIf="!_src_arquivo"></div>
            </div>
        </div>
    </div>
</div>