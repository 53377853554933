<div class="col-lg-12 wrapper-top-content">
    <div class="ibox-title">
        <h5>Gerênciar Perfis de Usuário</h5>
    </div>

    <div class="ibox-content    form-categoria-nivel">
        <div class="row">

            <div class="col-sm-5 m-b-xs">
                <Br />
                <div class="form-group">
                    <label>Nome Perfil</label>
                    <div class="row">
                        <div class="col-sm-8">
                            <input type="text" class="form-control" placeholder="Digite um nome para perfil" [(ngModel)]="_perfil_usuario.descricao" />
                            <small id="emailHelp" class="form-text text-muted" required>Digite uma descrição para perfil.</small>
                        </div>

                        <div class="col-sm-4">
                            <button type="submit" class="btn btn-primary" (click)="Adicionar()">Adicionar</button>
                        </div>
                    </div>
                </div>


                <Br />
                <div class="lista-perfil-usuario">
                    <div class="list-group">
                        <a *ngFor="let perfil of _perfis_usuarios" class="dropdown-item    {{(perfil.id == _temp_perfil_usuario.id) ? 'active': ' '}}" (click)="SelectPerfilUsuario(perfil)">
                            <input type="text" class="form-control
                                input-table" required [(ngModel)]="perfil.descricao" (change)="Editar(perfil)" />
                            <label class="text-primary float-right delete-perfil-usuario" *ngIf="(perfil.id == _temp_perfil_usuario.id)" (click)=ClickDelete(perfil)>x</label>
                        </a>
                    </div>
                </div>

            </div>

            <div class="col-sm-7">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead class="thead-dark">
                            <tr>
                                <th class="text-center">Descrição / Rota</th>
                                <th class="text-center">Operações</th>
                            </tr>
                        </thead>
                        <tr class="  " *ngFor="let funcao of  _funcoes_sistema">
                            <td style="max-width: 300px;white-space: pre-wrap;word-wrap:break-word;"><b>{{funcao.descricao}}</b><br /><br /><span>{{funcao.rota}}</span></td>
                            <td>
                                <div>

                                    <div class="form-check" *ngFor="let
                                            operacao of funcao._operacoes">
                                        <input class="form-check-input" type="checkbox" value="" [checked]="operacao.selected" (change)="ChangeOperacao($event,
                                                funcao, operacao.id)" id="defaultCheck0
                                                padding-top-12">
                                        <label class="form-check-label
                                                dropdown-item
                                                  " for="defaultCheck0" (click)="SelectItem()">
                                                {{operacao.descricao}}
                                            </label>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>