import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from 'src/app/core/global-variable';
import { OrcamentoAliquota } from 'src/app/domain/orcamento/orcamento';

@Injectable({
  providedIn: 'root'
})
export class OrcamentoAliquotaService {

  private url = GlobalVariable.BASE_API_URL + "orcamento/aliquota/";
  constructor(private http: HttpClient) { }

  GetAll(orcamento_id: number): Observable<OrcamentoAliquota[]> {
    return this.http.get<OrcamentoAliquota[]>(this.url + "getall?orcamento_id=" + orcamento_id).pipe(
      map(res => {
        return res;
      })
    );
  }

  Get(id: number) {
    return this.http.get<OrcamentoAliquota>(this.url + "get?id=" + id).pipe(
      map(res => {
        return res;
      })
    );
  }

  Create(data: OrcamentoAliquota): Observable<any> {
    return this.http.post<any>(this.url + "create", data).pipe(
      map(res => { return res; })
    );
  }

  Edit(data: OrcamentoAliquota): Observable<any> {
    return this.http.put<any>(this.url + "edit", data).pipe(
      map(res => { return res; })
    );
  }

  Delete(id: number): Observable<any> {
    return this.http.delete(this.url + "delete?id=" + id).pipe(
      map(res => { return res; })
    );
  }

  /**********FOB**********/
  GetAllFOB(orcamento_id: number): Observable<OrcamentoAliquota[]> {
    return this.http.get<OrcamentoAliquota[]>(this.url + 'fob/' + "getall?orcamento_id=" + orcamento_id).pipe(
      map(res => {
        return res;
      })
    );
  }

  GetFOB(id: number) {
    return this.http.get<OrcamentoAliquota>(this.url + 'fob/' + "get?id=" + id).pipe(
      map(res => {
        return res;
      })
    );
  }

  CreateFOB(data: OrcamentoAliquota): Observable<any> {
    return this.http.post<any>(this.url + 'fob/' + "create", data).pipe(
      map(res => { return res; })
    );
  }

  EditFOB(data: OrcamentoAliquota): Observable<any> {
    return this.http.put<any>(this.url + 'fob/' + "edit", data).pipe(
      map(res => { return res; })
    );
  }

  DeleteFOB(id: number): Observable<any> {
    return this.http.delete(this.url + 'fob/' + "delete?id=" + id).pipe(
      map(res => { return res; })
    );
  }
}
