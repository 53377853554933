import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ProdutoCategoriaNivel } from 'src/app/domain/suprimento/produto-categoria-nivel';
import { EspecificacaoProduto } from 'src/app/domain/suprimento/produto';

declare var $: any;

@Component({
  selector: 'app-modal-cores-insumo',
  templateUrl: './modal-cores-insumo.component.html',
  styleUrls: ['./modal-cores-insumo.component.css']
})

export class ModalCoresInsumoComponent implements OnInit {

  @Output() resposta_cores_selecionadas: EventEmitter<ProdutoCategoriaNivel> = new EventEmitter();
  @Input() _input_nivel: ProdutoCategoriaNivel;

  public _buscar_cores: string;

  constructor() { }

  ngOnInit() {
    $('#pesquisa-cor-modal').on('show.bs.modal', function (e) {
      $(".modal-max").css("max-height", ($("body").height() - 220) + "px");
    });
  }

  SelecionarCor(cor: EspecificacaoProduto) {
    if (this._input_nivel._multiple)
      cor._selecionado = !cor._selecionado;
    else {
      this._input_nivel.nivel.forEach(c => {
        if (cor.id != c.id)
          c._selecionado = false;
        else
          cor._selecionado = !cor._selecionado;
      });
    }
  }

  ConcluirSelecao() {
    this.resposta_cores_selecionadas.emit(this._input_nivel);
    $("#pesquisa-cor-modal").removeClass('show');
    $("#pesquisa-cor-modal").hide();
    $("#pesquisa-cor-modal").modal('hide');
  }

  Cancelar() {
    $("#pesquisa-cor-modal").removeClass('show');
    $("#pesquisa-cor-modal").hide();
    $("#pesquisa-cor-modal").modal('hide');
    $("#pesquisa-insumo-modal").removeClass('modal-z-index');
  }
}