import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from 'src/app/core/global-variable';
import { Dashboard, FaturamentoDashboard } from 'src/app/domain/auxiliar/dashboard';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  
  private url = GlobalVariable.BASE_API_URL + "dashboard/resumo";
  constructor(private http: HttpClient) { }

  Get(inicio, fim) {
    return this.http.get<Dashboard>(this.url+"?inicio="+inicio+"&fim="+fim).pipe(
      map(res => {
        return res;
      })
    );
  }

  GetAll(inicio, fim) {
    return this.http.get<FaturamentoDashboard[]>(this.url+"/faturamento?inicio="+inicio+"&fim="+fim).pipe(
      map(res => {
/*
        var arr = [
          {
            "data": "2019-01-03T00:00:00",
            "total_diario": 65.0
          },
          {
            "data": "2019-01-04T00:00:00",
            "total_diario": 43.0
          },
          {
            "data": "2019-01-08T00:00:00",
            "total_diario": 88.0
          },
          {
            "data": "2019-01-09T00:00:00",
            "total_diario": 115.0
          },
          {
            "data": "2019-01-11T00:00:00",
            "total_diario": 93.0
          },
          {
            "data": "2019-01-14T00:00:00",
            "total_diario": 107.0
          },
          {
            "data": "2019-01-15T00:00:00",
            "total_diario": 82.0
          },
          {
            "data": "2019-01-17T00:00:00",
            "total_diario": 106.0
          },
          {
            "data": "2019-01-18T00:00:00",
            "total_diario": 58.0
          },
          {
            "data": "2019-01-22T00:00:00",
            "total_diario": 97.0
          },
          {
            "data": "2019-01-24T00:00:00",
            "total_diario": 68.0
          },
          {
            "data": "2019-01-25T00:00:00",
            "total_diario": 46.0
          },
          {
            "data": "2019-01-28T00:00:00",
            "total_diario": 132.0
          },
          {
            "data": "2019-01-29T00:00:00",
            "total_diario": 31.0
          },
          {
            "data": "2019-01-30T00:00:00",
            "total_diario": 57.0
          },
          {
            "data": "2019-01-31T00:00:00",
            "total_diario": 83.0
          }
        ];
*/

        return res;
      })
    );
  }

}
