<!-- Modal -->
<div class="modal fade" id="data-entraga-parcial-modal" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog- modal-dialog-scrollable
            modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Cronograma de Entrega</h4>
                <div class="ibox-tools">
                    <div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        <button class="close" style="font-size: 12px;
                                padding-top: 23px;">
                                <i class="fa fa-question"></i>
                            </button>
                    </div>
                </div>
            </div>
            <div class="body-data">
                <div class="col-sm-12 text-left">
                    <h5 class="h5-nome-produto">{{_produto_selecionado.nome}}</h5>
                    <div class="modal-max">
                        <table class="table table-striped table-hover
                                table-bordered">
                            <thead class="thead-dark thead-fixed">
                                <tr>
                                    <th class="text-center th-data-entrega">
                                        Ordem</th>
                                    <th class="text-center th-input-stock">Datas de Entrega
                                    </th>
                                    <th class="text-center th-select-unity column-unidade">Unidade</th>
                                    <th class="text-center th-input-stock">Quantidades Parciais
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let entrega of _entregas_parcial">
                                    <td class="text-center">{{entrega._index}}</td>
                                    <td class="text-center">{{entrega._data_entrega}}</td>
                                    <td class="text-center">{{_produto_selecionado._unidade_nome}}</td>
                                    <td class="text-center">{{entrega._quantidade}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style="width:250px">
                        <table class="table table-striped table-hover
                                table-bordered">
                            <tbody>
                                <tr>
                                    <td class="th-select-unity"><b>ADICIONADO</b></td>
                                    <td class="text-center">{{_produto_selecionado._unidade_nome}}</td>
                                    <td class="text-center th-select-unity">{{_params_truck._adicionado}}</td>
                                </tr>
                                <tr>
                                    <td class="th-select-unity"><b>RESTANTE</b></td>
                                    <td class="text-center">{{_produto_selecionado._unidade_nome}}</td>
                                    <td class="text-center th-select-unity">{{_params_truck._restante}}</td>
                                </tr>
                                <tr>
                                    <td class="th-select-unity"><b>TOTAL GERAL</b></td>
                                    <td class="text-center"><b>{{_produto_selecionado._unidade_nome}}</b></td>
                                    <td class="text-center th-select-unity"><b>{{_params_truck._total}}</b></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-right button-modal">
                        <label class="text-danger" style="float:left;">{{_msg}}</label>
                        <button class="btn btn-default" data-dismiss="modal">Fechar</button>
                        <!--
                        <button class="btn btn-primary {{(this._entregas_parcial.length > 0 && _on_save) ?
                                '':'disabled'}}" (click)="OnSave()">Salvar</button>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>