import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/service/authenticate/authentication.service';

@Component({
  selector: 'app-login-auditor',
  templateUrl: './login-auditor.component.html',
  styleUrls: ['./login-auditor.component.css'],
  providers: [AuthenticationService]
})
export class LoginAuditorComponent implements OnInit {
  _percentage = 0;
  public _login: string;
  public _password: string;
  _token = this.route.snapshot.params['id'];
  _params = []
  constructor(private httpAuthenticate: AuthenticationService, private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.LogOut();

    this._params = atob(this._token.replace('_', '=').replace('_', '=').replace('_', '=')).split('|')[0].split(':')
    if (this._params.length == 5)
      this.LogNoIntoCompra();
    else
      this.LogNoInto();

    this.PageLoading(10);
  }

  LogNoIntoCompra() {
    this.PageLoading(40);
    this.httpAuthenticate.loginTokenCompra(this._token).subscribe(
      data => this.ConfirmLogin(),
      error => { },
      () => {
        this.PageLoading(100);
      }
    );
  }

  LogNoInto() {
    this.PageLoading(40);
    this.httpAuthenticate.loginToken(this._token).subscribe(
      data => this.ConfirmLogin(),
      error => { },
      () => { this.PageLoading(100); }
    );
  }

  ConfirmLogin(): void {
    setTimeout(() => {
      if (this.httpAuthenticate.isLoggedIn()) {
        if (this._params.length == 5)
          this.router.navigateByUrl('/auditor/compras/orcamento/' + this._params[0])
        else if (this._params.length == 4)
          this.router.navigateByUrl('/auditor/obra/relatorio/' + this._params[0])
        else
          this.router.navigateByUrl('/home')
      }
    }, 100);
  }

  LogOut() {
    this.httpAuthenticate.logoutEmail()
  }

  PageLoading(limit: number = 10): void {
    setTimeout(() => {
      if (this._percentage < limit) {
        this._percentage++;
        this.PageLoading(limit);
      }
    }, 10);
  }
}