export class OrcamentoConfiguracao{
    public id: number;
    public regiao: string;
    public estado: string;
    public cidade: string;
    public categorias: string;
    public forma_recebimento: string;
    public prazo_recebimento: string;
    public receber_por: number;
    public log: string;

    public icms: number;
    public ipi: number;
}

export enum EnumReceberCotacaoPor{
    categoria = 1,
    produto = 2
}