<div class="col-lg-12 wrapper-top-content">
    <div class="ibox">
        <div class="ibox-title" style="padding-right:20px;">
            <h5>Orçamento:&nbsp;&nbsp; {{_orcamento.codigo}} </h5>
            <div class="text-primary" style="float: right;"><b class="text-dark">Status: </b>&nbsp;&nbsp;{{_orcamento.status == 1 && _orcamento._showProcesso ? 'Aguardando Aprovação' : 'Finalizado'}} </div>
        </div>
        <div class="ibox-content">

            <app-cabecalho-orcamento [_input_tipo_frete]="_tipo_entrega"></app-cabecalho-orcamento>
            <div class="table-responsive min-height-grid-insumo
                div-table-fixed margin-top-15" *ngIf="_carregamento_concluido">
                <!-- table-striped table-hover table-bordered -->
                <table class="table table-striped table-hover table-bordered" style="border: 1px solid #EBEBEB;">
                    <thead class="thead-fixed">
                        <tr>
                            <th class="th-sequece-produto" rowspan="2">Item</th>
                            <th class="th-nome-produto" rowspan="2">Nome do Insumo
                            </th>
                            <th class="text-center th-select-unity
                            column-unidade" rowspan="2">Marca</th>
                            <th class="text-center th-select-unity
                            column-unidade" rowspan="2">Unidade</th>
                            <th class="text-center th-input-padrao-80" rowspan="2">Quantidade</th>
                            <th class="text-center th-select-unity" *ngIf="_ha_anexo" rowspan="2">Anexo</th>
                            <th class="text-center th-select-unity" rowspan="2" *ngIf="(_cotacao.recebimento_produto == 2)">Entrega Parcial
                            </th>
                            <th class="text-center th-input-padrao-100" rowspan="2">Preço R$</th>
                            <th class="text-center" *ngIf="boolIcms || boolIpi" [attr.colspan]="(boolIcms && boolIpi) ? 3:
                                (boolIpi) ? 2:1">Impostos R$</th>
                            <th class="text-center th-input-padrao-120" rowspan="2">Valor Total - R$</th>
                        </tr>
                        <tr>
                            <th class="text-center th-input-padrao-65" *ngIf="boolIcms">ICMS %</th>
                            <th class="text-center th-input-padrao-65" *ngIf="boolIpi">IPI %</th>
                            <th class="text-center th-input-padrao-65" *ngIf="boolIpi">Vlr.IPI</th>
                        </tr>
                    </thead>
                    <tbody class="" *ngFor="let produto of
                        _list_produtos">

                        <tr class="{{!produto._index_impar_par ?
                            'tr-background-impar':'tr-background-par'}}
                            {{(produto._orcamento_produto.estoque ||
                            !produto._orcamento_produto.id) ?
                            'tr-checked-stock-of':''}}">

                            <td class="text-center th-sequence-produto">{{produto._index}}</td>
                            <td class="td-nome-produto">{{produto.nome}}</td>
                            <td class="text-center th-select-unity">{{produto._orcamento_produto._marca}}</td>
                            <td class="text-center th-select-unity">{{produto._unidade_nome}}</td>
                            <td class="text-center th-input-stock">{{produto.quantidade}}</td>
                            <td class="text-center th-action-anexo" *ngIf="_ha_anexo">
                                <i *ngIf="produto.anexo && produto.anexo !=
                                    'null'" class="fa fa-file-alt btn-link
                                    cursor-pointer" (click)="MostrarFile(produto)"></i>
                            </td>

                            <td class="text-center th-action-parcial" *ngIf="(_cotacao.recebimento_produto == 2)">
                                <button class="btn btn-sm-grid btn-link">
                                    <i class="fa fa-truck"
                                        (click)="OpenModalTrunck(produto)"></i>
                                </button>
                                <small class="btn-link cursor-pointer"></small>
                            </td>
                            <td class="text-right th-input-price">
                                R$ {{produto._orcamento_produto._preco_string}}
                            </td>
                            <td class="text-center th-input-stock" *ngIf="boolIcms">
                                {{produto._orcamento_produto._icms}}
                            </td>
                            <td class="text-center th-input-stock" *ngIf="boolIpi">
                                {{produto._orcamento_produto._ipi}}
                            </td>
                            <td class="text-right th-input-stock" *ngIf="boolIpi">R$ {{produto._orcamento_produto._preco_ipi}}
                            </td>
                            <td class="text-right th-input-stock">R$ {{produto._orcamento_produto._preco_total}}
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <div class="form-group row">
                <div class="col-sm-8 text-termo">
                    <span>Valores expressos em moeda R$ real brasileiro;</span><br>
                    <span>Data de Entrega a contar a partir da confirmação do
                        pedido;</span><br>
                    <span>A descarga do material é de responsabilidade do
                        cliente;</span><br>
                    <span>Confira seu produto no ato da entrega/coleta;</span><br>
                    <span>Vendas no Cartão BNDES estão sujeitas à
                        disponibilidade de crédito do cliente junto a
                        instituição financeira emitente do cartão;</span><br>
                    <span>Estão excluídos de nosso fornecimento todo material ou
                        serviço não espeficado neste documento;</span><br><br>
                    <div *ngIf="((_orcamento.status == 1) &&
                        !(_cotacao._expirado)) && listProcesso.length <1">
                        <label class="btn-checked" (click)="ClickLiTermos()">
                            <span class="fa fa-check {{_li_termos ?
                                'active':''}}"></span>
                        </label>
                        <label class="margin-left-10 list-cor">Li e concordo com
                            os termos.</label>
                    </div>
                    <Br />
                    <div class="processo-aprovacao" *ngIf="(listProcesso.length>
                        0 && _orcamento._showProcesso)">
                        <h4>Processo de compra:</h4>
                        <br />
                        <div *ngFor="let processo of listProcesso">
                            <div class="{{(listProcesso[listProcesso.length - 1]
                                != processo)?'grid-proccess':''}}">
                                <label class="processo-status">{{(processo.status_auditoria
                                    == 1) ? 'Aprovado':
                                    (processo.status_auditoria ==
                                    2)?'Reprovado':'Aguardando Avaliação'}}</label>
                                <div class="proccess-b text-center">
                                    <i class="fa fa-{{(processo.status_auditoria
                                        == 1) ? 'check':
                                        (processo.status_auditoria ==
                                        2)?'thumbs-down':'question'}}"></i>
                                </div>
                                <label class="margin-buttom-5 margin-left-10"><b>{{(processo._tipo
                                        == 1)?'FATURANTE':'FISCALIZADORA'}}:</b>&nbsp;&nbsp;
                                    {{processo.nome_fantasia}}</label><br />
                                <label class="margin-buttom-5 margin-left-10">{{processo.nome_responsavel}}</label><br />
                                <hr style="margin:5px 0px 0px 0px;" *ngIf="listProcesso[listProcesso.length - 1]
                                    != processo" />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <table class="table table-striped table-bordered
                        table-aliquota" style="border: 0;">
                        <thead class="th-checked-tipo-frete" *ngIf="_cotacao.tipo_frete == 3">
                            <th colspan="2" style="padding-left: 0;border:0px;">Valor para entrega {{(_tipo_entrega == 'fob' && !_orcamento.fob)?'FOB':'CIF'}}
                            </th>
                        </thead>
                        <tbody class="">
                            <tr class="tr-background-impar">
                                <td class="text-left"><b>Subtotal</b></td>
                                <td class="th-input-padrao-120 text-right">R$ {{_orcamento._subtotal}}
                                </td>
                            </tr>
                            <tr class="tr-background-impar">
                                <td class="text-left" style="border:0;"></td>
                                <td class="th-input-padrao-120 text-right" style="border:0;"></td>
                            </tr>
                            <tr *ngFor="let aliquota of _aliquotas" class="tr-background-impar">
                                <td>{{aliquota.descricao}}</td>
                                <td class="th-input-padrao-120 text-right">R$ {{aliquota._valor}}
                                </td>
                            </tr>
                            <tr class="tr-background-impar" *ngIf="_aliquotas.length> 0">
                                <td class="text-left" style="border:0;"></td>
                                <td class="th-input-padrao-120 text-right" style="border:0;"></td>
                            </tr>
                            <tr class="tr-background-par">
                                <td class="text-left"><b>VALOR TOTAL</b></td>
                                <td class="th-input-padrao-120 text-right"><b>R$
                                        {{_orcamento._total}}</b></td>
                            </tr>

                            <tr class="tr-background-impar" *ngIf="_tipo_entrega
                                == 'fob'">
                                <td class="text-left" style="border:0;"></td>
                                <td class="th-input-padrao-120
                                    text-right" style="border:0;"></td>
                            </tr>

                            <tr class="tr-background-impar" *ngIf="_tipo_entrega
                                == 'fob'">
                                <td class="text-left" style="border:0;" colspan="2"><b>Informações de custo frete
                                        FOB:</b></td>
                            </tr>


                            <tr class="tr-background-impar" *ngIf="_tipo_entrega
                                == 'fob'">
                                <td class="text-left">Valor Médio km</td>
                                <td class="th-input-padrao-120
                                    text-right">R$ {{_orcamento.preco_frete_fob}}
                                </td>
                            </tr>
                            <tr class="tr-background-impar" *ngIf="_tipo_entrega
                                == 'fob'">
                                <td class="text-left">Valor Total</td>
                                <td class="th-input-padrao-120
                                    text-right">R$ {{_orcamento.preco_frete_total_fob}}
                                </td>
                            </tr>
                            <tr class="tr-background-impar" *ngIf="_tipo_entrega
                                == 'fob'">
                                <td class="text-left">Distância</td>
                                <td class="th-input-padrao-120
                                    text-right">{{_orcamento.distancia_km}} {{_orcamento.distancia_unidade}}
                                </td>
                            </tr>
                            <tr class="tr-background-impar" *ngIf="_tipo_entrega
                                == 'fob'">
                                <td class="text-left">Tempo Percurso</td>
                                <td class="th-input-padrao-120
                                    text-right">{{_orcamento.tempo}}
                                </td>
                            </tr>

                            <tr>
                                <td colspan="2" class="text-right
                                    no-padding" style="border:0px;
                                    padding-top: 15px
                                    !important">
                                    <div class="text-left" style="margin-bottom:
                                        10px;">
                                        <button class="btn btn-link" *ngIf="_tipo_entrega == 'fob'" (click)="carregarArquivoPropota(_orcamento.id,
                                            _orcamento.file_proposta_fob)">
                                            <b>Proposta:</b>&nbsp;&nbsp;{{_orcamento.file_proposta_fob}}</button>
                                        <button class="btn btn-link" *ngIf="_tipo_entrega == 'cif'" (click)="carregarArquivoPropota(_orcamento.id,
                                            _orcamento.file_proposta_cif)">
                                            <b>Proposta:</b>&nbsp;&nbsp;{{_orcamento.file_proposta_cif}}</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-sm-6">
                </div>
                <div class="col-sm-6 text-right">
                    <a class="btn btn-default" href="javascript:history.back()">
                        <i class="fa fa-arrow-left"></i>&nbsp;&nbsp;Voltar</a>
                    <button type="submit" *ngIf="(_orcamento.status == 1) &&
                        !(_cotacao._expirado) && listProcesso.length < 1" class="btn btn-primary
                        margin-left-10" style="background:
                        #08c65b; border-color: #08c65b;" (click)="ConfirmeRrightEnd()"><i
                            class="fa fa-check"></i>&nbsp;&nbsp;Finalizar e
                        Comprar</button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-data-modal [_nome_arquivo]="_nome_arquivo" [_src_arquivo]="_src_arquivo"></app-data-modal>
<app-entrega-parcial-view [_produto_selecionado]="_produto_selecionado" (_entregas_emit)="ReceiverEntregaProduto($event)"></app-entrega-parcial-view>
<app-message-modal [_titulo]="_msg_titulo" [_descricao]="_msg_descricao"></app-message-modal>