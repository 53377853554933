<div class="wrapper-top-content" style="width: 100%; max-width: 800px; margin: 0 auto;">
    <div class="ibox-title">
        <h5>Cadastro Marca</h5>
    </div>
    <div class="ibox-content form-marca-nivel">
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group">
                    <label>Descrição</label>
                    <div class="row">
                        <div class="col-sm-8">
                            <input type="text" class="form-control" placeholder="Digita à descrição" [(ngModel)]="_marca.descricao" />
                        </div>
                        <div class="col-sm-4 no-padding">
                            <button *ngIf="!(_marca.id > 0)" type="submit" class="btn btn-primary" (click)="Adicionar()">Adicionar</button>
                            <button *ngIf="(_marca.id > 0)" type="button" class="btn btn-default" (click)="GetAll()">Cancelar</button>&nbsp;&nbsp;&nbsp;&nbsp;
                            <button *ngIf="(_marca.id > 0)" type="submit" class="btn btn-primary" (click)="Editar()">Editar</button>
                        </div>
                    </div>
                </div>
                <div>
                    <table class="table">
                        <thead class="thead-ligth">
                            <tr>
                                <th>Descrição</th>
                                <th class="text-cen width-80-px">#</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let marca of _list_marca | filter : _marca.descricao" class="marca.id == _marca.id">
                                <td>{{marca.descricao}}</td>
                                <td>
                                    <label class="text-primary float-right delete-categoria-nivel cursor-pointer" (click)="ConfirmExcluir(marca)"><i class="fa fa-times"></i></label>
                                    <label class="text-primary float-right cursor-pointer" (click)="ActionEditar(marca)"><i class="fa fa-edit"></i>&nbsp;&nbsp;</label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>