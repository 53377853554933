<div class="col-lg-12 wrapper-top-content">
    <div class="ibox">
        <div class="ibox-title" style="padding-right:20px;">
            <h5>Obra</h5>
            <div style="float: right; margin-top:-4px;">

            </div>
        </div>
        <div class="ibox-content">
            <div class="table-responsive min-height-grid-insumo div-table-fixed margin-top-15">
                <!-- Eu amo simplificar,   table-striped table-hover table-bordered -->
                <table class="table table-striped">
                    <thead class="thead-fixed">
                        <tr>
                            <th class="th-sequence"></th>
                            <th class="th-nome">Nome</th>
                            <th class="text-center th-data">Função</th>
                            <th class="text-center th-data">Status</th>
                            <th class="text-center th-action"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let obra of obras">
                            <td class="text-center th-sequence">{{obra._index}}</td>
                            <td><label class="btn-link cursor-pointer" (click)="Ver(obra)">{{obra.nome}}</label></td>
                            <td class="text-center th-data">{{obra._tipo}}</td>
                            <td class="text-center th-data">{{obra._status}}</td>
                            <td class="text-center th-action-anexo">
                                <i class="fa fa-search btn-link cursor-pointer" (click)="ver(obra)"></i>&nbsp;&nbsp;
                                <i class="fa fa-edit btn-link cursor-pointer" (click)="edit(obra)"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="obras.length <= 0">Nenhuma obra encontrada!</div>
        </div>
    </div>
</div>