import { Component, OnInit, ɵConsole } from '@angular/core';
import { SolicitacaoService } from 'src/app/service/suprimento/solicitacao.service';
import { SolicitacaoProduto, SolicitacaoProdutoLog } from 'src/app/domain/suprimento/solicitacao-produto';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { StatusSolicitacaoProdutoEnum } from 'src/app/domain/suprimento/status-solicitacao-produto-enum';
import { ProdutoService } from 'src/app/service/suprimento/produto.service';
import { Produto } from 'src/app/domain/suprimento/produto';
import { ProdutoFornecedor, InsumoDoFornecedor } from 'src/app/domain/suprimento/produto-fornecedor';
import { ProdutoUnidadeMedida } from 'src/app/domain/suprimento/produto-unidade-medida';
import { Pagination } from 'src/app/domain/common/pagination';
import { SuprimentoService } from 'src/app/service/suprimento/suprimento-service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';

declare var $: any;

@Component({
  selector: 'app-solicitacao',
  templateUrl: './solicitacao.component.html',
  styleUrls: ['./solicitacao.component.css']
})
export class SolicitacaoComponent implements OnInit {
  _code = 'app-solicitacao';
  public _solicitacoes: SolicitacaoProduto[];
  public _solicitacao_selecionada: SolicitacaoProduto;
  public _solicitacao: SolicitacaoProduto;
  public _buscar: string;
  public _hidden: boolean;
  public _produto: InsumoDoFornecedor;
  public _filter: number;
  public _unidade_medida: ProdutoUnidadeMedida[];

  public _count_grid: number;
  public _total_pesquisa: number;
  public _paginas: Pagination[];

  _status_solicitacao_enum = StatusSolicitacaoProdutoEnum;
  constructor(
    private solicitacaoService: SolicitacaoService, 
    private produtoService: ProdutoService, 
    private confirmService: AlertConfirmService, 
    private suprimentoService: SuprimentoService,
    public userLoggedIn: UserLoggedInService) {
    this.confirmService.UnsetObservers()
    this.confirmService.confirmEmitter$.subscribe(c => { this.DeleteSolicitacao(c); this.SaveConfirmSolicitacao(c); })
    userLoggedIn.AllowedOperations()
  }

  ngOnInit() {
    this.Clear();
    this._solicitacoes = new Array<SolicitacaoProduto>();
    this.GetAllSolicitacao(0);
    this.solicitacaoService.LoadingPage();
  }

  OnChangeAll(data: ProdutoFornecedor) {
    data._status = true;
  }

  VerSolicitacao(solicitacao: SolicitacaoProduto): void {
    this.Clear();

    if (solicitacao.item_id > 0) {

      this.suprimentoService.get(solicitacao.item_id).subscribe(
        d => { this._produto = d; },
        e => { },
        () => {
          if (!this._produto.ProdutoFornecedor)
            this._produto.ProdutoFornecedor = new ProdutoFornecedor();
        }
      );
      this.SetListaUnidadeMedida();
      this.SetMask();
    }
    setTimeout(() => {
      this._solicitacao_selecionada = solicitacao;
      this.SetLog(this._solicitacao_selecionada);
      this.AtualizarSolicitacao(solicitacao);
    }, 10);

  }

  SetListaUnidadeMedida() {
    this.produtoService.GetAllUnitsMeasured().subscribe(
      data => { this._unidade_medida = data; },
      error => { },
      () => { }
    );
  }

  AtualizarSolicitacao(solicitacao: SolicitacaoProduto) {
    this._hidden = true;
    setTimeout(() => {
      this._solicitacao = solicitacao;
      this._hidden = false;
    }, 10);
  }

  DeleteSolicitacao(confirm: boolean) {
    if (this.confirmService._code != this._code)
      return;
    this.solicitacaoService.SetDeleteSolicitacao(this._solicitacao_selecionada).subscribe(
      d => { this.GetAllSolicitacao(0); },
      e => { },
      () => { }
    );
  }

  SaveConfirmSolicitacao(confirm: boolean): void {
    if (this.confirmService._code != this._code + "-nao-atende")
      return;
    this.SaveSolicitacao(StatusSolicitacaoProdutoEnum.Aberto);
  }

  SaveSolicitacao(status: StatusSolicitacaoProdutoEnum): void {
    this._solicitacao.status = status;

    if (status == StatusSolicitacaoProdutoEnum.Aberto) {
      this._solicitacao.item_id = 0;
      this._solicitacao.detalhe = null;
    }

    this.solicitacaoService.SetSolicitationProvider(this._solicitacao).subscribe(
      d => {
        if (StatusSolicitacaoProdutoEnum.Finalizado != status)
          this.GetAllSolicitacao(0);
      }
    );
  }

  OnChangeSearch() {
    this.Clear();
  }

  GetAllSolicitacao(filter: number, page: number = 1): void {
    this.Clear();
    this._buscar = "";
    this._filter = filter;
    this.solicitacaoService.GetAllUserLoggedIn(filter, page).subscribe(
      d => { this._solicitacoes = d; },
      e => { },
      () => {
        this._solicitacoes.forEach(c => {
          c._data_formatada = UseFullService.formatDateTime(new Date(c.data_criacao));
          c._status_descricao = UseFullService.OneToLowerCase(StatusSolicitacaoProdutoEnum[c.status]);

        });
        this.SetConfigProduto(page);
      }
    );
  }

  SetConfigProduto(page: number) {
    this._count_grid = this._solicitacoes.length;
    if (this._solicitacoes != null && this._solicitacoes.length > 0) {
      this._total_pesquisa = this._solicitacoes[0]._count_pesquisa;
      var count_page = UseFullService.CalcularPaginacao(100, this._total_pesquisa);
      this._paginas = Pagination.SetPage(page, count_page);
    }
  }

  GetPage(page: number): void {
    this.GetAllSolicitacao(this._filter, page);
  }

  SetLog(c: SolicitacaoProduto) {

    if (c.log != null && c.log != '') {
      var data = c.log.split('|');
      c._log = new Array<SolicitacaoProdutoLog>();

      var data_count = data.length - 1;
      data.forEach((l, x) => {
        if (l != "") {
          var log = JSON.parse(l) as SolicitacaoProdutoLog;
          log.produto = new InsumoDoFornecedor();

          if (log.item_id > 0) {
            this.suprimentoService.get(log.item_id).subscribe(
              d => { log.produto = d; },
              e => { },
              () => {
                c._log.push(log);
              }
            );
          } else
            c._log.push(log);

          if (x == data_count) {
            setTimeout(() => {
              this.OrdenarLog(c._log);
            }, 500);
          }
        }
      });
    }
  }

  OrdenarLog(log: SolicitacaoProdutoLog[]) {
    log.sort((a, b) => {
      var dateA = new Date(a.data).getTime();
      var dateB = new Date(b.data).getTime();
      return dateA > dateB ? 1 : -1;
    });
  }

  Clear(): void {
    this._solicitacao_selecionada = new SolicitacaoProduto();
    this._solicitacao = new SolicitacaoProduto();
    this._produto = new InsumoDoFornecedor();
    this._produto.ProdutoFornecedor = new ProdutoFornecedor();
  }


  SetMask() {
    setTimeout(() => {
      //$('.mask-money').mask('#.##0,00', { reverse: true }); 
      $(function () {
        $('[data-toggle="tooltip"]').tooltip()
      })
    }, 1000);
  }



  ExcluirSolicitacao(e, solicitacao: SolicitacaoProduto) {
    e.preventDefault();
    this.confirmService._code = this._code;
    this.confirmService._title = "Exclusão de Solitação";
    this.confirmService._message = "Deseja excluir a solicitação, de inclusão do produto: " + solicitacao.especificacao + "?";
    this.confirmService.Show();
  }

  SaveSolicitacaoNaoAtende() {
    this.confirmService._code = this._code + "-nao-atende";
    this.confirmService._title = "Reabrir Solitação";
    this.confirmService._message = "O chamado será reaberto, e você poderá editar as especificações " +
      "com maximo de detalhes do produto para que nossa sugestão seja acertiva." +
      "Caso confirme está ação, posteriormente faremos uma nova analise do seu pedido.";
    this.confirmService.Show();
  }

  SaveProductProvider(data: InsumoDoFornecedor) {
    if (!data.ProdutoFornecedor._status)
      return;
    data._loading = true;
    this.produtoService.SetProductProvider(data).subscribe(
      d => { },
      e => { setTimeout(() => { data._loading = false; }, 2000); },
      () => { data._loading = false; data._status = false; }
    );
  }

}