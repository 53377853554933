import { Component, OnInit, ComponentFactoryResolver, Output } from '@angular/core';
import { Empresa, EnumCategoriaEmpresa } from 'src/app/domain/empresa/empresa';
import { Estado } from 'src/app/domain/enum/estado';
import { Municipio } from 'src/app/domain/enum/municipio';
import { ViaCep } from 'src/app/service/usefull/viacep.service';
import { ContatoFinanceiro } from 'src/app/domain/empresa/contato-financeiro';
import { EmpresaFormComponent } from '../configuracao/empresa-form/empresa-form.component';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { GlobalVariable } from 'src/app/core/global-variable';

declare var $: any;
@Component({
  selector: 'app-empresa-faturante-form',
  templateUrl: './empresa-faturante-form.component.html',
  styleUrls: ['./empresa-faturante-form.component.css']
})
export class EmpresaFaturanteFormComponent extends EmpresaFormComponent implements OnInit {

  empresa_id = this.route.snapshot.params['id'];

  ngOnInit() {
    this._empresa = new Empresa();
    this._empresa.categoria = EnumCategoriaEmpresa.Faturante
    this._empresa.list_contatos_financeiro = new Array<ContatoFinanceiro>();
    this._empresa._files = new Array<File>();
    this._estados = new Array<Estado>();
    this._municipios = new Array<Municipio>();
    this._viacep = new ViaCep();
    this.GetEstado();
    this.getEmpresa(this.empresa_id);
    this.JqueryEnvet()
  }

  AdicionarContatoFinanceiro(){
    
  }

  getEmpresa(id: number) {
    this.empresaService.Get(id).subscribe(
      d => {
        if (d != null)
          this._empresa = d;
      },
      e => { },
      () => {
        if (this._empresa != null && this._empresa.id > 0) {
          this._empresa._cnpj = UseFullService.GetCnpjMasc(this._empresa.cnpj);
          var logo = this._empresa.cnpj + '/logo/miniatura/' + this._empresa.logo;
          if (this.userLoggedIn.img != logo) {
            var _currentUser = localStorage.getItem('currentUser');

            if (this.userLoggedIn.img)
              _currentUser = _currentUser.replace(this.userLoggedIn.img, logo);
            else
              _currentUser = _currentUser.replace('"img":""', '"img":"' + logo + '"');

            localStorage.setItem('currentUser', _currentUser)
            this.userLoggedIn.SetCurrentUser();
          }

          this._empresa._files = new Array<File>();
          this.MostrarArquivos();
          $("#image-logo").attr("src", GlobalVariable.BASE_API_URL + this._empresa.cnpj + '/logo/miniatura/' + this._empresa.logo);
          $("#image-logo").fadeIn();
          this.GetMunicipios(this._empresa.uf, false);
        }
        this.LoadContatoFinanceiro();
      }
    );
  }
  
  voltar() {
    this.router.navigateByUrl("gestao/faturamento/list");
  }

}