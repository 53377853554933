<!-- Modal -->
<div class="modal fade" id="pesquisa-insumo-modal" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Pesquisa Insumos</h4>
                <div class="ibox-tools">

                </div>
            </div>

            <div class="row caixa-pesquisa">

                <div class="col-sm-7">
                    <div class="input-group">
                        <input placeholder="Pesquisar" [(ngModel)]="_buscar" type="text" class="form-control form-control-sm">
                    </div>
                </div>
            </div>

            <div class="modal-max">


                <div class="table-responsive">
                    <table class="table table-striped table-hover table-sm">
                        <thead class="thead-dark">
                            <tr>
                                <th class="text-center">#</th>
                                <th>Insumo </th>
                                <th class="text-center">Categoria </th>
                            </tr>
                        </thead>
                        <tbody class="  " [hidden]="_carregando">
                            <tr *ngFor="let p of _produtos | filter : _buscar">
                                <td class="text-center">
                                    <div class="btn-group">
                                        <label class="btn btn-check-table
                                            btn-default {{p._selecionado ?
                                            'active': ''}}" (click)="ClickBond(p)">
                                            <span class="glyphicon-check-mark fa fa-check"></span>
                                        </label>
                                    </div>
                                </td>
                                <td>{{p.nome}}</td>
                                <td class="text-center">{{p._categoria}}</td>
                            </tr>

                        </tbody>
                    </table>



                    <div [hidden]="!_ha_produto" class="mensagem-produto
                          ">
                        Nenhum produto encontrado, para pesquisa selecionada.
                        <a href [routerLink]="['/suprimento/novo-insumo']">
                            Cadastrar um novo insumo!</a>
                    </div>

                    <div [hidden]="!_carregando" class="carregando-produtos
                          ">
                        <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                        </div>
                        carregando...
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary " data-dismiss="modal" *ngIf="_produto_selecionado.id" (click)="SelectItem()">OK</button>
            </div>
        </div>
    </div>
</div>