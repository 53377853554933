import { Component, OnInit } from '@angular/core';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { Cotacao } from 'src/app/domain/cotacao/cotacao';
import { Router } from '@angular/router';
import { ListOrcamento } from 'src/app/domain/orcamento/orcamento';
import { RepositoryService } from 'src/app/service/repository.service';
import { Empresa } from 'src/app/domain/empresa/empresa';
import { ObraCotacao } from 'src/app/domain/gestao/obra-cotacao';

@Component({
  selector: 'app-obra-cotacao-list',
  templateUrl: './obra-cotacao-list.component.html',
  styleUrls: ['./obra-cotacao-list.component.css']
})
export class ObraCotacaoListComponent implements OnInit {

  public code: string;
  obras = new Array<ObraCotacao>();

  navs = [
    { active: 'active', descricao: 'Todas' },
    { active: '', descricao: 'Faturante' },
    { active: '', descricao: 'Fiscalizadora' },
  ]

  constructor(
    public reposity: RepositoryService,
    public confirmService: AlertConfirmService,
    public userLoggedService: UserLoggedInService,
    public router: Router) {
    this.userLoggedService.SetCurrentUser();
  }

  ngOnInit() {
    this.userLoggedService.SetCurrentUser();
    this.GetAll();
  }

  GetAll() {
    this.reposity.path = "obra-cotacao/all"
    this.reposity.getAll().subscribe(
      d => {
        if (d && d.length > 0)
          this.obras = d;
      },
      e => { },
      () => {
        let x = 1;
        this.obras.forEach(c => { c._index = x++; });
      }
    );
  }

  edit(obraCotacao: ObraCotacao) {
    this.router.navigateByUrl("/gestao/obra/form/" + obraCotacao.id);
  }

  ver(obraCotacao: ObraCotacao) {
    this.router.navigateByUrl("/gestao/obra/relatorio/" + obraCotacao.id);
  }

  novaObra() {
    this.router.navigateByUrl("/gestao/obra/form");
  }
  
  verProduto(obraCotacao: ObraCotacao) {
    this.router.navigateByUrl("/auxiliar/relacao/produto/obra/form/" + obraCotacao.id);
  }
}