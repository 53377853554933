export class RelacaoProdutoNivel{
    public id: number;
    // relação do insumo do fornecedor "InsumoDoFornecedor" com niveis
    public produto_id: number;
    public nivel_id: number;
    public nivel_item_id: number;
    public nivel_virtual_id: number;

    public static NovaRelacaoProdutoNivel(nivel_id, nivel_item_id, nivel_virtual_id): RelacaoProdutoNivel{
        var relacaoProdutoNivel = new RelacaoProdutoNivel();
        relacaoProdutoNivel.nivel_id = nivel_id;
        relacaoProdutoNivel.nivel_item_id = nivel_item_id;
        relacaoProdutoNivel.nivel_virtual_id = nivel_virtual_id;
        return relacaoProdutoNivel;
    }
}