<div class="col-lg-12 wrapper-top-content">
    <div class="ibox">
        <div class="ibox-title" style="padding-right:20px;">
            <h5>Empresas</h5>
            <div style="float: right; margin-top:-8px;">
                <button class="btn btn-primary" (click)="novaEmpresa()">Nova Empresa</button>
            </div>
        </div>
        <div class="ibox-content">
            <div class="table-responsive min-height-grid-insumo div-table-fixed
                margin-top-15">
                <table class="table table-striped">
                    <thead class="thead-fixed">
                        <tr>
                            <th class="th-sequence"></th>
                            <th>Razão Social</th>
                            <th class="th-nome">Nome</th>
                            <th class="text-center th-data">CNPJ</th>
                            <th class="text-center th-action"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let empresa of empresas">
                            <td class="text-center th-sequence">{{empresa._index}}</td>
                            <td><label class="btn-link cursor-pointer" (click)="Ver(empresa)">{{empresa.razao_social}}</label></td>
                            <td> &nbsp;&nbsp;&nbsp;{{empresa.nome_fantasia}}</td>
                            <td class="text-center th-data">{{empresa.cnpj}}</td>
                            <td class="text-center th-action-anexo">
                                <i class="fa fa-search btn-link cursor-pointer" (click)="Ver(empresa)">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="empresas.length <= 0">Nenhuma empresa encontrada!</div>
        </div>
    </div>
</div>