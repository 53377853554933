import { Component, OnInit } from '@angular/core';
import { Dashboard, FaturamentoDashboard } from 'src/app/domain/auxiliar/dashboard';
import { DashboardService } from 'src/app/service/auxiliar/dashboard.service';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { Chart } from 'angular-highcharts';
import { RepositoryService } from 'src/app/service/repository.service';
import { chart } from 'highcharts';
declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard-comprador.component.html',
  styleUrls: ['./dashboard-comprador.component.css']
})
export class DashboardCompradorComponent implements OnInit {
  public model: any
  _orcamentos = new Array<any>()
  _transacoes = new Array<any>()

  _dashboard = new Dashboard();
  _inicio = "";
  _fim = "";
  _categorias = [];
  _data = [];
  orcamentosChat: Chart;
  compraEntregaChat: Chart;
  _faturamento_dashboard = new Array<FaturamentoDashboard>();

  _dataResumo = []
  date: any;

  constructor(private dashboardService: DashboardService, private _context: RepositoryService) { }

  ngOnInit() {
    var date = new Date();
    this._fim = UseFullService.formatDateUsForm(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    this._inicio = UseFullService.formatDateUsForm(new Date(date.getFullYear(), 1, 1));
    this.GetResumo();
    this.GetUltimoOrcamentoCompra();
  }

  GetResumo() {
    this.dashboardService.Get(this._inicio, this._fim).subscribe(
      d => {
        this._dashboard = d;
        Dashboard.Formatar(this._dashboard);
        this.startCharCompraEntrega()
      }
    );
    this.dashboardService.GetAll(this._inicio, this._fim).subscribe(
      d => {
        this._faturamento_dashboard = d;
      },
      e => { },
      () => { this.init(); }
    );
  }

  GetUltimoOrcamentoCompra() {
    this._context.path = 'dashboard/ultimo/orcamento'
    this._context.get('0&inicio=' + this._inicio + '&fim=' + this._fim).subscribe(
      d => {
        if (d && d.length > 0) {
          this._orcamentos = d
          this._orcamentos.forEach(c => {
            c.sub_total = UseFullService.ToFixedReal(c.sub_total)
            c.data_criacao = UseFullService.formatDate(new Date(c.data_criacao))
          })
        }
      }
    );

    this._context.path = 'dashboard/ultimo/compra'
    this._context.get('0&inicio=' + this._inicio + '&fim=' + this._fim).subscribe(
      d => {
        if (d && d.length > 0) {
          this._transacoes = d
          this._transacoes.forEach(c => {
            c.valor = UseFullService.ToFixedReal(c.valor)
            c.data_criacao = UseFullService.formatDate(new Date(c.data_criacao))
          })
        }
      });
  }

  init() {
    this.SetCategorias();
    setTimeout(() => { this.StartChat() }, 50);
    setTimeout(() => { $("[class='highcharts-credits']").remove(); }, 150);

    $(".chart-faturamento").on("mousemove", function () {
      $(".titulo-grafico").text(parseInt($('.valor-faturado').text()) > 1 ? "Orçamentos" : "Orçamento");
      $(".valor-faturado").text(UseFullService.ToFixed(UseFullService.ReplaceFloat($('.valor-faturado').text())));
    });
    var _this = this;
    $('#atualizarChat').click(() => { _this.StartChat() });
  }

  StartChat() {
    let orcamentosChat = new Chart(this.opcao as any);
    this.orcamentosChat = orcamentosChat;
  }

  startCharCompraEntrega() {
    const compraEntregaChat = new Chart(this.opcaoCompraEntrega() as any)
    this.compraEntregaChat = compraEntregaChat
  }

  SetCategorias() {
    this._faturamento_dashboard.forEach(c => {
      this._categorias.push(UseFullService.formatDateDiaMes(new Date(c.data)));
      this._data.push(c.total_diario);
    });
  }

  public opcao = {
    chart: {
      type: 'line'
    },
    title: {
      text: ''// titulo orcamento
    },
    subtitle: {
      //text: 'Source: WorldClimate.com'
    },
    xAxis: {
      categories: this._categorias
    },
    yAxis: {
      title: {
        text: 'Quantidade de orçamentos'
      }
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true
        },
        enableMouseTracking: true
      }
    },
    tooltip: {
      headerFormat: "<b  class='titulo-grafico'>{series.name}</b><br/>",
      pointFormat: "<span class='valor-faturado'>{point.y}</span>"
    },
    series: [{
      name: 'Orçamentos',
      data: this._data
    }]
  };


  opcaoCompraEntrega = () => {
    return {
      chart: {
        type: 'pie'
      },
      title: {
        text: ''// titulo orcamento
      },
      subtitle: {
        //text: 'Source: WorldClimate.com'
      },
      xAxis: {
        categories: ['a', 'b', 'c']
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            style: {
              color: 'black'
              //color: (Highcharts.theme && Highcharts.theme.contrastTextColor)|| 'black'
            }
          },
          //showInLegend: true
        }
      },
      tooltip: {
        headerFormat: "<b  class='titulo-grafico-chat'>{series.name}</b><br/>",
        pointFormat: "<span class='valores-chat'>{point.name}: <b>{point.percentage:.1f}%</b></span>"
      },
      series: [{
        type: 'pie',
        name: 'Resumo',
        data: [
          ['Cotações', this._dashboard.pedidos_orcamento],
          {
            name: 'Compras',
            y: this._dashboard.compras_efetuadas,
            sliced: true,
            selected: true
          },
          //['Entregas', this._dashboard.entregas_recebidas]
        ]
      }]
    }
  }


}
