<div class="col-lg-12 row">


    <div class="col-lg-6 wrapper-top-content padding-right-none ">
        <div class="ibox-title">
            <h5>Colaboradores Cadastrados</h5>
        </div>
        <div class="ibox-content    ">

            <div class="row bottom-10">

                <div class="col-sm-6 m-b-xs">
                    <div class="input-group">
                        <input placeholder="Pesquisar" [(ngModel)]="_buscar" type="text" class="form-control form-control-sm">
                        <span class="input-group-append">
                            <button type="button" class="btn btn-sm btn-primary">Buscar</button>
                        </span>
                    </div>
                </div>

                <div class="text-right text-right col-sm-6 m-b-xs">
                    <div class="btn-group btn-group-toggle" data-toggle="buttons">
                        <label class="btn btn btn-white active" (click)="GetAllUser(0)">
                  <input type="radio" name="options" autocomplete="off" checked="true"> Aguardando
                </label>
                        <label class="btn btn-white" (click)="GetAllUser(1)">
                  <input type="radio" name="options" autocomplete="off"> Ativo
                </label>
                        <label class="btn btn-white" (click)="GetAllUser(2)">
                      <input type="radio" name="options" autocomplete="off"> Inativo
                    </label>
                        <label class="btn btn-white" (click)="GetAllUser(3)">
                  <input type="radio" name="options" autocomplete="off"> Recusado
                </label>
                    </div>
                </div>


            </div>


            <div class="table-responsive">
                <table class="table table-striped table-hover">
                    <thead class="thead-dark">
                        <tr>
                            <th>CNPJ</th>
                            <th class="text-center width-150">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let usuario of usuarios | filter:_buscar" (click)="SelectUser(usuario)" class="{{usuario_selecionado.id == usuario.id ? 'table-info' : ''}}">
                            <td>{{usuario.nome}}</td>
                            <td class="text-center">
                                <a href (click)="clickAlterStatus($event, usuario, 1)" *ngIf="_busca_status == 0">Validar</a> <span *ngIf="_busca_status == 0"> | </span>
                                <a href (click)="clickAlterStatus($event, usuario, 3)" *ngIf="_busca_status == 0">Recusar</a>
                                <a href (click)="clickAlterStatus($event, usuario, 2)" *ngIf="_busca_status == 1">Inativar</a>
                                <a href (click)="clickAlterStatus($event, usuario, 1)" *ngIf="_busca_status == 2 || _busca_status == 3">Ativar</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <app-minha-conta class="col-lg-6 row   " [_usuario]="usuario_selecionado" [hidden]="!(usuario_selecionado.id > 0)"></app-minha-conta>

</div>