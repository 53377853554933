<div class="col-lg-12 wrapper-top-content">
    <div class="ibox-title" style="padding-right:20px;">
        <h5>Usuários</h5>
        <div style="float: right;">
            <input placeholder="Pesquisar" [(ngModel)]="_buscar" type="text" class="form-control form-control-sm input-seach">
        </div>
    </div>

    <div class="ibox-content form-categoria-nivel">
        <div class="row">

            <div class="col-sm-12">
                <div class="table-responsive min-height-grid-insumo
                    div-table-fixed margin-top-15">
                    <!-- table-striped table-hover table-bordered -->
                    <table class="table table-striped">
                        <thead class="thead-fixed">
                            <tr>
                                <th class="th-sequence"></th>
                                <th class="th-data text-center">Nome</th>
                                <th class="th-nome text-center">email</th>
                                <th class="text-center th-obra">telefone</th>
                                <th class="text-center th-codigo">cpf</th>
                                <th class="text-center th-data">cnpj</th>
                                <th class="text-center th-data">data</th>
                                <th class="text-center th-data">tipo</th>
                                <th class="text-center th-data"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="" *ngFor="let usuario of _usuarios |
                                filter:_buscar">
                                <td class="text-center th-sequence">{{usuario._index}}</td>
                                <td class="th-obra">{{usuario.nome}}</td>
                                <td class="th-obra">{{usuario.email}}</td>
                                <td class="th-obra">{{usuario.telefone}}</td>
                                <td class="th-obra">{{usuario.cpf}}</td>
                                <td class="th-obra">{{usuario.cnpj}}</td>
                                <td class="text-center th-data">{{usuario._data_criacao}}</td>
                                <td class="text-center th-data">{{!(usuario.tipo_pessoa) ? 'Juridica': 'Física'}}</td>
                                <td class="text-center th-action-anexo">
                                    <i class="fa fa-search btn-link
                                        cursor-pointer" (click)="Ver(usuario)">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modal-crm-cliente" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable
        modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Informações do Cliente</h4>

                <div class="ibox-tools">
                    <div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>

            </div>

            <div class="modal-body modal-confirm" style="padding: 1rem;">

                <div class="row">

                    <div class="col-sm-5">
                        <b>Nome:</b>&nbsp;&nbsp;{{_usuario.nome}}<br />
                        <b>Email:</b>&nbsp;&nbsp;{{_usuario.email}}<br />
                        <b>Telefone:</b>&nbsp;&nbsp;{{_usuario.telefone}}<br />
                    </div>

                    <div class="col-sm-7">
                        <b>Nome:</b>{{_usuario.cpf}}<br />
                        <b>Data:</b>{{_usuario._data_criacao}}<br />
                        <b>Pessoa:</b>{{!(_usuario.tipo_pessoa) ? 'Juridica / '+_usuario.cnpj : 'Física'}}<br />
                    </div>

                    <div class="col-sm-12">
                        <hr />
                    </div>
                    <div class="col-sm-5 m-b-xs">
                        <div class="form-group">
                            <label>Observação</label>
                            <div class="row">
                                <div class="col-sm-12">
                                    <textarea class="form-control" placeholder="Digita à observacao" rows="5" [(ngModel)]="_atendimentoUsuario.observacao"></textarea>
                                    <small id="emailHelp" class="form-text
                                        text-muted" required>Digite observação
                                        do atendimento.</small>
                                </div>
                                <div class="col-sm-10 margin-top-15">
                                    <button *ngIf="!_atendimentoUsuario.id" class="btn btn-primary" (click)="InsertOrUpdate()">Adicionar</button>
                                    <button *ngIf="_atendimentoUsuario.id" class="btn btn-default" (click)="Reset()">Cancelar</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <button *ngIf="_atendimentoUsuario.id" class="btn btn-primary margin-left-10" (click)="InsertOrUpdate()">Editar</button>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-sm-7">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Histórico de Atendimentos</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let atendimento of _listAtendimentoUsuario">
                                    <td>
                                        <a class="padding-top-12" (click)="ActionEditar(atendimento)">
                                            <label class="text-primary
                                                float-right
                                                delete-categoria-nivel
                                                cursor-pointer" (click)="ConfirmExcluir(atendimento)"><i
                                                    class="fa fa-trash-alt"></i></label>
                                            <label class="text-primary
                                                float-right cursor-pointer" (click)="ActionEditar(atendimento)"><i
                                                    class="fa fa-edit"></i>&nbsp;&nbsp;</label>

                                            <b>Data:</b>&nbsp;&nbsp; {{atendimento._data}}<br />
                                            <b>Observacao:</b>&nbsp;&nbsp; {{atendimento.observacao}}
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>