import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './core/page/home/home.component';
import { NotFoundComponent } from './core/page/not-found/not-found.component';
import { UserComponent } from './modules/user/user.component';
import { InsumoComponent } from './modules/suprimento/insumo/insumo.component';
import { LoginComponent } from './modules/user/login/login.component';
import { AuthGuard } from './core/Interceptor/auth-guard';
import { NewCotacaoComponent } from './modules/cotacao/new-cotacao/new-cotacao.component';
import { MinhaContaComponent } from './modules/user/minha-conta/minha-conta.component';
import { GerenciamentoAcessoComponent } from './modules/user/gerenciamento-acesso/gerenciamento-acesso.component';
import { SolicitacaoComponent } from './modules/suprimento/solicitacao/solicitacao.component';
import { SolicitacaoAdminComponent } from './modules/suprimento/solicitacao-admin/solicitacao-admin.component';
import { LoginSiteComponent } from './modules/user/login-site/login-site.component';
import { ConfiguracaoComponent } from './modules/configuracao/configuracao/configuracao.component';
import { ConfiguracaoAdminComponent } from './modules/configuracao/configuracao-admin/configuracao-admin.component';
import { TemporaryMessageComponent } from './core/page/temporary-message/temporary-message.component';
import { CategoriaVirtualComponent } from './modules/configuracao/configuracao-admin/categoria-virtual/categoria-virtual.component';
import { CategoriaNivelComponent } from './modules/configuracao/configuracao-admin/categoria-nivel/categoria-nivel.component';
import { VinculoNivelComponent } from './modules/configuracao/configuracao-admin/vinculo-nivel/vinculo-nivel.component';
import { FuncaoSistemaComponent } from './modules/configuracao/configuracao-admin/funcao-sistema/funcao-sistema.component';
import { PerfilUsuarioComponent } from './modules/configuracao/configuracao-admin/perfil-usuario/perfil-usuario.component';
import { InsumoCompradorComponent } from './modules/suprimento/comprador/insumo-comprador/insumo-comprador.component';
import { CotacaoViewComponent } from './modules/cotacao/cotacao-view/cotacao-view.component';
import { PrazoPagamento } from './domain/auxiliar/prazo-pagamento';
import { PrazoPagamentoComponent } from './modules/auxiliar/prazo-pagamento/prazo-pagamento.component';
import { CotacaoListComponent } from './modules/cotacao/cotacao-list/cotacao-list.component';
import { OrcamentoConfiguracaoComponent } from './modules/orcamento/fornecedor/orcamento-configuracao/orcamento-configuracao.component';
import { OrcamentoSolicitacaoComponent } from './modules/orcamento/fornecedor/orcamento-solicitacao/orcamento-solicitacao.component';
import { OrcamentoEditComponent } from './modules/orcamento/fornecedor/orcamento-edit/orcamento-edit.component';
import { OrcamentoFornecedorViewComponent } from './modules/orcamento/fornecedor/orcamento-fornecedor-view/orcamento-fornecedor-view.component';
import { OrcamentoCompradorViewComponent } from './modules/orcamento/comprador/orcamento-comprador-view/orcamento-comprador-view.component';
import { OrcamentoCompradorCotacaoListComponent } from './modules/orcamento/comprador/orcamento-comprador-cotacao-list/orcamento-comprador-cotacao-list.component';
import { OrcamentoCompradorGridComponent } from './modules/orcamento/comprador/orcamento-comprador-grid/orcamento-comprador-grid.component';
import { OrcamentoSolicitacaoHistoricoComponent } from './modules/orcamento/fornecedor/orcamento-solicitacao/orcamento-solicitacao-historico.component';
import { OrcamentoFornecedorListComponent } from './modules/orcamento/fornecedor/orcamento-fornecedor-list/orcamento-fornecedor-list.component';
import { OrcamentoFornecedorListEnviadosComponent } from './modules/orcamento/fornecedor/orcamento-fornecedor-list/orcamento-fornecedor-list-enviados.component';
import { VendaListComponent } from './modules/transacao/fornecedor/venda-list/venda-list.component';
import { CompraListComponent } from './modules/transacao/comprador/compra-list/compra-list.component';
import { CompraViewComponent } from './modules/transacao/comprador/compra-view/compra-view.component';
import { VendaViewComponent } from './modules/transacao/fornecedor/venda-view/venda-view.component';
import { EntregaListComponent } from './modules/entrega/entrega-list/entrega-list.component';
import { DashboardComponent } from './modules/dashboard/dashboard/dashboard.component';
import { DashboardService } from './service/auxiliar/dashboard.service';
import { LoginFornecedorEmailComponent } from './modules/user/login/login-forncedor-email/login-fornecedor-email.component';
import { OrcamentoEmailEditComponent } from './modules/orcamento/fornecedor/orcamento-email-edit/orcamento-email-edit.component';
import { OrcamentoEmailViewComponent } from './modules/orcamento/fornecedor/orcamento-email-view/orcamento-email-view.component';
import { DadosClientComponent } from './modules/configuracao/dados-client/dados-client.component';
import { EntregaCompradorComponent } from './modules/entrega/entrega-list/entrega-comprador.component';
import { OrcamentoCompradorSugestaoViewComponent } from './modules/orcamento/comprador/orcamento-comprador-sugestao-view/orcamento-comprador-sugestao-view.component';
import { OrcamentoFornecedorDisputaListComponent } from './modules/orcamento/fornecedor/orcamento-fornecedor-disputa-list/orcamento-fornecedor-disputa-list.component';
import { OrcamentoEditSugeridoComponent } from './modules/orcamento/fornecedor/orcamento-edit-sugerido/orcamento-edit-sugerido.component';
import { OrcamentoViewSugeridoComponent } from './modules/orcamento/fornecedor/orcamento-edit-sugerido/orcamento-view-sugerido.component';
import { OrcamentoCompradorCotacaoHistoricoListComponent } from './modules/orcamento/comprador/orcamento-comprador-cotacao-list/orcamento-comprador-cotacao-historico-list.component';
import { EmpresaFormComponent } from './modules/configuracao/empresa-form/empresa-form.component';
import { CadastreSeComponent } from './core/page/cadastre-se/cadastre-se.component';
import { NotPermissionComponent } from './core/page/not-permission/not-permission.component';
import { DashboardCompradorComponent } from './modules/dashboard/dashboard-comprador/dashboard-comprador.component';
import { ExtratoFinanceiroForncedorComponent } from './modules/gerenciamento/fornecedor/extrato-financeiro/extrato-financeiro-fornecedor.component';
import { CotacoesComponent } from './modules/gerenciamento/comprador/cotacoes/cotacoes.component';
import { GerenciamentoCotacaoViewComponent } from './modules/gerenciamento/comprador/cotacoes/cotacao-view/gerenciamento-cotacao-view.component';
import { ViewExtratoFinanceiroComponent } from './modules/gerenciamento/fornecedor/view-extrato-financeiro/view-extrato-financeiro.component';
import { SolicitacaoSistemaComponent } from './modules/gerenciamento/fornecedor/solicitacao-sistema/solicitacao-sistema.component';
import { GerenciarUsuariosComponent } from './modules/gerenciamento/comprador/gerenciar-usuarios/gerenciar-usuarios.component';
import { EmpresaFaturanteFormComponent } from './modules/gestao-cotacao/empresa-faturante-form.component';
import { EmpresaListComponent } from './modules/gestao-cotacao/empresa-list.component';
import { ObraCotacaoListComponent } from './modules/gestao-cotacao/obra/obra-cotacao-list.component';
import { ObraCotacaoFormComponent } from './modules/gestao-cotacao/obra/obra-cotacao-form.component';
import { ObraCotacaoViewComponent } from './modules/gestao-cotacao/obra/obra-cotacao-view.component';
import { ObraCotacaoRelatorioComponent } from './modules/gestao-cotacao/obra/obra-cotacao-relatorio.component';
import { LoginAuditorComponent } from './modules/user/login/login-auditor.component';
import { ObraCotacaoAuditorRelatorioComponent } from './modules/gestao-cotacao/obra/auditor/obra-cotacao-auditor-relatorio.component';
import { ObraCotacaoAuditorListComponent } from './modules/gestao-cotacao/obra/auditor/obra-cotacao-auditor-list.component';
import { AuditorCompraListComponent } from './modules/gestao-cotacao/compras/auditor-compra-list.component';
import { OrcamentoAuditorViewComponent } from './modules/gestao-cotacao/compras/orcamento-auditor-view.component';
import { ProdutoListComponent } from './modules/auxiliar/produto/produto-list.component';
import { ProdutoFormComponent } from './modules/auxiliar/produto/produto-form.component';
import { CategoriaComponent } from './modules/auxiliar/categoria/categoria.component';
import { CadastroNivelComponent } from './modules/auxiliar/cadastro-nivel/cadastro-nivel.component';
import { CadastroItemNivelComponent } from './modules/auxiliar/cadastro-item-nivel/cadastro-item-nivel.component';
import { CadastroCorComponent } from './modules/auxiliar/cadastro-cor/cadastro-cor.component';
import { CadastroMarcaComponent } from './modules/auxiliar/cadastro-marca/cadastro-marca.component';
import { RelacaoObraProdutoMarcaListComponent } from './modules/auxiliar/relacao-obra-produto-marca/relacao-obra-produto-marca-list.component';
import { RelacaoObraListComponent } from './modules/auxiliar/relacao-obra-produto-marca/relacao-obra-list.component';
import { OrcamentoCompradorViewListComponent } from './modules/orcamento/comprador/orcamento-comprador-grid/orcamento-comprador-view-list.component';

const appRoutes: Routes = [

  //redirect
  { path: '', redirectTo: 'home', pathMatch: 'full' },

  //inicio
  {
    path: 'inicio', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: '', component: TemporaryMessageComponent, canActivate: [AuthGuard] }]
  },

  //cotacao
  {
    path: 'cotacao', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'nova-cotacao', component: NewCotacaoComponent, canActivate: [AuthGuard] },
      { path: 'edit-cotacao/:id', component: NewCotacaoComponent, canActivate: [AuthGuard] },
      { path: 'produto/:id', component: InsumoCompradorComponent, canActivate: [AuthGuard] },
      { path: 'view/:id', component: CotacaoViewComponent, canActivate: [AuthGuard] },
      { path: 'historico', component: CotacaoListComponent, canActivate: [AuthGuard] },
      { path: 'abertas', component: OrcamentoSolicitacaoComponent, canActivate: [AuthGuard] },
      { path: 'historico-fornecedor', component: OrcamentoSolicitacaoHistoricoComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent }]
  },

  //suprimentos
  {
    path: 'suprimento', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'insumo', component: InsumoComponent, canActivate: [AuthGuard] },
      { path: 'solicitacao', component: SolicitacaoComponent, canActivate: [AuthGuard] },
      { path: 'gerenciar-solicitacoes', component: SolicitacaoAdminComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent }]
  },

  //Orçamentos

  /*

/cotacao/abertas:
/orcamentos/edit/:
/orcamentos/sugerido/edit/:
/orcamentos/sugerido/view/:
/cotacao/historico-fornecedor:
/orcamentos/view/:
/orcamentos/enviados-manual:
/orcamentos/disputa:
/orcamentos/fornecedor/historico:
/cotacao/historico-fornecedor
  */
  {
    path: 'orcamentos', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'enviados-manual', component: OrcamentoFornecedorListEnviadosComponent, canActivate: [AuthGuard] },
      { path: 'enviados-automatico', component: TemporaryMessageComponent, canActivate: [AuthGuard] },
      { path: 'configuracoes', component: OrcamentoConfiguracaoComponent, canActivate: [AuthGuard] },
      { path: 'edit/:id', component: OrcamentoEditComponent, canActivate: [AuthGuard] },
      { path: 'email/edit', component: OrcamentoEmailEditComponent, canActivate: [AuthGuard] },
      { path: 'sugerido/edit/:id', component: OrcamentoEditSugeridoComponent, canActivate: [AuthGuard] },
      { path: 'sugerido/view/:id', component: OrcamentoViewSugeridoComponent, canActivate: [AuthGuard] },
      { path: 'email/view', component: OrcamentoEmailViewComponent, canActivate: [AuthGuard] },
      { path: 'view/:id', component: OrcamentoFornecedorViewComponent, canActivate: [AuthGuard] },
      { path: 'cotacao-list', component: OrcamentoCompradorCotacaoListComponent, canActivate: [AuthGuard] },
      { path: 'cotacao-historico-list', component: OrcamentoCompradorCotacaoHistoricoListComponent, canActivate: [AuthGuard] },
      { path: 'cotacao-view/:id', component: OrcamentoCompradorViewListComponent, canActivate: [AuthGuard] },
      { path: 'cotacao-view-list/:id', component: OrcamentoCompradorGridComponent, canActivate: [AuthGuard] },
      { path: 'comprador-view/:id/:frete', component: OrcamentoCompradorViewComponent, canActivate: [AuthGuard] },
      { path: 'disputa', component: OrcamentoFornecedorDisputaListComponent, canActivate: [AuthGuard] },
      { path: 'comprador-sugestao-view/:id/:frete', component: OrcamentoCompradorSugestaoViewComponent, canActivate: [AuthGuard] },
      { path: 'fornecedor/historico', component: OrcamentoFornecedorListComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent }]
  },
  //Vendas
  {
    path: 'vendas', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'analise', component: VendaListComponent, canActivate: [AuthGuard] },
      { path: 'realizadas', component: VendaListComponent, canActivate: [AuthGuard] },
      { path: 'recusadas', component: VendaListComponent, canActivate: [AuthGuard] },
      { path: 'view/:id', component: VendaViewComponent, canActivate: [AuthGuard] },
      { path: 'view-link/:id', component: VendaViewComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent }]
  },
  //compras
  {
    path: 'compras', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'analise', component: CompraListComponent, canActivate: [AuthGuard] },
      { path: 'realizadas', component: CompraListComponent, canActivate: [AuthGuard] },
      { path: 'recusadas', component: CompraListComponent, canActivate: [AuthGuard] },
      { path: 'view/:id', component: CompraViewComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent }]
  },

  //Entregas
  {
    path: 'entregas', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'aguardando', component: EntregaCompradorComponent, canActivate: [AuthGuard] },
      { path: 'aguardando/:data', component: EntregaCompradorComponent, canActivate: [AuthGuard] },
      { path: 'recebidas', component: EntregaCompradorComponent, canActivate: [AuthGuard] },
      { path: 'realizadas', component: EntregaListComponent, canActivate: [AuthGuard] },
      { path: 'pendentes', component: EntregaListComponent, canActivate: [AuthGuard] },
      { path: 'pendentes/:data', component: EntregaListComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent }]
  },

  //pagina inicial
  {
    path: 'home', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: '', component: DashboardComponent },
      { path: 'not-permission', component: NotPermissionComponent },
      { path: '**', component: NotFoundComponent }
    ]
  },
  //pagina configuração
  {
    path: 'configuracao', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: '', component: EmpresaFormComponent },
      { path: 'user', component: DadosClientComponent },
      { path: '**', component: NotFoundComponent }
    ]
  },
  //pagina configuração
  {
    path: 'configuracao-adm', component: HomeComponent, canActivate: [AuthGuard], children: [
      {
        path: 'admin', component: ConfiguracaoAdminComponent, canActivate: [AuthGuard], children: [
          { path: 'categoria-virtual', component: CategoriaVirtualComponent },
          { path: 'categoria-nivel', component: CategoriaNivelComponent },
          { path: 'vincula-niveis', component: VinculoNivelComponent },
          { path: 'funcao-sistema', component: FuncaoSistemaComponent },
          { path: 'perfil-usuario', component: PerfilUsuarioComponent },
          { path: 'prazo-pagamento', component: PrazoPagamentoComponent },
          { path: 'nivel-item/:categoria_id/:nivel_id/:produto_id', component: CadastroItemNivelComponent }
        ]
      },
      { path: '', component: ConfiguracaoAdminComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent }
    ]
  },
  //pagina usuario
  {
    path: 'user', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'gerenciar-usuario', component: UserComponent, canActivate: [AuthGuard] },
      { path: 'gerenciar-usuario/:email', component: UserComponent, canActivate: [AuthGuard] },
      { path: 'gerenciar-acesso', component: GerenciamentoAcessoComponent, canActivate: [AuthGuard] },
      { path: 'minha-conta', component: MinhaContaComponent, canActivate: [AuthGuard] },
      { path: '', component: MinhaContaComponent }]
  },

  //pagina usuario
  {
    path: 'dashboard', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'comprador', component: DashboardCompradorComponent, canActivate: [AuthGuard] },
      { path: '**', component: DashboardComponent, canActivate: [AuthGuard] }
    ]
  },

  //pagina usuario
  {
    path: 'gerenciamento', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'extrato/fornecedores', component: ExtratoFinanceiroForncedorComponent, canActivate: [AuthGuard] },
      { path: 'extrato/fornecedores/view/:cnpj', component: ViewExtratoFinanceiroComponent, canActivate: [AuthGuard] },
      { path: 'comprador/cotacaoes', component: CotacoesComponent, canActivate: [AuthGuard] },
      { path: 'comprador/cotacao/view/:id', component: GerenciamentoCotacaoViewComponent, canActivate: [AuthGuard] },
      { path: 'comprador/cotacao/list/orcamentos/:id', component: SolicitacaoSistemaComponent, canActivate: [AuthGuard] },
      { path: 'comprador/usuarios', component: GerenciarUsuariosComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent }
    ]
  },

  //faturamento fiscalizado
  {
    path: 'gestao', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'faturamento/list', component: EmpresaListComponent, canActivate: [AuthGuard] },
      { path: 'faturamento/form/:id', component: EmpresaFaturanteFormComponent, canActivate: [AuthGuard] },
      { path: 'faturamento/form', component: EmpresaFaturanteFormComponent, canActivate: [AuthGuard] },
      { path: 'obra/list', component: ObraCotacaoListComponent, canActivate: [AuthGuard] },
      { path: 'obra/form/:id', component: ObraCotacaoFormComponent, canActivate: [AuthGuard] },
      { path: 'obra/view/:id', component: ObraCotacaoViewComponent, canActivate: [AuthGuard] },
      { path: 'obra/relatorio/:id', component: ObraCotacaoRelatorioComponent, canActivate: [AuthGuard] },
      { path: 'obra/form', component: ObraCotacaoFormComponent, canActivate: [AuthGuard] },
      { path: '**', component: DashboardComponent, canActivate: [AuthGuard] }
    ]
  },
  //auditor
  {
    path: 'auditor', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'obra/list', component: ObraCotacaoAuditorListComponent },
      { path: 'obra/relatorio/:id', component: ObraCotacaoAuditorRelatorioComponent },
      { path: 'compras/list', component: AuditorCompraListComponent },
      { path: 'compras/orcamento/:id', component: OrcamentoAuditorViewComponent }
      
    ]
  },
  //auxiliar
  {
    path: 'auxiliar', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'insumo/list', component: ProdutoListComponent },
      { path: 'insumo/form', component: ProdutoFormComponent },
      { path: 'insumo/form/:id', component: ProdutoFormComponent },   
      { path: 'categoria/form', component: CategoriaComponent },   
      { path: 'nivel/form', component: CadastroNivelComponent },   
      { path: 'marca/form', component: CadastroMarcaComponent },   
      { path: 'cor/form', component: CadastroCorComponent }, 
      { path: 'relacao/produto/obra/form/:id', component: RelacaoObraProdutoMarcaListComponent },
      { path: 'relacao/produto/obra/list', component: RelacaoObraListComponent }      
    ]
  },

  //all
  { path: 'login', component: LoginComponent },
  { path: 'login/email/:id', component: LoginFornecedorEmailComponent },
  { path: 'login/token/:id', component: LoginAuditorComponent },
  { path: 'login/compra/token/:id', component: LoginAuditorComponent },
  { path: 'login-site', component: LoginSiteComponent },
  { path: 'cadastre-se', component: HomeComponent, canActivate: [AuthGuard], children: [{ path: '', component: CadastreSeComponent }] },
  //redirect not found
  {
    path: '**',
    //component: NotFoundComponent
    redirectTo: 'home/not-found',
    pathMatch: 'full'
  }
];

export const AppRoutingProviders: any[] = [ ];

export const AppRoutingModule = RouterModule.forRoot(appRoutes, { useHash: true });