<div class="col-lg-12 wrapper-top-content">
    <div class="ibox">
        <div class="ibox-title" style="padding-right:20px;">
            <h5>Orçamento:&nbsp;&nbsp; {{_orcamento.codigo}} </h5>
            <div class="text-primary" style="float: right;"><b class="text-dark">Status: </b>&nbsp;&nbsp;{{_orcamento.status == 1 ? 'Aguardando Aprovação' : 'Finalizado'}} </div>
        </div>
        <div class="ibox-content">

            <app-cabecalho-cotacao></app-cabecalho-cotacao>

            <div class="table-responsive min-height-grid-insumo
                div-table-fixed margin-top-15" *ngIf="_carregamento_concluido">
                <!-- table-striped table-hover table-bordered -->
                <table class="table table-striped table-hover table-bordered" style="border: 1px solid #EBEBEB;">
                    <thead class="thead-fixed">
                        <tr>
                            <th class="th-sequece-produto" rowspan="2">Item</th>
                            <th class="th-nome-produto" rowspan="2">Nome do Insumo
                            </th>
                            <th class="text-center th-select-unity
                                column-unidade" rowspan="2">Marca</th>
                            <th class="text-center th-select-unity
                                    column-unidade" rowspan="2">Unidade</th>
                            <th class="text-center th-input-padrao-80" rowspan="2">Quantidade</th>
                            <th class="text-center th-select-unity" *ngIf="_ha_anexo" rowspan="2">Anexo</th>
                            <th class="text-center th-select-unity" rowspan="2" *ngIf="(_cotacao.recebimento_produto == 2)">Entrega Parcial
                            </th>
                            <th class="text-center th-input-padrao-100" rowspan="2">Preço R$</th>
                            <th class="text-center" *ngIf="boolIcms || boolIpi" [attr.colspan]="(boolIcms && boolIpi) ? 3:
                                (boolIpi) ? 2:1">Impostos R$</th>
                            <th class="text-center th-input-padrao-120" rowspan="2">Valor Total - R$</th>
                        </tr>
                        <tr>
                            <th class="text-center th-input-padrao-65" *ngIf="boolIcms">ICMS %</th>
                            <th class="text-center th-input-padrao-65" *ngIf="boolIpi">IPI %</th>
                            <th class="text-center th-input-padrao-65" *ngIf="boolIpi">Vlr.IPI</th>
                        </tr>
                    </thead>
                    <tbody class="" *ngFor="let produto of
                        _list_produtos">

                        <tr class="{{!produto._index_impar_par ?
                            'tr-background-impar':'tr-background-par'}}
                            {{(produto._orcamento_produto.estoque ||
                            !produto._orcamento_produto.id) ?
                            'tr-checked-stock-of':''}}">

                            <td class="text-center th-sequence-produto">{{produto._index}}</td>
                            <td class="td-nome-produto">{{produto.nome}}</td>
                            <td class="text-center th-select-unity">{{produto._orcamento_produto._marca}}</td>
                            <td class="text-center th-select-unity">{{produto._unidade_nome}}</td>
                            <td class="text-center th-input-stock">{{produto.quantidade}}</td>
                            <td class="text-center th-action-anexo" *ngIf="_ha_anexo">
                                <i *ngIf="produto.anexo && produto.anexo !=
                                    'null'" class="fa fa-file-alt btn-link
                                    cursor-pointer" (click)="MostrarFile(produto)"></i>
                            </td>

                            <td class="text-center th-action-parcial" *ngIf="(_cotacao.recebimento_produto == 2)">
                                <button class="btn btn-sm-grid btn-link">
                                    <i class="fa fa-truck"
                                        (click)="OpenModalTrunck(produto)"></i>
                                </button>
                                <small class="btn-link cursor-pointer"></small>
                            </td>
                            <td class="text-right th-input-price">
                                R$ {{produto._orcamento_produto._preco_string}}
                            </td>
                            <td class="text-center th-input-stock" *ngIf="boolIcms">
                                {{produto._orcamento_produto._icms}}
                            </td>
                            <td class="text-center th-input-stock" *ngIf="boolIpi">
                                {{produto._orcamento_produto._ipi}}
                            </td>
                            <td class="text-right th-input-stock" *ngIf="boolIpi">R$ {{produto._orcamento_produto._preco_ipi}}
                            </td>
                            <td class="text-right th-input-stock">R$ {{produto._orcamento_produto._preco_total}}
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <div class="form-group row">
                <div class="col-sm-8 text-termo">
                    <span>Valores expressos em moeda R$ real brasileiro;</span><br>
                    <span>Data de Entrega a contar a partir da confirmação do
                        pedido;</span><br>
                    <span>A descarga do material é de responsabilidade do
                        cliente;</span><br>
                    <span>Confira seu produto no ato da entrega/coleta;</span><br>
                    <span>Vendas no Cartão BNDES estão sujeitas à
                        disponibilidade de crédito do cliente junto a
                        instituição financeira emitente do cartão;</span><br>
                    <span>Estão excluídos de nosso fornecimento todo material ou
                        serviço não espeficado neste documento;</span><br><br>
                    <Br />
                    <app-auditor-view-processo></app-auditor-view-processo>
                </div>
                <div class="col-sm-4">

                    <table class="table table-striped table-bordered
                        table-aliquota" style="border: 0;">
                        <tbody class="">
                            <tr class="tr-background-impar">
                                <td class="text-left"><b>Subtotal</b></td>
                                <td class="th-input-padrao-120 text-right">R$ {{_orcamento._subtotal}}
                                </td>
                            </tr>
                            <tr class="tr-background-impar">
                                <td class="text-left" style="border:0;"></td>
                                <td class="th-input-padrao-120 text-right" style="border:0;"></td>
                            </tr>

                            <tr *ngFor="let aliquota of _aliquotas" class="tr-background-impar">
                                <td>{{aliquota.descricao}}</td>
                                <td class="th-input-padrao-120 text-right">R$ {{aliquota._valor}}
                                </td>
                            </tr>
                            <tr class="tr-background-impar" *ngIf="_aliquotas.length> 0">
                                <td class="text-left" style="border:0;"></td>
                                <td class="th-input-padrao-120 text-right" style="border:0;"></td>
                            </tr>
                            <tr class="tr-background-par">
                                <td class="text-left"><b>VALOR TOTAL</b></td>
                                <td class="th-input-padrao-120 text-right"><b>R$
                                        {{_orcamento._total}}</b></td>
                            </tr>


                            <tr class="tr-background-impar" *ngIf="_tipo_entrega
                                == 'fob'">
                                <td class="text-left" style="border:0;"></td>
                                <td class="th-input-padrao-120
                                    text-right" style="border:0;"></td>
                            </tr>

                            <tr class="tr-background-impar" *ngIf="_tipo_entrega
                                == 'fob'">
                                <td class="text-left" style="border:0;" colspan="2"><b>Informações de custo frete
                                        FOB:</b></td>
                            </tr>


                            <tr class="tr-background-impar" *ngIf="_tipo_entrega
                                == 'fob'">
                                <td class="text-left">Valor Médio km</td>
                                <td class="th-input-padrao-120
                                    text-right">R$ {{_orcamento.preco_frete_fob}}
                                </td>
                            </tr>
                            <tr class="tr-background-impar" *ngIf="_tipo_entrega
                                == 'fob'">
                                <td class="text-left">Valor Total</td>
                                <td class="th-input-padrao-120
                                    text-right">R$ {{_orcamento.preco_frete_total_fob}}
                                </td>
                            </tr>
                            <tr class="tr-background-impar" *ngIf="_tipo_entrega
                                == 'fob'">
                                <td class="text-left">Distância</td>
                                <td class="th-input-padrao-120
                                    text-right">{{_orcamento.distancia_km}} {{_orcamento.distancia_unidade}}
                                </td>
                            </tr>
                            <tr class="tr-background-impar" *ngIf="_tipo_entrega
                                == 'fob'">
                                <td class="text-left">Tempo Percurso</td>
                                <td class="th-input-padrao-120
                                    text-right">{{_orcamento.tempo}}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>

            <div class="form-group row">
                <div class="col-sm-6 margin-top-20">
                    <label class="btn btn-check-table label-checked
                        {{(_concordo)?'active':''}}" (click)="checkedConcordo()">
                        <span class="glyphicon-check-mark fa fa-check"></span>
                    </label>&nbsp;&nbsp;Li e concordo com os termos
                </div>
                <div class="col-sm-6 text-right">
                    <a class="btn btn-default" href="javascript:history.back()">
                        <i class="fa fa-arrow-left"></i>&nbsp;&nbsp;Voltar</a>

                    <button type="submit" *ngIf="(_orcamento.status == 1) &&
                            !(_cotacao._expirado)" class="btn btn-danger margin-left-10" (click)="update()"><i
                                class="fa fa-check"></i>&nbsp;&nbsp;Reprovar</button>

                    <button type="submit" *ngIf="(_orcamento.status == 1) &&
                                    !(_cotacao._expirado)" class="btn btn-primary
                                    margin-left-10" style="background:
                                    #08c65b; border-color: #08c65b;" (click)="aprovar()"><i
                                        class="fa fa-check"></i>&nbsp;&nbsp;Aprovar Compra</button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-data-modal [_nome_arquivo]="_nome_arquivo" [_src_arquivo]="_src_arquivo"></app-data-modal>
<app-entrega-parcial-view [_produto_selecionado]="_produto_selecionado" (_entregas_emit)="ReceiverEntregaProduto($event)"></app-entrega-parcial-view>
<app-message-modal [_titulo]="_msg_titulo" [_descricao]="_msg_descricao"></app-message-modal>
<!-- Modal -->
<div class="modal fade" id="confirm-config-modal" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable
        modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Aprovar ou Reprovar Compra</h4>
                <div class="ibox-tools">
                    <div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body modal-confirm" style="padding: 1rem;">
                <b>Obervação:</b>
                <textarea class="form-control" rows="3" [(ngModel)]="_observacao"></textarea>
                <div class="row no-margin">
                    <div class="col-sm-12 text-right margin-top-20">
                        <button class="btn btn-default margin-right-10" data-dismiss="modal">Não</button>
                        <button class="btn btn-primary" (click)="clickTrash()">Sim</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>