<div id="wrapper">

    <app-sid-bar></app-sid-bar>

    <div id="page-wrapper" style="left:0" class="gray-bg">

        <app-nav-bar></app-nav-bar>

        <app-breadcrumb></app-breadcrumb>

        <div class="home-content">
            <div class="row">
                <div class="col-lg-12  wrapper-none">
                    <router-outlet></router-outlet>
                </div>
                <!--
                anuncio-vertical
                <div class="div-anuncio-vertical bg-white">
                    Anúncio
                </div>
                -->
            </div>
        </div>
    </div>

</div>
<!--
<app-footer></app-footer>
-->
<app-alert-confirm></app-alert-confirm>