<!-- Modal -->
<div class="modal fade" id="pesquisa-insumo-modal" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Pesquisa Insumos</h4>

                <div class="ibox-tools">
                    <div>
                        <label class="margin-right-20">
                            {{_count_grid}}
                            <b *ngIf="_total_pesquisa >= 0">de</b>
                            {{_total_pesquisa}}
                        </label>
                        <label>
                            <div class="btn-group mr-2 pagination-position-grid"
                                role="group"
                                aria-label="First group">
                                <button type="button" *ngFor="let page of
                                    _paginas"
                                    class="btn btn-secondary {{page.active
                                    ?'active':''}}"
                                    (click)="GetPage(page.page)">{{page.page}}</button>
                            </div>
                        </label>


                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>

            </div>

            <div class="row caixa-pesquisa">

                <div class="col-sm-7">
                    <div class="input-group"><input (ngModelChange)="OnChangeSearch()" placeholder="Pesquisar" [(ngModel)]="_buscar" type="text" class="form-control form-control-sm">
                        <span class="input-group-append">
                            <button type="button" class="btn btn-sm
                                btn-primary">Buscar</button>
                        </span>
                    </div>
                </div>
                <div class="col-sm-5">

                    <select class="form-control-sm form-control input-s-sm
                        inline" [(ngModel)]="_categoria_id" (change)="OnChangeSearch()">
                        <option value="0">Informe a Categoria</option>
                        <option *ngFor="let categoria of _categorias"
                            value="{{categoria.id}}">{{categoria.descricao}}</option>
                    </select>

                </div>
            </div>

            <div class="modal-max">


                <div class="table-responsive">
                    <table class="table table-striped table-hover table-sm">
                        <thead class="thead-dark">
                            <tr>
                                <th class="text-center">#</th>
                                <th>Nome do Insumo </th>
                                <th class="text-center">Categoria </th>
                            </tr>
                        </thead>
                        <tbody class="  " [hidden]="_carregando">
                            <tr *ngFor="let produto of _suprimento.insumos">
                                <td class="text-center">
                                    <div class="btn-group">
                                        <label class="btn btn-check-table
                                            btn-default {{produto._selecionado ?
                                            'active': ''}}" (click)="ClickBond(produto)">
                                            <span class="glyphicon-check-mark fa fa-check"></span>
                                        </label>
                                    </div>
                                </td>
                                <td>{{produto.nome}}</td>
                                <td class="text-center">{{produto._categoria}}</td>
                            </tr>

                        </tbody>
                    </table>



                    <div [hidden]="!_ha_produto" class="mensagem-produto
                          ">
                        Nenhum produto encontrado, para pesquisa selecionada.
                        <a href [routerLink]="['/suprimento/novo-insumo']">
                            Cadastrar um novo insumo!</a>
                    </div>

                    <div [hidden]="!_carregando" class="carregando-produtos
                          ">
                        <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                        </div>
                        carregando...
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary " data-dismiss="modal" *ngIf="_save_active" (click)="SelectItem()">OK</button>
            </div>
        </div>
    </div>
</div>