import { Component, OnInit } from '@angular/core';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { ProdutoCategoria } from 'src/app/domain/suprimento/produto-categoria';
import { ProdutoService } from 'src/app/service/suprimento/produto.service';
import { CategoriaVirtualService } from 'src/app/service/suprimento/categoria-virtual.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { Produto } from 'src/app/domain/suprimento/produto';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/service/alert.service';

declare var $: any;
@Component({
  selector: 'app-produto-form',
  templateUrl: './produto-form.component.html',
  styleUrls: ['./produto-form.component.css']
})
export class ProdutoFormComponent implements OnInit {

  private _code = "app-produto-form";
  public _categorias: ProdutoCategoria[];
  public _text_categoria_pai: string;
  _produto = new Produto();

  constructor(
    private httpProduto: ProdutoService,
    private categoriaService: CategoriaVirtualService,
    private confirmService: AlertConfirmService,
    private route: ActivatedRoute,
    private router: Router,
    public alertService: AlertService) {
    this.confirmService.UnsetObservers();
    //this.confirmService.confirmEmitter$.subscribe(c => this.RemoverAll(c));
  }

  id = this.route.snapshot.params['id'];

  ngOnInit() {
    this.getListaCatetorias();
    if (this.id && this.id > 0)
      this.getProduto(this.id);
  }

  getProduto(id: any) {
    this.httpProduto.getProduto(id).subscribe(
      data => {
        if (data && data.id == id) {
          this._produto = data
          this._produto.prod_validado = (this._produto.prod_validado == "S")
        }
      },
      error => { },
      () => { }
    );
  }

  getListaCatetorias() {
    this.httpProduto.getAllCategories().subscribe(
      data => { this._categorias = data; },
      error => { },
      () => {  }
    );
  }

  save() {
    setTimeout(() => {
      this._produto.prod_validado = (this._produto.prod_validado) ? 'S' : 'N'
      this._produto.ha_niveis = (this._produto.ha_niveis) ? 1 : 0
      this.httpProduto.save(this._produto).subscribe(
        data => { this.alertService.SetMessageSuccess('Produto salvo com sucesso');  this._produto = data; },
        error => { },
        () => { this._produto.prod_validado = (this._produto.prod_validado == "S") }
      );
    }, 10);
  }

  voltar() { this.router.navigateByUrl('auxiliar/insumo/list'); }

}
