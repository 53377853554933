<div class="col-lg-12 wrapper-top-content">
    <div class="ibox-title">
        <h5>Lista Colaboradores</h5>
    </div>
    <div class="ibox-content">

        <div class="input-group">
            <input placeholder="Pesquisar" [(ngModel)]="_buscar" type="text" class="form-control form-control-sm">
            <span class="input-group-append">
                <button type="button" class="btn btn-sm btn-primary">Buscar</button>
                </span>
            <div class="separador-busca-tabela"></div>
            <div class="table-responsive">
                <table class="table table-striped table-hover
                        table-bordered">
                    <thead class="thead-dark">
                        <tr>
                            <th>Nome</th>
                            <th class="text-center" *ngIf="(userLoggedIn.Editar || userLoggedIn.Excluir) || userLoggedIn.type == 3">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let usuario of usuarios | filter : _buscar" class="{{usuario.id == usuario_id_selecionado ? 'table-info' : ''}}">
                            <td>{{usuario.nome}} <span *ngIf="usuario._administrador" style="float: right;" class="badge badge-info margin-left-10">Administrador</span></td>
                            <td class="text-center td-acoes" *ngIf="(userLoggedIn.Editar || userLoggedIn.Excluir) || userLoggedIn.type == 3">
                                <a href (click)="EditPermissionUser($event, usuario)" data-toggle="tooltip" data-placement="left" title="Gerenciar permissões de usuário" *ngIf="userLoggedIn.Editar"><i class="fa fa-user-lock"></i></a> <span *ngIf="userLoggedIn.Editar">|</span>
                                <a href (click)="EditUser($event, usuario)" data-toggle="tooltip" data-placement="left" title="Editar usuário" *ngIf="userLoggedIn.Editar || userLoggedIn.type == 3"><i class="fa fa-edit"></i></a> |
                                <a href (click)="ClickDeleteUser($event, usuario)" data-toggle="tooltip" data-placement="left" title="Excluir usuário" *ngIf="userLoggedIn.Excluir || userLoggedIn.type == 3"><i class="fa fa-trash-alt"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>