export class Municipio {
    public Id: number;
    public Codigo: number;
    public Nome: string;
    public Uf: string;    
    public _selecionado: boolean;

    static GetMunicipios(cidades: Array<Municipio>): string{        
        if(!cidades)
            return "";
        var cidade = ":";
        cidades.forEach(c=> { cidade += c.Codigo+":"; });
        return cidade;
    }

    static GetCidadesSelecionados(cidades: Array<any>, list: string): Array<Municipio> {
        var list_ids = list.split(':');

        function filtrar(obj) {
            for (let i = 0; i < list_ids.length; i++) {
                if (parseInt(list_ids[i]) == obj.Codigo)
                    return true;
            }
            return false;
        }
        var data = cidades.filter(filtrar);
        return data;
    }
}