import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from 'src/app/core/global-variable';
import { Cotacao, OrcamentoCotacao } from 'src/app/domain/cotacao/cotacao';

@Injectable({
    providedIn: 'root'
})
export class GerenciamentoCompradorService {

    private url = GlobalVariable.BASE_API_URL + "cotacao/";
    constructor(private http: HttpClient) {}

    GetAllEnviadas(status: number, inicio: string, fim: string) {
        return this.http.get<Cotacao[]> (this.url + "GetAlladministracao?status="+status+"&inicio="+inicio+"&fim="+fim).pipe(
            map(res => {
                return res;
            })
        );
    }

    GetAllAdministradorPropagacaoDetalhada(cotacao_id: number) {
        return this.http.get<OrcamentoCotacao[]> (this.url + "GetAllAdministradorPropagacaoDetalhada?cotacao_id="+cotacao_id).pipe(
            map(res => {
                return res;
            })
        );
    }
/*
    Get(id: number) {
        return this.http.get < PrazoPagamento > (this.url + "get?id=" + id).pipe(
            map(res => {
                return res;
            })
        );
    }

    Create(data: PrazoPagamento): Observable < any > {
        return this.http.post < any[] > (this.url + "create", data).pipe(
            map(res => { return res; })
        );
    }

    Edit(data: PrazoPagamento): Observable < any > {
        return this.http.put < any[] > (this.url + "edit", data).pipe(
            map(res => { return res; })
        );
    }

    Delete(id: number): Observable < any > {
        return this.http.delete(this.url + "delete?id=" + id).pipe(
            map(res => { return res; })
        );
    }

    */
}