import { Component, OnInit } from '@angular/core';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ListCompraVenda, EnumStatusTransacao, EnumStatusFaturamento } from 'src/app/domain/transacao/compra-venda';
import { CompraVendaService } from 'src/app/service/transacao/compra-venda.service';
import { ListOrcamento } from 'src/app/domain/orcamento/orcamento';
import { GerenciamentoFornecedorService } from 'src/app/service/gerenciamento/gerenciamento-fornecedor.sevice';
import { ExtratoFornecedor } from 'src/app/domain/gerenciamento/extrato-fornecedor';
import { UseFullService } from 'src/app/service/usefull/usefull.service';

@Component({
  selector: 'app-extrato-financeiro-fornecedor',
  templateUrl: './extrato-financeiro-fornecedor.component.html',
  styleUrls: ['./extrato-financeiro-fornecedor.component.css']
})
export class ExtratoFinanceiroForncedorComponent implements OnInit {

  public code: string;
  public _list_orcamento: Array<ListOrcamento>;
  public _transacoes: Array<ExtratoFornecedor>;
  public _buscar: string;

  constructor(
    private route: ActivatedRoute,
    private compraVendaService: CompraVendaService,
    private confirmService: AlertConfirmService,
    private userLoggedService: UserLoggedInService,
    private gerenciamentoFornecedorService: GerenciamentoFornecedorService,
    private router: Router) {
    this.userLoggedService.SetCurrentUser();
    this._transacoes = new Array<ExtratoFornecedor>();
  }

  _inicio = ''
  _fim = ''
  _status = EnumStatusFaturamento.Aberto;
  ngOnInit() {
    this.userLoggedService.SetCurrentUser();
    var date = new Date();
    this._fim = UseFullService.formatDateUsForm(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    this._inicio = UseFullService.formatDateUsForm(new Date(date.getFullYear(), date.getMonth(), 1));
    this.GetAll();
  }

  OnChangeSearch() {
    this.GetAll()
  }

  GetAll() {
    this.gerenciamentoFornecedorService.GetAll(this._status, this._inicio, this._fim).subscribe(
      d => {
        console.log(d)
        if (d && d.length > 0)
          this._transacoes = d;
        else
          this._transacoes = new Array<ExtratoFornecedor>();
      },
      e => { },
      () => {
        var x = 1;
        this._transacoes.forEach(c => {
          c._index = x++;
          if (c.total)
            c.total = UseFullService.ToFixedString(c.total)
          else
            c.total = "0,00"
        });
      }
    );
  }

  Ver(model: ExtratoFornecedor) {
    this.router.navigateByUrl("/gerenciamento/extrato/fornecedores/view/" + model.cnpj);
  }

}