import { Component, OnInit } from '@angular/core';
import { InputAny, EnumOjectInputAny } from '../../../cotacao/localidade/localidade.component';
import { Regiao } from 'src/app/domain/enum/regiao';
import { Estado } from 'src/app/domain/enum/estado';
import { Municipio } from 'src/app/domain/enum/municipio';
import { EnumService } from 'src/app/service/usefull/enum.service';
import { ProdutoService } from 'src/app/service/suprimento/produto.service';
import { ProdutoCategoria } from 'src/app/domain/suprimento/produto-categoria';
import { PrazoPagamento } from 'src/app/domain/auxiliar/prazo-pagamento';
import { Cotacao, EnumRecebimentoProduto } from 'src/app/domain/cotacao/cotacao';
import { PrazoPagamentoService } from 'src/app/service/auxiliar/prazo-pagamento.service';
import { EnumReceberCotacaoPor, OrcamentoConfiguracao } from 'src/app/domain/orcamento/orcamento-configuracao';
import { SuprimentoService } from 'src/app/service/suprimento/suprimento-service';
import { OrcamentoConfiguracaoService } from 'src/app/service/orcamento/orcamento-configuracao.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
declare var $: any;
@Component({
  selector: 'app-orcamento-configuracao',
  templateUrl: './orcamento-configuracao.component.html',
  styleUrls: ['./orcamento-configuracao.component.css']
})
export class OrcamentoConfiguracaoComponent implements OnInit {
  public cotacao_id: any
  public _input_modal: Array<InputAny>;
  _ha_estado = false;
  _ha_cidade = false;
  public _regioes: Regiao[];
  public _estados: Estado[];
  public _cidades: Municipio[];
  public _categorias: ProdutoCategoria[];
  public _prazos_pagamento: PrazoPagamento[];
  public _formas_pagamento: any[];
  public _receber_por: EnumReceberCotacaoPor;
  public _orcamentoConfiguracao: OrcamentoConfiguracao;


  _quantidade_produto = 0;
  _categorias_total: ProdutoCategoria[];

  constructor(
    public userLoggedIn: UserLoggedInService,
    private httpProduto: ProdutoService,
    private suprimentoService: SuprimentoService,
    private enumService: EnumService,
    private prazoPagamentoService: PrazoPagamentoService, private orcamentoConfigService: OrcamentoConfiguracaoService) {
    this._orcamentoConfiguracao = new OrcamentoConfiguracao();
    this.userLoggedIn.AllowedOperations()
  }

  ngOnInit() {
    this._receber_por = 1;
    this.GetQtProdutos();
    this.LoadData();
  }

  LoadData() {
    this.orcamentoConfigService.GetFornecedor().subscribe(
      d => {
        if (d && d.id > 0)
          this._orcamentoConfiguracao = d;
      },
      e => { },
      () => {
        console.log(this._orcamentoConfiguracao)
        if (this._orcamentoConfiguracao && this._orcamentoConfiguracao.id > 0) {
          this.GetRegiao(false);
          $("input[name='recebimento'][value='" + this._orcamentoConfiguracao.receber_por + "']").attr('checked', true);
          this._receber_por = this._orcamentoConfiguracao.receber_por;
        }
      }
    );
  }

  GetQtProdutos() {
    this.suprimentoService.getCountProdutoFornecedor().subscribe(
      d => { this._quantidade_produto = d; }
    );
  }

  Save() {
    if (!this._orcamentoConfiguracao)
      this._orcamentoConfiguracao = new OrcamentoConfiguracao();
    this._orcamentoConfiguracao.receber_por = $("[name='recebimento']:checked").val();

    if (this._orcamentoConfiguracao.receber_por == EnumReceberCotacaoPor.categoria)
      this._orcamentoConfiguracao.categorias = ProdutoCategoria.GetCategorias(this._categorias, this._categorias_total);
    else
      this._orcamentoConfiguracao.categorias = "";

    this._orcamentoConfiguracao.regiao = Regiao.GetRegioes(this._regioes);
    this._orcamentoConfiguracao.estado = Estado.GetEstados(this._estados);
    this._orcamentoConfiguracao.cidade = Municipio.GetMunicipios(this._cidades);
    this._orcamentoConfiguracao.forma_recebimento = Cotacao.GetIdStringMeioPagamento(this._formas_pagamento);
    this._orcamentoConfiguracao.prazo_recebimento = PrazoPagamento.GetAll(this._prazos_pagamento);

    this.orcamentoConfigService.Create(this._orcamentoConfiguracao).subscribe(
      d => { }
    );
  }

  GetRegiao(modal: boolean = true) {
    this.enumService.GetAll("Regiao").subscribe(
      d => {
        var data = d as Array<InputAny>;
        data.forEach(c => {
          c._selecionado = false,
            c.objeto = EnumOjectInputAny.regiao;
        });
        this._input_modal = data;
      },
      e => { },
      () => {
        if (!modal) {
          this._regioes = Regiao.GetRegiosSelecionadas(this._input_modal, this._orcamentoConfiguracao.regiao);
          setTimeout(() => {
            this.SelecionarEstado(false);
            this._ha_estado = (this._regioes.length > 0);
          }, 100);
          this.GetCategorias(false);
        }
      }
    );
  }

  SelecionarFormaPagamento() {
    this.GetAllFormaPagamento();
    $('#pesquisa-localidade-modal').modal("show");
  }

  SelecionarPrazoPagamento() {
    this.GetAllPrazoPagamento();
    $('#pesquisa-localidade-modal').modal("show");
  }

  SelecionarRegiao() {
    this.GetRegiao();
    $('#pesquisa-localidade-modal').modal("show");
  }

  SelecionarCategoria() {
    this.GetCategorias();
    $('#pesquisa-localidade-modal').modal("show");
  }

  GetCategorias(modal: boolean = true) {
    function PorNivel(obj) {
      return (!obj.pai_id || obj.pai_id < 1)
    }
    this.httpProduto.getAllCategories().subscribe(
      d => {
        var data = d as Array<any>;
        data.forEach(c => {
          c._selecionado = false,
            c.Nome = c.descricao,
            c.objeto = EnumOjectInputAny.categoria;
        });
        this._categorias_total = data;
        this._input_modal = data.filter(PorNivel);
      },
      e => { },
      () => {
        if (!modal) {
          this._categorias = ProdutoCategoria.GetCategoriasSelecionados(this._input_modal, this._orcamentoConfiguracao.categorias, this._categorias_total);
          this.GetAllFormaPagamento(false);
        }
      });
  }

  GetAllFormaPagamento(modal: boolean = true) {
    this._input_modal = new Array<InputAny>();

    var x = 1;
    Cotacao.GetAllMeioPagamento().forEach(c => {
      var data = new InputAny();
      data.Nome = c;
      data.Id = x++;
      data.descricao = c;
      data.id = data.Id;
      data._selecionado = false;
      data.objeto = EnumOjectInputAny.forma_pagamento;
      this._input_modal.push(data);
    });
    if (!modal) {
      this._formas_pagamento = Cotacao.GetMeioPagamentoSelecionados(this._input_modal, this._orcamentoConfiguracao.forma_recebimento);
      this.GetAllPrazoPagamento(false);
    }

  }

  GetAllPrazoPagamento(modal: boolean = true) {
    this._input_modal = new Array<InputAny>();
    this.prazoPagamentoService.GetAll().subscribe(
      d => {
        var data = d as Array<any>;
        data.forEach(c => {
          c._selecionado = false,
            c.Id = c.id,
            c.Nome = c.descricao,
            c.id = c.id,
            c.descricao = c.descricao,
            c.objeto = EnumOjectInputAny.prazo_pagamento;
          this._input_modal.push(c);
        });
      },
      e => { },
      () => {
        if (!modal)
          this._prazos_pagamento = PrazoPagamento.GetPrazoPagamentoSelecionados(this._input_modal, this._orcamentoConfiguracao.prazo_recebimento);
      }
    );
  }

  SelecionarEstado(modal: boolean = true) {
    this._input_modal = new Array<InputAny>();
    var qt_regioes = this._regioes.length;
    var x = 1;
    this._regioes.forEach(c => {
      this.enumService.GetAll("Estado", String(c.Id), "regiao").subscribe(
        d => {
          var data = d as Array<InputAny>;
          data.forEach(c => {
            c._selecionado = false,
              c.objeto = EnumOjectInputAny.estado;
            this._input_modal.push(c);
          });
        },
        e => { },
        () => {
          if (!modal && x == qt_regioes) {
            this._estados = Estado.GetEstadosSelecionados(this._input_modal, this._orcamentoConfiguracao.estado);
            setTimeout(() => {
              this._ha_cidade = (this._estados.length > 0);
              this.SelecionarCidade(false);
            }, 500);
          }
          x++;
        }
      );
    });
    if (modal)
      $('#pesquisa-localidade-modal').modal("show");
  }

  SelecionarCidade(modal: boolean = true) {
    this._input_modal = new Array<InputAny>();
    this._estados.forEach(c => {
      this.enumService.GetAll("Municipio", c.Uf).subscribe(
        d => {
          var data = d as Array<InputAny>;
          data.forEach(c => {
            c._selecionado = false,
              c.objeto = EnumOjectInputAny.cidade;
            this._input_modal.push(c);
          });
        },
        e => { },
        () => {
          if (!modal)
            this._cidades = Municipio.GetCidadesSelecionados(this._input_modal, this._orcamentoConfiguracao.cidade);
        }
      );
    });
    if (modal)
      $('#pesquisa-localidade-modal').modal("show");
  }

  ReceiverRespostaModal(data: any[]) {
    if (data.length < 1)
      return;
    switch (data[0][0].objeto) {
      case EnumOjectInputAny.regiao:
        this.SetRegioes(data[0]);
        break;
      case EnumOjectInputAny.estado:
        this.SetEstados(data[0]);
        break;
      case EnumOjectInputAny.cidade:
        this.SetCidades(data[0]);
        break;
      case EnumOjectInputAny.categoria:
        this.SetCategorias(data[0]);
        break;
      case EnumOjectInputAny.forma_pagamento:
        this.SetFormaPagamento(data[0]);
        break;
      case EnumOjectInputAny.prazo_pagamento:
        this.SetPrazoPagamento(data[0]);
        break;
    }

    $('#pesquisa-localidade-modal').modal("hide");
  }

  SetRegioes(data: Array<Regiao>) {
    this._regioes = new Array<Regiao>();
    this._estados = new Array<Estado>();
    this._cidades = new Array<Municipio>();
    data.forEach(c => {
      if (c._selecionado) {
        this._regioes.push(c as Regiao);
      }
    });
    this._ha_estado = (this._regioes.length > 0);
    this._ha_cidade = false;
  }

  SetEstados(data: Array<Estado>) {
    this._estados = new Array<Estado>();
    this._cidades = new Array<Municipio>();
    data.forEach(c => {
      if (c._selecionado) {
        this._estados.push(c as Estado);
      }
    });
    this._ha_cidade = (this._estados.length > 0);
  }

  SetCidades(data: Array<Municipio>) {
    this._cidades = new Array<Municipio>();
    data.forEach(c => {
      if (c._selecionado) {
        this._cidades.push(c as Municipio);
      }
    });
  }


  SetCategorias(data: Array<ProdutoCategoria>) {
    this._categorias = new Array<ProdutoCategoria>();
    data.forEach(c => { if (c._selecionado) { this._categorias.push(c as ProdutoCategoria); } });
  }


  SetFormaPagamento(data: Array<any>) {
    this._formas_pagamento = new Array<any>();
    data.forEach(c => {
      if (c._selecionado) { this._formas_pagamento.push(c as any); }
    });
  }


  SetPrazoPagamento(data: Array<PrazoPagamento>) {
    this._prazos_pagamento = new Array<PrazoPagamento>();
    data.forEach(c => {
      if (c._selecionado) {
        this._prazos_pagamento.push(c as PrazoPagamento);
      }
    });
  }

  SelectRecebimento(receber_por: EnumReceberCotacaoPor) {
    this._receber_por = receber_por;
  }
}