import { ListOrcamento } from '../orcamento/orcamento';

export class CompraVenda {
    public id: number;
    public user_compra_id: number;
    public user_conclusao_id: number;
    public orcamento_id: number;
    public status: number;
    public data_criacao: string;
    public data_conclusao: string;
}

export class ListCompraVenda extends ListOrcamento {
    public id_compra_venda: number;
    public status_compra_venda: number;
    public data_conclusao: string;
    public status_painel: number;
    public _status_painel_nome: string;

    
    static SetConfig(model: ListCompraVenda) {
        ListOrcamento.SetConfig(model);
        if(model.status_painel > 0)
            model._status_painel_nome = EnumStatusFaturamento[model.status_painel]
    }
}

export enum EnumStatusTransacao {
    analise = 0,
    aprovada = 1,
    recusada = 2
}


export class FaturamentoPainel {
    public id: number;
    public venda_id: number;
    public cnpj: string
    public status: string
    public total: string
    public porcentagem: string
    public total_painel: string
    public data: string
}

export enum EnumStatusFaturamento {
    Aberto = 0,
    Pago = 1,
    Cancelado = 2,
    Gratuito = 3
}