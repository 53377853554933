import { Component, OnInit } from '@angular/core';
import { FuncaoSistema, OperacoesFuncao, EnumOperacoesFuncao, RelacaoPerfilFuncao } from 'src/app/domain/configuracao/funcao-sistema';
import { FuncaoSistemaService } from 'src/app/service/configuracao/funcao-sistema.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { PerfilUsuario } from 'src/app/domain/configuracao/perfil-usuario';
import { PerfilUsuarioService } from 'src/app/service/configuracao/perfil-usuario.service';

@Component({
  selector: 'app-perfil-usuario',
  templateUrl: './perfil-usuario.component.html',
  styleUrls: ['./perfil-usuario.component.css']
})
export class PerfilUsuarioComponent implements OnInit {

  private _code = "app-perfil-usuario";

  private _temp_perfil_usuario: PerfilUsuario;
  _perfis_usuarios = new Array<PerfilUsuario>();
  _perfil_usuario = new PerfilUsuario();

  private relacoes_perfil_funcao: Array<RelacaoPerfilFuncao>;

  public _funcao_sistema: FuncaoSistema;
  public _funcoes_sistema: Array<FuncaoSistema>;
  public _operacaoes: Array<OperacoesFuncao>;

  constructor(private funcaoSistemaService: FuncaoSistemaService,
    private perfilUsuarioService: PerfilUsuarioService,
    private confirmService: AlertConfirmService) {
    this.confirmService.UnsetObservers();
    this.confirmService.confirmEmitter$.subscribe(c => this.Remover(c));

    this._funcao_sistema = new FuncaoSistema();
    this._funcoes_sistema = new Array<FuncaoSistema>();
    this._operacaoes = new Array<OperacoesFuncao>();

    this._perfis_usuarios = new Array<PerfilUsuario>();
    this._perfil_usuario = new PerfilUsuario();
    this._temp_perfil_usuario = new PerfilUsuario();

  }

  ngOnInit() {
    this.GetFuncoesSistema();
    this.GetAll();
  }

  SetOperacoes() {
    this._funcoes_sistema.forEach(c => { FuncaoSistema.SetOperacoesVinculadas(c); });
  }

  SetVinculoOperacoes() {
    var funcoes = this._funcoes_sistema;
    this._funcoes_sistema = new Array<FuncaoSistema>();
    funcoes.forEach(c => {
      var relacao = RelacaoPerfilFuncao.GetPorPerfilFuncao(this.relacoes_perfil_funcao, this._temp_perfil_usuario.id, c.id);
      var operacao = undefined;
      if (relacao && relacao.operacao && relacao.operacao.length > 0)
        operacao = relacao.operacao;
      RelacaoPerfilFuncao.SetOperacoes(c, operacao);
    });
    setTimeout(() => {
      this._funcoes_sistema = funcoes;
    }, 100);
  }

  GetFuncoesSistema() {
    this.funcaoSistemaService.GetAll().subscribe(
      d => { this._funcoes_sistema = d; },
      e => { },
      () => { this.SetOperacoes(); }
    );
  }

  GetAll() {
    this.perfilUsuarioService.GetAll().subscribe(
      d => { this._perfis_usuarios = d; },
      e => { },
      () => { }
    );
  }

  Adicionar() {
    this.perfilUsuarioService.Create(this._perfil_usuario).subscribe(() => {
      this.GetAll();
    });
  }

  Editar(data: PerfilUsuario) {
    this.perfilUsuarioService.Edit(data).subscribe(
      d => { },
      e => { this.GetAll(); },
      () => { });
  }

  GetAllRelacoesFuncao(perfil_id) {
    this.perfilUsuarioService.GetAllRelacoesPerfilFuncao(perfil_id).subscribe(
      d => { this.relacoes_perfil_funcao = d; },
      e => { },
      () => { this.SetVinculoOperacoes(); }
    );
  }


  EditarRelacoesFuncao(data: RelacaoPerfilFuncao) {
    this.perfilUsuarioService.EditRelacoesPerfilFuncao(data).subscribe(
      d => { },
      e => { this.GetAll(); },
      () => { });
  }

  ChangeOperacao(e, data: FuncaoSistema, operacao_id: number) {
    var relacao_perfil = RelacaoPerfilFuncao.GetPorPerfilFuncao(this.relacoes_perfil_funcao, this._temp_perfil_usuario.id, data.id);
    var relacao = relacao_perfil;

    if (e.srcElement.checked) {
      if (!relacao)
        relacao = new RelacaoPerfilFuncao();
      relacao.perfil_id = this._temp_perfil_usuario.id;
      relacao.funcao_id = data.id;
      RelacaoPerfilFuncao.AddOperacao(relacao, operacao_id);
      if (!this.relacoes_perfil_funcao || this.relacoes_perfil_funcao.length < 1) {
        this.relacoes_perfil_funcao = new Array<RelacaoPerfilFuncao>();
        this.relacoes_perfil_funcao.push(relacao);
      } else if (!relacao_perfil) {
        this.relacoes_perfil_funcao.push(relacao);
      }
      this.EditarRelacoesFuncao(relacao);
    } else if (relacao) {
      RelacaoPerfilFuncao.RemoveOperacao(relacao, operacao_id);
      this.EditarRelacoesFuncao(relacao);
    }

  }

  SelectPerfilUsuario(data: PerfilUsuario) {
    this._temp_perfil_usuario = data;
    this.GetAllRelacoesFuncao(data.id);
  }

  ClickDelete(data: PerfilUsuario): void {
    this._temp_perfil_usuario = data;
    this.confirmService._code = this._code;
    this.confirmService._title = "Excluir Perfil do Sistema";
    this.confirmService._message = "Deseja excluir o perfil " + data.descricao + "?";
    this.confirmService.Show();
  }

  Remover(confirm: boolean = false) {
    if (this.confirmService._code != this._code || confirm == false)
      return;
    this.perfilUsuarioService.Delete(this._temp_perfil_usuario.id).subscribe(
      d => { },
      e => { },
      () => { this.GetAll(); }
    );
  }

}
