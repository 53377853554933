<div class="col-lg-12 wrapper-top-content">
    <div class="row">
        <div class="col-sm-6">
            <div class="ibox">

                <div class="ibox-title">
                    <i-feather name="trending-up" class="text-primary" style="width: 18px; margin-right: 5px;"></i-feather>
                    <b>Orçamentos Recebidos</b>

                    <div style="float: right;"> </div>
                </div>
                <!--<label class="fa fa-calendar-alt label-calendar"></label>-->
                <ngb-datepicker class="datapicker-calendar" #dp [(ngModel)]="model" (navigate)="date= $event.next"></ngb-datepicker>
                <div [chart]="orcamentosChat" class="chart-faturamento" (click)="dp.toggle()" style="max-width: 100%;
                    height: 252px;"></div>
                <button style="display: none;" id="atualizarChat"></button>
            </div>
        </div>
        <div class="col-lg-6 no-padding">
            <div class="row no-margins">
                <div class="col-lg-6 padding-left-0">
                    <div class="ibox">
                        <div class="ibox-title" style="padding-bottom: 0;">
                            <i-feather name="bar-chart" class="text-primary" style="width: 18px; margin-right: 5px;"></i-feather>
                            <b>Resumo</b>
                        </div>
                        <div class="ibox-content" style="padding: 0; margin-top: -15px;">
                            <div class="text-center">
                                <div [chart]="compraEntregaChat" class="chart-resumo" (click)="dp.toggle()" style="max-width: 100%;
                    height: 110px; border-radius: 5px;"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 padding-left-0">
                    <div class="ibox">
                        <div class="ibox-title">
                            <i-feather name="trending-up" class="text-primary" style="width: 18px; margin-right: 5px;"></i-feather>
                            <b>Orçamentos</b>
                        </div>
                        <div class="ibox-content">
                            <div class="indicador-duplo text-center">
                                Solicitados
                                <h4 class="text-center">{{_dashboard.pedidos_orcamento}}</h4>
                            </div>
                            <div class="indicador-duplo border-left text-center">Recebidos
                                <h4 class="text-dark">{{_dashboard.orcamentos_recebidos}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 padding-left-0">
                    <div class="ibox">
                        <div class="ibox-title">
                            <i-feather name="shopping-cart" class="text-primary" style="width: 18px; margin-right: 5px;"></i-feather>
                            <b>Compras</b>
                        </div>
                        <div class="ibox-content">
                            <div class="indicador-duplo text-center">
                                Efetuadas
                                <h4 class="text-center">{{_dashboard.compras_efetuadas}}</h4>
                            </div>
                            <div class="indicador-duplo border-left
                                text-center">
                                Aprovadas
                                <h4 class="text-dark">{{_dashboard.compras_aprovadas}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 padding-left-0">
                    <div class="ibox">
                        <div class="ibox-title">
                            <i-feather name="truck" class="text-primary" style="width: 18px; margin-right: 5px;"></i-feather>
                            <b class="">Entregas</b>
                        </div>
                        <div class="ibox-content">
                            <div class="indicador-duplo text-center">
                                Pendentes
                                <h4 class="text-center">{{_dashboard.entregas_pendentes}}</h4>
                            </div>
                            <div class="indicador-duplo border-left
                                text-center">
                                Realizadas
                                <h4 class="text-dark">{{_dashboard.entregas_realizadas}}</h4>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!--
        <div class="col-lg-3 padding-left-0 padding-right-0 col-lg-left-right">
            <div class="ibox bg-white text-center" style="height: calc(100% - 15px);">
                Anúncio
            </div>
        </div>
        -->
        <div class="col-lg-6">
            <div class="ibox">
                <div class="ibox-title" style="padding-right:20px;">
                    <label class="icon-in">O</label><span style="font-weight:
                        500;">Últimos Orçamentos Recebidos</span>
                </div>
                <div class="ibox-content" style="height: 300px; overflow:
                    auto; padding:0px 15px;">
                    <div class="table-responsive min-height-grid-insumo div-table-fixed">
                        <!-- table-striped table-hover table-bordered -->
                        <table class="table table-striped">
                            <thead class="thead-fixed">
                                <tr>
                                    <th class="text-center th-codigo">Código</th>
                                    <th class="th-nome">Nome</th>
                                    <th class="th-obra">Obra</th>
                                    <th class="text-center width-120-px">Total R$</th>
                                    <th class="text-center width-40-px"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="" *ngFor="let orcamento of _orcamentos">
                                    <td class="text-center width-100-px">{{orcamento.codigo}}</td>
                                    <td>{{orcamento.nome}}</td>
                                    <td>{{orcamento.nome_obra}}</td>
                                    <td class="text-right width-120-px">R$ {{orcamento.sub_total}}</td>
                                    <td class="text-center width-40-px"><i class="fa fa-search btn-link cursor-pointer" [routerLink]="'/orcamentos/cotacao-view/'+orcamento.cotacao_id"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="_orcamentos.length < 1">Nenhum orçamento encontrado no mês corrente.</div>
                </div>
            </div>
        </div>



        <div class="col-lg-6 padding-left-0">
            <div class="ibox">
                <div class="ibox-title" style="padding-right:20px;">
                    <label class="icon-in">C</label><span style="font-weight:
                        500;">Compras</span>
                </div>
                <div class="ibox-content" style="height: 300px; overflow:
                    auto; padding:0px 15px;">
                    <div class="table-responsive min-height-grid-insumo
                        div-table-fixed">
                        <!-- table-striped table-hover table-bordered -->
                        <table class="table table-striped">
                            <thead class="thead-fixed">
                                <tr>
                                    <th class="text-center th-codigo">Código</th>
                                    <th class="th-nome">Nome</th>
                                    <th class="text-center th-data">Data</th>
                                    <th class="text-right th-data">Total R$</th>
                                    <th class="text-center th-action"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="" *ngFor="let model of _transacoes">
                                    <td class="text-center th-codigo">{{model.codigo}}</td>
                                    <td><label class="btn-link cursor-pointer" (click)="Ver(cotacao)">{{model.nome}}</label></td>
                                    <td class="text-center th-data">{{model.data_criacao}}</td>
                                    <td class="text-right th-data">R$ {{model.valor}}
                                    </td>
                                    <td class="text-center th-action-anexo">
                                        <i class="fa fa-search btn-link
                                            cursor-pointer" [routerLink]="'/compras/view/'+model.id" (click)="Ver(model)">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="_transacoes.length < 1">Nenhuma ordem de compra encontrada, no mês corrente!.
                    </div>
                </div>
            </div>
        </div>



    </div>
</div>