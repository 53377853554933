import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { EmpresaService } from 'src/app/service/configuracao/empresa.service';
import { ListOrcamento } from 'src/app/domain/orcamento/orcamento';
import { CabecalhoCotacao } from 'src/app/service/commom/cabecalho-cotacao';
import { Empresa } from 'src/app/domain/empresa/empresa';
import { RepositoryService } from 'src/app/service/repository.service';
import { EnumProcess } from 'src/app/domain/common/interactiveTable';
import { GlobalVariable } from 'src/app/core/global-variable';
import { Cotacao } from 'src/app/domain/cotacao/cotacao';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { PersonType, TypeUser } from 'src/app/domain/usuario/Usuario';
import { iif } from 'rxjs';

@Component({
  selector: 'app-cabecalho-orcamento',
  templateUrl: './cabecalho-orcamento.component.html',
  styleUrls: ['./cabecalho-orcamento.component.css']
})

export class CabecalhoOrcamentoComponent implements OnInit {

  @Input() _input_tipo_frete: string;
  url = GlobalVariable.BASE_API_URL
  _id = this.route.snapshot.params['id'];
  public cabecalho: any;

  _cpf_cnpj: string

  constructor(
    private route: ActivatedRoute,
    public userLoggedService: UserLoggedInService,
    private repositoty: RepositoryService
  ) {

  }

  ngOnInit() {
    this.cabecalho = {}
    this.getCabecalhoCotacao();
  }

  getCabecalhoCotacao() {
    let item = this.GetPage()
    if (this.userLoggedService.type == TypeUser.unregisteredProvider) {
      this._id = this.userLoggedService.id
      item = EnumProcess.cotacao
    }

    this.repositoty.path = 'empresa/cabecalho/get'
    this.repositoty.get(this._id + '&item=' + item).subscribe(
      d => {
        if (d && d != null) {
          this.cabecalho = d
          if (this.cabecalho.obra_id > 0)
            this.getAllObraRelacaoEmpresas(this.cabecalho.obra_id);
          this.setFormat()
        }
      }
    );
  }

  _obra_list_empresas = new Array<Empresa>();
  getAllObraRelacaoEmpresas(obra_id: number) {
    this.repositoty.path = "obra-cotacao/relacao-empresa/all"
    this.repositoty.getAll('', '', '?obra_id=' + obra_id + '&detail=true').subscribe(
      d => {
        if (d && d.length > 0)
          this._obra_list_empresas = d
      }
    );
  }

  setFormat() {

    if (this.cabecalho.inscricao_estadual) {
      const data = this.cabecalho.inscricao_estadual
      this.cabecalho.inscricao_estadual = data.slice(0, 2) +'.'+ data.slice(2, 5)+'.'+ data.slice(5, 8) +'-'+ data.slice(8, 20)
    }

    this.cabecalho._data_criacao = UseFullService.formatDate(new Date(this.cabecalho.data_criacao));
    this.cabecalho._data_max_recebimento_orcamento = UseFullService.formatDate(new Date(this.cabecalho.data_max_recebimento_orcamento));
    this.cabecalho._data_recebimento_produto = UseFullService.formatDate(new Date(this.cabecalho.data_recebimento_produto));
    this.cabecalho._meio_pagamento = Cotacao.GetMeioPagamento(this.cabecalho.meio_pagamento);

    if (this.cabecalho.c_cpnjcpf.length > 11)
      this.cabecalho.c_cpnjcpf = UseFullService.GetCnpjMasc(this.cabecalho.c_cpnjcpf)
    else
      this.cabecalho.c_cpnjcpf = UseFullService.GetCpfMasc(this.cabecalho.c_cpnjcpf)

    if (this.cabecalho.f_cnpj && UseFullService.IsCNPJ(this.cabecalho.f_cnpj))
      this.cabecalho.f_cnpj = UseFullService.GetCnpjMasc(this.cabecalho.f_cnpj)
    else
      this.cabecalho.f_cnpj = ''

    if (!this.cabecalho.f_responsavel && this.userLoggedService.type == TypeUser.provider)
      this.cabecalho.f_responsavel = this.userLoggedService.name

    const fob = 'FOB - Retirada por conta do cliente'
    const cif = 'CIF - Entrega por conta do fornecedor'
    if (this.cabecalho.tipo_frete == 3) {
      this.cabecalho._tipo_frete = 'CIF/FOB';
      if (((this.cabecalho.codigo_processo_venda && this.cabecalho.codigo_processo_venda.match('fob')) || this._input_tipo_frete == 'fob') && this._input_tipo_frete != 'cif')
        this.cabecalho._tipo_frete = fob;
      else if ((this.cabecalho.codigo_processo_venda && this.cabecalho.codigo_processo_venda.match('cif')) || this._input_tipo_frete == 'cif')
        this.cabecalho._tipo_frete = cif;
    } else
      this.cabecalho._tipo_frete = (this.cabecalho.tipo_frete == 1) ? cif : fob
  }

  getCPFCNPJ(cpf, cnpj) {
    if (this.userLoggedService.person == PersonType.fisica)
      return UseFullService.GetCpfMasc(cpf);
    else
      return UseFullService.GetCnpjMasc(cnpj);
  }

  GetPage() {
    if (window.location.href.match('cotacao'))
      return EnumProcess.cotacao;
    if (window.location.href.match('orcamento'))
      return EnumProcess.orcamento;
    if (window.location.href.match('compra'))
      return EnumProcess.compra;
    if (window.location.href.match('venda'))
      return EnumProcess.compra;
  }
}

