export class EnumGeral {
    public id: number;
    public tipo: number;
    public descricao: string;
    public item_id: number;
    public ativo: number;
}

export enum TypeEnumGeral {
    niveis = 3,
    categoria = 4,
    cor = 5
}

export class SelectedNivel extends EnumGeral {
    public active: boolean;
}

export class ProdutoCategoria extends EnumGeral {
    public id: number;
    public descricao: string;
    public origem: number;
    public pai_id: number;
    public pai_nome: string;
    public _active: boolean;
    public _selecionado: boolean;
    public ativo: number;

    public static FiltrarPorId(categorias: Array<ProdutoCategoria>, id: number): ProdutoCategoria {
        function filtrar(obj) {
            return ("id" in obj && obj.id == id);
        }
        var categoria = categorias.filter(filtrar);
        return categoria[0];
    }

    public static FiltrarPorIdOrigem(categorias: Array<ProdutoCategoria>, id: number, origem: number = 0): ProdutoCategoria {
        function filtrar(obj) {
            return ("id" in obj && obj.id == id && obj.origem == origem)
        }
        var categoria = categorias.filter(filtrar);
        return categoria[0];
    }

    public static GetName(categorias: Array<ProdutoCategoria>, id: number): string {
        function filtrar(obj) {
            if ("id" in obj && obj.id == id)
                return true;
            else
                return false;
        }
        var categoria = categorias.filter(filtrar);
        return categoria[0].descricao;
    }

    static GetCategorias(_categorias: ProdutoCategoria[], all_categories: ProdutoCategoria[]): string {
        var tempName = ''
        function IsNameCategory(obj) {
            return (obj.pai_nome == tempName)
        }
        if (!_categorias)
            return;
        var data = ":"
        _categorias.forEach(c => {
            if (c.origem == 1) {
                tempName = c.descricao;
                var tempCategories = all_categories.filter(IsNameCategory)
                tempCategories.forEach(e => {
                    data += e.id + ":"
                });
            } else
                data += c.id + ":"
        });
        return data
    }

    static GetCategoriasSelecionados(categorias: Array<any>, list: string, all_categories: ProdutoCategoria[]): Array<ProdutoCategoria> {
        var list_ids = list.split(':');
        //obtem categorias selecionadas
        function filtrar(obj) {
            for (let i = 0; i < list_ids.length; i++) {
                if (parseInt(list_ids[i]) == obj.id)
                    return true;
            }
            return false;
        }
        var data = categorias.filter(filtrar);
        //obtem todas as selecionadas que são vinculadas a uma categoria virtual
        function filtrarVirtual(obj) {
            for (let i = 0; i < list_ids.length; i++) {
                if (parseInt(list_ids[i]) == obj.id && (obj.pai_id && obj.pai_id > 0))
                    return true;
            }
            return false;
        }
        var dataVirtual = all_categories.filter(filtrarVirtual);
        //filtra categeria virtual de acordo com as categorias relacionadas que forão selecionadas
        var categoriaVirtual = ProdutoCategoria.VerificarRelalacaoCategoriaVirutal(dataVirtual, all_categories);
        categoriaVirtual.forEach(c => { data.push(c); });
        return data;
    }

    static VerificarRelalacaoCategoriaVirutal(data: ProdutoCategoria[], all_categories: ProdutoCategoria[]): ProdutoCategoria[] {

        function onlyUnique(obj, index, self) {
            var retorno = false
            data.forEach(c => {
                if (obj.origem == 1 && obj.descricao == c.pai_nome) {
                    retorno = true
                }
            });
            return retorno;
        }

        return all_categories.filter(onlyUnique);
    }

}