import { Injectable } from '@angular/core';
import { Authenticate } from 'src/app/domain/authenticate';
import { Permissao, EnumOperacoesFuncao } from 'src/app/domain/configuracao/funcao-sistema';
import { Router } from '@angular/router';
import { UserService } from '../user/user.service';
import { Usuario } from 'src/app/domain/usuario/Usuario';

@Injectable({
    providedIn: 'root'
})
export class UserLoggedInService {

    private _currentUser: Authenticate;
    public id: number;
    public name: string;
    public login: string;
    public expires: string;
    public type: number;
    public person: number;
    public img: string;
    public urlToken: string;
    static authorization: Array<Permissao>

    public Visualizar: boolean
    public Inserir: boolean
    public Editar: boolean
    public Excluir: boolean
    public Enviar: boolean

    constructor(private router: Router, private usuarioService: UserService) {
        this.SetCurrentUser();
    }

    SetCurrentUser(): void {
        try {            
            this._currentUser = JSON.parse(localStorage.getItem('currentUser')) as Authenticate;
            if (this._currentUser && this._currentUser.token && this._currentUser.user)
                this.SetProperties();
        } catch (error) {
        }
    }

    SetProperties() {
        this.id = this._currentUser.user.id
        this.name = this._currentUser.user.name
        this.login = this._currentUser.user.login
        this.expires = this._currentUser.expires
        this.type = this._currentUser.user.type
        this.img = this._currentUser.user.img
        this.urlToken = this._currentUser.urlToken
        this.person = this._currentUser.user.person
        if (this._currentUser.user.access)
            UserLoggedInService.authorization = JSON.parse(atob(this._currentUser.user.access)) as Array<Permissao>;
    }

    AllowedOperations() {
        this.Editar = UserLoggedInService.TemPermissao(this.router.url, EnumOperacoesFuncao.Editar);
        this.Inserir = UserLoggedInService.TemPermissao(this.router.url, EnumOperacoesFuncao.Inserir);
        this.Excluir = UserLoggedInService.TemPermissao(this.router.url, EnumOperacoesFuncao.Excluir);
        this.Enviar = UserLoggedInService.TemPermissao(this.router.url, EnumOperacoesFuncao.Enviar);
    }

    public static TemPermissao(url: string, operacao: EnumOperacoesFuncao): boolean {
        var result = false;
        UserLoggedInService.authorization.forEach(c => {
            var rota = c.rota
            if (parseInt(url.split('/').pop()))
                rota += url.split('/').pop();
            if (rota == url && c.operacao.match(operacao + ':'))
                result = true;
        })
        return result;
    }

}
