import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { EnumService } from 'src/app/service/usefull/enum.service';
import { ViaCepService, ViaCep } from 'src/app/service/usefull/viacep.service';
import { UserService } from 'src/app/service/user/user.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { Enderecos } from 'src/app/domain/empresa/Enderecos';
import { Estado } from 'src/app/domain/enum/estado';
import { Municipio } from 'src/app/domain/enum/municipio';
import { EnderecoService } from 'src/app/service/configuracao/endereco.service';
import { RepositoryService } from 'src/app/service/repository.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { EmpresaService } from 'src/app/service/configuracao/empresa.service';

declare var $: any;
@Component({
  selector: 'app-endereco-modal',
  templateUrl: './endereco-modal.component.html',
  styleUrls: ['./endereco-modal.component.css']
})
export class EnderecoModalComponent implements OnInit {

  @Output() _endereco_modal: EventEmitter<any> = new EventEmitter();
  @Input() _input_endereco: Enderecos;

  _endereco = new Enderecos()
  _viacep = new ViaCep();
  _estados = new Array<Estado>();
  _municipios = new Array<Municipio>();

  constructor(
    public alertConfirm: AlertConfirmService,
    public route: ActivatedRoute,
    public router: Router,
    public repositoryService: RepositoryService,
    public enumService: EnumService,
    public viaCepService: ViaCepService,
    public userService: UserService,
    public empresaService: EmpresaService,
    public enderecoService: EnderecoService,
    public userLoggedInService: UserLoggedInService) { }

  ngOnInit() {
    var _this = this;    
    $('#new-endereco-modal').on('show.bs.modal', function (e) { 
      $("#cep").mask("99999-999");
      $("#number").mask("999999999999999999999999");
      $("#numero").mask("999999999999999999999999");
      _this.GetEstado();
      setTimeout(() => { _this.CheckCreateEdit(); }, 500);       
    });
  }

  CheckCreateEdit() {          
    this._endereco = this._input_endereco 
    if(this._input_endereco.id > 0)
      this.GetMunicipios(this._endereco.uf, false)
  }
  
  GetEstado() {
    this.enumService.GetAll("Estado").subscribe(
      d => { this._estados = d; },
      e => { },
      () => { }
    );
  }

  OnChangeEstado() {
    this.GetMunicipios(this._endereco.uf);
  }

  GetMunicipios(uf: string, via_cep: boolean = true) {
    this.enumService.GetAll("Municipio", uf).subscribe(
      d => { this._municipios = d; },
      e => { },
      () => {
        if (via_cep)
          this.SetCidadeViaCep();
      }
    );
  }

  OnChangeCep() {
    this.LimparInputs();
    setTimeout(() => {
      if (this._endereco.cep != undefined && this._endereco.cep.length == 9)
        this.GetCep();
    }, 10);
  }

  GetCep() {    
    this.viaCepService.Get(this._endereco.cep).subscribe(
      d => { this._viacep = d; },
      e => { },
      () => { this.SetEndereco(); }
    );
  }

  SetEndereco() {
    if (this._viacep.erro)
      return;
    this._endereco.bairro = this._viacep.bairro;
    this._endereco.endereco = this._viacep.logradouro + " " + this._viacep.complemento;
    this._endereco.uf = this._viacep.uf;
    this.GetMunicipios(this._viacep.uf);
  }

  LimparInputs() {
    this._endereco.bairro = "";
    this._endereco.endereco = "";
    this._endereco.uf = "";
    this._endereco.cidade = "";    
  }

  reload() {
    this.LimparInputs()    
    this._endereco_modal.emit()
    $('#new-endereco-modal').modal('hide')    
  }

  SetCidadeViaCep() {
    this._endereco.cidade = this._viacep.ibge;
  }

  Insert(){    
    this._endereco.cidade_nome = $("option[value='"+this._endereco.cidade+"']").text()
    this._endereco.uf_nome = $("option[value='"+this._endereco.uf+"']").text()
    if(this._endereco.id > 0){
      this.enderecoService.Edit(this._endereco).subscribe(
        d => { },
        e => { },
        () => { this.reload(); }
      );

    }else{
      this.enderecoService.Create(this._endereco).subscribe(
        d => { },
        e => { },
        () => { this.reload(); }
      );
    }
  }
}