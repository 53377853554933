<!-- Modal -->
<div class="modal fade teste" id="orcamento-veiw-modal" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable
        modal-lg">
        <app-alert-confirm-secundario [_titulo_confirm]="_titulo_confirm" [_descricao_confirm]="_descricao_confirm" [_id_model_pai]="_id_model_pai" (SendConfirm)="ReciverConfirm($event)"></app-alert-confirm-secundario>
        <div class="modal-backdrop show"></div>

        <div class="modal-content {{_atualizando?'opacity':''}}">
            <div class="modal-header">
                <h4 class="modal-title">Orçamento:&nbsp;&nbsp; {{_orcamento.codigo}} </h4>
                <div class="orcamento-left-right">
                    <button type="button" class="btn btn-primary" [disabled]="!_button_anterior" (click)="SetLeft()"><i
                            class="fa fa-chevron-circle-left"
                            aria-hidden="true"> </i>&nbsp;&nbsp; Anterior</button>
                    <button type="button" class="btn btn-primary" [disabled]="!_button_proximo" (click)="SetRight()">Próximo&nbsp;&nbsp;<i
                            class="fa
                            fa-chevron-circle-right" aria-hidden="true"></i>
                    </button>
                </div>

                <div class="text-primary" style="float: right; margin-right:
                    35px;
                    margin-top: 10px;"><b class="text-dark">Status: </b>&nbsp;&nbsp;{{_orcamento.status == 1 && _orcamento._showProcesso ? 'Aguardando Aprovação' : 'Finalizado'}} </div>

                <div class="ibox-tools">
                    <div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="body-data">

                <div class="ibox">
                    <div class="ibox-content">

                        <app-cabecalho-orcamento></app-cabecalho-orcamento>

                        <div class="table-responsive min-height-grid-insumo
                            div-table-fixed" *ngIf="_carregamento_concluido">
                            <!-- table-striped table-hover table-bordered -->
                            <table class="table table-striped table-hover
                                table-bordered" style="border: 1px solid
                                #EBEBEB;">
                                <thead class="thead-fixed">
                                    <tr>
                                        <th class="th-sequece-produto" rowspan="2">Item</th>
                                        <th class="th-nome-produto" rowspan="2">Nome do Insumo
                                        </th>
                                        <th class="text-center th-select-unity
                                            column-unidade" rowspan="2">Marca</th>
                                        <th class="text-center th-select-unity
                                                column-unidade" rowspan="2">Unidade</th>
                                        <th class="text-center
                                            th-input-padrao-80" rowspan="2">Quantidade</th>
                                        <th class="text-center
                                            th-input-padrao-100" rowspan="2">Preço R$
                                        </th>
                                        <th class="text-center" *ngIf="boolIcms
                                            || boolIpi" [attr.colspan]="(boolIcms &&
                                            boolIpi) ? 3: (boolIpi) ? 2:1">Impostos R$
                                        </th>
                                        <th class="text-center
                                            th-input-padrao-120" rowspan="2">Valor Total - R$</th>
                                    </tr>
                                    <tr>
                                        <th class="text-center
                                            th-input-padrao-65" *ngIf="boolIcms">ICMS %</th>
                                        <th class="text-center
                                            th-input-padrao-65" *ngIf="boolIpi">IPI %
                                        </th>
                                        <th class="text-center
                                            th-input-padrao-80" *ngIf="boolIpi">Vlr.IPI</th>
                                    </tr>
                                </thead>
                                <tbody class="" *ngFor="let
                                    produto of
                                    _list_produtos">

                                    <tr class="{{!produto._index_impar_par ?
                                        'tr-background-impar':'tr-background-par'}}
                                        {{(produto._orcamento_produto.estoque ||
                                        !produto._orcamento_produto.id) ?
                                        'tr-checked-stock-of':''}}">
                                        <td class="text-center
                                            th-sequence-produto">{{produto._index}}</td>
                                        <td class="td-nome-produto">{{produto.nome}}</td>
                                        <td class="text-center th-select-unity">{{produto._orcamento_produto._marca}}</td>
                                        <td class="text-center th-select-unity">{{produto._unidade_nome}}</td>
                                        <td class="text-center th-input-stock">{{produto._quantidade}}</td>
                                        <td class="text-right th-input-price">
                                            R$ {{produto._orcamento_produto._preco_string}}
                                        </td>
                                        <td class="text-center th-input-stock" *ngIf="boolIcms">
                                            {{produto._orcamento_produto._icms_string}}
                                        </td>
                                        <td class="text-center th-input-stock" *ngIf="boolIpi">
                                            {{produto._orcamento_produto._ipi_string}}
                                        </td>
                                        <td class="text-right th-input-price" *ngIf="boolIpi">R$ {{produto._orcamento_produto._preco_ipi}}
                                        </td>
                                        <td class="text-right th-input-stock">R$ {{produto._orcamento_produto._preco_total}}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-8 text-termo">
                                <span>Valores expressos em moeda R$ real
                                    brasileiro;</span><br>
                                <span>Data de Entrega a contar a partir da
                                    confirmação do
                                    pedido;</span><br>
                                <span>A descarga do material é de
                                    responsabilidade do
                                    cliente;</span><br>
                                <span>Confira seu produto no ato da
                                    entrega/coleta;</span><br>
                                <span>Vendas no Cartão BNDES estão sujeitas à
                                    disponibilidade de crédito do cliente junto
                                    a
                                    instituição financeira emitente do cartão;</span><br>
                                <span>Estão excluídos de nosso fornecimento todo
                                    material ou
                                    serviço não espeficado neste documento;</span><br>
                                <Br />

                                <div class="processo-aprovacao" *ngIf="(listProcesso.length > 0 && _orcamento._showProcesso)">
                                    <h4>Processo de compra:</h4>
                                    <br />
                                    <div *ngFor="let processo of listProcesso">

                                        <div class="{{(listProcesso[listProcesso.length - 1] != processo)?'grid-proccess':''}}">
                                            <label class="processo-status">{{(processo.status_auditoria == 1) ? 'Aprovado': (processo.status_auditoria == 2)?'Reprovado':'Aguardando Avaliação'}}</label>
                                            <div class="proccess-b text-center">
                                                <i class="fa fa-{{(processo.status_auditoria == 1) ? 'check': (processo.status_auditoria == 2)?'thumbs-down':'question'}}"></i>
                                            </div>

                                            <label class="margin-buttom-5 margin-left-10"><b>{{(processo._tipo == 1)?'FATURANTE':'FISCALIZADORA'}}:</b>&nbsp;&nbsp;
                                    {{processo.nome_fantasia}}</label><br />
                                            <label class="margin-buttom-5 margin-left-10">{{processo.nome_responsavel}}</label><br />
                                            <hr style="margin:5px 0px 0px 0px;" *ngIf="listProcesso[listProcesso.length - 1] != processo" />
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <table class="table table-striped table-bordered
                                    table-aliquota" style="border: 0;">
                                    <thead class="th-checked-tipo-frete" *ngIf="_cotacao.tipo_frete == 3">
                                        <th colspan="2" style="padding-left: 0;border:0px;">Valor para entrega {{(_tipo_entrega == 'fob' && !_orcamento.fob)?'FOB':'CIF'}}</th>
                                    </thead>
                                    <tbody class="">
                                        <tr class="tr-background-impar">
                                            <td class="text-left"><b>Subtotal</b></td>
                                            <td class="th-input-padrao-120
                                                text-right">R$ {{_orcamento._subtotal}}
                                            </td>
                                        </tr>
                                        <tr class="tr-background-impar">
                                            <td class="text-left" style="border:0;"></td>
                                            <td class="th-input-padrao-120
                                                text-right" style="border:0;"></td>
                                        </tr>

                                        <tr *ngFor="let aliquota of _aliquotas" class="tr-background-impar">
                                            <td>{{aliquota.descricao}}</td>
                                            <td class="th-input-padrao-120
                                                text-right">R$ {{aliquota._valor}}
                                            </td>
                                        </tr>

                                        <tr class="tr-background-impar" *ngIf="_aliquotas.length> 0">
                                            <td class="text-left" style="border:0;"></td>
                                            <td class="th-input-padrao-120
                                                text-right" style="border:0;"></td>
                                        </tr>
                                        <tr class="tr-background-par">
                                            <td class="text-left"><b>VALOR TOTAL</b></td>
                                            <td class="th-input-padrao-120
                                                text-right"><b>R$
                                                    {{_orcamento._total}}</b></td>
                                        </tr>
                                        <tr class="tr-background-impar" *ngIf="_tipo_entrega == 'fob'">
                                            <td class="text-left" style="border:0;"></td>
                                            <td class="th-input-padrao-120
                                                text-right" style="border:0;"></td>
                                        </tr>
                                        <tr class="tr-background-impar" *ngIf="_tipo_entrega == 'fob'">
                                            <td class="text-left" style="border:0;" colspan="2"><b>Informações de custo frete FOB:</b></td>
                                        </tr>
                                        <tr class="tr-background-impar" *ngIf="_tipo_entrega == 'fob'">
                                            <td class="text-left">Valor Médio km</td>
                                            <td class="th-input-padrao-120
                                                text-right">R$ {{_orcamento.preco_frete_fob}}
                                            </td>
                                        </tr>
                                        <tr class="tr-background-impar" *ngIf="_tipo_entrega == 'fob'">
                                            <td class="text-left">Valor Total</td>
                                            <td class="th-input-padrao-120
                                                text-right">R$ {{_orcamento.preco_frete_total_fob}}
                                            </td>
                                        </tr>
                                        <tr class="tr-background-impar" *ngIf="_tipo_entrega == 'fob'">
                                            <td class="text-left">Distância</td>
                                            <td class="th-input-padrao-120
                                                text-right">{{_orcamento.distancia_km}} {{_orcamento.distancia_unidade}}</td>
                                        </tr>
                                        <tr class="tr-background-impar" *ngIf="_tipo_entrega == 'fob'">
                                            <td class="text-left">Tempo Percurso</td>
                                            <td class="th-input-padrao-120
                                                text-right">{{_orcamento.tempo}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                            </div>
                            <div class="col-sm-6 text-right">
                                <button type="submit" class="btn btn-default" (click)="VerDetalhada(_orcamento.id)">
                                    <i class="fa fa-search"></i>&nbsp;&nbsp;Visualizar Orçamento Detalhado</button>
                                <button type="submit" *ngIf="(_orcamento.status
                                    == 1) && !(_cotacao._expirado)  && listProcesso.length < 1" class="btn
                                    btn-primary
                                    margin-left-10" style="background:
                                    #08c65b; border-color: #08c65b;" (click)="ConfirmeRrightEnd()"><i
                                        class="fa fa-check"></i>&nbsp;&nbsp;Finalizar
                                    e Comprar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-message-modal [_titulo]="_msg_titulo" [_descricao]="_msg_descricao"></app-message-modal>