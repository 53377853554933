<!-- Modal -->
<div class="modal fade" id="pesquisa-localidade-modal" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <div class="modal-header">

                <div class="input-group">

                    <h4 class="modal-title margin-right-20">Selecione <span class="text-info"></span></h4>
                    <input class="form-control form-control-sm col-lg-5 input-pesquisa" placeholder="Pesquisar" [(ngModel)]="_buscar" type="text">
                    <span class="input-group-append">
                  <button class="btn btn-sm btn-primary" type="button" >Buscar</button>
                </span>
                </div>

                <div class="ibox-tools">
                    <div>
                        <button type="button" class="close" aria-label="Close">
                  <span aria-hidden="true"  data-dismiss="modal">&times;</span>
                </button>
                    </div>
                </div>

            </div>

            <div class="modal-max">
                <div class="row" style="margin-bottom:15px; padding: 15px;">

                    <div class="col-sm-4" *ngFor="let row of _input_modal[0] | filter : _buscar">
                        <label class="btn btn-check-table label-checked
                      btn-default {{(row._selecionado) ? 'active':''}} modal-checked-style" (click)="Selecionar(row)">
                      <span class="glyphicon-check-mark fa fa-check"></span>
                    </label>
                        <label class="margin-left-10 list-cor modal-checked-style-text">{{row.Nome}}</label>
                    </div>

                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="ConcluirSelecao()">Concluir</button>
            </div>
        </div>
    </div>
</div>