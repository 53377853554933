export class Enderecos {
    public id: number
    public usuario_id: number
    public cep: string
    public endereco: string
    public numero: string
    public complemento: string
    public bairro: string
    public cidade: string
    public cidade_nome: string
    public uf: string
    public uf_nome: string
    public data: Date
    public log: string
    public _origem: number
    public status: number
    public origem: number
}

export enum EnumOrigemEndereco {
    endereco = 0,
    empresa = 1,
    obraCotacao = 2
}