<div class="container-md" style="margin: 0 auto; max-width:1024px;">
    <div class="col-lg-12 wrapper-top-content">
        <div class="ibox">
            <div class="ibox-title" style="padding-right:20px;">
                <h5>Obras</h5>
            </div>
            <div class="ibox-content">
                <div class="table-responsive min-height-grid-insumo
                    div-table-fixed margin-top-15">
                    <!-- Eu amo simplificar,   table-striped table-hover table-bordered -->
                    <table class="table table-striped">
                        <thead class="thead-fixed">
                            <tr>
                                <th class="th-sequence"></th>
                                <th class="th-nome">Nome</th>
                                <th class="th-nome">Endereço</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let obra of obras" (click)="ver(obra)" class="cursor-pointer">
                                <td class="text-center th-sequence">{{obra._index}}</td>
                                <td><label class="btn-link cursor-pointer">{{obra.nome}}</label></td>
                                <td><label class="btn-link cursor-pointer">{{obra._endereco}}</label></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="obras.length <= 0">Nenhuma obra encontrada!</div>
            </div>
        </div>
    </div>
</div>