import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from 'src/app/core/global-variable';
import { Enderecos } from 'src/app/domain/empresa/Enderecos';

@Injectable({
  providedIn: 'root'
})
export class EnderecoService {
  
  private url = GlobalVariable.BASE_API_URL + "enderecos/";
  constructor(private http: HttpClient) { }

  GetAll() {
    return this.http.get<Enderecos[]>(this.url + "getall").pipe(
      map(res => {
        return res;
      })
    );
  }

  Get(id: number, cotacao_id = 0) {
    return this.http.get<Enderecos>(this.url + "get?id="+id+"&cotacao_id="+cotacao_id).pipe(
      map(res => {
        return res;
      })
    );
  }
  
  Create(data: Enderecos): Observable<any>{
    return this.http.post<any[]>(this.url+"create", data).pipe(
      map(res=> { return res; })
    );
  }
  
  Edit(data: Enderecos): Observable<any>{
    return this.http.put<any[]>(this.url+"edit", data).pipe(
      map(res=> { return res; })
    );
  }
  
  Delete(id: number): Observable<any> {    
    return this.http.delete(this.url+"delete?id="+id).pipe(
      map(res=> { return res; })
    );
  }
}
