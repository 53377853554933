import { ProdutoCategoria } from 'src/app/domain/suprimento/produto-categoria';
import { ProdutoUnidadeMedida } from 'src/app/domain/suprimento/produto-unidade-medida';
import { InsumoDoFornecedor } from 'src/app/domain/suprimento/produto-fornecedor';
import { ProdutoCategoriaNivel, ComponetesSelecao, TipoSelecao } from 'src/app/domain/suprimento/produto-categoria-nivel';

import { Suprimento } from 'src/app/domain/suprimento/suprimento';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { ProdutoComprador } from 'src/app/domain/suprimento/comprador/produto-comprador';
import { ProdutoCompradorService } from 'src/app/service/suprimento/comprador/produto-comprador.service';
import { GlobalVariable } from 'src/app/core/global-variable';
import { Router, ActivatedRoute } from '@angular/router';
import { CotacaoService } from 'src/app/service/suprimento/comprador/cotacao.service';
import { Cotacao, EnumTipoFrete } from 'src/app/domain/cotacao/cotacao';
import { EntregaParcialComprador } from 'src/app/domain/suprimento/comprador/entrega-parcial-comprador';
import { ProdutoService } from 'src/app/service/suprimento/produto.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { EmpresaService } from 'src/app/service/configuracao/empresa.service';
import { OrcamentoService } from 'src/app/service/orcamento/orcamento.service';
import { Orcamento, OrcamentoAliquota, EnumStatusOrcamento, EnumTipoOrcamento, EnumStatusTipoOrcamento } from 'src/app/domain/orcamento/orcamento';
import { OrcamentoProduto, EnumEstoqueProdutoOrcamento } from 'src/app/domain/orcamento/orcamento-produto';
import { OrcamentoProdutoService } from 'src/app/service/orcamento/orcamento-produto.service';
import { OrcamentoAliquotaService } from 'src/app/service/orcamento/orcamento-aliquota.service';
import { OrcamentoConfiguracaoService } from 'src/app/service/orcamento/orcamento-configuracao.service';
import { OrcamentoConfiguracao } from 'src/app/domain/orcamento/orcamento-configuracao';
import { Empresa } from 'src/app/domain/empresa/empresa';
import { Usuario, TypeUser } from 'src/app/domain/usuario/Usuario';
import { UserService } from 'src/app/service/user/user.service';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { Enderecos } from 'src/app/domain/empresa/Enderecos';
import { EnderecoService } from 'src/app/service/configuracao/endereco.service';
import { VerificaService } from 'src/app/service/auxiliar/verifica.service';
import { AuthenticationService } from 'src/app/service/authenticate/authentication.service';
import { Directive } from "@angular/core";

declare var $: any;


@Directive()
export class OrcamentoFornecedorCtrl {
    public _code_dinamic: string
    _code = 'app-orcamento-edit-aliquota'
    _code_right = 'app-orcamento-edit-end'
    url = GlobalVariable.BASE_API_URL

    constructor(
        public verificaService: VerificaService,
        public configuracaoService: OrcamentoConfiguracaoService,
        public aliquotaService: OrcamentoAliquotaService,
        public orcamentoProdutoService: OrcamentoProdutoService,
        public orcamentoService: OrcamentoService,
        public httpProduto: ProdutoService,
        public confirmService: AlertConfirmService,
        public produtoCompradorService: ProdutoCompradorService,
        public route: ActivatedRoute,
        public router: Router,
        public cotacaoService: CotacaoService,
        public userLoggedService: UserLoggedInService,
        public empresaService: EmpresaService,
        public userService: UserService,
        public enderecoService: EnderecoService,
        public httpAuthenticate: AuthenticationService) {
        this._produto_selecionado = new ProdutoComprador();

        this.confirmService.UnsetObservers();
        this.confirmService.confirmEmitter$.subscribe(c => {
            this.DeleteAliquota(c);
            this.EndBudget(c);
            this.EditBudget(c);
            this.EditarOrcamento(c);
            this.DeleteAliquotaFob(c);
        });

        this._ha_produto = false;
        this._buscar = '';
        this._categoria_id = 0;
        this._suprimento = new Suprimento();
        this._suprimento.insumos = new Array<InsumoDoFornecedor>();
        this._input_nivel = new ProdutoCategoriaNivel();
        this._categorias = new Array<ProdutoCategoria>();
    }

    _input_cotacao = "";
    _list_produtos = new Array<ProdutoComprador>();
    _produto_selecionado: ProdutoComprador;
    public _input_nivel: ProdutoCategoriaNivel;
    public _suprimento: Suprimento;
    public _categorias: ProdutoCategoria[];
    public _unidade_medida: ProdutoUnidadeMedida[];
    public _ha_produto: boolean;
    count_nivel = 0;
    public _categoria_nivel: boolean;
    public _niveis: Array<ProdutoCategoriaNivel>;
    public _buscar: string;
    public _categoria_id: number;
    public _vinculado: boolean;
    public _loading_save: boolean;
    public _enumComponetesSelecao = ComponetesSelecao;
    orcamento_id = this.route.snapshot.params['id'];
    _orcamento = new Orcamento();
    _orcamento_produtos = new Array<OrcamentoProduto>();
    _aliquotas_fob = new Array<OrcamentoAliquota>();
    _aliquotas = new Array<OrcamentoAliquota>();
    _aliquota = new OrcamentoAliquota();
    _aliquotaFob = new OrcamentoAliquota();
    _temp_aliquota = new OrcamentoAliquota();
    _temp_aliquotaFOB = new OrcamentoAliquota();
    _configuracoes = new OrcamentoConfiguracao();

    ngOnInit() {
        this.userLoggedService.SetCurrentUser();
        this.userLoggedService.AllowedOperations();
        this._niveis = new Array<ProdutoCategoriaNivel>();
        this.GetConfiguracoes();
        this.SetListaUnidadeMedida();
        this.GetEmpresaLoggedIn();
    }

    ReceiverEntregaProduto(e){}


    removeFile(cifOrFob: number) {
        if (cifOrFob === EnumTipoFrete.cif || this._cotacao.tipo_frete === EnumTipoFrete.cif)
            this._orcamento.file_proposta_cif = '';
        if (cifOrFob === EnumTipoFrete.fob || this._cotacao.tipo_frete === EnumTipoFrete.fob)
            this._orcamento.file_proposta_fob = '';
        this.orcamentoService.removeProposta(this._orcamento).subscribe(d => {
            this._orcamento.file_proposta_fob = d.file_proposta_fob
            this._orcamento.file_proposta_cif = d.file_proposta_cif
        });
    }


    uploadFile(input: any, cifOrFob: number) {
        if (input.files && input.files[0]) {
            if (this._cotacao.tipo_frete === EnumTipoFrete.cif) {
                this._orcamento.file_proposta_cif = input.files[0];
            } else if (this._cotacao.tipo_frete === EnumTipoFrete.fob) {
                this._orcamento.file_proposta_fob = input.files[0];
            } else {
                if (cifOrFob === EnumTipoFrete.cif)
                    this._orcamento.file_proposta_cif = input.files[0];
                if (cifOrFob === EnumTipoFrete.fob)
                    this._orcamento.file_proposta_fob = input.files[0];
            }
        }

        this.orcamentoService.uploadProposta(this._orcamento).subscribe(d => {
            this._orcamento.file_proposta_fob = d.file_proposta_fob
            this._orcamento.file_proposta_cif = d.file_proposta_cif
        });
    }

    GetConfiguracoes() {
        this.configuracaoService.GetFornecedor().subscribe(
            d => { this._configuracoes = d; }
        );
    }

    GetOrcamento() {
        this.orcamentoService.Get(this.orcamento_id).subscribe(
            d => {
                this._orcamento = d;
            },
            e => { },
            () => {
                this.GetCotacao(this._orcamento.cotacao_id);
                this.GetAllProdutos(this._orcamento.cotacao_id);
                this.SetUser(this.userLoggedService.id);
            }
        );
    }

    public loadding_produto_orcamento: boolean
    public boolIcms: boolean
    public boolIpi: boolean
    GetOrcamentoProduto(orcamento_id: number) {
        this.orcamentoProdutoService.GetAll(orcamento_id).subscribe(
            d => { this._orcamento_produtos = d; },
            e => { },
            () => {
                ProdutoComprador.SetProdutoOrcamento(this._list_produtos, this._orcamento_produtos, this._orcamento);
                this.loadding_produto_orcamento = true
                this.HaIPIICMS();
                this.CarregarAliquota(this._orcamento.id);
            }
        );
    }

    HaIPIICMS() {
        this._orcamento_produtos.forEach(c => {
            if (c.icms > 0)
                this.boolIcms = true
            if (c.ipi > 0)
                this.boolIpi = true
        });
    }

    GetLoadingOrcamento(): boolean { return this.loadding_produto_orcamento; }

    _cotacao = new Cotacao();
    GetCotacao(id: number) {
        this.cotacaoService.Get(id).subscribe(
            d => { this._cotacao = d },
            e => { },
            () => {
                this.verificaService.EstadoOrcamento(this._orcamento, this._cotacao);
                this._cotacao._meio_pagamento = Cotacao.GetMeioPagamento(this._cotacao.meio_pagamento);
                Cotacao.SetFormat(this._cotacao);
                this.GetEndereco(this._cotacao.endereco_id);
            }
        );
    }

    _endereco = new Enderecos()
    GetEndereco(endereco_id: number) {
        this.enderecoService.Get(endereco_id).subscribe(
            d => { this._endereco = d }
        )
    }

    GetAllProdutos(cotacao_id: number) {
        this.produtoCompradorService.GetAll(cotacao_id).subscribe(
            d => {
                d.forEach(c => { c._orcamento_produto = new OrcamentoProduto() });
                this._list_produtos = d;
            },
            e => { },
            () => {
                this.SetConfig();
                this.CarregarDadosTrunk();
                this.GetOrcamentoProduto(this._orcamento.id);
            }
        );
    }

    _ha_anexo = false;
    SetConfig() {
        for (let i = 0; i < this._list_produtos.length; i++) {
            this._list_produtos[i]._index = i + 1;
            this._list_produtos[i]._index_impar_par = Number.isInteger((i + 1) / 2);
            this._list_produtos[i]._name_file = this._list_produtos[i].anexo;
            if (this._list_produtos[i].unidade_id > 0)
                this._list_produtos[i]._unidade_nome = ProdutoUnidadeMedida.Get(this._unidade_medida, this._list_produtos[i].unidade_id).nome;
            this._list_produtos[i]._quantidade = this._list_produtos[i].quantidade.toFixed(2).replace(".", ",");
            if (this._list_produtos[i].anexo && this._list_produtos[i].anexo != 'null')
                this._ha_anexo = true;
        }
    }

    SetListaUnidadeMedida() {
        this.httpProduto.GetAllUnitsMeasured().subscribe(
            data => { this._unidade_medida = data; },
            error => { },
            () => {
                this.GetOrcamento();
            }
        );
    }

    public _nome_arquivo: string;
    public _src_arquivo: string;
    MostrarFile(data: ProdutoComprador) {
        if (data._file) {
            var file = data._file;
            this._nome_arquivo = file.name;
            var _this = this;
            var reader = new FileReader();
            reader.onload = function (e) {
                var target = e.target as any;
                if (file.type == "application/pdf") {
                    _this._src_arquivo = undefined;
                    setTimeout(() => {
                        $("#exibir-pdf").html("<iframe width='100%' height='" + ($('body').height() - 100) + "px' src='" + target.result + "'></iframe>");
                    }, 10);
                } else
                    _this._src_arquivo = target.result;
            };
            reader.readAsDataURL(file);
        } else
            this.CarregarDataModal(data);
        $('#data-modal').modal('show');
    }

    CarregarDataModal(data: ProdutoComprador) {
        this._nome_arquivo = "";
        this._src_arquivo = "";
        $("#exibir-pdf").html("");

        this._nome_arquivo = data.anexo;
        if (!data.anexo.match(".png") && !data.anexo.match(".jpg") && !data.anexo.match(".gif")) {
            this._src_arquivo = undefined;
            setTimeout(() => {
                $("#exibir-pdf").html("<iframe width='100%' height='" + ($('body').height() - 100) + "px' src='" + GlobalVariable.BASE_API_URL + "comprador/cotacao_" + data.cotacao_id + "/" + data.id + "_" + data.anexo + "'></iframe>");
            });
        } else
            this._src_arquivo = GlobalVariable.BASE_API_URL + "comprador/cotacao_" + data.cotacao_id + "/" + data.id + "_" + data.anexo;
    }

    carregarArquivoPropota(id, nome) {
        this._nome_arquivo = "";
        this._src_arquivo = "";
        $("#exibir-pdf").html("");
        const _src_arquivo = GlobalVariable.BASE_API_URL + "fornecedor/orcamento_" + id + "/" + nome;
        setTimeout(() => { $("#exibir-pdf").html("<iframe width='100%' height='" + ($('body').height() - 100) + "px' src='" + _src_arquivo + "'></iframe>"); });
        $('#data-modal').modal('show');
    }

    _carregamento_concluido = false;
    CarregarDadosTrunk() {
        this._list_produtos.forEach(c => {
            this.produtoCompradorService.GetAllEntregaPacial(c.id).subscribe(
                d => { c.EntregasParcial = d; },
                e => { },
                () => {
                    c._qt_entrega_parcial = c.EntregasParcial.length;
                    var x = 1;
                    c.EntregasParcial.forEach(c => {
                        c._index = x++;
                        EntregaParcialComprador.SetFormatDate(c);
                    });
                }
            );
        });
        this._carregamento_concluido = true;
    }

    Reload() { this.router.navigateByUrl("/cotacao/edit-cotacao/" + this._cotacao.id); }

    OpenModalTrunck(produto: ProdutoComprador) {
        if (produto.unidade_id < 1)
            throw "Selecione uma unidade!";
        if (produto.quantidade <= 0)
            throw "Preencha a quantidade!";

        this._produto_selecionado = produto;
        this._produto_selecionado._unidade_nome = ProdutoUnidadeMedida.Get(this._unidade_medida, produto.unidade_id).nome;

        $("#data-entraga-parcial-modal").modal("show");
    }

    OnChangeProduct(data: OrcamentoProduto, produto_id: number = 0) {
        setTimeout(() => {
            data.icms = data._icms;
            data.ipi = data._ipi;
            data.preco = data._preco;
            if (!data.id || data.id < 1) {
                data.orcamento_id = this._orcamento.id;
                data.produto_comprador_id = produto_id;
                this.orcamentoProdutoService.Create(data).subscribe(
                    d => { data.id = d.guid; }
                );
            } else
                this.orcamentoProdutoService.Edit(data).subscribe();
            OrcamentoProduto.SetConfig(data, this._list_produtos, this._orcamento);
            Orcamento.SetConfig(this._orcamento, this._orcamento_produtos, this._aliquotas);
            if (this._cotacao.tipo_frete == EnumTipoFrete.ciffob)
                Orcamento.SetConfigFob(this._orcamento, this._orcamento_produtos, this._aliquotas_fob);
            this.OnChangeOrcamentoPreco();
        }, 10);
    }

    selectFob(e) {
        this._orcamento.fob = (e.srcElement.checked) ? 0 : 1;
        this.OnChangeOrcamento(this._orcamento);
    }

    selectCif(e) {
        this._orcamento.cif = (e.srcElement.checked) ? 0 : 1;
        this.OnChangeOrcamento(this._orcamento);
    }

    OnChangeOrcamento(orcamento: Orcamento) {
        setTimeout(() => {
            this.orcamentoService.Edit(orcamento).subscribe(
                d => { },
                e => { throw (e) },
                () => {
                    setTimeout(() => { this.ngOnInit(); }, 50);
                }
            );
        }, 10);
    }

    OnChangeOrcamentoPreco() {
        setTimeout(() => { this.orcamentoService.Edit(this._orcamento).subscribe(); }, 10);
    }

    AdicionarAliquota(aliquota: OrcamentoAliquota) {
        aliquota.orcamento_id = this._orcamento.id;
        this.aliquotaService.Create(aliquota).subscribe(
            d => { this.CarregarAliquota(this._orcamento.id, true); },
            e => { },
            () => { this._aliquota = new OrcamentoAliquota(); }
        );
    }

    AdicionarAliquotaFob(aliquota: OrcamentoAliquota) {
        aliquota.orcamento_id = this._orcamento.id;
        this.aliquotaService.CreateFOB(aliquota).subscribe(
            d => { this.CarregarAliquotaFob(this._orcamento.id, true); },
            e => { },
            () => { this._aliquotaFob = new OrcamentoAliquota(); }
        );
    }

    CarregarAliquota(orcamento_id: number, update = false) {
        this.CarregarAliquotaFob(orcamento_id);
        this.aliquotaService.GetAll(orcamento_id).subscribe(
            d => { this._aliquotas = d; },
            e => { },
            () => {
                OrcamentoAliquota.Set(this._aliquotas);
                setTimeout(() => {
                    Orcamento.SetConfig(this._orcamento, this._orcamento_produtos, this._aliquotas);
                    if (update)
                        this.OnChangeOrcamentoPreco();
                }, 10);
            }
        );
    }

    CarregarAliquotaFob(orcamento_id: number, update = false) {
        this.aliquotaService.GetAllFOB(orcamento_id).subscribe(
            d => { this._aliquotas_fob = d; },
            e => { },
            () => {
                OrcamentoAliquota.Set(this._aliquotas_fob);
                setTimeout(() => {
                    Orcamento.SetConfigFob(this._orcamento, this._orcamento_produtos, this._aliquotas_fob);
                    if (update)
                        this.OnChangeOrcamentoPreco();
                }, 10);
            }
        );
    }

    ConfirmeRemoveAliquotaFob(data: OrcamentoAliquota) {
        this._temp_aliquotaFOB = data;
        this.confirmService._code = this._code + 'fob';
        this.confirmService._title = "Excluir Alíquota";
        this.confirmService._message = "Deseja remover a alíquota: <b>" + data.descricao + "</b>, do orçamento: <b>" + this._orcamento.codigo + "</b>?";
        this.confirmService.Show();
    }

    DeleteAliquotaFob(confirm: boolean) {
        if (!this._temp_aliquotaFOB || !confirm || this.confirmService._code != this._code + 'fob')
            return;
        this.aliquotaService.DeleteFOB(this._temp_aliquotaFOB.id).subscribe(
            d => { },
            e => { },
            () => { this.CarregarAliquotaFob(this._orcamento.id, true); }
        );
    }

    ConfirmeRemoveAliquota(data: OrcamentoAliquota) {
        this._temp_aliquota = data;
        this.confirmService._code = this._code;
        this.confirmService._title = "Excluir Alíquota";
        this.confirmService._message = "Deseja remover a alíquota: <b>" + data.descricao + "</b>, do orçamento: <b>" + this._orcamento.codigo + "</b>?";
        this.confirmService.Show();
    }

    ConfirmeRrightEnd() {
        this.ValidarItens()
        this.ConfirmaEnvioOrcamento()
    }

    ConfirmaEnvioOrcamento() {
        if (this._cotacao.tipo_frete == EnumTipoFrete.ciffob && this._orcamento.fob && this._orcamento.cif)
            throw ("Selecione ao menos uma forma de entrega, para continuar. CIF/FOB.");
        /*
            if (!this._li_termos)
                throw ("Leia os termos para enviar o orçamento.");
        */
        this.confirmService._code = this._code_right;
        this.confirmService._title = "Enviar Orçamento";
        this.confirmService._message = "Clique em <b>SIM</b> para confirmar o envio.";
        this.confirmService.Show();
    }

    ValidarItens() {
        this._list_produtos.forEach(pc => {
            if (pc._orcamento_produto.estoque == EnumEstoqueProdutoOrcamento.possui) {
                if (!pc._orcamento_produto.preco || parseFloat(pc._orcamento_produto.preco) <= 0)
                    throw ("Todos os produtos deve ter preço maior que 0, caso não haja este produto no estoque desmarque-o.")
                if (pc._marcas && pc._marcas.length > 0 && pc._orcamento_produto.marca_id < 1)
                    throw ("Há produto(s) sem referência de marca.")
            }
        })

        if (this._cotacao.tipo_frete == EnumTipoFrete.fob && !this._orcamento.file_proposta_fob) {
            throw ("Não ha anexo para proposta FOB.")
        } else if (this._cotacao.tipo_frete == EnumTipoFrete.cif && !this._orcamento.file_proposta_cif) {
            throw ("Não ha anexo para proposta CIF.")
        } else if (this._cotacao.tipo_frete == EnumTipoFrete.ciffob) {
            if (!this._orcamento.file_proposta_cif && !this._orcamento.cif)
                throw ("Não ha anexo para proposta CIF.")
            if (!this._orcamento.file_proposta_fob && !this._orcamento.fob)
                throw ("Não ha anexo para proposta FOB.")
        }
    }

    ConfirmeEditEstoque() {
        this.confirmService._code = "estoque-edit-null";
        this.confirmService._title = "Reeabrir Orçamento Para Edição";
        this.confirmService._message = "Ao reeabrir o orçamento ele deixarar de ser uma pre-venda, Clique em <b>SIM</b> para confirmar.";
        this.confirmService.Show();
    }

    EditOrcamento(confirm: boolean) {
        if (!this._temp_aliquota || !confirm || this.confirmService._code != this._code) {
            return;
        } else {
            this.aliquotaService.Delete(this._temp_aliquota.id).subscribe(d => { }, e => { },
                () => { this.CarregarAliquota(this._orcamento.id); }
            );
        }
    }

    DeleteAliquota(confirm: boolean) {
        if (!this._temp_aliquota || !confirm || this.confirmService._code != this._code)
            return;
        this.aliquotaService.Delete(this._temp_aliquota.id).subscribe(
            d => { },
            e => { },
            () => { this.CarregarAliquota(this._orcamento.id, true); }
        );
    }

    EndBudget(confirm: boolean) {
        if (!this._orcamento || !confirm || this.confirmService._code != this._code_right) {
            return;
        } else {
            this._orcamento.status = EnumStatusOrcamento.enviado;
            this._orcamento.data_envio = UseFullService.formatDateTimeUS(new Date);
            this._orcamento.user_id = this.userLoggedService.id;
            this._orcamento.valor_total = UseFullService.ConvertFloat(this._orcamento._total);
            this._orcamento.sub_total = UseFullService.ConvertFloat(this._orcamento._subtotal);

            this.orcamentoService.Edit(this._orcamento).subscribe(
                d => { },
                e => { },
                () => {
                    if (this._orcamento.tipo == EnumTipoOrcamento.sugerido)
                        this.router.navigateByUrl("/orcamentos/sugerido/view/" + this._orcamento.id);
                    else
                        this.router.navigateByUrl("/orcamentos/view/" + this._orcamento.id);
                }
            );
        }
    }

    EditarOrcamento(confirm: boolean) {
        if (!this._orcamento || !confirm || this.confirmService._code != this._code_dinamic)
            return;
        else {
            this._orcamento.valor_total = UseFullService.ReplaceFloat(this._orcamento._total);
            this._orcamento.sub_total = UseFullService.ReplaceFloat(this._orcamento._subtotal);
            this.orcamentoService.Edit(this._orcamento).subscribe(
                d => { },
                e => { },
                () => { this.router.navigateByUrl("/orcamentos/edit/" + this._orcamento.id); }
            );
        }
    }

    EditBudget(confirm: boolean) {
        if (!this._orcamento || !confirm || this.confirmService._code != "estoque-edit-null") {
            return;
        } else {
            this.orcamentoService.GetPaiSugerido(this._orcamento.id).subscribe(d => {
                if (!d)
                    return;
                d.status = EnumStatusOrcamento.aberto;
                d.valor_total = UseFullService.ReplaceFloat(this._orcamento._total);
                d.sub_total = UseFullService.ReplaceFloat(this._orcamento._subtotal);
                this.orcamentoService.Edit(d).subscribe(
                    d => { },
                    e => { },
                    () => { this.router.navigateByUrl("/orcamentos/edit/" + d.id); }
                );
            });
        }
    }


    _li_termos = false;
    ClickLiTermos() {
        if (this.userLoggedService.Editar || this.userLoggedService.type == TypeUser.unregisteredProvider)
            this._li_termos = !this._li_termos;
    }

    ClickChecked(produto: OrcamentoProduto, e) {
        produto.estoque = (e.srcElement.checked) ? 0 : 1;
        this.OnChangeProduct(produto, produto.id);
    }

    async ClickCheckedIcms(orcamento: Orcamento, e) {
        orcamento.ha_icms = (e.srcElement.checked) ? 0 : 1;
        await this.OnChangeOrcamento(orcamento);
        if (orcamento.ha_icms) {
            this._orcamento_produtos.forEach(c => {
                c._icms = 0;
                this.OnChangeProduct(c);
            });
        }
    }

    async ClickCheckedIpi(orcamento: Orcamento, e) {
        orcamento.ha_ipi = (e.srcElement.checked) ? 0 : 1;
        await this.OnChangeOrcamento(orcamento);
        if (orcamento.ha_ipi) {
            this._orcamento_produtos.forEach(c => {
                c._ipi = 0;
                this.OnChangeProduct(c);
            });
        }
    }

    ChangeDescontoLinear(orcamento: Orcamento, e) {
        this.OnChangeOrcamento(orcamento);
        this._orcamento_produtos.forEach(c => {
            c._preco = "0";
            this.OnChangeProduct(c);
        });
    }


    _empresa = new Empresa();
    GetEmpresaLoggedIn() {
        this.empresaService.GetLoggedIn().subscribe(
            d => {
                if (d != null)
                    this._empresa = d;
                else if (this.userLoggedService.type != TypeUser.unregisteredProvider)
                    this.SetMessageEmpresa()
            },
            e => { this.SetMessageEmpresa() },
            () => { }
        );
    }
    public _msg_titulo: string;
    public _msg_descricao: string;
    SetMessageEmpresa() {
        if (this.userLoggedService.type != TypeUser.unregisteredProvider) {
            this._msg_titulo = '<label class="titulo-msg">Dados da Empresa Desatualizados&nbsp;&nbsp;<i class="fa fa-thumbs-down"></i></label>'
            this._msg_descricao = '<div class="text-center"><br />Os dados da empresa estão desatualizados ou incompleto para ter acesso a todas as ferramentas atualize-os.<br /><br /></div>'
            this.verificaService.AutalizarDadosEmpresa()
        }
    }

    _usuario_responsavel = new Usuario();
    SetUser(id: number) {
        this.userService.get(id).subscribe(
            d => {
                if (d && d != null)
                    this._usuario_responsavel = d;
            },
            e => { },
            () => { }
        );
    }


    ConfiguracoesEmpresa() { this.router.navigateByUrl("/configuracao") }
}