import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from '../../core/global-variable';

import { Suprimento } from '../../domain/suprimento/suprimento';
import { ProdutoCategoria } from 'src/app/domain/suprimento/produto-categoria';
import { ProdutoUnidadeMedida } from 'src/app/domain/suprimento/produto-unidade-medida';
import { ProdutoFornecedor, InsumoDoFornecedor } from 'src/app/domain/suprimento/produto-fornecedor';
import { Produto } from 'src/app/domain/suprimento/produto';

@Injectable({
  providedIn: 'root'
})
export class SuprimentoService {

  private url = GlobalVariable.BASE_API_URL + "supplies/";
  private produto: Suprimento[];
  constructor(private http: HttpClient) { }

  GetVinculos(id: number): Observable<Suprimento> {
    return this.http.get<Suprimento>(this.url + "GetVinculos?id=" + id).pipe(
      map(res => {
        return res;
      })
    );
  }

  get(id: number): Observable<InsumoDoFornecedor> {
    return this.http.get<InsumoDoFornecedor>(this.url + "/get?id=" + id).pipe(
      map(res => {
        return res;
      })
    );
  }

  getCountProdutoFornecedor(): Observable<number> {
    return this.http.get<number>(this.url + "/Provider/getcountall").pipe(
      map(res => {
        return res;
      })
    );
  }

  getAll(
    buscar: string,
    categoria_id: number,
    vinculado: boolean,
    page: number = 1,
    all: boolean = false,
    categoria_virtual_id: number = 0,
    niveis: string = "",
    status = 0,
    limit = 50): Observable<Suprimento> {
    return this.http.get<Suprimento>(this.url + "getallproducts?limite=" + limit + "&buscar=" +
      buscar + "&categoria_id=" + categoria_id + "&vinculado=" +
      vinculado + "&page=" + page + "&all=" + all + "&categoria_virtual_id=" +
      categoria_virtual_id + "&niveis=" + niveis + "&status=" + status).pipe(
        map(res => {
          return res;
        })
      );
  }

  getCountAll(
    buscar: string,
    categoria_id: number,
    vinculado: boolean,
    page: number = 1,
    all: boolean = false, categoria_virtual_id: number = 0, status = 0,
    limit = 50): Observable<number> {
    return this.http.get<number>(this.url + "getcountallproducts?limite=" + limit + "&buscar=" + buscar +
      "&categoria_id=" + categoria_id + "&vinculado=" + vinculado + "&page=" + page + "&all=" + all +
      "&categoria_virtual_id=" + categoria_virtual_id + "&status=" + status).pipe(
        map(res => {
          return res;
        })
      );
  }

  GetInsumoNivel(buscar: string, categoria_id: number): Observable<InsumoDoFornecedor> {
    return this.http.get<InsumoDoFornecedor>(this.url + "GetInsumoNivel?buscar=" + buscar + "&categoria_id=" + categoria_id).pipe(
      map(res => {
        return res;
      })
    );
  }

  getAllCategories(): Observable<ProdutoCategoria[]> {
    return this.http.get<ProdutoCategoria[]>(this.url + "getAllcategories").pipe(
      map(res => {
        return res;
      })
    );
  }

  SetProductProvider(productProvider: ProdutoFornecedor): Observable<ProdutoFornecedor[]> {
    if (productProvider.id > 0)
      return this.SetEditProductProvider(productProvider);
    else
      return this.SetSaveProductProvider(productProvider);
  }

  SetSaveProductProvider(productProvider: ProdutoFornecedor): Observable<ProdutoFornecedor[]> {
    var url = GlobalVariable.BASE_API_URL + "suppliesProvider/create";
    return this.http.post<ProdutoFornecedor[]>(url, productProvider).pipe(
      map(res => { return res; })
    );
  }

  SetEditProductProvider(productProvider: ProdutoFornecedor): Observable<ProdutoFornecedor[]> {
    var url = GlobalVariable.BASE_API_URL + "suppliesProvider/edit";
    return this.http.put<ProdutoFornecedor[]>(url, productProvider).pipe(
      map(res => { return res; })
    );
  }

  GetAllUnitsMeasured(): Observable<ProdutoUnidadeMedida[]> {
    return this.http.get<ProdutoUnidadeMedida[]>(GlobalVariable.BASE_API_URL + "suppliesProvider/GetAllUnitsMeasured").pipe(
      map(r => { return r; })
    );
  }

  Delete(id: number): Observable<Suprimento> {
    return this.http.delete<Suprimento>(this.url + "suppliesProvider/delete?id=" + id).pipe(
      map(res => {
        return res;
      })
    );
  }



  getAllNiveis(): Observable<Produto[]> {
    return this.http.get<Produto[]>(this.url + 'getall/haniveis').pipe(map(r => { return r }));
  }
}
