import { Component, OnInit } from '@angular/core';
import { ProdutoService } from 'src/app/service/suprimento/produto.service';
import { InsumoComponentCtrl } from '../../suprimento/insumo/insumo-component-ctrl';
import { ProdutoCategoriaNivel } from 'src/app/domain/suprimento/produto-categoria-nivel';
import { Produto } from 'src/app/domain/suprimento/produto';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { ObraService } from 'src/app/service/obra/obra.service';
import { Obra } from 'src/app/domain/obra/obra';
import { RepositoryService } from 'src/app/service/repository.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ObraCotacao } from 'src/app/domain/gestao/obra-cotacao';
import { ProdutoComprador } from 'src/app/domain/suprimento/comprador/produto-comprador';
import { RelacaoObraProduto, RelacaoProdutoMarcaCategoria, Marca, CompradorCategoriaProduto } from 'src/app/domain/suprimento/marca';
import { timeStamp, exception } from 'console';
import { EnumState } from 'src/app/domain/common/interactiveTable';
import { TypeEnumGeral, ProdutoCategoria } from 'src/app/domain/suprimento/produto-categoria';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { ProdutoUnidadeMedida } from 'src/app/domain/suprimento/produto-unidade-medida';
import { OrigemProduto } from 'src/app/domain/suprimento/produto-fornecedor';
declare var $: any
@Component({
  selector: 'app-relacao-obra-produto-marca-list',
  templateUrl: './relacao-obra-produto-marca-list.component.html',
  styleUrls: ['./relacao-obra-produto-marca-list.component.css'],
  providers: [ProdutoService]
})
export class RelacaoObraProdutoMarcaListComponent implements OnInit {
  _buscar_produto = []
  _carregando = false
  _obra = new ObraCotacao()
  _model = new RelacaoObraProduto()
  _input = new Array<Marca>()

  constructor(
    private userLoggedIn: UserLoggedInService,
    private repository: RepositoryService,
    private obraService: ObraService,
    private confirm: AlertConfirmService,
    private route: ActivatedRoute,
    private router: Router,
    private httpProduto: ProdutoService
  ) { }

  id = this.route.snapshot.params['id'];
  ngOnInit() {
    this.userLoggedIn.AllowedOperations();
    this.confirm.UnsetObservers();
    this.GetAllCategoria();
  }

  onKeydownCategoria(event, categoria) {
    if (event.key === "Enter" && this._model._type) {
      this.createCategoria(categoria)
    }
  }

  _unidade_medida = new Array<ProdutoUnidadeMedida>();
  SetListaUnidadeMedida() {
    this.httpProduto.GetAllUnitsMeasured().subscribe(d => {
      this._unidade_medida = d;
      this.getAll();
    });
  }

  _list_categoria = new Array<CompradorCategoriaProduto>();
  GetAllCategoria() {
    this.repository.path = 'supplies/relacao-produto/getall/categoria-por-obra';
    this.repository.getAll('&obraId=' + this.id).subscribe(
      d => {
        this._list_categoria = d
        this.SetListaUnidadeMedida();
      }
    );
  }

  getAllObra() {
    this.repository.path = "obra-cotacao/all"
    this.repository.get(this.id).subscribe(d => { if (d && d.id > 0) this._obra = d; });
  }

  getAll() {
    this.tempList = this._list_categoria;
    this.repository.path = "supplies/relacao-produto/getall"
    const countCategorias = this._list_categoria.length
    if (countCategorias < 1)
      this.insertCategoria(this._model)
    for (let i = 0; i < countCategorias; i++) {
      this._list_categoria[i]._index = i + 1
      this.repository.getAll('&obraId=' + this.id + '&categoriaId=' + this._list_categoria[i].id).subscribe(
        d => { this._list_categoria[i]._listRelacaoObraProduto = d }, e => { },
        () => {
          if ((countCategorias - 1) == i) { setTimeout(() => { this.atulizarLista(true) }, 50); }
        })
    }
  }


  _categoria_id = 0
  _buscar = ''
  OnChangeSearch() {
    if (this._categoria_id < 0)
      this.getAll()
    else
      this.getAllPorCategoria()
  }

  tempList = null
  getAllPorCategoria() {
    if (this._categoria_id > 0)
      this._list_categoria = this.tempList.filter(o => { return (o.id == this._categoria_id) })
    else
      this._list_categoria = this.tempList

    this.repository.getAll('&like=' + this._buscar + '&obraId=' + this.id + '&categoriaId=' + this._categoria_id).subscribe(d => {
      console.log(d);
    })

  }

  atulizarLista(order = false) {
    this._model._categoria_index = this._list_categoria.length
    for (let i = 0; i < this._model._categoria_index; i++) {
      let x = 1
      if (this._list_categoria[i]._listRelacaoObraProduto && this._list_categoria[i]._listRelacaoObraProduto.length > 0) {
        if (order) {
          this._list_categoria[i]._listRelacaoObraProduto.sort(function (a, b) {
            if (a._produto.nome > b._produto.nome) { return 1; }
            if (a._produto.nome < b._produto.nome) { return -1; } return 0;
          });
        }
        this._list_categoria[i]._listRelacaoObraProduto.forEach(c => { c._index = x++; this.formatValues(c); })
      } else {
        this._list_categoria[i]._listRelacaoObraProduto = []
      }
    }
    this._model._index = this._list_categoria[this._model._categoria_index - 1]._listRelacaoObraProduto.length + 1
    if (this._model._categoria_index < 1)
      this.insertCategoria(this._model)
    else
      this.insertProduto(this._model);
    /*
      this.listModel.sort(function (a, b) {if (a._produto_categoria > b._produto_categoria) {return 1;}
        if (a._produto_categoria < b._produto_categoria) {return -1;}return 0;});
    */
  }


  formatValues(c: RelacaoObraProduto) { RelacaoObraProduto.formatValues(c) }

  tempInsumo = new RelacaoObraProduto()
  showModalMarcas(insumo: RelacaoObraProduto) {
    this.tempInsumo = insumo
    $('#modal-multiselect').modal('show')
    if (this._input.length < 1) { this.GetAllMarcas() } else { this.selectMarcas() }
  }

  selectMarcas() {
    this._input.forEach(c => { c._selected = false; c._state = EnumState.none })
    for (let i = 0; i < this.tempInsumo._marcas.length; i++) {
      const marca = this._input.filter(o => { return (o.id == this.tempInsumo._marcas[i].marca_id) })
      if (marca && marca.length) {
        marca[0]._selected = true
        marca[0]._state = EnumState.none
      }
    }
  }

  GetAllMarcas() {
    this.repository.path = 'supplies/marca/getall';
    this.repository.getAll().subscribe(d => { this._input = d; this.selectMarcas(); });
  }

  receiverData(list: Array<Marca>) {
    this.repository.path = "supplies/relacao-produto-marca/create"
    list.filter(o => { return (o._selected || o._state == EnumState.delete) }).forEach(c => {
      const data = this.tempInsumo._marcas.filter(o => { return (o.marca_id == c.id) })
      if (c._state == EnumState.delete) {
        if (data && data.length > 0) { data[0]._state = EnumState.delete }
      } else if (!data || data.length < 1) {
        const relacao = new RelacaoProdutoMarcaCategoria()
        relacao.produto_id = this.tempInsumo.produto_id
        relacao.origem = this.tempInsumo.origem
        relacao.categoria_id = this.tempInsumo.comprador_categoria_id
        relacao.marca_id = c.id
        this.tempInsumo._marcas.push(relacao)
      }
    })
    this.repository.post(this.tempInsumo._marcas).subscribe(d => {
      this.tempInsumo._marcas = d;
      this.selectMarcas()
    })
  }

  ReceiverProdutos(insumo: ProdutoComprador) {
    if (!insumo.id) {
      if (insumo.categoria_id > 0 && insumo.origem == OrigemProduto.InsumoDoFonecedor) {
        this.createInsumoVirtual(insumo)
        return;
      }
      this._buscar_produto[insumo._categoria_index + '_' + insumo._index] = ''
      if (insumo._index > 0 && insumo._categoria_index)
        this._list_categoria[insumo._categoria_index - 1]._listRelacaoObraProduto.splice(insumo._index - 1, 1)
      return;
    }

    this.repository.path = "supplies/relacao-produto/create"
    const model = new RelacaoObraProduto()
    model.produto_id = insumo.id
    model.obra_id = this.id
    model.origem = insumo.origem
    const categoria = this._list_categoria[insumo._categoria_index - 1]
    model.comprador_categoria_id = categoria.id

    const data = categoria._listRelacaoObraProduto.filter(o => { return (o.produto_id == insumo.produto_id && o.origem == insumo.origem) })
    if (data && data.length > 0) {
      throw String("O insumo " + insumo.nome + " já foi cadastro")
    } else {
      const x = (insumo._index > 0) ? insumo._index - 1 : categoria._listRelacaoObraProduto.length;
      this.repository.post(model).subscribe(d => {
        categoria._listRelacaoObraProduto[x] = d;
        categoria._listRelacaoObraProduto[x]._index = insumo._index
        this.atulizarLista()
      })
    }
  }

  update(model: RelacaoObraProduto, verifica = false) {
    this.repository.path = "supplies/relacao-produto/update"
    model.valor = UseFullService.ConvertFloat(model.valor) || 0
    model.previsto_total = UseFullService.ConvertFloat(model.previsto_total) || 0
    model.crossdocking = model.crossdocking || 0
    setTimeout(() => { this.repository.put(model).subscribe(d => { this.formatValues(model) }) }, 10);
  }

  remove(categoria: CompradorCategoriaProduto, model: RelacaoObraProduto) {
    this.repository.path = "supplies/relacao-produto/delete"
    this.repository.delete(model.id).subscribe(d => {
      this._list_categoria[categoria._index - 1]._listRelacaoObraProduto.splice(model._index - 1, 1);
      this.atulizarLista();
    })
  }

  mouseEnter(item: any) {
    item._selected_hover = true
  }

  mouseLeave(item: any) { item._selected_hover = false }

  rowClick(categoraia, item: any) {
    this._list_categoria[categoraia._index - 1]._listRelacaoObraProduto.forEach(c => { c._selected = (item == c) ? true : false })
  }

  addRow(categoria, model: any) {
    this._buscar = ''
    const item = new RelacaoObraProduto()

    if (categoria.id) {
      item.comprador_categoria_id = categoria.id
      item._type = 0
      //item._index = (model) ? model._index : 1
      item._textInputItemTipo = "Digite o nome do insumo para buscar"
      if (model) {
        this._list_categoria[categoria._index - 1]._listRelacaoObraProduto.splice(model._index, 0, item)
        model._toSon = true
      } else
        this._list_categoria[categoria._index - 1]._listRelacaoObraProduto.splice(0, 0, item)
      this.atulizarLista()
      this._buscar_produto = []
    }
  }

  removeRow(model: RelacaoObraProduto) {
    /*
    model._toSon = false
    this.listModel.splice(model._index, 1)
    this.atulizarLista()
    */
  }

  //$("#pesquisa-insumo-modal").modal("show"); 
  insertProduto(item: RelacaoObraProduto) {
    item._type = 0
    item._textInputItemTipo = "Digite o nome do insumo para buscar"
  }

  insertCategoria(item: RelacaoObraProduto) {
    item._type = 1
    item._textInputItemTipo = "Digite o nome da categoria para buscar"
  }

  createCategoria(descricao: string) {
    const model = new CompradorCategoriaProduto()
    model.descricao = descricao
    model.obra_id = this.id
    this.repository.path = "supplies/relacao-produto/createCategoria"
    this.repository.post(model).subscribe(d => { this.GetAllCategoria(); })
  }

  listCategoria = new Array<CompradorCategoriaProduto>()
  seachCategoria(model: RelacaoObraProduto, descricao: string) {
    if (model._type != 1)
      return false
    setTimeout(() => {
      this.repository.path = "supplies/relacao-produto/getall/categoria"
      this.repository.getAll("&like=" + descricao).subscribe(d => { this.listCategoria = d; })
    }, 100);
  }

  addCategoria() {
    if (this._model._type != 0)
      return false
    setTimeout(() => { }, 100);
  }

  updateCategoria(categoria: CompradorCategoriaProduto) {
    setTimeout(() => {
      const _listRelacaoObraProduto = categoria._listRelacaoObraProduto
      categoria._listRelacaoObraProduto = null
      console.log(categoria)
      this.repository.path = "supplies/relacao-produto/putCategoria"
      this.repository.put(categoria).subscribe()
      categoria._listRelacaoObraProduto = _listRelacaoObraProduto
    }, 50);
  }

  removeCategoria(categoria: CompradorCategoriaProduto) {
    setTimeout(() => {
      if (categoria._listRelacaoObraProduto.length > 0)
        throw "A insumo(s) cadastrados na categoria."
      this.repository.path = "supplies/relacao-produto/deleteCategoria"
      this.repository.delete(categoria.id).subscribe(d => {
        this._list_categoria.splice(categoria._index - 1, 1);
        this.atulizarLista();
      })
    }, 50);
  }

  createInsumoVirtual(insumo: ProdutoComprador) {
    this.repository.path = "supplies/set/insumo/virtual/save"
    insumo.ProdutoFornecedor = null
    this.repository.post(insumo).subscribe(d => {
      insumo.id = d.id
      this.ReceiverProdutos(insumo)
    })
  }
}