import { Component, OnInit } from '@angular/core';
import { PrazoPagamento } from 'src/app/domain/auxiliar/prazo-pagamento';
import { PrazoPagamentoService } from 'src/app/service/auxiliar/prazo-pagamento.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { getMaxListeners } from 'cluster';
import { EnderecoModalComponent } from 'src/app/core/page/endereco-modal/endereco-modal.component';
import { ObraCotacao, RelacaoObraEmpresa, RelacaoObraEmpresaAuditor } from 'src/app/domain/gestao/obra-cotacao';
import { EnumOrigemEndereco } from 'src/app/domain/empresa/Enderecos';
import { Empresa } from 'src/app/domain/empresa/empresa';
import { ContatoFinanceiro } from 'src/app/domain/empresa/contato-financeiro';
import { UseFullService } from 'src/app/service/usefull/usefull.service';

declare var $: any;
@Component({
  selector: 'app-obra-cotacao-view',
  templateUrl: './obra-cotacao-view.component.html',
  styleUrls: ['./obra-cotacao-view.component.css']
})
export class ObraCotacaoViewComponent extends EnderecoModalComponent implements OnInit {

  public code: 'app-obra-cotacao-view';
  obra = new ObraCotacao();

  empresas = new Array<Empresa>();
  _empresa = new Empresa();
  empresaSelecionada = 0;
  relacao_empresa = new RelacaoObraEmpresa();
  empresas_relacionadas = new Array<Empresa>();
  responsaveis = new Array<ContatoFinanceiro>();


  id = this.route.snapshot.params['id'];

  ngOnInit() {
    $("#cep").mask("99999-999");
    $("#number").mask("999999999999999999999999");
    $("#numero").mask("999999999999999999999999");
    this.GetEstado();
    if (this.id && this.id > 0) {
      this.get(this.id);
      this.GetAll();
      this.GetAllRelacaoEmpresas();
      this.relacao_empresa.obra_id = this.id
    }
    this.alertConfirm.UnsetObservers();
    this.alertConfirm.confirmEmitter$.subscribe(c => this.delete(c));
  }

  get(id) {
    this.repositoryService.path = "obra-cotacao/get"
    this.repositoryService.get(id).subscribe(
      d => {
        if (d && d.id > 0) {
          this.obra = d
          this.obra._inicio = UseFullService.formatDateString(this.obra.inicio)
          this.obra._fim = UseFullService.formatDateString(this.obra.fim)
          this._endereco = this.obra.endereco
          this.GetMunicipios(this._endereco.uf, false)
        }
      });
  }

  GetAll() {
    this.repositoryService.path = "empresa/faturanteOrFiscalizadora"
    this.repositoryService.getAll().subscribe(
      d => {
        if (d && d.length > 0)
          this.empresas = d;
      }
    );
  }

  GetAllRelacaoEmpresas() {
    this.repositoryService.path = "obra-cotacao/relacao-empresa/all"
    this.repositoryService.getAll('', '', '?obra_id=' + this.id).subscribe(
      d => {
        if (d && d.length > 0)
          this.empresas_relacionadas = d;
      }
    );
  }

  addRelacaoEmpresa() {
    this.repositoryService.path = "obra-cotacao/relacao-empresa/save"
    this.repositoryService.post(this.relacao_empresa).subscribe(
      d => {
        console.log(d);
        if (d && d.id > 0) {
          this.GetAllRelacaoEmpresas();
          this.resetRelacaoEmpresa()
        }
      }
    );
  }
  
  resetRelacaoEmpresa() {
    this.relacao_empresa = new RelacaoObraEmpresa();
    this.relacao_empresa.obra_id = this.id
  }

  buscarReponsaveis(empresa: Empresa) {
    this.empresas_relacionadas.forEach(c => c._active = false)
    empresa._active = true;
    this.repositoryService.path = "obra-cotacao/relacao-empresa/responsavel/all"
    this.repositoryService.getAll('', '', '?rid=' + empresa.relacao_id + '&emp=' + empresa.id).subscribe(
      d => {
        if (d && d.length > 0)
          this.responsaveis = d
      }
    );
  }

  selecionarResponsavel(responsavel: ContatoFinanceiro) {
    const empresa = Empresa.getActive(this.empresas_relacionadas)
    const relacao_auditor = new RelacaoObraEmpresaAuditor()
    relacao_auditor.relacao_obra_empresa_id = empresa.relacao_id
    relacao_auditor.responsavel_id = responsavel.id
    if (responsavel._auditor)
      relacao_auditor.id = responsavel._auditor;

    this.repositoryService.path = "obra-cotacao/relacao-auditor/save"
    this.repositoryService.post(relacao_auditor).subscribe(
      d => {
        if (d && d.id > 0)
          this.buscarReponsaveis(empresa)
      }
    );
  }

  _tempData = new Empresa()
  clickTrash(relacaoEmpresa: Empresa) {
    this._tempData = relacaoEmpresa;
    this.alertConfirm._code = this.code;
    this.alertConfirm._title = "Desvincular Empresa";
    this.alertConfirm._message = "Deseja desvincular a empresa, <b>" + relacaoEmpresa.nome_fantasia + "</b> da obra ?";
    this.alertConfirm.Show();
  }

  delete(confirm: boolean = false) {
    if (this.alertConfirm._code != this.code || confirm == false)
      return;
    this.repositoryService.path = 'obra-cotacao/relacao-empresa/delete'
    this.repositoryService.delete(this._tempData.relacao_id).subscribe(
      d => { this.GetAllRelacaoEmpresas(); }
    );
  }

  continuar(){ this.router.navigateByUrl('gestao/obra/relatorio/'+this.id); }
  voltar(produto: any){ this.router.navigateByUrl('gestao/obra/list'); }
}
