<!-- Modal -->
<div class="modal fade" id="pesquisa-cor-modal" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <div class="modal-header">

                <div class="input-group">

                    <h4 class="modal-title margin-right-20">Selecione <span class="text-info">{{_input_nivel.nome}}</span></h4>
                    <input class="form-control form-control-sm col-lg-5" placeholder="Pesquisar" [(ngModel)]="_buscar_cores" type="text">
                    <span class="input-group-append">
                        <button class="btn btn-sm btn-primary" type="button">Buscar</button>
                    </span>
                </div>

                <div class="ibox-tools">
                    <div>
                        <button type="button" class="close" aria-label="Close">
                            <span aria-hidden="true" (click)="Cancelar()">&times;</span>
                        </button>
                    </div>
                </div>

            </div>

            <div class="modal-max">
                <div class="row" style="margin-bottom:15px; padding: 15px;">
                    <div class="col-sm-4 selected-itens" *ngFor="let cor of _input_nivel.nivel
                        | filter:_buscar_cores" [hidden]="!(cor._temp_id_vinculo>
                        0)">
                        <label *ngIf="(cor._temp_id_vinculo> 0)">
                            <label class="btn btn-check-table label-checked
                                btn-default {{(cor._selecionado) ? 'active':''}}
                                modal-checked-style"
                                (click)="SelecionarCor(cor)">
                                <span class="glyphicon-check-mark fa fa-check"></span>
                            </label>
                        <label class="margin-left-10 list-cor
                                modal-checked-style-text">{{cor.descricao}}</label>
                        </label>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="Cancelar()">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="ConcluirSelecao()">Concluir</button>
            </div>
        </div>
    </div>
</div>