
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalVariable } from 'src/app/core/global-variable';

@Injectable({
    providedIn: 'root'
})
export class RepositoryService {
    public path: string
    private getUrl(): string { return GlobalVariable.BASE_API_URL + this.path  }

    constructor(private http: HttpClient) { }

    public getAttr(option: string = '', object = ''): Observable<any> {
        return this.http.get<any>(this.getUrl() + "/getAttr?options=" + option + "&object=" + object).pipe(
            map(res => {
                return res;
            })
        );
    }
    public getAll(option: string = '', object = '', _uri = ''): Observable<any[]> {
        const params = (_uri) ? _uri : "?options=" + option + "&object=" + object;
        return this.http.get<any[]>(this.getUrl() + params).pipe(
            map(res => {
                return res;
            })
        );
    }
    public get(id: any, object = ''): Observable<any> {
        return this.http.get<any>(this.getUrl() + "?id=" + id + "&object=" + object).pipe(
            map(res => {
                return res;
            })
        );
    }

    public post(data: any): Observable<any> {
        return this.http.post<any>(this.getUrl(), data).pipe(
            map(res => { return res; })
        );
    }

    public put(data: any): Observable<any> {
        return this.http.put<any>(this.getUrl(), data).pipe(
            map(res => { return res; })
        );
    }

    public delete(id: number): Observable<any> {
        return this.http.delete<any>(this.getUrl() + '?id=' + id).pipe(
            map(res => { return res; })
        );
    }

    public deleteAll(ids: []): Observable<any> {        
        return this.http.delete<any>(this.getUrl() + '?ids=' + JSON.stringify(ids)).pipe(
            map(res => { return res; })
        );
    }

    /**async **/
    public async getAsync(id: number, object = ''): Promise<Observable<any>> {
        const data = await this.http.get<any>(this.getUrl() + "?id=" + id + "&object=" + object).pipe(
            map( async res => {
                return await res;
            })
        );
        return data
    }
}