<!-- Modal -->
<div class="modal fade" id="modal-multiselect" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <div class="modal-header">

                <div class="input-group">

                    <h4 class="modal-title margin-right-20">Selecione <span class="text-info">{{_input.nome}}</span></h4>
                    <input class="form-control form-control-sm col-lg-5" placeholder="Pesquisar" [(ngModel)]="_search" type="text">
                    <span class="input-group-append">
                        <button class="btn btn-sm btn-primary" type="button">Buscar</button>
                    </span>
                </div>

                <div class="ibox-tools">
                    <div>
                        <button type="button" class="close" aria-label="Close" data-dismiss="modal">
                            <span aria-hidden="true" (click)="Cancelar()">&times;</span>
                        </button>
                    </div>
                </div>

            </div>

            <div class="modal-max">
                <div class="row no-margins" style="padding:15px 0;">
                    <div class="col-sm-4 selected-itens" *ngFor="let model of _input | filter:_search">
                        <label>
                            <label class="btn btn-check-table label-checked
                                btn-default {{(model._selected) ? 'active':''}}
                                modal-checked-style"
                                (click)="SelecionarModel(model)">
                                <span class="glyphicon-check-mark fa fa-check"></span>
                            </label>
                        <label style="margin-left: 5px;" class="list-model modal-checked-style-text">{{model.descricao}}</label>
                        </label>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="Cancelar()">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="ConcluirSelecao()">Concluir</button>
            </div>
        </div>
    </div>
</div>