import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserLogin } from '../../domain/user-login';
import { GlobalVariable } from 'src/app/core/global-variable';
import { Usuario, TypeUser } from 'src/app/domain/usuario/Usuario';
import { StatusUsuarioEnum } from 'src/app/domain/usuario/status-usuario-enum';
import { AtendimentoUsuario } from 'src/app/domain/usuario/atendimento-usuario';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private url = GlobalVariable.BASE_API_URL + "user/";
  public usuarios: Usuario[];
  public usuario: Usuario;
  
  constructor(private http: HttpClient) {}
  
  getAllUserCNPJ(cnpj): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(this.url + "getalluserscnpj?cnpj="+cnpj).pipe(
      map(res=>{
        return res;
      })
    );
  }
  getAllCNPJ(): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(this.url + "getallcnpj").pipe(
      map(res=>{
        return res;
      })
    );
  }
  getAllAdiministradoresCNPJ(): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(GlobalVariable.BASE_API_URL + "autorizacao/perfilusuario/administradores").pipe(
      map(res=>{
        return res;
      })
    );
  }
  getAll(): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(this.url + "getall").pipe(
      map(res=>{
        return res;
      })
    );
  }

  getAllPerfil(perfil = TypeUser.client): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(this.url + "getallPerfil?perfil="+perfil).pipe(
      map(res=>{
        return res;
      })
    );
  }
  
  get(id: number): Observable<Usuario> {
    return this.http.get<Usuario>(this.url + "get?id="+id).pipe(
      map(res=>{
        return res;
      })
    );
  }
  
  getLoggedIn(): Observable<Usuario> {
    return this.http.get<Usuario>(this.url + "getuser").pipe(
      map(res=>{
        return res;
      })
    );
  }

  getAllStatus(status: number): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(this.url + "getuserstatus?status="+status).pipe(
      map(res=>{
        return res;
      })
    );
  }

  SetUserProvider(usuario: Usuario): Observable<any> {  
    if(usuario.id == undefined || usuario.id <= 0)
      return this.SetSaveUserProvider(usuario);
    else
      return this.SetEditUserProvider(usuario);
  }

  SetSaveUserProvider(usuario: Usuario): Observable<any> { 
    return this.http.post<any>(this.url + "createUserProvider", usuario).pipe(
      map(res=> { return res; })
    );
  }

  SetEditUserProvider(usuario: Usuario): Observable<any> { 
    return this.http.put<any>(this.url + "editUserProvider", usuario).pipe(
      map(res=> { return res; })
    );
  }

  SetEditStatusProvider(usuario: Usuario): Observable<any> { 
    return this.http.put<any>(this.url + "editUserStatus", usuario).pipe(
      map(res=> { return res; })
    );
  }

  SetDeleteUserProvider(usuario: Usuario): Observable<any> { 
    return this.http.delete<any>(this.url + "deleteUserProvider?id="+usuario.id).pipe(
      map(res=> { return res; })
    );
  }

  /* atendimentos do usuario */
  getAllAtendimentoUser(usuario_id: number): Observable<AtendimentoUsuario[]> {
    return this.http.get<AtendimentoUsuario[]>(this.url + "getAtendimentoUser?id="+usuario_id).pipe(
      map(res=>{
        return res;
      })
    );
  }

  SetEditAtendimentoUser(data: AtendimentoUsuario): Observable<any> { 
    return this.http.post<any>(this.url + "createAtendimentoUser", data).pipe(
      map(res=> { return res; })
    );
  }

  SetDeleteAtendimentoUser(data: AtendimentoUsuario): Observable<any> { 
    return this.http.delete<any>(this.url + "deleteAtendimentoUser?id="+data.id).pipe(
      map(res=> { return res; })
    );
  }
  /* end - atendimento usuario */
}