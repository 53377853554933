import { Component, OnInit } from '@angular/core';
import { FuncaoSistema, OperacoesFuncao, EnumOperacoesFuncao } from 'src/app/domain/configuracao/funcao-sistema';
import { FuncaoSistemaService } from 'src/app/service/configuracao/funcao-sistema.service';
import { isNumber } from '@ng-bootstrap/ng-bootstrap/util/util';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';

@Component({
  selector: 'app-funcao-sistema',
  templateUrl: './funcao-sistema.component.html',
  styleUrls: ['./funcao-sistema.component.css']
})
export class FuncaoSistemaComponent implements OnInit {

  private _code = "app-funcao-sistema";
  public _funcao_sistema: FuncaoSistema;
  public _funcoes_sistema: Array<FuncaoSistema>;
  public _operacaoes: Array<OperacoesFuncao>;
  private temp_funcao_sistema: FuncaoSistema;

  constructor(private funcaoSistema: FuncaoSistemaService,
    private confirmService: AlertConfirmService) {
    this.confirmService.UnsetObservers();
    this.confirmService.confirmEmitter$.subscribe(c => this.Remover(c));
    this._funcao_sistema = new FuncaoSistema();
    this._funcoes_sistema = new Array<FuncaoSistema>();
    this._operacaoes = new Array<OperacoesFuncao>();
  }

  ngOnInit() {
    this.GetFuncoesSistema();
  }

  SetOperacoes() {
    this._funcoes_sistema.forEach(c => { FuncaoSistema.SetOperacoes(c); });
  }

  GetFuncoesSistema() {
    this.funcaoSistema.GetAll().subscribe(
      d => { this._funcoes_sistema = d; },
      e => { },
      () => { this.SetOperacoes(); }
    );
  }

  AdicionarFuncao() {
    this.funcaoSistema.Create(this._funcao_sistema).subscribe(() => {
      this.GetFuncoesSistema();
    });
  }

  Editar(data: FuncaoSistema) {
    this.funcaoSistema.Edit(data).subscribe(
      d => { },
      e => { this.GetFuncoesSistema(); },
      () => { });
  }

  ChangeNivel(e, data: FuncaoSistema, operacao_id: number) {
    if (e.srcElement.checked) {
      FuncaoSistema.AddOperacao(data, operacao_id);
      this.Editar(data);
    } else {
      FuncaoSistema.RemoveOperacao(data, operacao_id);
      this.Editar(data);
    }
  }

  ClickDelete(e, data: FuncaoSistema): void {
    e.preventDefault();
    this.temp_funcao_sistema = data;
    this.confirmService._code = this._code;
    this.confirmService._title = "Excluir Função do Sistema";
    this.confirmService._message = "Deseja excluir a função " + data.descricao + ", da rota " + data.rota + "?";
    this.confirmService.Show();
  }

  Remover(confirm: boolean = false) {
    if (this.confirmService._code != this._code || confirm == false)
      return;
    this.funcaoSistema.Delete(this.temp_funcao_sistema.id).subscribe(
      d => { },
      e => { },
      () => { this.GetFuncoesSistema(); }
    );
  }

}
