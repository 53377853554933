<div class="col-lg-12 wrapper-top-content">
    <div class="ibox">
        <div class="ibox-title" style="padding-right:20px;">
            <h5>Solicitação de Orçamento {{_orcamento.codigo}}: </h5>
            <div style="float: right;"> </div>
        </div>
        <div class="ibox-content">

            <app-cabecalho-orcamento></app-cabecalho-orcamento>

            <div class="table-responsive min-height-grid-insumo
                div-table-fixed margin-top-15" *ngIf="_carregamento_concluido">
                <!-- table-striped table-hover table-bordered -->
                <table class="table table-striped table-hover table-bordered" style="border: 1px solid #EBEBEB;">
                    <thead class="thead-fixed">
                        <tr>
                            <th class="th-sequece-produto" rowspan="2">Item</th>
                            <th class="th-nome-produto" rowspan="2">Nome do Insumo
                            </th>
                            <th class="text-center th-select-unity column-unidade" rowspan="2">Unidade</th>
                            <th class="text-center th-input-padrao-80" rowspan="2">Quantidade</th>
                            <th class="text-center th-select-unity" rowspan="2" *ngIf="_ha_anexo">Anexo</th>
                            <th class="text-center th-select-unity" rowspan="2" *ngIf="(_cotacao.recebimento_produto == 2)">Entrega Parcial
                            </th>
                            <th class="text-center" colspan="4">Preço R$</th>
                            <th class="text-center" *ngIf="boolIcms || boolIpi" [attr.colspan]="(boolIcms && boolIpi) ? 3: (boolIpi) ? 2:1">Impostos R$</th>
                            <th class="text-center th-input-padrao-120" rowspan="2">Valor Total - R$</th>
                        </tr>
                        <tr>
                            <th class="text-center th-input-padrao-100">Registrado</th>
                            <th class="text-center th-input-padrao-100">Orçado</th>
                            <th class="text-center th-input-padrao-100 bg-warning" data-toggle="tooltip" data-placement="top" title="Preço do concorrente.">Sugerido</th>
                            <th class="text-center th-input-padrao-100">Orçado</th>
                            <th class="text-center th-input-padrao-65" *ngIf="boolIcms">ICMS %</th>
                            <th class="text-center th-input-padrao-65" *ngIf="boolIpi">IPI %</th>
                            <th class="text-center th-input-padrao-65" *ngIf="boolIpi">Vlr.IPI</th>
                        </tr>
                    </thead>
                    <tbody class="  " *ngFor="let produto of _list_produtos">

                        <tr class="{{!produto._index_impar_par ?
                            'tr-background-impar':'tr-background-par'}}  {{produto._orcamento_produto.estoque ? 'tr-checked-stock-of':''}}">
                            <!--<td class="text-center th-sequence-produto">
                                <input class="" type="checkbox" [checked]="!produto._orcamento_produto.estoque" (click)="ClickChecked(produto._orcamento_produto, $event)" value="produto.id">
                            </td>-->
                            <td class="text-center th-sequence-produto">{{produto._index}}</td>
                            <td class="td-nome-produto">{{produto.nome}}</td>
                            <td class="text-center th-select-unity">{{produto._unidade_nome}}</td>
                            <td class="text-center th-input-stock">{{produto._quantidade}}</td>
                            <td class="text-center th-action-anexo" *ngIf="_ha_anexo">
                                <i *ngIf="produto.anexo && produto.anexo !=
                                    'null'" class="fa fa-file-alt btn-link
                                    cursor-pointer" (click)="MostrarFile(produto)"></i>
                            </td>

                            <td class="text-center th-action-parcial" *ngIf="(_cotacao.recebimento_produto == 2)">
                                <button class="btn btn-sm-grid btn-link">
                                    <i class="fa fa-truck"
                                        (click)="OpenModalTrunck(produto)"></i>
                                </button>
                                <small class="btn-link cursor-pointer"></small>
                            </td>
                            <td class="text-right th-input-stock">R$ {{produto._orcamento_produto._preco_registrado}}</td>
                            <td class="text-right th-input-stock">R$ {{produto._historico_valor_orcado}}</td>
                            <td class="text-right th-input-stock  bg-warning-td">R$ {{produto._valor_sugerido}}</td>
                            <td class="text-right th-input-price">
                                <!--readonly-->
                                <span>R$ {{produto._orcamento_produto._preco}}</span>
                            </td>
                            <td class="text-center th-input-stock" *ngIf="boolIcms">
                                <span>{{produto._orcamento_produto._icms}}</span>
                            </td>
                            <td class="text-center th-input-stock" *ngIf="boolIpi">
                                <span>{{produto._orcamento_produto._ipi}}</span>
                            </td>
                            <td class="text-right th-input-stock" *ngIf="boolIpi">R$ {{produto._orcamento_produto._preco_ipi}}</td>
                            <td class="text-right th-input-stock">R$ {{produto._orcamento_produto._preco_total}}</td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <div class="form-group row">
                <div class="col-sm-8 text-termo">
                    <span>Valores expressos em moeda R$ real brasileiro;</span><br>
                    <span>Data de Entrega a contar a partir da confirmação do
                        pedido;</span><br>
                    <span>A descarga do material é de responsabilidade do
                        cliente;</span><br>
                    <span>Confira seu produto no ato da entrega/coleta;</span><br>
                    <span>Vendas no Cartão BNDES estão sujeitas à
                        disponibilidade de crédito do cliente junto a
                        instituição financeira emitente do cartão;</span><br>
                    <span>Estão excluídos de nosso fornecimento todo material ou
                        serviço não espeficado neste documento;</span><br><br>
                </div>
                <div class="col-sm-4   ">

                    <table class="table table-striped table-bordered
                        table-aliquota" style="border: 0;">

                        <tbody class="  ">
                            <tr class="tr-background-impar">
                                <td class="text-left"><b>Subtotal</b></td>
                                <td class="th-input-padrao-120 text-right">R$ {{_orcamento._subtotal}}</td>
                            </tr>
                            <tr class="tr-background-impar">
                                <td class="text-left" style="border:0;"></td>
                                <td class="th-input-padrao-120 text-right" style="border:0;"></td>
                            </tr>

                            <tr *ngFor="let aliquota of _aliquotas" class="tr-background-impar">
                                <td> {{aliquota.descricao}}
                                </td>
                                <td class="th-input-padrao-120 text-right">R$ {{aliquota._valor}}</td>
                            </tr>
                            <tr class="tr-background-impar">
                                <td class="text-left" style="border:0;"></td>
                                <td class="th-input-padrao-120 text-right" style="border:0;"></td>
                            </tr>
                            <tr class="tr-background-par">
                                <td class="text-left"><b>VALOR TOTAL</b></td>
                                <td class="th-input-padrao-120 text-right"><b>R$ {{_orcamento._total}}</b></td>
                            </tr>
                            <tr>
                                <td colspan="2" class="text-right
                                    no-padding" style="border:0px;
                                    padding-top: 15px
                                    !important">
                                    <div class="text-left" style="margin-bottom:
                                        10px;">
                                        <button class="btn btn-link" *ngIf="(_orcamento.file_proposta_fob
                                            && _cotacao.tipo_frete != 3)" (click)="carregarArquivoPropota(_orcamento.id,
                                            _orcamento.file_proposta_fob)">

                                            <b>Proposta:</b>&nbsp;&nbsp;{{_orcamento.file_proposta_fob}}
                                        </button>
                                        <button class="btn btn-link" *ngIf="(_orcamento.file_proposta_cif)" (click)="carregarArquivoPropota(_orcamento.id,
                                            _orcamento.file_proposta_cif)">

                                            <b>Proposta:</b>&nbsp;&nbsp;{{_orcamento.file_proposta_cif}}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>

            <div class="form-group row">
                <div class="col-sm-6">
                </div>
                <div class="col-sm-6 text-right">

                    <a class="btn btn-default" href="javascript:history.back()"> <i class="fa fa-arrow-left"></i>&nbsp;&nbsp;Voltar</a>
                    <button type="submit" class="btn btn-default margin-left-10" *ngIf="!_cotacao._expirado" (click)="ConfirmeEditEstoque()">Estoque Referente Imcopleto</button>
                </div>
            </div>

        </div>
    </div>
</div>

<app-message-modal [_titulo]="_msg_titulo" [_descricao]="_msg_descricao"></app-message-modal>
<app-data-modal [_nome_arquivo]="_nome_arquivo" [_src_arquivo]="_src_arquivo"></app-data-modal>
<app-entrega-parcial-view [_produto_selecionado]="_produto_selecionado" (_entregas_emit)="ReceiverEntregaProduto($event)"></app-entrega-parcial-view>
<!--#08c65b-->