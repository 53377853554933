<div class="col-lg-12 wrapper-top-content">
    <div class="ibox">
        <div class="ibox-title" style="padding-right:20px;">
            <h5>Entregas {{_titulo}}</h5>
            <div style="float: right;"> </div>
        </div>
        <div class="ibox-content" style="min-height:350px">


            <ngb-datepicker #dp (select)="onDateSelection($event)" [displayMonths]="1" [dayTemplate]="t" outsideDays="hidden" class="calendario-pick">
            </ngb-datepicker>

            <ng-template #t let-date let-focused="focused">
                <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)" [class.faded]="isHovered(date)
                    || isInside(date)" (mouseenter)="hoveredDate= date" (mouseleave)="hoveredDate= null">
                    {{ date.day }}
                </span>
            </ng-template>

            <div class="row cards-entrega">

                <div class="col-sm-12" style="margin-bottom: 15px;"><b>Resultado da busca:</b> {{_entregas.length}} entrega{{_entregas.length > 1?'s':''}} no período selecionado!</div>
                <div class="col-sm-6" *ngFor="let model of _entregas">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">{{model._index}} - Entrega {{model._codigo_orcamento}}
                                <label class="btn-link" data-toggle="tooltip" data-placement="top" title="Confirme recebimento do produto." style="margin: 0 10px 0 10px;" *ngIf="!model.status_recebimento" (click)="ConfirmarEntrega(model)">
                                    <i class="fa fa-truck"></i>
                                    <i class="fa fa-check" style="margin-top: -3px;
                                    position: absolute;
                                    margin-left: -1px;"></i>
                                </label>
                                <label class="btn-link" (click)="Ver(model.compra_venda_id)">ver detalhes</label>
                            </h4>
                            <label style="width:50%"><b>Solicitação:</b>&nbsp;&nbsp;{{model._data_criacao}}</label>
                            <label><b>Entrega Até:</b>&nbsp;&nbsp;{{model._data_max_entrega}}</label><br />
                            <label>{{model._nome_obra}}</label><br />
                            <label>
                                {{(model._tipo_frete == 1) ? '' : 'FOB'}}
                                <label *ngIf="(model._tipo_frete == 1)">
                                    {{ model._endereco.endereco}},&nbsp;n°:&nbsp;{{ model._endereco.numero }}&nbsp;{{model._endereco.complemento}}
                                    &nbsp;Bairro:&nbsp;{{ model._endereco.bairro}},&nbsp;{{ model._endereco.cidade_nome}} - {{model._endereco.uf}}
                                    &nbsp;CEP:&nbsp;{{model._endereco.cep}} - {{model._endereco.uf}}
                                    &nbsp;CEP:&nbsp;{{model._endereco.cep}}
                                </label>
                            </label>

                        </div>
                    </div>
                </div>


            </div>


            <!--
            <pre>From: {{ fromDate | json }} </pre>
            <pre>To: {{ toDate | json }} </pre>
-->
        </div>
    </div>
</div>


<app-message-modal [_titulo]="_msg_titulo" [_descricao]="_msg_descricao"></app-message-modal>