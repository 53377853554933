import { Component, OnInit, Input } from '@angular/core';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { Cotacao } from 'src/app/domain/cotacao/cotacao';
import { Router, ActivatedRoute } from '@angular/router';
import { Orcamento, ListOrcamento } from 'src/app/domain/orcamento/orcamento';
import { RepositoryService } from 'src/app/service/repository.service';
import { Empresa } from 'src/app/domain/empresa/empresa';
import { EmailValidate, EnumStatusEmailValidade, ProcessoEmailOrcmento } from 'src/app/domain/auxiliar/EmailValidate';

@Component({
  selector: 'app-auditor-view-processo',
  templateUrl: './auditor-view-processo.component.html',
  styleUrls: ['./auditor-view-processo.component.css']
})
export class AuditorViewProcessoComponent implements OnInit {
  listProcesso = new Array<ProcessoEmailOrcmento>()
  orcamento_id = this.route.snapshot.params['id'];
  constructor(public route: ActivatedRoute, public repository: RepositoryService) { }

  ngOnInit() { this.getEstadoValidacaoOrcamento(this.orcamento_id) }

  getProcessoOrcamento(id = this.orcamento_id) {
    this.repository.path = "obra/emailvalidate/getProcessoOrcamento"
    this.repository.get(id).subscribe(
      d => {
        if (d && d.length > 0)
          this.listProcesso = d
      });
  }

  getEstadoValidacaoOrcamento(id = this.orcamento_id) {
    this.repository.path = "obra/emailvalidate/get/estadoorcamento"
    this.repository.get(id).subscribe(
      d => {
        if (d) {
          this.getProcessoOrcamento(id)
        }
      });
  }
}