export class Estado{    
    public Id: number;
    public CodigoUF: number;
    public Regiao: string;
    public Nome: string;
    public Uf: string;
    public _selecionado: boolean;
    
    static GetEstados(estados: Array<Estado>): string{
        if(!estados)
            return "";
        var estado = ":";
        estados.forEach(c=>{ estado += c.Uf+":"; });
        return estado;
    }
    
    static GetEstadosSelecionados(estados: Array<any>, list: string): Array<Estado> {
        var list_ids = list.split(':');

        function filtrar(obj) {
            for (let i = 0; i < list_ids.length; i++) {
                if (list_ids[i] == obj.Uf)
                    return true;
            }
            return false;
        }
        var data = estados.filter(filtrar);
        return data;
    }
}