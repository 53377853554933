import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from 'src/app/core/global-variable';
import { ProdutoCategoriaNivel } from 'src/app/domain/suprimento/produto-categoria-nivel';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class CategoriaNivelService {
  
  private url = GlobalVariable.BASE_API_URL + "supplies/categoria/nivel/";
  constructor(private http: HttpClient) { }


  GetAll(categoria: number) {
    return this.http.get<ProdutoCategoriaNivel[]>(this.url + "getall?categoria="+categoria).pipe(
      map(res => {
        return res;
      })
    );
  }

  Get(id: number) {
    return this.http.get<ProdutoCategoriaNivel>(this.url + "get?id="+id).pipe(
      map(res => {
        return res;
      })
    );
  }
  
  SetSolicitationProvider(produtoCategoriaNivel: ProdutoCategoriaNivel): Observable<any> {    
    if(produtoCategoriaNivel.id > 0)
      return this.Update(produtoCategoriaNivel);
    else
      return this.Create(produtoCategoriaNivel);
  }

  Create(produtoCategoriaNivel: ProdutoCategoriaNivel): Observable<any>{
    return this.http.post<any[]>(this.url+"create", produtoCategoriaNivel).pipe(
      map(res=> { return res; })
    );
  }

  Update(produtoCategoriaNivel: ProdutoCategoriaNivel): Observable<any>{
    return this.http.put<any[]>(this.url+"edit", produtoCategoriaNivel).pipe(
      map(res=> { return res; })
    );
  }
  
  SetDelete(produtoCategoriaNivel: ProdutoCategoriaNivel): Observable<any> {    
    return this.http.delete(this.url+"delete?id="+produtoCategoriaNivel.id).pipe(
      map(res=> { return res; })
    );
  }
  
}
