import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/domain/usuario/Usuario';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { UserService } from 'src/app/service/user/user.service';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { AtendimentoUsuario } from 'src/app/domain/usuario/atendimento-usuario';

@Component({
  selector: 'app-gerenciar-usuarios',
  templateUrl: './gerenciar-usuarios.component.html',
  styleUrls: ['./gerenciar-usuarios.component.css']
})
export class GerenciarUsuariosComponent implements OnInit {
  code = "app-atendimento-usuario"
  public _usuarios: Usuario[];
  public _usuario: Usuario;
  public _atendimentoUsuario: AtendimentoUsuario
  public _listAtendimentoUsuario: AtendimentoUsuario[]
  public _buscar: string;

  constructor(private usuarioService: UserService,
    private confirmService: AlertConfirmService) {
    this.confirmService.UnsetObservers();
    this.confirmService.confirmEmitter$.subscribe(c => { this.Excluir(c); });
  }

  ngOnInit() {
    this.Reset()
    this._usuario = new Usuario()
    this._usuarios = new Array<Usuario>()
    this._listAtendimentoUsuario = new Array<AtendimentoUsuario>()
    this.GetAll()
  }

  GetAll() {
    this.usuarioService.getAllPerfil().subscribe(
      d => { this._usuarios = d; },
      e => { },
      () => {
        var x = 1;
        this._usuarios.forEach(c => {
          c._index = x++;
          c._data_criacao = UseFullService.formatDateHora(new Date(c.data_criacao));
        });
      }
    );
  }

  Ver(usuario: Usuario) {
    this.Reset()
    this._usuario = usuario
    this.GetAllAtendimendoUser(usuario.id)
    UseFullService.ShowModal('#modal-crm-cliente');
  }

  GetAllAtendimendoUser(user_id: number) {
    this.usuarioService.getAllAtendimentoUser(user_id).subscribe(
      d => { this._listAtendimentoUsuario = d; },
      e => { },
      () => {
        this.Reset();
        this._listAtendimentoUsuario.forEach(c=>{
          c._data = UseFullService.formatDateHora(new Date(c.data));
        });
      }
    );
  }

  InsertOrUpdate() {
    if (!this._atendimentoUsuario.id)
      this._atendimentoUsuario.usuario_id = this._usuario.id
    this.usuarioService.SetEditAtendimentoUser(this._atendimentoUsuario).subscribe(
      d => { },
      e => { },
      () => {
        this.GetAllAtendimendoUser(this._usuario.id);
      }
    );
  }

  Reset() {
    this._atendimentoUsuario = new AtendimentoUsuario();
  }

  ActionEditar(data: AtendimentoUsuario) {
    this._atendimentoUsuario = data;
  }

  ConfirmExcluir(data: AtendimentoUsuario) {
    this._atendimentoUsuario = data;
    this.confirmService._code = this.code;
    this.confirmService._title = "Excluir Observação De Atendimento";
    this.confirmService._message = "Deseja excluir a observação do atendimento " + data.observacao + "?";
    this.confirmService.Show();
  }

  Excluir(confirm: boolean = false) {
    if (this.confirmService._code != this.code || confirm == false)
      return;
    this.usuarioService.SetDeleteAtendimentoUser(this._atendimentoUsuario).subscribe(
      d => {
        this.GetAllAtendimendoUser(this._usuario.id);
      },
      e => { },
      () => { }
    );
  }

}

