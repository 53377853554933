import { Component, OnInit } from '@angular/core';
import { CotacaoService } from 'src/app/service/suprimento/comprador/cotacao.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { Cotacao } from 'src/app/domain/cotacao/cotacao';
import { Router } from '@angular/router';
import { OrcamentoService } from 'src/app/service/orcamento/orcamento.service';
import { EnumStatusOrcamento, ListOrcamento, EnumStatusTipoOrcamento, EnumTipoOrcamento } from 'src/app/domain/orcamento/orcamento';

@Component({
  selector: 'app-orcamento-solicitacao',
  templateUrl: './orcamento-solicitacao.component.html',
  styleUrls: ['./orcamento-solicitacao.component.css']
})
export class OrcamentoSolicitacaoComponent implements OnInit {

  _titulo = "Cotações Abertas";

  public code: string;
  public _list_cotacao: Array<Cotacao>;
  public _cotacao: Cotacao;

  constructor(
    private cotacaoService: CotacaoService,
    private confirmService: AlertConfirmService,
    private userLoggedService: UserLoggedInService,
    private router: Router) {
    this.confirmService.UnsetObservers();
    this.confirmService.confirmEmitter$.subscribe(c => { this.Excluir(c); this.Dublicar(c); });
    this.userLoggedService.SetCurrentUser();
    this._list_cotacao = new Array<Cotacao>();
    this.code = 'app-cotacao-list';
  }

  ngOnInit() {
    this.GetAll();
  }

  GetAll() {
    this.cotacaoService.GetAllFornecedor(this.userLoggedService.id, EnumStatusOrcamento.aberto).subscribe(
      d => {
        if (d && d.length > 0)
          this._list_cotacao = d;
      },
      e => { },
      () => {
        var x = 1;
        this._list_cotacao.forEach(c => {
          c._index = x++
          Cotacao.SetFormat(c)
          c._orcamento_status_nome = ListOrcamento.GetStatus(c._orcamento_status)
        });
      }
    );
  }



  Ver(cotacao: Cotacao) {
    if (cotacao._orcamento_status == EnumStatusOrcamento.aberto)
      this.router.navigateByUrl("/orcamentos/edit/" + cotacao._orcamento_id);
    else
      this.router.navigateByUrl("/orcamentos/view/" + cotacao._orcamento_id);
  }

  ConfirmDelete(cotacao: Cotacao) {
    this._cotacao = cotacao;
    this.confirmService._code = this.code;
    this.confirmService._title = "Excluir Cotação: " + cotacao.codigo;
    this.confirmService._message = "Deseja excluir a cotação: <b>" + cotacao.nome + "</b>?";
    this.confirmService.Show();
  }

  ConfirmDuplicar(cotacao: Cotacao) {
    this._cotacao = cotacao;
    this.confirmService._code = this.code + "DUPLICAR";
    this.confirmService._title = "Duplicar Cotação: " + cotacao.codigo;
    this.confirmService._message = "Deseja duplicar a cotação: <b>" + cotacao.nome + "</b>?";
    this.confirmService.Show();
  }

  Excluir(confirm: boolean = false) {
    if (this.confirmService._code != this.code || confirm == false)
      return;
    this.cotacaoService.Delete(this._cotacao.id).subscribe(
      d => { this.GetAll(); },
      e => { },
      () => { }
    );
  }

  Dublicar(confirm: boolean = false) {
    if (this.confirmService._code != this.code + "DUPLICAR" || confirm == false)
      return;
    this.cotacaoService.Duplicar(this._cotacao).subscribe(
      d => { this.GetAll(); },
      e => { },
      () => { }
    );
  }


  Editar(cotacao: Cotacao) {
    this.router.navigateByUrl("/cotacao/edit-cotacao/" + cotacao.id);
  }

}
