import { Component, OnInit } from '@angular/core';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Orcamento, ListOrcamento } from 'src/app/domain/orcamento/orcamento';
import { ListCompraVenda, EnumStatusTransacao } from 'src/app/domain/transacao/compra-venda';
import { CompraVendaService } from 'src/app/service/transacao/compra-venda.service';

declare var $: any
@Component({
  selector: 'app-compra-list',
  templateUrl: './compra-list.component.html',
  styleUrls: ['./compra-list.component.css']
})
export class CompraListComponent implements OnInit {

  public code: string;
  public _list_orcamento: Array<ListOrcamento>;
  public _transacoes: Array<ListCompraVenda>;
  public _transacao: ListCompraVenda;

  constructor(
    private route: ActivatedRoute,
    private compraVendaService: CompraVendaService,
    private confirmService: AlertConfirmService,
    private userLoggedService: UserLoggedInService,
    private router: Router) {
    this.userLoggedService.SetCurrentUser();
    this._transacoes = new Array<ListCompraVenda>();
    this._transacao = new ListCompraVenda();
  }

  _titulo = "";
  _status = 0;
  ngOnInit() {
    this.userLoggedService.SetCurrentUser();
    this.SetPageConfig();
    $(".modal-backdrop.fade.show").hide();
  }

  SetPageConfig() {
    switch (this.route.snapshot.routeConfig.path) {
      case "analise":
        this._titulo = "Em Análise";
        this._status = EnumStatusTransacao.analise;
        break;
      case "realizadas":
        this._titulo = "Realizadas";
        this._status = EnumStatusTransacao.aprovada;
        break;
      case "recusadas":
        this._titulo = "Recusadas";
        this._status = EnumStatusTransacao.recusada;
        break;
    }
    this.GetAll();
  }

  GetAll() {
    this.compraVendaService.GetAllUserStatus(this._status).subscribe(
      d => {
        if (d && d.length > 0)
          this._transacoes = d;
      },
      e => { },
      () => {
        var x = 1;
        this._transacoes.forEach(c => {
          c._index = x++;
          ListOrcamento.SetConfig(c);
        });
      }
    );
  }

  Ver(model: ListCompraVenda) {
    this.router.navigateByUrl("/compras/view/" + model.id_compra_venda);
  }

}