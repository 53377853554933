export class PrazoPagamento {
    public id: number;
    public descricao: string;
    public meio_pagamento: number;
    public ativo: number;

    public _selecionado: boolean;
    public _meio_pagamento: string;

    static GetAll(_prazos_pagamento: PrazoPagamento[]): string {
        if (!_prazos_pagamento)
            return "";
        var data = ":";
        _prazos_pagamento.forEach(c => { data += c.id + ":"; });
        return data;
    }
    
    static GetPrazoPagamentoSelecionados(prazos_pagamento: any[], list: string): PrazoPagamento[] {
        var list_ids = list.split(':');
        function filtrar(obj) {
            for (let i = 0; i < list_ids.length; i++) {
                if (parseInt(list_ids[i]) == obj.id)
                    return true;
            }
            return false;
        }
        var data = prazos_pagamento.filter(filtrar);
        return data;    
  }
}