import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Usuario } from 'src/app/domain/usuario/Usuario';
import { UserService } from 'src/app/service/user/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';

declare var $: any;

@Component({
  selector: 'app-novo-usuario',
  templateUrl: './novo-usuario.component.html',
  styleUrls: ['./novo-usuario.component.css']
})
export class NovoUsuarioComponent implements OnInit {
  
  @Input() usuario;
  @Output() resposta_usuario: EventEmitter<Usuario> = new EventEmitter();
  public _alterar_senha: boolean;
  constructor(private userService: UserService, public userLoggedIn: UserLoggedInService, private router: Router, private confirmService: AlertConfirmService,  private route: ActivatedRoute) { }


  email = this.route.snapshot.params['email'];
  ngOnInit() {  
    $('#mask-cpf').mask("999.999.999-99", {reverse: true}); 
    $('#mask-phone').mask('(00) 0000-00009');
    this.usuario.email = this.email
  }

  SaveUser(): void{    
    this.userService.SetUserProvider(this.usuario).subscribe(
      d => {document.location.reload(true);},
      e => {},
      () => {}
    );
  }

  CancelUser(): void{
    this.usuario = new Usuario();
    this._alterar_senha = false;
    this.resposta_usuario.emit(this.usuario);
  }

  AlterarSenha(e, s:boolean){    
    e.preventDefault(); 
    this._alterar_senha = s;
    if(s){
      this.usuario.senha = "";
    }
  }
  
}
