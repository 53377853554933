<div class="processo-aprovacao">
    <h4>Processo de Auditoria:</h4><br />
    <div *ngFor="let processo of listProcesso">

        <div class="{{(listProcesso[listProcesso.length - 1] !=
            processo)?'grid-proccess':''}}">
            <label class="processo-status">{{(processo.status_auditoria == 1) ? 'Aprovado': (processo.status_auditoria == 2)?'Reprovado':'Aguardando Avaliação'}}</label>
            <div class="proccess-b text-center">
                <i class="fa fa-{{(processo.status_auditoria
                    == 1) ? 'check':
                    (processo.status_auditoria ==
                    2)?'thumbs-down':'question'}}"></i>
            </div>
            <label class="margin-buttom-5 margin-left-10"><b>{{(processo._tipo == 1)?'FATURANTE':'FISCALIZADORA'}}:</b>&nbsp;&nbsp;
                {{processo.nome_fantasia}}</label><br />
            <label class="margin-buttom-5 margin-left-10">{{processo.nome_responsavel}}</label><br />
            <hr style="margin:5px 0px 0px 0px;" *ngIf="listProcesso[listProcesso.length - 1]
                != processo" />
            <br />
        </div>
    </div>
</div>