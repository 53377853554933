import { Component, OnInit } from '@angular/core';
import { PrazoPagamento } from 'src/app/domain/auxiliar/prazo-pagamento';
import { PrazoPagamentoService } from 'src/app/service/auxiliar/prazo-pagamento.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { getMaxListeners } from 'cluster';
import { EnderecoModalComponent } from 'src/app/core/page/endereco-modal/endereco-modal.component';
import { ObraCotacao } from 'src/app/domain/gestao/obra-cotacao';
import { EnumOrigemEndereco } from 'src/app/domain/empresa/Enderecos';
import { UseFullService } from 'src/app/service/usefull/usefull.service';

declare var $: any;
@Component({
  selector: 'app-obra-cotacao-form',
  templateUrl: './obra-cotacao-form.component.html',
  styleUrls: ['./obra-cotacao-form.component.css']
})
export class ObraCotacaoFormComponent extends EnderecoModalComponent implements OnInit {

  public code: string;
  obra = new ObraCotacao();

  id = this.route.snapshot.params['id'];

  ngOnInit() {
    $("#cep").mask("99999-999");
    $("#number").mask("999999999999999999999999");
    $("#numero").mask("999999999999999999999999");
    this.GetEstado();
    if (this.id && this.id > 0) {
      this.get(this.id);
    }
  }

  get(id) {
    this.repositoryService.path = "obra-cotacao/get"
    this.repositoryService.get(id).subscribe(
      d => {
        if (d && d.id > 0) {
          this.obra = d
          this._endereco = this.obra.endereco
          this.GetMunicipios(this._endereco.uf, false)
          this.SetDate()
        }
      });
  }

  saveObraCotacao() {
    if (this.obra._inicio)
    this.obra.inicio = UseFullService.formatNgbDateStructString(this.obra._inicio);
    if (this.obra._fim)
      this.obra.fim = UseFullService.formatNgbDateStructString(this.obra._fim);
    this._endereco.cidade_nome = $("option[value='" + this._endereco.cidade + "']").text()
    this._endereco.uf_nome = $("option[value='" + this._endereco.uf + "']").text()
    this._endereco.origem = EnumOrigemEndereco.obraCotacao;
    this.obra.endereco = this._endereco;
    this.repositoryService.path = "obra-cotacao/save"
    this.repositoryService.post(this.obra).subscribe(
      d => {
        if (d && d.id > 0){   
          setTimeout(() => { this.router.navigate(['/gestao/obra/relatorio/'+d.id]); }, 10);
        }
      }
    );
  }

  voltar(){ this.router.navigateByUrl('gestao/obra/list'); }
  
  toggleCalendar() {
    UseFullService.TraduzirCalendario();
  }

  ChangeDate() {
    setTimeout(() => {
      if (this.obra._fim)
        $('input[name=obra_fim]').val(UseFullService.GetFormatDate(this.obra._fim.day, this.obra._fim.month, this.obra._fim.year));
      if (this.obra._inicio)
        $('input[name=obra_inicio]').val(UseFullService.GetFormatDate(this.obra._inicio.day, this.obra._inicio.month, this.obra._inicio.year));
    }, 10);
  }

  SetDate(){
    this.obra._inicio = UseFullService.formatNgbDate(this.obra.inicio)
    this.obra._fim = UseFullService.formatNgbDate(this.obra.fim) 
    setTimeout(()=>{   
      $('input[name=obra_inicio]').val(UseFullService.formatDateString(this.obra.inicio))
      $('input[name=obra_fim]').val(UseFullService.formatDateString(this.obra.fim))
     }, 10);
  }

}
