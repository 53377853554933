import { Component, OnInit } from '@angular/core';
import { GerenciamentoCompradorService } from 'src/app/service/gerenciamento/gerenciamento-comprador.sevice';
import { Cotacao } from 'src/app/domain/cotacao/cotacao';
import { Router } from '@angular/router';
import { UseFullService } from 'src/app/service/usefull/usefull.service';

@Component({
  selector: 'app-cotacoes',
  templateUrl: './cotacoes.component.html',
  styleUrls: ['./cotacoes.component.css']
})
export class CotacoesComponent implements OnInit {

  public _cotacoes: Cotacao[];
  public _buscar: string
  constructor(private comrpadorService: GerenciamentoCompradorService,
    private router: Router) { }

  _inicio = ''
  _fim = ''
  _status = 1

  ngOnInit() {
    var date = new Date();
    this._fim = UseFullService.formatDateUsForm(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    this._inicio = UseFullService.formatDateUsForm(new Date(date.getFullYear(), date.getMonth(), 1));
    this.GetAll()
  }

  OnChangeSearch() { this.GetAll() }

  GetAll() {
    this.comrpadorService.GetAllEnviadas(this._status, this._inicio, this._fim).subscribe(
      d => {
        if (d && d.length > 0)
          this._cotacoes = d;
        else
          this._cotacoes = new Array<Cotacao>()
      },
      e => { },
      () => {

        if (this._cotacoes && this._cotacoes.length > 0) {
          var x = 1;
          this._cotacoes.forEach(c => {
            c._index = x++;
            c._qt_email = UseFullService.GetListStringQT(c.list_email)
            Cotacao.SetFormat(c)
          });
        }
      }
    );
  }

  Ver(cotacao: Cotacao) {
    this.router.navigateByUrl("/gerenciamento/comprador/cotacao/view/" + cotacao.id);
  }

  VerOrcamentos(cotacao: Cotacao) {
    this.router.navigateByUrl("/gerenciamento/comprador/cotacao/list/orcamentos/" + cotacao.id);
  }

}