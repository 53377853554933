<div class="col-lg-6 wrapper-top-content {{grid_use_detalhes}}" [hidden]="_hidden && _usuario.id < 1">
    <div class="ibox-title">
        <h5>{{_title}}</h5>
    </div>
    <div class="ibox-content   ">
        <div class="form-group row">
            <label class="col-sm-3"><b>Nome:</b></label>
            <div class="col-sm-8">{{usuario.nome}}{{_usuario.nome}}</div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3"><b>Email:</b></label>
            <div class="col-sm-8">{{usuario.email}}{{_usuario.email}}</div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3"><b>Telefone:</b></label>
            <div class="col-sm-8">{{usuario.telefone}}{{_usuario.telefone}}</div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3"><b>CPF:</b></label>
            <div class="col-sm-8">{{usuario.cpf}}{{_usuario.cpf}}</div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3"><b>CNPJ:</b></label>
            <div class="col-sm-8">{{usuario.cnpj}}{{_usuario.cnpj}}</div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3"><b>Inscrição Estadual:</b></label>
            <div class="col-sm-8">{{usuario.inscricao_estadual}}{{_usuario.inscricao_estadual}}</div>
        </div>
    </div>

</div>