<div class="col-lg-12 wrapper-top-content">
    <div class="ibox-title">
        <h5>Relatório de Configurações Da Obra</h5>
    </div>
    <div class="ibox-content form-categoria-nivel">
        <div class="row">
            <div class="col-sm-12 m-b-xs">
                <div class="list-group title-grid">
                    <b>DADOS DA OBRA</b>
                </div>
                <p class="margin-left-10">
                    <b>Nome da Obra:</b>&nbsp;&nbsp;{{obra.nome}}<br />
                    <b>Início da Obra:</b>&nbsp;&nbsp;{{obra._inicio}}<br />
                    <b>Fim da Obra:</b>&nbsp;&nbsp;{{obra._fim}}<br /><br />
                    <b>Endereço:</b>&nbsp;&nbsp;{{_endereco.endereco}}&nbsp;n°&nbsp;{{_endereco.numero}}&nbsp;{{_endereco.complemento}}<br />
                    <b>Setor:</b>&nbsp;&nbsp;{{_endereco.bairro}}<br />
                    <b>Cidade:</b>&nbsp;&nbsp;{{_endereco.cidade_nome}} - {{_endereco.uf}}
                    <br />
                    <b>CEP:</b>&nbsp;&nbsp;{{_endereco.cep}}<br />
                </p>
            </div>
            <div class="col-sm-12">
                <div class="list-group title-grid">
                    <b>DADOS COMPRADOR</b>
                </div>
                <div class="margin-left-10">
                    <label class="margin-buttom-5"><b>Empresa:</b>&nbsp;&nbsp;{{_empresa.nome_fantasia}}</label><br />
                    <div>
                        <b>Endereço:</b>&nbsp;&nbsp;{{_empresa.endereco}}&nbsp;n°&nbsp;{{_empresa.numero}}&nbsp;{{_empresa.complemento}}<br />
                        <b>Setor:</b>&nbsp;&nbsp;{{_empresa.bairro}}<br />
                        <b>Cidade:</b>&nbsp;&nbsp;{{_empresa._cidade}} - {{_empresa.uf}}
                        <br />
                        <b>CEP:</b>&nbsp;&nbsp;{{_empresa.cep}}<br />
                    </div>
                    <br />
                </div>
            </div>
            <div class="col-sm-12">
                <div class="list-group title-grid">
                    <b>EMPRESAS E AUDITORES</b>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div *ngFor="let r_emp of empresas_relacionadas" class="margin-left-10">
                            <br />
                            <h4 class="title-grid">
                                {{(r_emp.tipo_relacao == 1)?'FATURANTE':'FISCALIZADORA'}}
                            </h4>
                            <div class="margin-left-10">
                                <label class="margin-buttom-5"><b>Razão social:</b>&nbsp;&nbsp;{{r_emp.razao_social}}</label><br />
                                <label class="margin-buttom-5"><b>Nome fantasia:</b>&nbsp;&nbsp;{{r_emp.nome_fantasia}}</label><br />
                                <label class="margin-buttom-5"><b>CNPJ:</b>&nbsp;&nbsp;{{r_emp.cnpj}}</label><br />
                                <label class="margin-buttom-5"><b>RESPONSÁVEIS</b></label>

                                <div class="margin-left-10">
                                    <table class="table table-striped table-bordered">
                                        <tbody>
                                            <tr *ngFor="let r of r_emp.responsaveis" class="margin-left-10">
                                                <td style="padding:15px;">
                                                    <label class="margin-buttom-5"><b>Nome:</b>&nbsp;&nbsp;{{r.nome}}</label><br />
                                                    <label class="margin-buttom-5"><b>Telefone:</b>&nbsp;&nbsp;{{r.telefone}}</label><br />
                                                    <label class="margin-buttom-5"><b>Email:</b>&nbsp;&nbsp;{{r.email}}</label><br /><br />

                                                    <label class="margin-buttom-5"><b>Auditor(a):</b>&nbsp;&nbsp;{{(r._auditor)? 'Sim':'Não'}}</label><br />
                                                    <label class="margin-buttom-5"><b>Status:</b>&nbsp;&nbsp;{{(!r._status_email_validate)? 'Aguardando validação' : (r._status_email_validate === 1) ? 'Aprovado' : 'Reprovado'}}</label><br />
                                                    <label class="margin-buttom-5" *ngIf="r._observacao"><b >Observação:</b>&nbsp;&nbsp;{{r._observacao}}</label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6" style="padding-left:50px;">
                        <br />
                        <h3 style="margin-left: -15px;">RESUMO DO PROCESSO</h3>
                        <br />
                        <div *ngFor="let r_emp of empresas_relacionadas">
                            <div class="{{(empresas_relacionadas[empresas_relacionadas.length - 1] != r_emp)?'grid-proccess':''}}">
                                <div class="proccess-b"></div>

                                <label class="margin-buttom-5 margin-left-10">{{r_emp.nome_fantasia}}</label><br />
                                <label class="margin-buttom-5 margin-left-10"><b>{{(r_emp.tipo_relacao
                                    == 1)?'FATURANTE':'FISCALIZADORA'}}</b></label>
                                <div *ngFor="let r of
                                    r_emp.responsaveis" style=" padding: 5px; margin-left: 25px; font-size: 12px;">
                                    <span class="margin-buttom-5"><b>Responsável:</b>&nbsp;&nbsp;{{r.nome}}&nbsp;&nbsp;|&nbsp;&nbsp;<b>Auditor(a):&nbsp;&nbsp;</b>{{(r._auditor)? 'Sim':'Não'}} </span>
                                </div>
                                <hr style="margin:5px 0px 0px 0px;" *ngIf="empresas_relacionadas[empresas_relacionadas.length - 1] != r_emp" />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 text-center margin-top-20">
                <button class="btn btn-primary" (click)="update()">Avaliar Configurações</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="confirm-config-modal" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable
        modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Aprovar ou Reprovar Configurações</h4>
                <div class="ibox-tools">
                    <div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body modal-confirm" style="padding: 1rem;">
                <b>Obervação:</b>
                <textarea class="form-control" rows="3" [(ngModel)]="_observacao"></textarea>
                <div class="row no-margin">
                    <div class="col-sm-6 text-left margin-top-20">
                        <label class="btn btn-check-table label-checked
                            {{(_concordo)?'active':''}}" (click)="checkedConcordo()">
                            <span class="glyphicon-check-mark fa fa-check"></span>
                        </label>&nbsp;&nbsp;Concordo com as configurações do comprador
                    </div>
                    <div class="col-sm-6 text-right margin-top-20">
                        <button class="btn btn-default margin-right-10" data-dismiss="modal">Cancelar</button>
                        <button class="btn btn-danger margin-right-10" (click)="save(2)">Reprovar</button>
                        <button class="btn btn-primary" (click)="save(1)">Aprovar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>