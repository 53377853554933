import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/service/user/user.service';
import { Usuario } from 'src/app/domain/usuario/Usuario';
import { StatusUsuarioEnum } from 'src/app/domain/usuario/status-usuario-enum';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';

@Component({
  selector: 'app-gerenciamento-acesso',
  templateUrl: './gerenciamento-acesso.component.html',
  styleUrls: ['./gerenciamento-acesso.component.css']
})
export class GerenciamentoAcessoComponent implements OnInit {

  _code = "app-gerenciamento-acesso";

  temp_usuario = new Usuario();
  public usuario_selecionado: Usuario;

  constructor(private userService: UserService, private confirmService: AlertConfirmService) {
    this.confirmService.UnsetObservers();
    this.confirmService.confirmEmitter$.subscribe(c => this.SetAlterUser(c));}
  public usuarios: Usuario[];
  public _buscar: string;
  public _busca_status: number;

  ngOnInit() {

    this.usuario_selecionado = new Usuario();
    this._buscar = "";
    this.usuarios = new Array<Usuario>();
    this.GetAllUser(StatusUsuarioEnum.aguardando);
  }

  SelectUser(user: Usuario): void{
    this.usuario_selecionado = user;
  }

  GetAllUser(status: StatusUsuarioEnum) {
    this._busca_status = status;
    this.userService.getAllStatus(status).subscribe(
      d=>{ this.usuarios = d; },
      e=>{},
      ()=>{}
    );
    this.usuario_selecionado = new Usuario();
  }

  GetAll() {
    this.userService.getAll().subscribe(
      d=>{ this.usuarios = d; },
      e=>{},
      ()=>{}
    );
    this.usuario_selecionado = new Usuario();
  }

  clickAlterStatus(e, usuario: Usuario, status: StatusUsuarioEnum): void{
    e.preventDefault(); 
    this.temp_usuario = usuario;
    this.temp_usuario.status = status;
    this.confirmService._code = this._code;
    this.confirmService._title = "Alterar Status";
    this.confirmService._message = "Deseja alterar o status do usuário "+usuario.nome+"?";
    this.confirmService.Show(); 
  }


  ValidarUser(e, usuario: Usuario): void{
    e.preventDefault();
    usuario.status = StatusUsuarioEnum.ativo;
    this.userService.SetEditStatusProvider(usuario).subscribe(
      d=>{},
      e=>{},
      ()=>{ this.GetAllUser(this._busca_status); }
    );
  }

  InativarUser(e, usuario: Usuario): void{
    e.preventDefault();
    usuario.status = StatusUsuarioEnum.inativo;
    this.userService.SetEditStatusProvider(usuario).subscribe(
      d=>{},
      e=>{},
      ()=>{ this.GetAllUser(this._busca_status); }
    );
  }

  RecusarUser(e, usuario: Usuario): void{
    e.preventDefault();
    usuario.status = StatusUsuarioEnum.recusado;
    this.userService.SetEditStatusProvider(usuario).subscribe(
      d=>{},
      e=>{},
      ()=>{ this.GetAllUser(this._busca_status); }
    );
  }

  AtivarUser(e, usuario: Usuario): void{
    e.preventDefault();
    usuario.status = StatusUsuarioEnum.ativo;
    this.userService.SetEditStatusProvider(usuario).subscribe(
      d=>{},
      e=>{},
      ()=>{ this.GetAllUser(this._busca_status); }
    );
  }

  SetAlterUser(confirm: boolean): void{    
    if(this.confirmService._code != this._code)
      return;
    this.userService.SetEditStatusProvider(this.temp_usuario).subscribe(
      d=>{},
      e=>{},
      ()=>{ this.GetAllUser(this._busca_status); }
    );
  }

}

