<div class="col-lg-12 wrapper-top-content" *ngIf="(userLoggedIn.Inserir ||
    userLoggedIn.Editar) || userLoggedIn.type == 3">
    <div class="ibox-title">
        <h5>Cadastro de Colaborador</h5>
    </div>
    <div class="ibox-content" [hidden]="(usuario._load)">
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Nome:</label>
            <div class="col-sm-10">
                <input type="text" [(ngModel)]="usuario.nome" (ngModelChange)="OnChangeAll()" class="form-control" placeholder="Nome de usuário">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">E-mail:</label>
            <div class="col-sm-10">
                <input type="email" [(ngModel)]="usuario.email" class="form-control" placeholder="E-mail">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">CPF:</label>
            <div class="col-sm-4">
                <input class="form-control" id="mask-cpf" [(ngModel)]="usuario.cpf" placeholder="000.000.000-00">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Telefone:</label>
            <div class="col-sm-4">
                <input class="form-control" id="mask-phone" [(ngModel)]="usuario.telefone" placeholder="(00) 00000-0000">
            </div>
        </div>
        <div class="form-group row" [hidden]="!(usuario.id> 0)">
            <label class="col-sm-12 col-form-label" [hidden]="_alterar_senha">
                <a href (click)="AlterarSenha($event, true)">Quero alterar senha!</a>
            </label>
            <label class="col-sm-12 col-form-label" [hidden]="!_alterar_senha">
                <a href (click)="AlterarSenha($event, false)">Não quero alterar senha!</a>
            </label>
        </div>
        <div class="form-group row" [hidden]="!_alterar_senha && (usuario.id>
            0)">
            <label class="col-sm-2 col-form-label">Senha:</label>
            <div class="col-sm-4">
                <input type="password" class="form-control" [(ngModel)]="usuario.senha" placeholder="Senha">
            </div>
        </div>
        <div class="form-group row" [hidden]="!_alterar_senha && (usuario.id>
            0)">
            <label class="col-sm-2 col-form-label">Repita Senha:</label>
            <div class="col-sm-4">
                <input type="password" class="form-control" [(ngModel)]="usuario.repita_senha" placeholder="Repita
                    Senha">
            </div>
        </div>
        <br /><br />
        <div class="form-group row">
            <div class="col-sm-4">
                <button type="submit" class="btn btn-default Right" (click)="CancelUser()" [hidden]="!(usuario.id> 0)">Cancelar</button>
                <label [hidden]="!(usuario.id> 0)">&nbsp;</label>
                <button type="submit" class="btn btn-primary" *ngIf="(userLoggedIn.Inserir || userLoggedIn.Editar) ||
                    userLoggedIn.type == 3" (click)="SaveUser()">Salvar</button>
            </div>
        </div>
    </div>

</div>