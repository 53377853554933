import { Component, OnInit, ComponentFactoryResolver, Input } from '@angular/core';
import { ProdutoCategoriaNivel, ComponetesSelecao, TipoSelecao } from 'src/app/domain/suprimento/produto-categoria-nivel';

import { GlobalVariable } from 'src/app/core/global-variable';
import { Cotacao } from 'src/app/domain/cotacao/cotacao';
import { EnumStatusOrcamento, EnumTipoOrcamento } from 'src/app/domain/orcamento/orcamento';
import { Usuario, TypeUser } from 'src/app/domain/usuario/Usuario';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { OrcamentoFornecedorCtrl } from '../orcamento-forncedor-ctrl';

declare var $: any;

@Component({
  selector: 'app-orcamento-email-edit',
  templateUrl: './orcamento-email-edit.component.html',
  styleUrls: ['./../orcamento-edit/orcamento-edit.component.css']
})
export class OrcamentoEmailEditComponent extends OrcamentoFornecedorCtrl implements OnInit {

  _msg_titulo = ''
  _msg_descricao = ''

  _cotacao_id = 0
  ngOnInit() {
    this.confirmService.UnsetObservers();
    this.confirmService.confirmEmitter$.subscribe(c => { this.DeleteAliquota(c); this.FinalizarOrcamentoEmail(c); });

    this.userLoggedService.SetCurrentUser();
    this._niveis = new Array<ProdutoCategoriaNivel>();
    this.VerificaOrcamento()
  }
  ReceiverEntregaProduto(e) {

  }

  VerificaOrcamento() {
    this.orcamentoService.CreateEmail(this.userLoggedService.urlToken).subscribe(
      d => {
        this._cotacao_id = d.cotacao_id
        this._orcamento = d;
        if (d.status != EnumStatusOrcamento.aberto) {
          if (d.tipo == EnumTipoOrcamento.sugerido && d.status > EnumStatusOrcamento.enviado)
            this.router.navigateByUrl('/orcamentos/email/view');
          else if(d.tipo == EnumTipoOrcamento.comum)
            this.router.navigateByUrl('/orcamentos/email/view')
        }

        if (this.userLoggedService.type == TypeUser.provider)
          this.router.navigateByUrl('/orcamentos/edit/' + d.id);
        else {
          this.SetListaUnidadeMedidaEmail()
          this.GetEmpresaLoggedIn()
        }
      },
      e => { this.httpAuthenticate.logout(); });
  }

  GetCotacaoEmail(id: number) {
    this.cotacaoService.Get(id).subscribe(
      d => { this._cotacao = d; },
      e => { },
      () => {
        this._cotacao._meio_pagamento = Cotacao.GetMeioPagamento(this._cotacao.meio_pagamento);
        Cotacao.SetFormat(this._cotacao);
        this.GetEndereco(this._cotacao.endereco_id);
        this.GetAllProdutos(this._cotacao_id);
        this.CarregarAliquota(this._orcamento.id);
      }
    );
  }

  SetListaUnidadeMedidaEmail() {
    this.httpProduto.GetAllUnitsMeasured().subscribe(
      data => { this._unidade_medida = data; },
      error => { },
      () => {
        this.GetCotacaoEmail(this._cotacao_id);
      }
    );
  }

  ConfirmarEnvioOrcamentoEmail() {

    this.ValidarItens()
    /*
        if (!this._li_termos)
          throw ("Leia os termos para enviar o orçamento!")
    */
    this.confirmService._code = this._code_right
    this.confirmService._title = "Enviar Orçamento"
    this.confirmService._message = "Confirma o envio?"
    this.confirmService._message_input_geral = " <b>Insira o CNPJ:</b>"

    this.confirmService._message_input_secundario = " <b>Insira o CEP:</b>"



    this.confirmService._require = 'cnpj'
    this.confirmService._placeholder = "__.___.___/____-__"
    setTimeout(() => {
      $(".input-geral").mask("99.999.999/9999-99")
      $(".input-secundario").mask("99999-999")
    }, 1000)
    if (UseFullService.IsCNPJ(this._orcamento.cnpj))
      this.confirmService._input = this._orcamento.cnpj
    this.confirmService._input_secundario = this._orcamento.cep
    this.confirmService.Show(true)
  }

  FinalizarOrcamentoEmail(confirm: boolean) {
    if (!this._orcamento || !confirm || this.confirmService._code != this._code_right)
      return;
    this.VerificaCnpj()
  }

  VerificaCnpj() {
    var _this = this
    this._orcamento.cnpj = this.confirmService._input
    this._orcamento.cep = this.confirmService._input_secundario
    this.userService.getAllUserCNPJ(this._orcamento.cnpj).subscribe(
      d => {
        if (d && d != null && d.length > 0) {
          setTimeout(() => {
            this._msg_titulo = 'CNPJ Já Cadastrado'
            this._msg_descricao = 'Por questões de segurança o orçamento não pode ser enviado, sua empresa já contém um cadastro. '
            this._msg_descricao += ' Solicite seu acesso a um administrador do sistema, para enviar o orçamento.'
            this._msg_descricao += '<br /><br /><b>Sugestão de contato</b>'
            this._msg_descricao += '<br /><b>CNPJ:</b> ' + d[0].cnpj
            this._msg_descricao += '<br /><b>Usuario:</b> ' + d[0].nome
            this._msg_descricao += '<br /><b>E-mail:</b> ' + d[0].email
            this._msg_descricao += '<br /><br /><b>Deseja enviar ao administrador solicitação de acesso? </b>'
            this._msg_descricao += '<div class="confirm-message-email">'
            if (this._orcamento.solicitacao_acesso < 1) {
              this._msg_descricao += '<label class="btn btn-secondary">Não</label><label class="btn btn-primary" >Sim</label></div>'
            } else
              this._msg_descricao += '<label class="text-success">Solicitação acesso já foi enviada!</label></div>'

            this._msg_descricao += '<br /><br />Caso possua uma conta no painel da engenharia. <a href="' + GlobalVariable.HOST + '#/login/email/' + this.userLoggedService.urlToken + '">clique aqui!</a>'
            this._msg_descricao += '<br /><br />Para mais informações e suporte acesse: <a href="https://www.paineldaengenharia.com.br/fornecedor">www.paineldaengenharia.com.br</a>'
            $('#message-modal').modal('show')

            setTimeout(() => {
              $('.confirm-message-email textarea').remove()
              const disabled = (this._orcamento.solicitacao_acesso > 0) ? 'disabled' : ''
              const text = (this._orcamento.observacao_solicitante) ? this._orcamento.observacao_solicitante : ''
              $('.confirm-message-email').append('<textarea class="form-control   " ' + disabled + ' placeholder="Digite algo que ajude o administrador te identificar" rows="3">' + text + '</textarea>')
              $('.confirm-message-email > label').click((e) => {
                if ($(e.currentTarget).attr('class').match('btn'))
                  _this.SolicitarAcesso($(e.currentTarget).attr('class').match('btn-primary'), d[0].id)
              });
            }, 500)
          }, 500);
        } else
          this.EnviarOrcamentoEmail()
      }
    )
  }

  EnviarOrcamentoEmail() {
    this._orcamento.status = EnumStatusOrcamento.enviado
    this._orcamento.data_envio = UseFullService.formatDateTimeUS(new Date)
    if (this.userLoggedService.type == TypeUser.provider)
      this._orcamento.user_id = this.userLoggedService.id
    this._orcamento.valor_total = UseFullService.ReplaceFloat(this._orcamento._total)
    this._orcamento.sub_total = UseFullService.ReplaceFloat(this._orcamento._subtotal)
    this.orcamentoService.Edit(this._orcamento).subscribe(d => {
      this.httpAuthenticate.loginEmail(this.userLoggedService.urlToken).subscribe(
        data => { },
        error => { },
        () => {
          if (this.httpAuthenticate.isLoggedIn())
            this.router.navigateByUrl('/orcamentos/email/view')
        }
      )
    })
  }

  SolicitarAcesso(solicitacao: boolean, usuarioId: number) {
    if (solicitacao) {
      this._orcamento.id_usuario_solicitado = usuarioId
      this._orcamento.observacao_solicitante = $('.confirm-message-email textarea').val()
      this._orcamento.solicitacao_acesso = 1
      this._orcamento.valor_total = UseFullService.ReplaceFloat(this._orcamento._total)
      this._orcamento.sub_total = UseFullService.ReplaceFloat(this._orcamento._subtotal)
      this.orcamentoService.Edit(this._orcamento).subscribe(d => {
        $('.confirm-message-email').html('<label class="text-success">Solicitação enviada com sucesso!</label>')
      })
    } else
      $('#message-modal').modal('hide')
  }

}