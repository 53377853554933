import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from 'src/app/core/global-variable';
import { Entrega } from 'src/app/domain/entrega/entrega';

@Injectable({
  providedIn: 'root'
})
export class EntregaService {
      
  private url = GlobalVariable.BASE_API_URL + "entrega/";
  constructor(private http: HttpClient) { }
  
  GetAllUser(status: number = 0, inicio, fim): Observable<Entrega[]> {
    return this.http.get<Entrega[]>(this.url + "getallUser?status="+status+"&inicio="+inicio+"&fim="+fim).pipe(
      map(res => {
        return res;
      })
    );
  }
  
  GetAllCNPJ(status: number = 0, inicio, fim): Observable<Entrega[]> {
    return this.http.get<Entrega[]>(this.url + "getallcnpj?status="+status+"&inicio="+inicio+"&fim="+fim).pipe(
      map(res => {
        return res;
      })
    );
  }

  Get(id: number) {
    return this.http.get<Entrega>(this.url + "get?id="+id).pipe(
      map(res => {
        return res;
      })
    );
  }
  
  Edit(data: Entrega): Observable<any>{
    return this.http.put<any>(this.url+"edit", data).pipe(
      map(res=> { return res; })
    );
  }
}
