import { Component, OnInit, ComponentFactoryResolver, Output } from '@angular/core';
import { Empresa } from 'src/app/domain/empresa/empresa';
import { EnumService } from 'src/app/service/usefull/enum.service';
import { Estado } from 'src/app/domain/enum/estado';
import { Municipio } from 'src/app/domain/enum/municipio';
import { ViaCepService, ViaCep } from 'src/app/service/usefull/viacep.service';
import { EmpresaService } from 'src/app/service/configuracao/empresa.service';
import { throttleTime } from 'rxjs/operators';
import { UserService } from 'src/app/service/user/user.service';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { GlobalVariable } from 'src/app/core/global-variable';
import { Usuario, PersonType } from 'src/app/domain/usuario/Usuario';

declare var $: any;
@Component({
  selector: 'app-dados-client',
  templateUrl: './dados-client.component.html',
  styleUrls: ['./dados-client.component.css']
})
export class DadosClientComponent implements OnInit {

  public _nome_arquivo: string;
  public _src_arquivo: string;

  public _empresa: Empresa;
  public _estados: Estado[];
  public _municipios: Municipio[];
  public _viacep: ViaCep;

  constructor(
    private enumService: EnumService,
    private viaCepService: ViaCepService,
    private empresaService: EmpresaService,
    private userService: UserService,
    public userLoggedInService: UserLoggedInService) {
    this._nome_arquivo = "";
    this._src_arquivo = "";
  }

  ngOnInit() {
    this._empresa = new Empresa();
    this._empresa._files = new Array<File>();
    this._estados = new Array<Estado>();
    this._municipios = new Array<Municipio>();
    this._viacep = new ViaCep();
    this.GetEstado();
    this.GetEmpresaLoggedIn();
    this.JqueryEnvet()
    
  }

  reload() {
    window.location.reload();
  }

  GetEmpresaLoggedIn() {

    this.empresaService.GetLoggedIn().subscribe(
      d => {
        if (d != null) {
          this._empresa = d;
          this.SetConfigUser()
        } else
          this.CarregarDadosUsuario();
      },
      e => { },
      () => { }
    );
  }
  _cnpjCpf = '';
  SetConfigUser() {
    if (this._empresa != null && this._empresa.id > 0) {
      this._cnpjCpf = (this.userLoggedInService.person == PersonType.fisica) ? this._empresa.cpf : this._empresa.cnpj
      var logo = this._cnpjCpf + '/logo/miniatura/' + this._empresa.logo;    
      if(this.userLoggedInService.img != logo){
        var _currentUser = localStorage.getItem('currentUser');
        if(this.userLoggedInService.img)      
          _currentUser = _currentUser.replace(this.userLoggedInService.img, logo);
        else
          _currentUser = _currentUser.replace('"img":""', '"img":"'+logo+'"');
          
        localStorage.setItem('currentUser', _currentUser)
        this.userLoggedInService.SetCurrentUser();
      }

      this._empresa._files = new Array<File>();
      this.MostrarArquivos();
      $("#image-logo").attr("src", GlobalVariable.BASE_API_URL + logo);
      $("#image-logo").fadeIn();
      this.GetMunicipios(this._empresa.uf, false);
    }
    
    if (this._empresa.cnpj)
      this._empresa._cnpj = UseFullService.GetCnpjMasc(this._empresa.cnpj);
    if (this._empresa.cpf)
      this._empresa._cpf = UseFullService.GetCpfMasc(this._empresa.cpf);
  }

  MostrarLogo() {
    this.CarregarDataModal("logo/" + this._empresa.logo);
  }

  GetEstado() {
    this.enumService.GetAll("Estado").subscribe(
      d => { this._estados = d; },
      e => { },
      () => { }
    );
  }

  OnChangeEstado() {
    this.GetMunicipios(this._empresa.uf);
  }

  GetMunicipios(uf: string, via_cep: boolean = true) {
    this.enumService.GetAll("Municipio", uf).subscribe(
      d => { this._municipios = d; },
      e => { },
      () => {
        if (via_cep)
          this.SetCidadeViaCep();
      }
    );
  }

  OnChangeCep() {
    this.LimparInputs();
    setTimeout(() => {
      if (this._empresa.cep != undefined && this._empresa.cep.length == 9)
        this.GetCep();
    }, 10);
  }

  GetCep() {
    this.viaCepService.Get(this._empresa.cep).subscribe(
      d => { this._viacep = d; },
      e => { },
      () => { this.SetEndereco(); }
    );
  }

  SetEndereco() {
    if (this._viacep.erro)
      return;
    this._empresa.bairro = this._viacep.bairro;
    this._empresa.endereco = this._viacep.logradouro + " " + this._viacep.complemento;
    this._empresa.uf = this._viacep.uf;
    this.GetMunicipios(this._viacep.uf);
  }

  LimparInputs() {
    this._empresa.bairro = "";
    this._empresa.endereco = "";
    this._empresa.uf = "";
    this._empresa.cidade = "";
  }

  SetCidadeViaCep() {
    this._empresa.cidade = this._viacep.ibge;
  }

  CarregarArquivos(input: any) {

    for (let i = 0; i < input.files.length; i++)
      this._empresa._files.push(input.files[i]);

    setTimeout(() => {
      this.MostrarArquivos();
    }, 50);

  }
  MostrarArquivos() {

    let badge = '';

    if (this._empresa.arquivos_contrato_social != undefined && this._empresa.arquivos_contrato_social != "") {
      var files_contrato = this._empresa.arquivos_contrato_social.split(':');
      files_contrato.forEach(c => {
        badge += '<div class="badge badge-success"><div class="badge-close" id="' + c + '"><label id="' + c + '">x</label></div>' + c + '</div> ';
      });
    }

    for (let i = 0; i < this._empresa._files.length; i++) {
      badge += '<div class="badge badge-primary"><div class="badge-close" id="' + i + '"><label id="' + i + '">x</label></div>' + this._empresa._files[i].name + '</div> ';
    }
    $("#previow_file").html(badge);
    $("#previow_file").fadeIn();
  }

  CarregarLogo(input: any) {
    this._empresa._img = input.files[0];

    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var target = e.target as any;
        $("#image-logo").attr("src", target.result);
        $("#image-logo").show();
      };

      reader.readAsDataURL(input.files[0]);
    }
  }

  Insert() {    
    if(this.userLoggedInService.person == 1){
      this._empresa.razao_social = this.userLoggedInService.name
      this._empresa.nome_fantasia = this.userLoggedInService.name
    }
    this.empresaService.Insert(this._empresa).subscribe(
      d => { },
      e => { },
      () => { this.reload(); }
    );
  }

  CarregarDadosUsuario() {
    this.userService.getLoggedIn().subscribe(
      d => {
        this._empresa.cnpj = d.cnpj
        if(d.tipo_pessoa == PersonType.fisica)
          this._empresa.cpf = d.cpf
        this._empresa.inscricao_estadual = d.inscricao_estadual
        this.SetConfigUser();
      }
    );
  }

  JqueryEnvet() {

    $("#cep").mask("99999-999");
    $("#number").mask("999999999999999999999999");
    $("#numero").mask("999999999999999999999999");
    $("#cnpj").mask("99.999.999/9999-99");

    var _this = this;
    var active = false;
    $("#previow_file").on('click', 'div.badge-close', function () {

      var id = $(this).attr("id");

      if ($.isNumeric(id))
        _this._empresa._files.splice(id, 1);
      else {

        var divisor_fim = "";
        var divisor_inicio = "";
        if (_this._empresa.arquivos_contrato_social.indexOf(id + ":") != -1)
          divisor_fim = ":";
        else if (_this._empresa.arquivos_contrato_social.indexOf(":" + id) != -1 && divisor_fim == "")
          divisor_inicio = ":";

        _this._empresa.arquivos_contrato_social = _this._empresa.arquivos_contrato_social.replace(divisor_inicio + id + divisor_fim, '');

        if (_this._empresa.log == null) {
          _this._empresa.log = "";
        }
        _this._empresa.log += '{ "name":"' + id + '", "data":"' + UseFullService.formatDateTime() + '", ' +
          '"user_id":"' + _this.userLoggedInService.id + '"}|';
      }

      setTimeout(() => { _this.MostrarArquivos(); }, 100);
      active = true;
    });

    $("#previow_file").on('click', 'div.badge-primary', function () {
      if (active) {
        active = false;
        return;
      } else {
        _this.CarregarDataModal($(this).find('.badge-close').attr('id'));
      }
    });

    $("#previow_file").on('click', 'div.badge-success', function () {
      if (active) {
        active = false;
        return;
      } else {
        _this.CarregarDataModal("contrato_social/" + $(this).find('.badge-close').attr('id'));
      }
    });
  }

  CarregarDataModal(id) {
    this._nome_arquivo = "";
    this._src_arquivo = "";
    $("#exibir-pdf").html("");

    if ($.isNumeric(id)) {

      if (!this._empresa._files && !this._empresa._files[id])
        return;

      var file = this._empresa._files[id];
      this.PreviewLoadData(file);
    } else {
      this._nome_arquivo = id;
      if (id.match(".pdf"))
        $("#exibir-pdf").html("<iframe width='100%' height='" + ($('body').height() - 100) + "px' src='" + GlobalVariable.BASE_API_URL + this._cnpjCpf + "/" + id + "'></iframe>");
      else {

        if (this._empresa._img != undefined && this._empresa._img.size > 0)
          this.PreviewLoadData(this._empresa._img);
        else
          this._src_arquivo = GlobalVariable.BASE_API_URL + this._cnpjCpf + "/" + id;
      }
    }

    $('#data-modal').modal('show');
  }

  PreviewLoadData(file: File) {
    this._nome_arquivo = file.name;
    var _this = this;
    var reader = new FileReader();
    reader.onload = function (e) {
      var target = e.target as any;

      if (file.type == "application/pdf") {
        $("#exibir-pdf").html("<iframe width='100%' height='" + ($('body').height() - 100) + "px' src='" + target.result + "'></iframe>");
        _this._src_arquivo = "";
      } else
        _this._src_arquivo = target.result;
    };

    reader.readAsDataURL(file);
  }
}

export class TargetImage {
  public error: any;
  public result: string;
}