import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { from } from 'rxjs';
import { Authenticate } from 'src/app/domain/authenticate';

@Injectable()
export class CustomHttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var observableFromPromise = from(this.handleAccess(request, next));
    return observableFromPromise;
  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler):
    Promise<HttpEvent<any>> {
    //await "WE1R6W4ER65WE1Q1E5E1";
    const currentUser = JSON.parse(localStorage.getItem('currentUser')) as Authenticate;

    //let changedRequest = request;
    const headerSettings: { [name: string]: string | string[]; } = {};

    //headerSettings['Content-Type'] = 'application/json';
    //let headers = new HttpHeaders();
    //headers = headers.set('Content-Type', 'multipart/form-data;').set('Accept', '');

    for (const key of request.headers.keys()) {
      headerSettings[key] = request.headers.getAll(key);
    }

    if (currentUser && currentUser.token){
      headerSettings['Authorization'] = 'Bearer ' + currentUser.token;
    }
    headerSettings['x-domain'] = 'paineldaengenharia';
    const newHeader = new HttpHeaders(headerSettings);

    //changedRequest = request.clone({ headers: newHeader });
    return next.handle(request.clone({ headers: newHeader })).toPromise();
  }
}