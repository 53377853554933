import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/service/authenticate/authentication.service';
import { Authenticate } from 'src/app/domain/authenticate';

@Component({
  selector: 'app-login-fornecedor-email',
  templateUrl: './login-fornecedor-email.component.html',
  styleUrls: ['./login-fornecedor-email.component.css'],
  providers: [AuthenticationService]
})
export class LoginFornecedorEmailComponent implements OnInit {

  public _login: string;
  public _password: string;
  public _token: string;

  constructor(private httpAuthenticate: AuthenticationService, private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this._token = this.route.snapshot.params['id'];
    this.LogOut()
  }

  LogInto() {
    this.httpAuthenticate.login(this._login, this._password, this._token).subscribe(
      data => this.ConfirmLogin(),
      error => { },
      () => { }
    );
  }

  LogNoInto() {
    this.httpAuthenticate.loginEmail(this._token).subscribe(
      data => this.ConfirmLogin(),
      error => { },
      () => { }
    );
  }

  ConfirmLogin(): void {
    setTimeout(() => {
      if (this.httpAuthenticate.isLoggedIn()){
        this.router.navigateByUrl('/orcamentos/email/edit')
      }
    }, 100);
  }

  LogOut() {
    this.httpAuthenticate.logoutEmail()
  }
}