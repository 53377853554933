import { InteractiveTable } from '../common/interactiveTable'
import { ProdutoComprador } from './comprador/produto-comprador'
import { UseFullService } from 'src/app/service/usefull/usefull.service'

export class Marca extends InteractiveTable {
    public id: number
    public descricao: string
}

export class RelacaoObraProduto extends InteractiveTable {
    public id: number
    public obra_id: number
    public produto_id: number
    public previsto_total: string
    public origem: number
    public unidade_id: number
    public crossdocking: number
    public comprador_categoria_id: number
    public valor: string

    public _marcas: Array<RelacaoProdutoMarcaCategoria>
    public _produto: any
    public _produto_categoria: string
    public _categoria_index: number
    public _categoria_id: number
    public _unidade: string

    static formatValues(c: RelacaoObraProduto) {
        c.previsto_total = (c.previsto_total) ? UseFullService.ToFixedReal(c.previsto_total) : ''
        c.valor = (c.valor) ? UseFullService.ToFixedReal(c.valor) : ''
        c.crossdocking = (c.crossdocking > 0) ? c.crossdocking : null
    }

    static format(c: RelacaoObraProduto) {
        c.previsto_total = UseFullService.ToFixedReal(c.previsto_total)
        c.valor = UseFullService.ToFixedReal(c.valor)
        c.crossdocking = c.crossdocking
    }
}

export class RelacaoProdutoMarcaCategoria extends InteractiveTable {
    public id: number
    public produto_id: number
    public marca_id: number
    public categoria_id: number
    public origem: number
}

export class CompradorCategoriaProduto extends InteractiveTable {
    public id: number
    public obra_id: number
    public categoria_id: number
    public descricao: string

    public _listRelacaoObraProduto: Array<RelacaoObraProduto>
}