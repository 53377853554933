import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { GlobalVariable } from 'src/app/core/global-variable';
import { ExtratoFornecedor } from 'src/app/domain/gerenciamento/extrato-fornecedor';
import { ContatoFinanceiro } from 'src/app/domain/empresa/contato-financeiro';

@Injectable({
    providedIn: 'root'
})
export class GerenciamentoFornecedorService {

    private url = GlobalVariable.BASE_API_URL;
    constructor(private http: HttpClient) {}

    GetAll(status: number, inicio: string, fim: string) {
        return this.http.get<ExtratoFornecedor[]> (this.url + "transacao/extratoAll/financeiro?status="+status+"&inicio="+inicio+"&fim="+fim).pipe(
            map(res => {
                return res;
            })
        );
    }

    GetCNPJ(cnpj:string, status: number, inicio: string, fim: string) {
        return this.http.get<ExtratoFornecedor[]> (this.url + "transacao/extrato/financeiro?cnpj="+cnpj+"&status="+status+"&inicio="+inicio+"&fim="+fim).pipe(
            map(res => {
                return res;
            })
        );
    }


    GetContatosFinanceiro(cnpj:string) {
        return this.http.get<ContatoFinanceiro[]> (this.url + "transacao/empresa/contatos/financeiro?cnpj="+cnpj).pipe(
            map(res => {
                return res;
            })
        );
    }

    
}