import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { FaturamentoDashboard } from 'src/app/domain/auxiliar/dashboard';
declare var $: any;

@Component({
  selector: 'app-dashboard-venda',
  templateUrl: './dashboard-venda.component.html',
  styleUrls: ['./dashboard-venda.component.css']
})
export class DashboardVendaComponent implements OnInit {


  constructor() { }

  ngOnInit() {
  }


}