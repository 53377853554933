<div class="col-lg-12 wrapper-top-content">
    <div class="ibox-title">
        <h5>Dados da Empresa</h5>
    </div>

    <form #EmpresaForm="ngForm" appIdentityRevealed action="#">
        <div class="ibox-content form-empresa">

            <div class="form-group row">
                <label class="col-sm-2 col-form-label"><b>Razão Social</b> *</label>
                <div class="col-sm-4">
                    <input type="text" id="razao_social" name="razao_social" [(ngModel)]="_empresa.razao_social" required minlength="4" class="form-control" placeholder="Razão Social" #razao_social="ngModel">

                    <div *ngIf="razao_social.invalid && (razao_social.dirty ||
                        razao_social.touched)" class="input-error-msg
                        text-danger">
                        <div *ngIf="razao_social.errors.required">
                            Razão Social é obrigatório.
                        </div>
                        <div *ngIf="razao_social.errors.minlength">
                            Razão Social é obrigatório.
                        </div>
                    </div>
                </div>
                <label class="col-sm-2 col-form-label"><b>CNPJ</b> *</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control input-cnpj" id="cnpj" name="cnpj" [(ngModel)]="_empresa._cnpj" disabled>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2 col-form-label"><b>Nome Fantasia</b> *</label>
                <div class="col-sm-4">
                    <input type="text" required minlength="4" id="nome_fantasia" name="nome_fantasia" class="form-control" [(ngModel)]="_empresa.nome_fantasia" placeholder="Nome Fantasia" #nome_fantasia="ngModel">
                    <div *ngIf="nome_fantasia.invalid && (nome_fantasia.dirty ||
                        nome_fantasia.touched)" class="input-error-msg
                        text-danger">
                        <div *ngIf="nome_fantasia.errors.required">
                            Nome Fantasia é obrigatório.
                        </div>
                        <div *ngIf="nome_fantasia.errors.minlength">
                            Nome Fantasia é obrigatório.
                        </div>
                    </div>
                </div>
                <label class="col-sm-2 col-form-label"><b>Inscrição Estadual</b></label>
                <div class="col-sm-4">
                    <input type="text" class="form-control
                        input-inscricao-estadual" name="inscricao_estadual" id="number" #inscricao_estadual="ngModel" [(ngModel)]="_empresa.inscricao_estadual" placeholder="Inscrição Estadual">
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2 col-form-label"><b>Telefone</b> *</label>
                <div class="col-sm-4">
                    <input type="text" required minlength="4" id="telefone" placeholder="(00) 0000-0000" name="telefone" class="form-control input-fone" [(ngModel)]="_empresa.telefone" #telefone="ngModel">
                    <div *ngIf="telefone.invalid && (telefone.dirty ||
                        telefone.touched)" class="input-error-msg
                        text-danger">
                        <div *ngIf="telefone.errors.required">
                            Telefone é obrigatório.
                        </div>
                        <div *ngIf="telefone.errors.minlength">
                            Telefone é obrigatório.
                        </div>
                    </div>
                </div>
                <label class="col-sm-2 col-form-label"><b>Tipo Empresa</b> *</label>
                <div class="col-sm-3">
                    <select [(ngModel)]="_empresa.categoria" name="categoria" required id="categoria" #categoria="ngModel" class="form-control select-max-200">
                        <option value="" selected>Selecione o tipo</option>
                        <option value="1" selected>Revendedor</option>
                        <option value="2" selected>Representante</option>
                        <option value="3" selected>Indústria</option>
                        <option value="4" selected>Indústria e Revendedor</option>
                    </select>


                    <div *ngIf="categoria.invalid && (categoria.dirty ||
                        categoria.touched)" class="input-error-msg text-danger">
                        <div *ngIf="categoria.errors.required">
                            Tipo de empresa é obrigatório.
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2 col-form-label"><b>Logo</b></label>
                <div class="col-sm-4">
                    <div class="fileUpload btn btn-primary">
                        <span>+ Escolher Logo</span>

                        <input #imageInput type="file" name="logo" id="fileImage" class="upload" accept="image/*" placeholder="Inserir Logo" (change)="CarregarLogo(imageInput)">
                    </div>
                    <br />
                    <img id="image-logo" (click)="MostrarLogo()" />
                </div>


                <label class="col-sm-2 col-form-label"><b>Contrato Social</b></label>
                <div class="col-sm-4">

                    <div class="fileUpload btn btn-primary">
                        <span>+ Escolher Arquivo</span>
                        <input #fileInput type="file" name="file" id="file" accept="image/*,.pdf" class="upload" placeholder="Inserir Contrato Social" (change)="CarregarArquivos(fileInput)" multiple>
                    </div>
                    <!--
                        <div class="info-cor">
                        <div class="badge badge-pill badge-success info-cor-badge">&nbsp;</div> verde<br />
                        <div class="badge badge-pill badge-primary info-cor-badge">&nbsp;</div> verde
                        </div>
                    -->
                    <div id="previow_file">

                    </div>
                </div>
            </div>

            <br />
            <h4 style="color: #676a6c;">Dados de Endereço</h4>
            <hr />

            <div class="form-group row">
                <label class="col-sm-2 col-form-label"><b>CEP</b> *</label>
                <div class="col-sm-3">
                    <input type="text" [(ngModel)]="_empresa.cep" id="cep" name="cep" required minlength="9" #cep="ngModel" (ngModelChange)="OnChangeCep()" class="form-control
                        input-cep" placeholder="00000-000">

                    <div *ngIf="cep.invalid && (cep.dirty || cep.touched)" class="input-error-msg text-danger">
                        <div *ngIf="cep.errors.required">
                            CEP é obrigatório.
                        </div>
                        <div *ngIf="cep.errors.minlength">
                            CEP deve ter 8 caracteres.
                        </div>
                    </div>
                </div>
                <label class="col-sm-1"></label>
                <label class="col-sm-2 col-form-label"><b>Número</b> *</label>
                <div class="col-sm-3">
                    <input type="text" [(ngModel)]="_empresa.numero" id="number" name="numero" #numero="ngModel" required minlength="1" class="form-control input-number" placeholder="Número">
                    <div *ngIf="numero.invalid && (cep.dirty || numero.touched)" class="input-error-msg text-danger">
                        <div *ngIf="numero.errors.required">
                            Numero é obrigatório.
                        </div>
                        <div *ngIf="numero.errors.minlength">
                            Numero é obrigatório.
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2 col-form-label"><b>Endereço</b> *</label>
                <div class="col-sm-4">
                    <input type="text" [(ngModel)]="_empresa.endereco" name="endereco" #endereco="ngModel" required minlength="1" class="form-control" placeholder="endereco">

                    <div *ngIf="endereco.invalid && (endereco.dirty ||
                        endereco.touched)" class="input-error-msg text-danger">
                        <div *ngIf="endereco.errors.required">
                            Endereço é obrigatório.
                        </div>
                        <div *ngIf="endereco.errors.minlength">
                            Endereço é obrigatório.
                        </div>
                    </div>
                </div>
                <label class="col-sm-2 col-form-label"><b>Complemento</b></label>
                <div class="col-sm-4">
                    <input class="form-control" name="complemento" [(ngModel)]="_empresa.complemento" placeholder="Complemento">
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2 col-form-label"><b>Estado</b> *</label>
                <div class="col-sm-3">
                    <select [(ngModel)]="_empresa.uf" name="thisuf" #thisuf="ngModel" required (ngModelChange)="OnChangeEstado()" class="form-control
                        select-cidade">
                        <option value="" selected>Selecione Estado</option>
                        <option *ngFor="let estado of _estados"
                            value="{{estado.Uf}}">{{estado.Nome}}</option>
                    </select>

                    <div *ngIf="thisuf.invalid && (thisuf.dirty ||
                        thisuf.touched)" class="input-error-msg text-danger">
                        <div *ngIf="thisuf.errors.required">
                            Estado é obrigatório.
                        </div>
                    </div>
                </div>
                <label class="col-sm-1"></label>
                <label class="col-sm-2 col-form-label"><b>Cidade</b> *</label>
                <div class="col-sm-3">
                    <select [(ngModel)]="_empresa.cidade" class="form-control
                        select-cidade" name="thiscidade" #thiscidade="ngModel" required>
                        <option value="" selected>Selecione Cidade</option>
                        <option *ngFor="let cidade of _municipios"
                            value="{{cidade.Codigo}}">{{cidade.Nome}}</option>
                    </select>

                    <div *ngIf="thiscidade.invalid && (thiscidade.dirty ||
                        thiscidade.touched)" class="input-error-msg
                        text-danger">
                        <div *ngIf="thiscidade.errors.required">
                            Cidade é obrigatório.
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2 col-form-label"><b>Bairro</b> *</label>
                <div class="col-sm-4">
                    <input class="form-control input-bairro" [(ngModel)]="_empresa.bairro" name="bairro" #bairro="ngModel" required minlength="2" placeholder="Bairro">

                    <div *ngIf="bairro.invalid && (bairro.dirty ||
                        bairro.touched)" class="input-error-msg text-danger">
                        <div *ngIf="bairro.errors.required">
                            Bairro é obrigatório.
                        </div>
                        <div *ngIf="bairro.errors.minlength">
                            Bairro é obrigatório.
                        </div>
                    </div>
                </div>
            </div>

            <br />
            <h4 style="color: #676a6c;">Contato Financeiro</h4>
            <hr />

            <div *ngFor="let c_financeiro of _empresa.list_contatos_financeiro" class="{{(c_financeiro._index > 0)? 'separacao-endereco':''}}">
                <div class="form-group row">
                    <label class="col-sm-2 col-form-label"><b>Nome</b> *</label>
                    <div class="col-sm-4">
                        <input type="text" [(ngModel)]="c_financeiro.nome" name="nome_financeiro_{{c_financeiro._index}}" #nome_financeiro="ngModel" required minlength="2" class="form-control" placeholder="Nome">

                        <div *ngIf="nome_financeiro.invalid && (nome_financeiro.dirty ||
                        nome_financeiro.touched)" class="input-error-msg
                            text-danger">
                            <div *ngIf="nome_financeiro.errors.required">
                                Nome é obrigatório.
                            </div>
                            <div *ngIf="nome_financeiro.errors.minlength">
                                Nome é obrigatório.
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 text-right">
                        <button *ngIf="c_financeiro._index > 0" class="btn btn-link btn-link-color" data-toggle="tooltip" data-placement="top" title="Remover Contato Financeiro">
                            <i class="fa fa-trash-alt" (click)="DeleteContatoFinanceiro(c_financeiro)"></i>
                        </button>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-2 col-form-label"><b>Email</b> *</label>
                    <div class="col-sm-4">
                        <input type="text" [(ngModel)]="c_financeiro.email" name="email_financeiro_{{c_financeiro._index}}" #email_financeiro="ngModel" required class="form-control" placeholder="email@email.com">

                        <div *ngIf="email_financeiro.invalid && (email_financeiro.dirty ||
                        email_financeiro.touched)" class="input-error-msg
                            text-danger">
                            <div *ngIf="email_financeiro.errors.required">
                                Email é obrigatório.
                            </div>
                            <div *ngIf="email_financeiro.errors.minlength">
                                Email é obrigatório.
                            </div>
                        </div>
                    </div>
                    <label class="col-sm-2 col-form-label"><b>Celular</b> *</label>
                    <div class="col-sm-3">
                        <input type="email" [(ngModel)]="c_financeiro.telefone" id="fone" required name="telefone_financeiro_{{c_financeiro._index}}" minlength="8" #telefone_financeiro="ngModel" class="form-control input-fone" placeholder="(XX) X XXXX-XXXX">

                        <div *ngIf="telefone_financeiro.invalid && (telefone_financeiro.dirty ||
                        telefone_financeiro.touched)" class="input-error-msg
                            text-danger">
                            <div *ngIf="telefone_financeiro.errors.required">
                                Número telefone é obrigatório.
                            </div>
                            <div *ngIf="telefone_financeiro.errors.minlength">
                                Número telefone é obrigatório.
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="form-group row">
                <div class="col-sm-2"></div>
                <div class="col-sm-4">
                    <button class="btn btn-link btn-link-color" style="padding: 0px;" (click)="AdicionarContatoFinanceiro()">+ Adicionar Outro Contato Financeiro</button>
                </div>
            </div>

            <br /><br />
            <div class="form-group row">
                <div class="text-right col-sm-12">
                    <button type="button" class="btn btn-default" (click)="reload()">Cancelar</button>
                    <button type="button" class="btn btn-primary" style="margin-left: 10px;" *ngIf="userLoggedIn.Editar" [disabled]="EmpresaForm.invalid" (click)="Insert()">Salvar</button>
                </div>
            </div>

        </div>


    </form>

</div>

<!-- <app-image-modal (resposta_produto_selecionado)="ReciverPesquisaProduto($event)"></app-image-modal> -->
<app-data-modal [_nome_arquivo]="_nome_arquivo" [_src_arquivo]="_src_arquivo"></app-data-modal>