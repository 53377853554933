<div class="col-lg-12 row">

    <div class="col-lg-7 wrapper-top-content padding-right-none">
        <div class="ibox-title">
            <h5>Insumos Solicitados</h5>
            <div class="ibox-tools">
                <div>
                    <label class="margin-right-20">
                        {{_count_grid}}
                        <b *ngIf="_total_pesquisa >= 0">de</b>
                        {{_total_pesquisa}}
                    </label>
                    <label>
                        <div class="btn-group mr-2 pagination-position-grid"
                            role="group"
                            aria-label="First group">
                            <button type="button" *ngFor="let page of
                                _paginas"
                                class="btn btn-secondary {{page.active
                                ?'active':''}}"
                                (click)="GetPage(page.page)">{{page.page}}</button>
                        </div>
                    </label>
                </div>
            </div>
        </div>
        <div class="ibox-content   ">

            <div class="row bottom-10">

                <div class="col-sm-6 m-b-xs">
                    <div class="input-group">
                        <input placeholder="Pesquisar" [(ngModel)]="_buscar" (ngModelChange)="OnChangeSearch()" type="text" class="form-control form-control-sm">
                        <span class="input-group-append">
                            <button type="button" class="btn btn-sm
                                btn-primary">Buscar</button>
                        </span>
                    </div>
                </div>

                <div class="text-right text-right col-sm-6 m-b-xs">
                    <div class="btn-group btn-group-toggle">
                        <label class="btn btn btn-white {{_filter == 0 ?
                            'active':''}}" (click)="GetAllSolicitacao(0)">
                            <input type="radio" name="options"
                                autocomplete="off" checked="true"> Abertos
                        </label>
                        <label class="btn btn-white {{_filter> 0 && _filter < 4 ?
                                'active':''}}" (click)="GetAllSolicitacao(1)">
                                <input type="radio" name="options"
                                    autocomplete="off"> Respondidos
                            </label>
                        <label class="btn btn-white {{_filter == 4 ?
                                'active':''}}" (click)="GetAllSolicitacao(4)">
                                <input type="radio" name="options"
                                    autocomplete="off"> Finalizados
                            </label>
                    </div>
                </div>


            </div>

            <!-- Lista de solicitações -->
            <div class="table-responsive">
                <table class="table table-striped table-hover
                        table-bordered">
                    <thead class="thead-dark">
                        <tr>
                            <th>Especificação</th>
                            <th class="text-center">Marca</th>
                            <th class="text-center">Data</th>
                            <th class="text-center">Estado</th>
                            <th class="text-center" *ngIf="userLoggedIn.Excluir"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let solicitacao of _solicitacoes |
                                filter:_buscar" (click)="VerSolicitacao(solicitacao)" class="{{solicitacao.id ==
                                _solicitacao_selecionada.id ? 'table-info' :
                                ''}}">
                            <td>{{solicitacao.especificacao}}</td>
                            <td class="text-center">{{solicitacao.marca}}</td>
                            <td class="text-center">{{solicitacao._data_formatada}}</td>
                            <td class="text-center" [attr.colspan]="solicitacao.status != 3 &&
                                    solicitacao.status != 0 ? 2 :
                                    1">{{solicitacao._status_descricao}}</td>
                            <td class="text-center" *ngIf="(solicitacao.status ==
                                    0 || solicitacao.status == 3) && userLoggedIn.Excluir">
                                <a href (click)="ExcluirSolicitacao($event,
                                        solicitacao)" *ngIf="userLoggedIn.Excluir">excluir</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <!-- Cadastro Solicitação Insumo -->
    <div class="col-lg-5 wrapper-top-content grid-right-detalhes animated
            fadeIn
            margin-bottom-40" [hidden]="_hidden">

        <div>

            <object *ngIf="(_solicitacao.status == 0 || _solicitacao.status == 3 || _solicitacao.status == undefined) && (_solicitacao.id> 0 && userLoggedIn.Editar) ||  (!_solicitacao.id && userLoggedIn.Inserir)">
                    <div class="ibox-title">
                        <h5>{{_solicitacao.id > 0 ? 'Atualizar Solicitação Insumo' : 'Nova Solicitação Insumo'}}</h5>
                    </div>
                    <div class="ibox-content">
                        <div class="form-group row">
                            <label class="col-sm-3"><b>Especificação:</b></label>
                            <div class="col-sm-8">
                                <textarea placeholder="Especificação Completa"
                                    [(ngModel)]="_solicitacao.especificacao"
                                    class="form-control form-control-sm"></textarea>
                                <small>Especifique com o máximo de clareza.</small>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-3"><b>Marca:</b></label>
                            <div class="col-sm-6">
                                <input placeholder="Marca"
                                    [(ngModel)]="_solicitacao.marca" type="text"
                                    class="form-control form-control-sm">
                            </div>
                        </div>

                        <div class="margin-top-20">
                            <button *ngIf="_solicitacao.id> 0" type="button"
                                class="btn
                                btn-sm btn-default margin-right-10 animated
                                fadeIn"
                                ng
                                (click)="Clear()">Cancelar</button>
                            <button type="button" *ngIf="(_solicitacao.id > 0 && userLoggedIn.Editar) ||  (!_solicitacao.id && userLoggedIn.Inserir)" class="btn btn-sm btn-primary"
                                (click)="SaveSolicitacao(0)">{{_solicitacao.id >
                                0
                                ?'Atualizar':'Salvar'}}</button>
                        </div>

                    </div>
                </object>


            <!-- Descrição da solicitação e cadastro de vinculo-->
            <object [hidden]="!(_solicitacao_selecionada.id> 0)">
                    <div class="ibox-title {{_solicitacao.status == 0 ||
                        _solicitacao.status == 3 ? 'grid-2' : ''}}   ">
                        <h5>Descrição Solicitação</h5>
                    </div>

                    <div class="ibox-content   ">
                        <div class="form-group row">
                            <label class="col-sm-3"><b>Especificação:</b></label>
                            <div class="col-sm-8">{{_solicitacao_selecionada.especificacao}}</div>
                            <label class="col-sm-3"><b>Marca:</b></label>
                            <div class="col-sm-8">{{_solicitacao_selecionada.marca}}</div>
                            <label class="col-sm-3"><b>Status:</b></label>
                            <div class="col-sm-8
                                {{_solicitacao_selecionada.status
                                == 3 ? 'spotlight-text-red':''}}">{{_solicitacao_selecionada._status_descricao}}</div>
                            <label class="col-sm-3"
                                *ngIf="_solicitacao_selecionada.status == 3"><b>Motivo:</b></label>
                            <div class="col-sm-8"
                                *ngIf="_solicitacao_selecionada.status == 3"><span
                                    class="spotlight-text-red">{{_solicitacao_selecionada.detalhe}}</span></div>
                            <label class="col-sm-3"><b>Data:</b></label>
                            <div class="col-sm-8">{{_solicitacao_selecionada._data_formatada}}</div>
                        </div>
                    </div>
                    <div class="ibox-content   "
                        *ngIf="_produto.id>
                        0">
                        <div class="form-group row">

                            <label class="col-sm-3"><b>Insumo Vinculado:</b></label>
                            <div class="col-sm-8">{{_produto.nome}}</div>

                            <label class="col-sm-3 margin-top-10"><b>Categoria:</b></label>
                            <div class="col-sm-8 margin-top-10">{{_produto._categoria}}</div>
                            <div class="margin-top-20 col-sm-12"
                                *ngIf="(_solicitacao_selecionada.status == 1 ||
                                _solicitacao_selecionada.status == 2) && userLoggedIn.Editar">

                                <label class="margin-right-10">O produto <b>{{_solicitacao_selecionada._status_descricao}}</b>,
                                    atende sua solicitação ? </label>

                                <button type="button" class="btn btn-sm
                                    btn-primary
                                    margin-right-10"
                                    (click)="SaveSolicitacao(4)" *ngIf="userLoggedIn.Editar">Sim</button>
                                <button *ngIf="_solicitacao.id > 0 && userLoggedIn.Editar" type="button"
                                    class="btn
                                    btn-sm btn-default   " ng
                                    (click)="SaveSolicitacaoNaoAtende()">Não</button>
                            </div>
                            <div class="col-lg-12 form-group row" *ngIf="_solicitacao_selecionada.status == 4 && userLoggedIn.Editar">
                                <label class="col-sm-3 margin-top-10"><b>Codigo:</b></label>
                                <div class="col-sm-8 margin-top-10">
                                    <label class="col-sm-6 row">
                                        <input class="form-control input-code
                                            text-center"
                                            type="text"
                                            (ngModelChange)="OnChangeAll(_produto.ProdutoFornecedor)"
                                            [(ngModel)]="_produto.ProdutoFornecedor.codigo_opcional"
                                            placeholder="000">
                                    </label>
                                </div>

                                <label class="col-sm-3"><b>Unidade:</b></label>
                                <div class="col-sm-8">

                                    <label class="col-sm-6 row">
                                        <select class="form-control-sm
                                            form-control
                                            input-s-sm inline select-unity"
                                            (ngModelChange)="OnChangeAll(_produto.ProdutoFornecedor)"
                                            [(ngModel)]="_produto.ProdutoFornecedor.unidade_id">
                                            <option value="undefined">Unidade</option>
                                            <option *ngFor="let unidade of
                                                _unidade_medida"
                                                value="{{unidade.id}}">{{unidade.nome}}</option>
                                        </select>
                                    </label>
                                </div>

                                <label class="col-sm-3"><b>Preço:</b></label>
                                <div class="col-sm-8">
                                    <label class="col-sm-6 row">
                                        <input class="form-control input-price"
                                            currencyMask [options]="{ prefix:
                                            'R$ ',
                                            thousands: '.', decimal: ',' }"
                                            (ngModelChange)="OnChangeAll(_produto.ProdutoFornecedor)"
                                            [(ngModel)]="_produto.ProdutoFornecedor.preco"
                                            type="text" placeholder="0,00">
                                    </label>
                                </div>

                                <label class="col-sm-3"><b>Estoque:</b></label>
                                <div class="col-sm-8">
                                    <label class="col-sm-6 row">
                                        <input class="form-control input-stock
                                            text-center"
                                            type="number"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Campo estoque deve ter apenas
                                            números
                                            e virugula." type="number"
                                            pattern="[0-9]+$"
                                            (ngModelChange)="OnChangeAll(_produto.ProdutoFornecedor)"
                                            [(ngModel)]="_produto.ProdutoFornecedor.estoque"
                                            placeholder="0">
                                    </label>
                                </div>

                                <label class="col-sm-3"><b>CrossDocking:</b></label>
                                <div class="col-sm-8">
                                    <label class="col-sm-6 row">
                                        <input class="form-control input-stock
                                            text-center"
                                            type="number"
                                            data-toggle="tooltip"
                                            data-placement="left"
                                            title="Tempo de entrega do produto pelo
                                            fornecedor. (dias)" type="number"
                                            pattern="[0-9]+$"
                                            (ngModelChange)="OnChangeAll(_produto.ProdutoFornecedor)"
                                            [(ngModel)]="_produto.ProdutoFornecedor.cross_docking"
                                            placeholder="0">
                                    </label>
                                </div>

                                <div class="col-sm-12 margin-top-20">
                                    <button class="btn btn-sm-grid btn-primary
                                        {{
                                        _produto.ProdutoFornecedor._status ? '':
                                        'disabled' }}"
                                        (click)="SaveProductProvider(_produto)">Salvar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="ibox-title   "
                        *ngIf="_solicitacao_selecionada.log &&
                        _solicitacao_selecionada.log != ''">
                        Histórico
                    </div>
                    <div class="ibox-content    text-11"
                        *ngFor="let
                        log of _solicitacao_selecionada._log">

                        <div class="form-group row">
                            <div class="col-sm-3" [hidden]="log.especificacao ==
                                null"><b>Especificação:</b></div>
                            <div class="col-sm-8" [hidden]="log.especificacao ==
                                null">{{log.especificacao}}</div>
                            <div class="col-sm-3" [hidden]="log.marca == null"><b>Marca:</b></div>
                            <div class="col-sm-8" [hidden]="log.marca == null">{{log.marca}}</div>
                            <div class="col-sm-3"><b>Data:</b></div>
                            <div class="col-sm-8">{{log.data}}</div>
                            <div class="col-sm-3"><b>Status:</b></div>
                            <div class="col-sm-8">{{_status_solicitacao_enum[log.status]}}</div>
                            <div class="col-sm-3" [hidden]="(log.produto.nome ==
                                null)
                                && (log.produto.nome != '')"><b>Produto:</b></div>
                            <div class="col-sm-8" [hidden]="(log.produto.nome ==
                                null)
                                && (log.produto.nome != '')">{{log.produto.nome}}</div>
                            <div class="col-sm-3" [hidden]="log.status != 3"><b>Motivo:</b></div>
                            <div class="col-sm-8" [hidden]="log.status != 3">{{log.detalhe}}</div>
                        </div>

                    </div>

                </object>
        </div>

    </div>

</div>