<div class="col-lg-12 wrapper-top-content">
    <div class="ibox">
        <div class="ibox-title" style="padding-right:20px;">
            <h5>Resumo Cotação: {{_cotacao.codigo}}</h5>
            <div class="text-primary" style="float: right;"><b class="text-dark">Status: </b>&nbsp;&nbsp;{{_cotacao._status}} </div>
        </div>
        <div class="ibox-content">

            <app-cabecalho-cotacao></app-cabecalho-cotacao>

            <div class="table-responsive min-height-grid-insumo
                div-table-fixed margin-top-15" *ngIf="_carregamento_concluido">
                <!-- table-striped table-hover table-bordered -->
                <table class="table table-striped table-hover table-bordered" style="border:
                    1px solid #EBEBEB;">
                    <thead class="thead-fixed">
                        <tr>
                            <th class="th-sequece-produto">Item</th>
                            <th class="th-nome-produto">Nome do Insumo</th>
                            <th class="text-center">Marca</th>
                            <th class="text-center th-select-unity column-unidade">Unidade</th>
                            <th class="text-center th-input-stock" data-toggle="tooltip" data-placement="top" title="Campo
                                estoque deve ter apenas números e virugula.">Quantidade</th>
                            <th class="text-center th-select-unity" *ngIf="_ha_anexo">Anexo</th>
                        </tr>
                    </thead>
                    <tbody class="" *ngFor="let produto of
                        _list_produtos">

                        <tr class="{{!produto._index_impar_par ?
                            'tr-background-impar': 'tr-background-par'}}">
                            <td class="text-center th-sequence-produto">{{produto._index}}</td>
                            <td class="td-nome-produto">{{produto.nome}}</td>
                            <td class="text-center th-stock">
                                <a class="btn btn-link btn-sm" (click)="showModalMarcas(produto)">{{produto._marcas.length}}&nbsp;marcas</a></td>
                            <td class="text-center th-unity">{{produto._unidade_nome}}</td>
                            <td class="text-center th-stock">{{produto._quantidade}}</td>
                            <td class="text-center th-anexo" *ngIf="_ha_anexo">
                                <i *ngIf="produto.anexo && produto.anexo
                                    !='null'" class="fa fa-file-alt btn-link
                                    cursor-pointer" (click)="MostrarFile(produto)"></i>
                            </td>
                        </tr>
                        <tr *ngIf="(produto._qt_entrega_parcial> 0)">
                            <td class="text-center" colspan="5">

                                <table class="table table-entrega-parcial">
                                    <tbody>
                                        <tr class="{{!produto._index_impar_par
                                            ? 'tr-background-impar':
                                            'tr-background-par'}}" *ngFor="let
                                            entrega of
                                            produto.EntregasParcial">
                                            <td class="text-center
                                                th-sequence-son">{{produto._index}}.{{entrega._index}}</td>
                                            <td class="text-center" [attr.rowspan]="produto.EntregasParcial.length" *ngIf="entrega._index <2">Cronograma de Recebimento</td>

                                            <td class="text-center">{{entrega._data_entrega}}</td>
                                            <td class="text-center
                                                th-unity">{{produto._unidade_nome}}</td>
                                            <td class="text-center
                                                th-stock" style="border-right:0px;">{{entrega.quantidade}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <div class="form-group row">
                <div class="col-sm-4">
                    <table class="table table-striped table-hover
                        table-bordered">
                        <thead class="thead-fixed">

                        </thead>
                    </table>
                </div>
                <div class="col-sm-4">

                </div>
                <div class="col-sm-4 row">
                </div>
            </div>

            <div class="form-group row">
                <div class="col-sm-6">
                    <h5 *ngIf="(_cotacao._expirado) || (!_boolQuantidade || !_boolUnidade)">AVISO:</h5>
                    <b class="text-danger" *ngIf="(_cotacao._expirado)">A data de vencimento não pode ser menor que a data atual. <br /></b>
                    <b class="text-danger" *ngIf="(!_boolQuantidade || !_boolUnidade)">Para todos os itens deve ser informado a <span class="sublinhar">Unidade</span> e <span class="sublinhar">Quantidade</span>.<br /></b>
                </div>
                <div class="col-sm-6 text-right">
                    <a href="cotacao/historico" class="btn btn-default" *ngIf="(_cotacao.status != 0)"><i class="fa fa-arrow-left"></i>&nbsp;&nbsp;Voltar</a>
                    <button type="submit" class="btn btn-default" (click)="ReloadProduto()" *ngIf="(_cotacao.status == 0)"><i class="fa fa-arrow-left"></i>&nbsp;&nbsp;Voltar</button>
                    <button type="submit" class="btn btn-default margin-left-10" *ngIf="(_cotacao.status == 0)" (click)="Reload()">Alterar</button>
                    <button type="submit" class="btn btn-primary
                        margin-left-10" *ngIf="(_cotacao.status == 0 && (_boolQuantidade && _boolUnidade) && !_cotacao._expirado)" style="background: #08c65b; border-color: #08c65b;" (click)="ConfirmeSolicitacaoOrcamento(_cotacao)"><i
                            class="fa fa-check"></i>&nbsp;&nbsp;Solicitar Orçamento</button>
                </div>
            </div>

        </div>
    </div>
</div>

<app-data-modal [_nome_arquivo]="_nome_arquivo" [_src_arquivo]="_src_arquivo"></app-data-modal>
<app-message-modal [_titulo]="_msg_titulo" [_descricao]="_msg_descricao"></app-message-modal>
<!-- Modal -->
<div class="modal fade" id="marcas-modal" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title"><b>Marca(s) do produto: </b>{{_marca_descricao}}</div>

                <div class="ibox-tools">
                    <div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span> 
                        </button>
                    </div>
                </div>

            </div>

            <div class="modal-body modal-confirm" style="padding: 1rem;">
                <div class="" *ngFor="let marca of _tempProduto._marcas">{{marca._descricao}}</div>
            </div>
        </div>
    </div>
</div>