import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExtratoFornecedor, EnumStatusFaturamento } from 'src/app/domain/gerenciamento/extrato-fornecedor';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { GerenciamentoFornecedorService } from 'src/app/service/gerenciamento/gerenciamento-fornecedor.sevice';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { CompraVendaService } from 'src/app/service/transacao/compra-venda.service';
import { ContatoFinanceiro } from 'src/app/domain/empresa/contato-financeiro';

@Component({
  selector: 'app-view-extrato-financeiro',
  templateUrl: './view-extrato-financeiro.component.html',
  styleUrls: ['./view-extrato-financeiro.component.css']
})
export class ViewExtratoFinanceiroComponent implements OnInit {



  public code: string;
  public _transacoes: Array<ExtratoFornecedor>;
  public _contatosFinanceiros: ContatoFinanceiro[];

  constructor(
    private route: ActivatedRoute,
    private compraVendaService: CompraVendaService,
    private confirmService: AlertConfirmService,
    private userLoggedService: UserLoggedInService,
    private gerenciamentoFornecedorService: GerenciamentoFornecedorService,
    private router: Router) {
    this.userLoggedService.SetCurrentUser();
    this._transacoes = new Array<ExtratoFornecedor>();
    this._contatosFinanceiros = new Array<ContatoFinanceiro>();
  }

  _inicio = ''
  _fim = ''
  _status = EnumStatusFaturamento.Aberto;
  _cnpj = this.route.snapshot.params['cnpj'];
  ngOnInit() {
    this.userLoggedService.SetCurrentUser();
    var date = new Date();
    this._fim = UseFullService.formatDateUsForm(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    this._inicio = UseFullService.formatDateUsForm(new Date(date.getFullYear(), date.getMonth(), 1));
    this.GetAll()
    this.GetAllCotnatos()
  }

  OnChangeSearch() {
    this.GetAll()
  }

  GetAll() {
    this.gerenciamentoFornecedorService.GetCNPJ(this._cnpj, this._status, this._inicio, this._fim).subscribe(
      d => {
        this._transacoes = d;
      },
      e => { },
      () => {
        var x = 1;
        this._transacoes.forEach(c => {
          c._index = x++;
          c._data = UseFullService.formatDate(new Date(c.data))
          c.sub_total = UseFullService.ToFixedString(c.sub_total)
          c.total_painel = UseFullService.ToFixedString(c.total_painel)
          c.valor_total = UseFullService.ToFixedString(c.valor_total)
        });
      }
    );
  }

  GetAllCotnatos() {
    this.gerenciamentoFornecedorService.GetContatosFinanceiro(this._cnpj).subscribe(
      d => {
        this._contatosFinanceiros = d;
      },
      e => { },
      () => { }
    );
  }



  RetornarParaLista() {
    this.router.navigateByUrl("/gerenciamento/extrato/fornecedores");
  }

}
