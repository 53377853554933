<div class="col-lg-12 wrapper-top-content">
    <div class="ibox">
        <div class="ibox-title" style="padding-right:20px;">
            <h5>Resumo Cotação: {{_cotacao.codigo}}</h5>
            <div class="text-primary" style="float: right;"><b class="text-dark">Status: </b>&nbsp;&nbsp;{{_cotacao._status}} </div>
        </div>
        <div class="ibox-content">


            <div class="row">

                <div class="col-logo">

                    <div class="Right">
                        <div class="text-left div-img-logo">
                            <img src="{{url}}{{userLoggedService.img}}" class="config-img" *ngIf="userLoggedService.img">

                            <div onclick="ConfiguracoesEmpresa()" class="sua-logo {{(!userLoggedService.img &&
                                userLoggedService.img == '') ?
                                'sua-logo-0':''}}" *ngIf="(!userLoggedService.img ||
                                userLoggedService.img=='')">
                                <label>sua logo</label>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="col-sm-12 text-right
                    cabecalho-orcamento">

                    <table class="table table-cabecalho">
                        <tbody>
                            <tr>
                                <td class="text-left th-width"><b>Empresa/Nome:</b>&nbsp;&nbsp;{{cabecalho._nome_empresa}}</td>
                                <td class="text-left th-width"><b>Transporte:</b>&nbsp;&nbsp;{{_cotacao.tipo_frete == 1 ? 'CIF - Entrega por conta do fornecedor' : 'FOB - Retirada por conta do cliente'}}
                                </td>
                                <!--<td class="text-left th-width "><b>Preço por KM:</b>&nbsp;&nbsp;{{_cotacao.preco_por_km}}</td>-->
                            </tr>
                            <tr>
                                <td class="text-left th-width"><b>CNPJ/CPF:</b>&nbsp;&nbsp;{{cabecalho._cpf_cnpj}}
                                </td>
                                <td class="text-left th-width"><b>Periodo de Recebimento:</b>&nbsp;&nbsp;{{_cotacao.recebimento_produto == 1 ? 'Total' : 'Parcial' }}</td>
                            </tr>
                            <tr>
                                <td class="text-left th-width"><b>Nome da Obra:</b>&nbsp;&nbsp;{{_cotacao.nome_obra}}</td>
                                <td class="text-left th-width">

                                    <span class="margin-right-20"><b>Data:</b>&nbsp;&nbsp;{{_cotacao._data_criacao}}</span>
                                    <span class="margin-right-20"><b>Vencimento:</b>&nbsp;&nbsp;{{_cotacao._data_max_recebimento_orcamento}}</span>
                                    <span *ngIf="(_cotacao.recebimento_produto != 2)"><b>Entrega:</b>&nbsp;&nbsp;{{_cotacao._data_recebimento_produto}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left th-width" *ngIf="(_cotacao.tipo_frete == 1)">
                                    <div class="label-endereco">
                                        <b>Endereço de entrega:</b>
                                    </div>
                                    <div class="label-detalhe-endereco">
                                        <label>
                                            {{
                                            _endereco.endereco}},&nbsp;n°:&nbsp;{{
                                            _endereco.numero
                                            }}&nbsp;{{_endereco.complemento}}
                                            &nbsp;Bairro:&nbsp;{{
                                            _endereco.bairro}},&nbsp;{{
                                            _endereco.cidade_nome}} -
                                            {{_endereco.uf}}
                                            &nbsp;CEP:&nbsp;{{_endereco.cep}}
                                        </label>
                                    </div>
                                </td>
                                <td class="text-left th-width"><b>Forma de
                                        pagamento:</b>&nbsp;&nbsp;{{_cotacao.forma_pagamento == 1 ? 'À vista' : 'Prazo'}} - {{_cotacao._meio_pagamento}}
                                </td>
                                <td *ngIf="(_cotacao.tipo_frete != 1)">
                                </td>
                            </tr>
                            <!--
                            <tr>
                                <th class="text-left th-width ">Validade da cotação</th>
                                <td class="text-left ">{{_cotacao.data_max_recebimento_orcamento}}</td>
                            </tr>
                            -->
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="table-responsive min-height-grid-insumo
                div-table-fixed margin-top-15" *ngIf="_carregamento_concluido">
                <!-- table-striped table-hover table-bordered -->
                <table class="table table-striped table-hover table-bordered" style="border:
                    1px solid #EBEBEB;">
                    <thead class="thead-fixed">
                        <tr>
                            <th class="th-sequece-produto">Item</th>
                            <th class="th-nome-produto">Nome do Insumo</th>
                            <th class="text-center th-select-unity" style="width: 250px;">Categoria</th>
                            <th class="text-center th-select-unity column-unidade">Unidade</th>
                            <th class="text-center th-input-stock" data-toggle="tooltip" data-placement="top" title="Campo
                                estoque deve ter apenas números e virugula.">Quantidade</th>
                            <th class="text-center th-select-unity" *ngIf="_ha_anexo">Anexo</th>
                        </tr>
                    </thead>
                    <tbody class="" *ngFor="let produto of
                        _list_produtos">

                        <tr class="{{!produto._index_impar_par ?
                            'tr-background-impar': 'tr-background-par'}}">
                            <td class="text-center th-sequence-produto">{{produto._index}}</td>
                            <td class="td-nome-produto">{{produto.nome}}</td>
                            <td class="text-center" style="max-width: 250px;">{{produto._categoria}}</td>
                            <td class="text-center th-unity">{{produto._unidade_nome}}</td>
                            <td class="text-center th-stock">{{produto._quantidade}}</td>
                            <td class="text-center th-anexo" *ngIf="_ha_anexo">
                                <i *ngIf="produto.anexo && produto.anexo
                                    !='null'" class="fa fa-file-alt btn-link
                                    cursor-pointer" (click)="MostrarFile(produto)"></i>
                            </td>
                        </tr>
                        <tr *ngIf="(produto._qt_entrega_parcial> 0)">
                            <td class="text-center" colspan="6">

                                <table class="table table-entrega-parcial">
                                    <tbody>
                                        <tr class="{{!produto._index_impar_par
                                            ? 'tr-background-impar':
                                            'tr-background-par'}}" *ngFor="let
                                            entrega of
                                            produto.EntregasParcial">
                                            <td class="text-center
                                                th-sequence-son">{{produto._index}}.{{entrega._index}}</td>
                                            <td class="text-center" [attr.rowspan]="produto.EntregasParcial.length" *ngIf="entrega._index < 2">Cronograma de Recebimento</td>

                                            <td class="text-center">{{entrega._data_entrega}}</td>
                                            <td class="text-center
                                                th-unity">{{produto._unidade_nome}}</td>
                                            <td class="text-center
                                                th-stock">{{entrega.quantidade}}</td>
                                            <td class="text-center th-anexo
                                                th-border-right"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <div class="form-group row">
                <div class="col-sm-4">
                    <table class="table table-striped table-hover
                        table-bordered">
                        <thead class="thead-fixed">

                        </thead>
                    </table>
                </div>
                <div class="col-sm-4">

                </div>
                <div class="col-sm-4 row">
                </div>
            </div>

            <div class="form-group row">
                <div class="col-sm-6">
                    <p><b>USUARIO COTAÇÃO</b></p>
                    <b>Nome:</b> &nbsp;&nbsp;{{usuario_cotacao.nome}}<br />
                    <b>Email:</b> &nbsp;&nbsp;{{usuario_cotacao.email}}<br />
                    <b>Telefone:</b> &nbsp;&nbsp;{{usuario_cotacao.telefone}}<br /><br />
                    <div *ngIf="_emails.length > 0">
                        <strong>Enviado Para:</strong><br />
                        <div *ngFor="let email of _emails">
                            {{email}}
                        </div>
                    </div>
                    <h5 *ngIf="(_cotacao._expirado) || (!_boolQuantidade || !_boolUnidade)">AVISO:</h5>
                    <b class="text-danger" *ngIf="(_cotacao._expirado)">A data de vencimento não pode ser menor que a data atual. <br /></b>
                    <b class="text-danger" *ngIf="(!_boolQuantidade || !_boolUnidade)">Para todos os itens deve ser informado a <span class="sublinhar">Unidade</span> e <span class="sublinhar">Quantidade</span>.<br /></b>
                </div>
                <div class="col-sm-6 text-right">
                    <button type="submit" class="btn btn-default" (click)="RetornarParaLista()"><i class="fa fa-arrow-left"></i>&nbsp;&nbsp;Voltar</button>
                    <button type="submit" class="btn btn-default
                    margin-left-10" (click)="ConfirmeCancelamentoCotacao()" *ngIf="_cotacao.status == 1"><i class="fa fa-times"></i>&nbsp;&nbsp;Cancelar</button>
                    <button type="submit" class="btn btn-primary
                        margin-left-10" *ngIf="((_boolQuantidade && _boolUnidade) && !_cotacao._expirado && _cotacao.status == 1)" style="background: #08c65b; border-color: #08c65b;" (click)="ConfirmeSolicitacaoOrcamento(_cotacao)"><i
                            class="fa fa-voicemail"></i>&nbsp;&nbsp;Enviar Por Email</button>
                </div>
            </div>

        </div>
    </div>
</div>

<app-solicitacao-sistema></app-solicitacao-sistema>

<app-data-modal [_nome_arquivo]="_nome_arquivo" [_src_arquivo]="_src_arquivo"></app-data-modal>
<app-message-modal [_titulo]="_msg_titulo" [_descricao]="_msg_descricao"></app-message-modal>
<!--#08c65b-->