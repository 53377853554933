<div class="col-lg-12 wrapper-top-content">
    <div class="ibox">
        <div class="ibox-title" style="padding-right:20px;">
            <h5>Orçamentos Recebidos</h5>
            <div style="float: right;"></div>
        </div>

        <div class="ibox-content">
            <div class="row">
                <div class="col-lg-{{(_cotacao.tipo_frete == 3)?'6':'12'}}" style="margin-left: -15px;">
                    <div class="card text-white bg-primary mb-2" style="max-width:
                        18rem; min-width: 18rem; margin-left: 15px; float:
                        left;">
                        <div class="card-header">
                            <b>Orçamento Negociado {{(_cotacao.tipo_frete == 3)?'CIF':''}}</b>
                        </div>
                        <div class="card-body cursor-pointer card-body-hover
                            animated" (click)="VerSugerido(_orcamento_sugerido, 'cif')">
                            <div class="text-center" style="height: 76px;" *ngIf="!(_orcamento_sugerido.id> 0)">
                                <label style="padding-top: 27px;">
                                    <span *ngIf="(!_cotacao._expirado)">
                                        <i class="fa fa-clock"></i>&nbsp;&nbsp;
                                        Em Andamento
                                    </span>
                                </label>
                            </div>
                            <p class="card-text" *ngIf="(_orcamento_sugerido.id>
                                0)">
                                <b>Subtotal: &nbsp;&nbsp; </b>R$ {{_orcamento_sugerido.sub_total}} <br />
                                <b>Valor Total: &nbsp;&nbsp;</b> R$ {{_orcamento_sugerido.valor_total}} <br />
                                <b>Status: &nbsp;&nbsp;</b> {{(_orcamento_sugerido.status == 1)? (_cotacao._expirado)?'Expirado':'Aguardando Aprovação':'Orçamento Aprovado'}}
                                <br />
                                <br *ngIf="(_orcamento_sugerido.status <2)" />
                                <b *ngIf="(_orcamento_sugerido.status == 2)">Venda:
                                    &nbsp;&nbsp;</b>
                                <span *ngIf="(_orcamento_sugerido.status == 2)">
                                    {{(_orcamento_sugerido._compra_status ==
                                    0)?'Aguardando
                                    Fornecedor':(_orcamento_sugerido._compra_status
                                    == 2)
                                    ?'Recusada':'Aprovada'}}
                                </span>
                            </p>
                        </div>
                    </div>

                    <div style="margin-left: 20px; float: left; max-width:
                        calc(100% - 325px);">
                        <h4>Dados da Cotação</h4>
                        <b>Codigo:&nbsp;&nbsp;</b>{{_cotacao.codigo}} <br />
                        <b>Nome:&nbsp;&nbsp;&nbsp;</b>{{_cotacao.nome}} <br />
                        <b>Obra:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>{{_cotacao.nome_obra}}<br />

                        <span class="margin-right-20"><b>Data:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{_cotacao._data_criacao}}</span><br />
                        <span class="margin-right-20"><b>Vencimento:</b>&nbsp;&nbsp;{{_cotacao._data_max_recebimento_orcamento}}</span><br />
                        <span *ngIf="(_cotacao.recebimento_produto != 2)"><b>Entrega:</b>&nbsp;&nbsp;{{_cotacao._data_recebimento_produto}}</span>
                    </div>
                </div>

                <div class="col-lg-6" *ngIf="(_cotacao.tipo_frete == 3 && !_orcamento_sugerido.fob)">
                    <div class="card text-white bg-primary mb-2" style="max-width: 18rem; min-width: 18rem; margin-left: 15px; float: left;">
                        <div class="card-header"><b>Orçamento Negociado FOB</b>
                        </div>
                        <div class="card-body cursor-pointer card-body-hover
                            animated" (click)="VerSugerido(_orcamento_sugerido, 'fob')">
                            <div class="text-center" style="height: 76px;" *ngIf="!(_orcamento_sugerido.id> 0)">
                                <label style="padding-top: 27px;">
                                    <span *ngIf="(!_cotacao._expirado)">
                                        <i class="fa fa-clock"></i>&nbsp;&nbsp;
                                        Em Andamento
                                    </span>
                                </label>
                            </div>
                            <p class="card-text" *ngIf="(_orcamento_sugerido.id>
                                0)">
                                <b>Subtotal: &nbsp;&nbsp; </b>R$ {{_orcamento_sugerido.sub_total}} <br />
                                <b>Valor Total: &nbsp;&nbsp;</b> R$ {{_orcamento_sugerido.valor_total_fob}} <br />
                                <b>Status: &nbsp;&nbsp;</b> {{(_orcamento_sugerido.status == 1)? (_cotacao._expirado)?'Expirado':'Aguardando Aprovação':'Orçamento Aprovado'}}
                                <br />
                                <br *ngIf="(_orcamento_sugerido.status <2)" />
                                <b *ngIf="(_orcamento_sugerido.status == 2)">Venda:
                                    &nbsp;&nbsp;</b>
                                <span *ngIf="(_orcamento_sugerido.status == 2)">
                                    {{(_orcamento_sugerido._compra_status == 0)?'Aguardando
                                    Fornecedor':(_orcamento_sugerido._compra_status == 2)?'Recusada':'Aprovada'}}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <hr style="margin-top:10px; margin-bottom: 0px;" />
            <div class="row">

                <!--
                <div class="col-sm-12" *ngIf="listGrid.length > 0">
                    <b>Melhor Preço Por Insumo</b>
                    <table class="table table-striped">
                        <thead class="thead-dark">
                            <tr>
                                <th>Orçamento</th>
                                <th>Insumo</th>
                                <th>Preço</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let x of listGrid">
                                <td>{{x._codigo}}</td>
                                <td>{{x.nome}}</td>
                                <td>{{x._preco}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                -->

                <div class="col-lg-{{((_list_orcamento_cif.length > 0) && (_list_orcamento_fob.length > 0)) ? '6' : '12'}} grid-orcamentos
                    {{(_list_orcamento_fob.length> 0)?'grid-orcamento-cif':''}}" *ngIf="(_list_orcamento_cif.length > 0)">

                    <button type="button" class="btn btn-default" (click)="MelhorOrcamento(1)" data-toggle="modal" data-target="#modal-cif-fob">Melhores Preços Por Item - CIF</button><br />
                    <h4 style="margin-top: 10px;">Orçamentos CIF:</h4>
                    <!--  {{(orcamento.status == 2 && orcamento._compra_status == 1)?'bg-green-header':''}} -->
                    <div *ngFor="let orcamento of _list_orcamento_cif" class="card text-dark bg-light mb-2 {{(orcamento._selected)?'':'opacity-5'}} grid-orcamento">
                        <div class="card-header">Orçamento n°: {{orcamento.codigo}}</div>
                        <!-- {{(orcamento.status == 2 && orcamento._compra_status == 1)?'bg-green-body':''}} -->
                        <div class="card-body cursor-pointer card-body-hover
                            animated" (click)="Ver(orcamento, 'cif')">
                            <p class="card-text">
                                <b>Subtotal: &nbsp;&nbsp; </b>R$ {{orcamento.sub_total}}
                                <br />
                                <b>Valor Total: &nbsp;&nbsp;</b> R$ {{orcamento.valor_total}}
                                <br />
                                <b>Situação: &nbsp;&nbsp;</b>
                                <span class="color-{{orcamento._status_cif}}">{{orcamento._status_cif}}</span>
                                <br />
                                <br *ngIf="(orcamento.status <2)" />
                                <b *ngIf="(orcamento.status == 2 &&
                                    orcamento._status_cif != 'Cancelado')">Venda:&nbsp;&nbsp;</b>
                                <span *ngIf="(orcamento.status == 2 && orcamento._status_cif != 'Cancelado')">
                                    <span *ngIf="(orcamento._compra_status == 0)" data-placement="top" data-toggle="tooltip"
                                        data-original-title="Aguardando liberação do fornecedor para compra aprovada."> Em análise
                                    </span> {{(orcamento._compra_status == 0)?'':(orcamento._compra_status == 2) ?'Recusada':'Aprovada'}}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-{{((_list_orcamento_cif.length > 0) && (_list_orcamento_fob.length > 0)) ? '6' : '12'}} grid-orcamentos" *ngIf="(_list_orcamento_fob.length> 0)">

                    <button type="button" class="btn btn-default" (click)="MelhorOrcamento(2)" data-toggle="modal" data-target="#modal-cif-fob">Melhores Preços Por Item - FOB</button><br />
                    <h4 style="margin-top: 10px;">Orçamentos FOB:</h4>

                    <div class="preco-km">
                        <label class="">Custo por KM:</label>
                        <input class="form-control" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" (ngModelChange)="OnChangePrecoPorKm()" [(ngModel)]="_cotacao.preco_por_km" type="text" placeholder="0,00" [disabled]="(_orcamento_aprovado.id)">
                    </div>
                    <div class="card text-dark bg-light mb-2 grid-orcamentos-fob" *ngFor="let orcamento of _list_orcamento_fob">
                        <div class="card-header">Orçamento n°: {{orcamento.codigo}}
                        </div>
                        <div class="card-body cursor-pointer card-body-hover
                            animated" (click)="Ver(orcamento, 'fob')">
                            <p class="card-text">
                                <b>Subtotal: &nbsp;&nbsp; </b>R$ {{orcamento.sub_total}}
                                <br />
                                <b>Valor Total Sem Frete: &nbsp;&nbsp;</b> R$ {{(_cotacao.tipo_frete == 3) ? orcamento.valor_total_fob : orcamento.valor_total}}
                                <br />
                                <b>Valor Médio km: &nbsp;&nbsp;</b> R$ {{orcamento.preco_frete_fob}}
                                <br />
                                <b>Valor Total: &nbsp;&nbsp;</b> R$ {{orcamento.preco_frete_total_fob}}
                                <br />
                                <b>Distância: &nbsp;&nbsp;</b> {{orcamento.distancia_km}} {{orcamento.distancia_unidade}}
                                <br />
                                <b>Tempo Percurso: &nbsp;&nbsp;</b> {{orcamento.tempo}}
                                <br />
                                <b>Situação: &nbsp;&nbsp;</b> <span class="color-{{orcamento._status_fob}}">{{orcamento._status_fob}}</span>
                                <br />
                                <br *ngIf="(orcamento.status < 2)" />
                                <b *ngIf="(orcamento.status == 2 && orcamento._status_fob != 'Cancelado')">Venda:&nbsp;&nbsp;</b>
                                <span *ngIf="(orcamento.status == 2 && orcamento._status_fob != 'Cancelado')">
                                    <span *ngIf="(orcamento._compra_status == 0)" data-placement="top" data-toggle="tooltip"
                                        data-original-title="Aguardando liberação do fornecedor para compra aprovada.">Em análise
                                    </span> {{(orcamento._compra_status == 0)?'':(orcamento._compra_status == 2) ?'Recusada':'Aprovada'}}
                                </span>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<app-orcamento-comprador-modal (gridLoad)="ReceiverReload($event)" [_list_orcamento]="_list_orcamento" [_grid_tipo_frete]="_grid_tipo_frete"></app-orcamento-comprador-modal>

<div class="modal fade" id="modal-cif-fob" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Melhor Preço Por Insumo - {{_cif_fob_modal}}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body" style="padding: 0px;">
                <table class="table table-striped">
                    <thead class="thead-dark">
                        <tr>
                            <th class="text-center">Orçamento</th>
                            <th>Insumo</th>
                            <th class="text-center">Unidade</th>
                            <th class="text-center">QTD</th>
                            <th class="text-right">Valor Unt R$</th>
                            <th class="text-right">Valor Total R$</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let x of listGrid">
                            <td class="text-center">{{x._codigo}}</td>
                            <td>{{x.nome}}</td>
                            <td class="text-center">{{x._unidade}}</td>
                            <td class="text-center">{{x.quantidade}}</td>
                            <td class="text-right">{{x.preco}}</td>
                            <td class="text-right">{{x.precoTotal}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="4"></td>
                            <td class="text-right"><b>Valor Total</b></td>
                            <td class="text-right">{{_precoTotalModal}}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>