import { ErrorHandler, Injectable } from '@angular/core'
import { AlertService } from 'src/app/service/alert.service'
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private alert: AlertService) { }
  handleError(error) {
    if(error && !error.status && typeof(error) == 'string'){
      this.alert.SetMsgError(error)
    }    
    console.log(error)
    //throw error;
  }
}