import { ProdutoCategoria } from './produto-categoria';

export class ProdutoFornecedor {
    public id: number;
    public produto_id: number;
    public fornecedor_cnpj: number;
    public codigo_opcional: string;
    public unidade_id: number;
    public preco: any;
    public estoque: number;
    public vinculado: boolean;
    public data_criacao: Date;
    public origem: number;
    public cross_docking: number;
    public marca_id: number;

    public _loading: boolean;
    public _status: boolean;
    public _marca: string;
    public _produto_obra_id: number;
}

export enum OrigemProduto {
    InsumoDoFonecedor = 1,
    Produto = 2
}

export class InsumoDoFornecedor {
    public _niveis: string;
    constructor() {
        this.ProdutoFornecedor = new ProdutoFornecedor();
    }

    public id: number;
    public nome: string;
    public categoria_id: number;
    public marca: string;
    public data_criacao: Date;
    public origem: number;
    public ha_niveis: number;
    public busca: string;
    public ProdutoFornecedor: ProdutoFornecedor;

    public static FiltrarPorNome(produtos: Array<InsumoDoFornecedor>, nome: string): InsumoDoFornecedor {
        function filtrar(obj) {
            if ("nome" in obj && obj.nome == nome)
                return true;
            else
                return false;
        }
        var produto = produtos.filter(filtrar);
        return produto[0];
    }

    public static FiltrarPorStringProdutos(produtos: Array<InsumoDoFornecedor>, nome: string): Array<InsumoDoFornecedor> {
        function filtrar(obj: InsumoDoFornecedor) {

            if (obj.nome == nome || obj.busca.toLowerCase().includes(nome))
                return true;
            else
                return false;
        }
        var produto = produtos.filter(filtrar);
        return produto;
    }

    public static FiltrarExisteNivel(produtos: Array<InsumoDoFornecedor>, nome: string): InsumoDoFornecedor {
        function filtrar(obj: InsumoDoFornecedor) {
            return (obj.ha_niveis > 0);
        }

        function filtarPossiveisNomes(obj: InsumoDoFornecedor) {
            var result = false;
            if (obj.nome.toLocaleLowerCase() == nome.toLocaleLowerCase())
                return true;
            if (obj.busca && obj.busca.length > 3) {
                var possiveisNomes = obj.busca.split(':');
                possiveisNomes.forEach(c => {
                    if (nome.toLocaleLowerCase() == c.toLocaleLowerCase())
                        result = true;
                });
            }
            return result;
        }

        var resul_produtos = produtos.filter(filtrar);
        var data = resul_produtos.filter(filtarPossiveisNomes);
        return data[0];
    }

    public static FiltrarHaNivel(produtos: Array<InsumoDoFornecedor>): InsumoDoFornecedor {
        function filtrar(obj: InsumoDoFornecedor) {
            return (obj.ha_niveis > 0);
        }

        var data = produtos.filter(filtrar);
        return data[0];
    }

    public static RemoverJaAdicionado(insumos: InsumoDoFornecedor[], novos_insumos: Array<InsumoDoFornecedor>): Array<InsumoDoFornecedor> {
        var nome: string;
        function filtrar(obj) { return ("nome" in obj && obj.nome == nome); }

        var model = new Array<InsumoDoFornecedor>();
        novos_insumos.forEach(c => {
            nome = c.nome;
            if (insumos.filter(filtrar).length < 1)
                model.push(c);
        });
        return model;
    }

    public _loading: boolean;
    public _status: boolean;

    public _selecionado: boolean;

    public _categoria: string;
    
    public _index: number;
    public _virtual_index: number;
}

export class SubCategoriaProduto {
    public id: number;
    public produto_id: number;
    public nivel_id: number;
}