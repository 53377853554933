import { Component, OnInit } from '@angular/core';
import { ProdutoService } from 'src/app/service/suprimento/produto.service';
import { ProdutoCategoria } from 'src/app/domain/suprimento/produto-categoria';
import { CategoriaVirtualService } from 'src/app/service/suprimento/categoria-virtual.service';
import { CategoriaVirtual } from 'src/app/domain/suprimento/categoria-virtual';
import { HTTPListener } from 'src/app/core/Interceptor/loader-interceptor';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';

@Component({
  selector: 'app-categoria-virtual',
  templateUrl: './categoria-virtual.component.html',
  styleUrls: ['./categoria-virtual.component.css']
})
export class CategoriaVirtualComponent implements OnInit {

  private _code = "app-categoria-virtual";
  public _categorias: ProdutoCategoria[];
  public _produto_categoria_virtual: CategoriaVirtual;
  public _categorias_virtuais: CategoriaVirtual[];
  public _text_categoria_pai: string;
  public _categoria = new CategoriaVirtual();

  constructor(private httpProduto: ProdutoService, private categoriaService: CategoriaVirtualService, private confirmService: AlertConfirmService) {
    this.confirmService.UnsetObservers();
    this.confirmService.confirmEmitter$.subscribe(c => this.RemoverAll(c));

  }

  ngOnInit() {
    this._categorias_virtuais = new Array<CategoriaVirtual>();
    this._produto_categoria_virtual = new CategoriaVirtual();
    this.SetListaCatetorias();
    this.SetListaCatetoriasVirtuais();
  }

  SelectCategoriaVirtual(categoriavirtual: CategoriaVirtual) {
    this._produto_categoria_virtual = categoriavirtual;
    this._categorias.forEach(c => {
      c._active = false;
      this._produto_categoria_virtual.categorias.forEach(e => {
        c._active = (e.id == c.id || c._active);
      });
    });
  }

  ChangeCategoria(e, categoria: ProdutoCategoria) {
    this._categoria.filho_id = categoria.id;
    this._categoria.nome = this._produto_categoria_virtual.nome;
    if (e.srcElement.checked && categoria.id > 0)
      this.AdicionarCategoriaPai(this._categoria)
    else
      this.RemoveCategoria(categoria);
  }

  RemoveCategoria(categoria: ProdutoCategoria) {
    this.categoriaService.SetDelete(this._produto_categoria_virtual.nome, categoria.id).subscribe(
      d => { },
      e => { },
      () => { this.SetListaCatetoriasVirtuais(); }
    );
  }

  AdicionarCategoriaPai(categoria: CategoriaVirtual = null) {
    if (categoria == null) {
      categoria = new CategoriaVirtual();
      categoria.nome = this._text_categoria_pai;
    }
    this.categoriaService.Create(categoria).subscribe(
      d => { },
      e => { },
      () => { this.SetListaCatetoriasVirtuais(); }
    );
  }

  SetListaCatetoriasVirtuais() {
    this.categoriaService.GetAll().subscribe(
      data => { this._categorias_virtuais = data; },
      error => { },
      () => {
        if (this._produto_categoria_virtual && this._produto_categoria_virtual.id > 0) {
          this._categorias_virtuais.forEach(e => {
            if (this._produto_categoria_virtual.id == e.id) {
              this.SelectCategoriaVirtual(e);
            }
          });
        }
      }
    );
  }

  SetListaCatetorias() {
    this.httpProduto.getAllCategories().subscribe(
      data => { this._categorias = data; },
      error => { },
      () => { }
    );
  }

  ClickRemoveAll(categoria: CategoriaVirtual) {
    this._produto_categoria_virtual = categoria;
    this.confirmService._code = this._code;
    this.confirmService._title = "Exclusão de Categoria Virtual";
    this.confirmService._message = "Deseja excluir a categoria virtual, " + this._produto_categoria_virtual.nome + "?";
    this.confirmService.Show();
  }

  RemoverAll(confirm: boolean = false) {
    if (this.confirmService._code != this._code || confirm == false)
      return;
    this.categoriaService.SetDeleteAll(this._produto_categoria_virtual.nome).subscribe(
      d => { },
      e => { },
      () => {
        this._produto_categoria_virtual = new CategoriaVirtual();
        this.SetListaCatetoriasVirtuais();
      }
    );
  }

}
