<div class="col-lg-12 wrapper-top-content">
    <div class="ibox">
        <div class="ibox-title" style="padding-right:20px;">
            <h5>Vendas {{_titulo}}</h5>
            <div style="float: right;"> </div>
        </div>
        <div class="ibox-content">
            <div class="table-responsive min-height-grid-insumo div-table-fixed margin-top-15">
                <!-- table-striped table-hover table-bordered -->
                <table class="table table-striped">
                    <thead class="thead-fixed">
                        <tr>
                            <th class="th-sequence"></th>
                            <th class="th-nome">Nome</th>
                            <th class="text-center th-codigo">Código</th>
                            <th class="text-center th-data">Data</th>
                            <th class="text-right th-data">Subtotal R$</th>
                            <th class="text-right th-data">Total R$</th>
                            <th class="text-center th-action"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="  " *ngFor="let model of _transacoes">
                            <td class="text-center th-sequence">{{model._index}}</td>
                            <td>
                                <label class="btn-link cursor-pointer" (click)="Ver(cotacao)">{{model.cotacao_nome}}</label>
                                <span class="badge badge-info margin-left-10">{{model._status_painel_nome}}</span>
                            </td>
                            <td class="text-center th-codigo">{{model.codigo}}</td>
                            <td class="text-center th-data">{{model.data_criacao}}</td>
                            <td class="text-right th-data">R$ {{model.sub_total}}</td>
                            <td class="text-right th-data">R$ {{model._valor_total_temp}}</td>
                            <td class="text-center th-action-anexo">
                                <i class="fa fa-search btn-link cursor-pointer" (click)="Ver(model)">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div *ngIf="_transacoes.length <= 0">Nenhuma ordem de venda encontrada!</div>
        </div>
    </div>
</div>