import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { EnumState } from 'src/app/domain/common/interactiveTable';

declare var $: any;

@Component({
  selector: 'app-modal-multiselect',
  templateUrl: './modal-multiselect.component.html',
  styleUrls: ['./modal-multiselect.component.css']
})

export class ModalMultiSelectComponent implements OnInit {

  @Output() _return: EventEmitter<any> = new EventEmitter();
  @Input() _input: any;
  @Input() _multiple: boolean;

  public _search: string;

  constructor() { }

  ngOnInit() {
    $('#modal-multiselect').on('show.bs.modal', function (e) {
      $(".modal-max").css("max-height", ($("body").height() - 220) + "px");
    });
  }

  SelecionarModel(model: any) {
    if (this._multiple){
      model._selected = !model._selected;      
      model._state = (model._selected) ? EnumState.none : EnumState.delete
    }else {
      this._input.forEach(c => {
        if (model.id != c.id)
          c._selected = false;
        else
          model._selected = !model._selected;
      });
    }
    
  }

  ConcluirSelecao() {
    this._return.emit(this._input);
    $("#modal-multiselect").removeClass('show');
    $("#modal-multiselect").hide();
    $("#modal-multiselect").modal('hide');
  }

  Cancelar() {
    $("#modal-multiselect").removeClass('show');
    $("#modal-multiselect").hide();
    $("#modal-multiselect").modal('hide');
    $("#modal-multiselect").removeClass('modal-z-index');
  }
}