<nav class="navbar-default navbar-static-side" style="position: relative;">

    <div class="sidebar-collapse">
        <ul class="nav metismenu" id="side-menu">
            <li class="nav-header" style="margin-top:60px"></li>
            <li *ngFor="let menu of _listMenu.list" class="text-center
                {{menu.class}} nav-select" [hidden]="!menu.autorizado">
                <a href="" (click)="MenuClick($event, menu)" *ngIf="menu.autorizado" class="nav-item" data-placement="right" data-toggle="tooltip" [attr.data-original-title]="menu.text">
                    <i-feather name="{{menu.icon}}" style="color: #fff;width: 19px; height: 19px;"></i-feather>
                </a>
            </li>
            <li class="nav-header" style="margin-top:60px"></li>
            <li class="nav-header" style="margin-top:60px"></li>
        </ul>

    </div>

    <!--<div *ngIf="userLoggedService.type == 35" class="anuncio-side-bar bg-white"><img src="http://localhost:50414/1/anuncio.jpg"></div>-->
</nav>