import { Injectable } from '@angular/core';
import { Empresa } from 'src/app/domain/empresa/empresa';
import { EmpresaService } from '../configuracao/empresa.service';
import { UserLoggedInService } from '../authenticate/user-logged-in.service';
import { PersonType } from 'src/app/domain/usuario/Usuario';
import { UseFullService } from '../usefull/usefull.service';


export class CabecalhoCotacao {

    constructor(private empresaService: EmpresaService, private userLoggedService: UserLoggedInService, user_id = 0) {
        if (empresaService != null) {
            this.usuario_id = user_id
            if (user_id > 0)
                this.GetDataEmpresaPorUsuario()
            else
                this.GetDataEmpresa()
        }
    }

    public usuario_id: number
    public _cpf_cnpj: string
    public _nome_empresa: string

    GetDataEmpresa() {
        this.empresaService.GetLoggedIn().subscribe(d => {
            if (d) {
                if (this.userLoggedService.person == PersonType.fisica) {
                    this._cpf_cnpj = UseFullService.GetCpfMasc(d.cpf);
                    this._nome_empresa = this.userLoggedService.name
                } else {
                    this._cpf_cnpj = UseFullService.GetCpfMasc(d.cnpj);
                    this._nome_empresa = d.nome_fantasia
                }
            }
        });
    }

    GetDataEmpresaPorUsuario() {
        this.empresaService.GetPorUserId(this.usuario_id).subscribe(d => {
            if (d._tipo_usuario == PersonType.fisica) {
                this._cpf_cnpj = UseFullService.GetCpfMasc(d.cpf);
                this._nome_empresa = d._nome_usuario
            } else {
                this._cpf_cnpj = UseFullService.GetCpfMasc(d.cnpj);
                this._nome_empresa = d.nome_fantasia
            }
        });
    }
}