<div class="col-lg-12 wrapper-top-content">
    <div class="ibox-title">
        <h5>Gerênciar Categorias e Niveis</h5>
    </div>

    <div class="ibox-content form-categoria-nivel">
        <div class="row">

            <div class="col-sm-4 m-b-xs">
                <label>Selecione um produto: </label> <br />
                <button type="button" class="btn btn-sm btn-info" (click)="SugerirProduto()">Selecionar Produto</button><br />
                <label>{{_produto.nome}}</label>
                <br />
                <br />
                <div *ngIf="(_produto.id> 0)">

                    <b>Categoria:</b>
                    <label class="margin-left-10">{{_produto._categoria}}</label>
                    <br />
                    <label class="margin-top-15"><b>Nivel:</b></label>

                    <div class="" *ngFor="let nivel of _selected_niveis">
                        <button class="btn btn-primary" (click)="ChangeNivel(nivel)">Inserir
                            {{nivel.descricao}}</button>
                        <br />
                        <br />
                    </div>
                </div>
            </div>

            <div class="col-sm-8 m-b-xs">

                <select class="form-control-sm form-control input-s-sm
                    inline col-sm-4" [(ngModel)]="_categoria_nivel.categoria_id" (change)="OnChangeSearch()">
                    <option value="0">Selecione a Categoria Para buscar</option>
                    <option *ngFor="let categoria of _categorias"
                        value="{{categoria.id}}">{{categoria.descricao}}</option>
                </select>

                <div class="form-group">
                    <table class="table table-striped table-hover">
                        <thead class="thead-dark">
                            <tr>
                                <th>Componente</th>
                                <th>Selecao</th>
                                <th>order</th>
                                <th>pre-fixo</th>
                                <th>Nivel</th>
                                <th>Descrição</th>
                                <th class="text-center">#</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let nivel of _categoria_niveis">
                                <td>
                                    <select class="form-control-sm form-control
                                        input-s-sm
                                        inline" [(ngModel)]="nivel.selecao" (change)="Adicionar(nivel)">
                                        <option value="0">Select</option>
                                        <option value="1">Modal</option>
                                    </select>
                                </td>
                                <td>
                                    <select class="form-control-sm form-control
                                        input-s-sm
                                        inline" [(ngModel)]="nivel.multiplo" (change)="Adicionar(nivel)">
                                        <option value="0">Simples</option>
                                        <option value="1">Multipla</option>
                                    </select>
                                </td>
                                <td>
                                    <input type="text" class="form-control" style="width: 50px;" [(ngModel)]="nivel.order" (change)="Adicionar(nivel)" placeholder="descricao"></td>
                                <td>
                                    <input type="text" class="form-control" style="width: 100px;" [(ngModel)]="nivel.pre_fixo" (change)="Adicionar(nivel)" placeholder="pre-fixo"></td>
                                <td>{{_niveis[nivel.nivel_id]}}</td>
                                <td>
                                    <input type="text" class="form-control" style="width: 100px;" [(ngModel)]="nivel.nome" (change)="Adicionar(nivel)" placeholder="descricao"></td>
                                <td class="text-center">
                                    <a *ngIf="nivel.nivel_id != 5" [routerLink]="'/configuracao-adm/admin/nivel-item/'+nivel.categoria_id+'/'+nivel.nivel_id+'/'+nivel.produto_id"><i class="fa fa-cog" ></i></a> &nbsp;&nbsp;
                                    <a href="#" (click)="ClickDelete($event, nivel)"><i class="fa fa-trash" ></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
</div>
<app-modal-insumo-nivel (resposta_produto_selecionado)="ReciverPesquisaProduto($event)"></app-modal-insumo-nivel>