import { Component, OnInit } from '@angular/core';
import { GerenciamentoCompradorService } from 'src/app/service/gerenciamento/gerenciamento-comprador.sevice';
import { Cotacao, OrcamentoCotacao } from 'src/app/domain/cotacao/cotacao';
import { Router, ActivatedRoute } from '@angular/router';
import { UseFullService } from 'src/app/service/usefull/usefull.service';

@Component({
  selector: 'app-solicitacao-sistema',
  templateUrl: './solicitacao-sistema.component.html',
  styleUrls: ['./solicitacao-sistema.component.css']
})
export class SolicitacaoSistemaComponent implements OnInit {

  public _orcamentosCotacao: OrcamentoCotacao[];
  public _buscar: string;
  constructor(
    private comrpadorService: GerenciamentoCompradorService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  cotacao_id = this.route.snapshot.params['id'];
  _inicio = ''
  _fim = ''
  _status = 1

  ngOnInit() {
    var date = new Date();
    this._fim = UseFullService.formatDateUsForm(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    this._inicio = UseFullService.formatDateUsForm(new Date(date.getFullYear(), date.getMonth(), 1));
    this.GetAll()
  }

  OnChangeSearch() { this.GetAll() }

  GetAll() {
    this.comrpadorService.GetAllAdministradorPropagacaoDetalhada(this.cotacao_id).subscribe(
      d => {
        if (d && d.length > 0)
          this._orcamentosCotacao = d;
        else
          this._orcamentosCotacao = new Array<OrcamentoCotacao>()
      },
      e => { },
      () => {

        if (this._orcamentosCotacao && this._orcamentosCotacao.length > 0) {
          var x = 1;
          this._orcamentosCotacao.forEach(c => {
            c._index = x++;
          });
        }
      }
    );
  }

}