import { Component, OnInit } from '@angular/core';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { Cotacao } from 'src/app/domain/cotacao/cotacao';
import { Router } from '@angular/router';
import { Orcamento, ListOrcamento } from 'src/app/domain/orcamento/orcamento';
import { RepositoryService } from 'src/app/service/repository.service';
import { Empresa } from 'src/app/domain/empresa/empresa';

@Component({
  selector: 'app-empresa-list',
  templateUrl: './empresa-list.component.html',
  styleUrls: ['./empresa-list.component.css']
})
export class EmpresaListComponent implements OnInit {

  public code: string;
  public _list_orcamento: Array<ListOrcamento>;
  public empresas: Array<Empresa>;
  public _empresa: Empresa;
  
  navs = [
    { active: 'active', descricao: 'Todas' },
    { active: '', descricao: 'Faturante' },
    { active: '', descricao: 'Fiscalizadora' },
  ]

  constructor(
    public reposity: RepositoryService,
    public confirmService: AlertConfirmService,
    public userLoggedService: UserLoggedInService,
    public router: Router) {
    this.userLoggedService.SetCurrentUser();
    this.empresas = new Array<Empresa>();
    this._empresa = new Empresa();
  }

  ngOnInit() {
    this.userLoggedService.SetCurrentUser();
    this.GetAll();
  }

  GetAll() {
    this.reposity.path = "empresa/faturanteOrFiscalizadora"
    this.reposity.getAll().subscribe(
      d => {
        if (d && d.length > 0)
          this.empresas = d;
      },
      e => { },
      () => {
        let x = 1;
        this.empresas.forEach(c => { 
          c._index = x++; 
          c._categoria = Empresa.getCategoriaName(c.categoria) 
        });
      }
    );
  }

  Ver(cotacao: Cotacao) {
    this.router.navigateByUrl("gestao/faturamento/form/" + cotacao.id);
  }

  novaEmpresa() {
    this.router.navigateByUrl("gestao/faturamento/form");
  }

  verTab(item){
    this.navs.forEach(c=>c.active = '')
    item.active = 'active'
  }

}