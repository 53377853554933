import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { ProdutoService } from '../../../service/suprimento/produto.service';
import { InsumoComponentCtrl } from './insumo-component-ctrl';
import { ProdutoCategoriaNivel } from 'src/app/domain/suprimento/produto-categoria-nivel';
import { InsumoDoFornecedor, ProdutoFornecedor } from 'src/app/domain/suprimento/produto-fornecedor';
import { EnumState } from 'src/app/domain/common/interactiveTable';
import { Marca } from 'src/app/domain/suprimento/marca';
import { ProdutoCategoria } from 'src/app/domain/suprimento/produto-categoria';

declare var $: any;

@Component({
  selector: 'app-insumo',
  templateUrl: './insumo.component.html',
  styleUrls: ['./insumo.component.css'],
  providers: [ProdutoService]
})
export class InsumoComponent extends InsumoComponentCtrl implements OnInit {
  ngOnInit() {
    this.userLoggedService.AllowedOperations();
    this.confirmService.UnsetObservers();
    this.confirmService.confirmEmitter$.subscribe(c => { this.DeleteProductProvider(c); this.DeleteMultiple(c); });
    this._niveis = new Array<ProdutoCategoriaNivel>();
    this.SetListaCatetorias();
    this.SetListaUnidadeMedida();
  }

  mouseEnter(item: any) { item._selected_hover = true }

  mouseLeave(item: any) { item._selected_hover = false }

  gerenciarMarca(produto: InsumoDoFornecedor) { this.showModalMarcas(produto) }

  _input = new Array<Marca>()
  tempInsumo: InsumoDoFornecedor
  tempProduto = new Array<ProdutoFornecedor>()
  showModalMarcas(insumo: InsumoDoFornecedor) {
    this.tempInsumo = insumo
    $('#modal-multiselect').modal('show')
    if (this._input.length < 1) { this.GetAllMarcas() } else { this.selectMarcas() }
  }

  selectMarcas() {
    this._input.forEach(c => { c._selected = false; c._state = EnumState.none })
    this.repository.path = 'suppliesProvider/get/all';
    this.repository.getAll('&id=' + this.tempInsumo.id).subscribe(d => {
      this.tempProduto = d
      for (let i = 0; i < this.tempProduto.length; i++) {
        const marca = this._input.filter(o => { return (o.id == this.tempProduto[i].marca_id) })
        if (marca && marca.length) {
          marca[0]._selected = true
          marca[0]._state = EnumState.none
        }
      }
    });
  }

  GetAllMarcas() {
    this.repository.path = 'supplies/marca/getall';
    this.repository.getAll().subscribe(d => { this._input = d; this.selectMarcas(); });
  }

  receiverData(list: Array<Marca>) {
    this.repository.path = "supplies/relacao-produto-marca/create"

    const data = list.filter(o => { return (o._selected || o._state == EnumState.delete) });
    const addProduto = []
    const deleteProduto = []
    data.forEach(c => {
      if (c._state == 3) {
        const itens = this._suprimento.insumos.filter(o => { return (o.ProdutoFornecedor.marca_id == c.id && o.id == this.tempInsumo.id) })
        if (itens || itens.length > 0)
          deleteProduto.push(itens[0])
      } else {
        const itens = this._suprimento.insumos.filter(o => { return (o.ProdutoFornecedor.marca_id == c.id && o.id == this.tempInsumo.id) })
        if (!itens || itens.length < 1)
          addProduto.push(c)
      }
    });
    //itens delete
    if (deleteProduto && deleteProduto.length > 0) this.deleteProduto(deleteProduto);

    //itens add
    if (addProduto && addProduto.length > 0)
      this.addProduto(addProduto);
  }

  async addProduto(addProduto: any[]) {
    this.repository.path = "suppliesProvider/Create"
    var _this = this;
    const data = new InsumoDoFornecedor();
    data.categoria_id = this.tempInsumo.categoria_id
    data.data_criacao = this.tempInsumo.data_criacao
    data.ha_niveis = this.tempInsumo.ha_niveis
    data.id = this.tempInsumo.id
    data.nome = this.tempInsumo.nome
    data.origem = this.tempInsumo.origem
    data.ProdutoFornecedor = new ProdutoFornecedor();
    data.ProdutoFornecedor.produto_id = this.tempInsumo.id
    data.ProdutoFornecedor.unidade_id = this.tempInsumo.ProdutoFornecedor.unidade_id
    for (let i = 0; i < addProduto.length; i++) {
      let promise = await new Promise(function (resolve, reject) {
        data.ProdutoFornecedor.marca_id = addProduto[i].id
        data.ProdutoFornecedor.cross_docking = (data.ProdutoFornecedor.cross_docking) ? data.ProdutoFornecedor.cross_docking : 0
        _this.repository.post(data).subscribe(d => {
          _this._suprimento.insumos.splice(_this.tempInsumo._index, 0, d)
          _this.config()
          resolve(true);
        },
          e => {
            reject(false)
          })
      });
    }
    _this.config()
  }

  tempDeleteProduto = []
  deleteProduto(deleteProduto: any[]) {
    this.tempDeleteProduto = []
    this.tempDeleteProduto = deleteProduto
    this.ConfirmDeleteMultiple()
  }

  config() {
    let x = 1
    this._suprimento.insumos.forEach(e => {     
      if (e.categoria_id > 0) {
        e._index = x++
        const marca = this._input.filter(o => { return (o.id == e.ProdutoFornecedor.marca_id) })
        if (marca && marca.length > 0)
          e.ProdutoFornecedor._marca = marca[0].descricao
        e._categoria = ProdutoCategoria.GetName(this._categorias, e.categoria_id);
        e.ProdutoFornecedor.estoque = (e.ProdutoFornecedor.estoque > 0) ? e.ProdutoFornecedor.estoque : null
        e.ProdutoFornecedor.preco = (e.ProdutoFornecedor.preco > 0) ? e.ProdutoFornecedor.preco : null
        e.ProdutoFornecedor.cross_docking = (e.ProdutoFornecedor.cross_docking > 0) ? e.ProdutoFornecedor.cross_docking : null 
      }
    });
  }

  ConfirmDeleteMultiple() {
    this.confirmService._code = this._code + 'multiple';
    this.confirmService._title = "Excluir Vinculos Desmarcados";
    this.confirmService._message = "Deseja excluir dados das marcas desmarcadas ?";
    this.confirmService.Show();
  }

  async DeleteMultiple(confirm: boolean) {
    if (!confirm || this.confirmService._code != this._code + 'multiple')
      return;
    var _this = this
    for (let i = 0; i < this.tempDeleteProduto.length; i++) {
      let promise = await new Promise(function (resolve, reject) {
        _this.httpSuprimentos.Delete(_this.tempDeleteProduto[i].ProdutoFornecedor.id).subscribe(
          d => {
            console.log(_this.tempDeleteProduto[i]._index)
            _this._suprimento.insumos.splice(_this.tempDeleteProduto[i]._index - 1, 1);
            _this.config()
          },
          e => { },
          () => { resolve(true); });
      });
    }
  }
}