<div class="middle-box text-center loginscreen   ">
    <form action="#">

        <div>
            <div>
                <img alt="image" class="" src="./assets/images/Simples_logo.png" />
            </div>
            <h3>Painel da Engenharia</h3>

            <div class="form-group">
                <input type="email" name="email" class="form-control" [(ngModel)]="_login" placeholder="email">
            </div>
            <div class="form-group">
                <input type="password" name="password" class="form-control" [(ngModel)]="_password" placeholder="senha">
            </div>
            <button type="submit" class="btn btn-primary block full-width m-b" (click)="LogInto()">Entrar</button>

            <a href="#"><small>Esqueceu a senha?</small></a>
            <p class="text-muted text-center">
                <small>Não possui um cadastro,</small>
                <a href="#"><small> novo cadastro</small></a>
            </p>
            <p class="m-t"> <small>www.paineldaengenharia.com.br © 2019</small>
            </p>
        </div>
    </form>
</div>