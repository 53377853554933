<div class="col-lg-12 wrapper-top-content">
    <div class="ibox-title">
        <h5>OBRA: {{obra.nome}}</h5>
    </div>

    <div class="ibox-content form-categoria-nivel">
        <div class="row">
            <div class="col-sm-12 m-b-xs">
                <p>
                    <b>INICIO:</b>&nbsp;&nbsp;{{obra._inicio}}<br />
                    <b>FIM:</b>&nbsp;&nbsp;{{obra._fim}}<br />
                </p>
                <p>
                    <b>Endereço:</b>&nbsp;&nbsp;{{_endereco.endereco}}&nbsp;n°&nbsp;{{_endereco.numero}}&nbsp;{{_endereco.complemento}}<br />
                    <b>Setor:</b>&nbsp;&nbsp;{{_endereco.bairro}}<br />
                    <b>Cidade:</b>&nbsp;&nbsp;{{_endereco.cidade}} - {{_endereco.uf}}<br />
                    <b>CEP:</b>&nbsp;&nbsp;{{_endereco.cep}}<br />
                </p>
            </div>
            <div class="col-sm-12">
                <div class="form-group">
                    <div class="col-sm-12 div-relacao no-padding">
                        <label><b>FATURANTE/FISCALIZADORA:</b></label><br />
                        <select class="form-control" [(ngModel)]="relacao_empresa.empresa_id" ng style="display: inline;">
                            <option value="undefined">Selecione uma empresa</option>
                            <option *ngFor="let e of empresas" value="{{e.id}}">{{e.razao_social}}</option>
                        </select>
                    </div>
                    <div class="col-sm-12 div-relacao no-padding margin-left-10">
                        <label><b>Tipo:</b></label><br />
                        <select class="form-control" [(ngModel)]="relacao_empresa.tipo" ng style="max-width: 200px; display: inline;">
                            <option value="undefined">Selecione o tipo</option>
                            <option value="1">Faturante</option>
                            <option value="2">Fiscalizadora</option>
                        </select>
                        <button class="btn btn-primary margin-left-10" n style="margin-top: -5px;" (click)="addRelacaoEmpresa()">ADICIONAR</button>
                    </div>
                </div>
            </div>
            <div class="col-sm-12" *ngIf="empresas_relacionadas.length > 0">
                <div class="list-group">
                    <b>Empresas</b>
                </div>
                <hr />
            </div>
            <div class="col-sm-6" style="padding-right:0px;" *ngIf="empresas_relacionadas.length > 0">
                <div class="list-group">
                    <table class="table table-hover table-striped">
                        <thead>
                            <tr>
                                <th>Razão social</th>
                                <th class="text-center">Tipo</th>
                                <th class="text-center width-40-px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let r_emp of empresas_relacionadas" class="cursor-pointer grid-selected-empresa
                                {{(r_emp._active) ?
                                'active':''}}" (click)="buscarReponsaveis(r_emp)">
                                <td><i class="fa fa-caret-left icon-active"></i>{{r_emp.nome_fantasia}}</td>
                                <td class="text-center width-150-px">
                                    {{(r_emp.tipo_relacao == 1)?'Faturante':'Fiscalizadora'}}
                                </td>
                                <td class="text-center width-40-px"><i class="fa
                                        fa-trash cursor-pointer cursor-opacity" (click)="clickTrash(r_emp)"></i></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-sm-6" *ngIf="responsaveis.length> 0" style="border-left: 1px solid #eee;margin-left: -1px;">
                <div class="list-group">
                    <table class="table table-hover table-striped">
                        <thead>
                            <tr>
                                <th>Responsáveis:</th>
                                <th class="text-center">Auditar ?</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let r of responsaveis" class="cursor-pointer">
                                <td>
                                    <label class="margin-buttom-5"><b>Nome:</b>&nbsp;&nbsp;{{r.nome}}</label><br />
                                    <label class="margin-buttom-5"><b>Telefone:</b>&nbsp;&nbsp;{{r.telefone}}</label><br />
                                    <label class="margin-buttom-5"><b>Email:</b>&nbsp;&nbsp;{{r.email}}</label><br />
                                </td>
                                <td class="text-center width-100-px">
                                    <label class="btn btn-check-table label-checked {{(r._auditor)?'active':''}}" (click)="selecionarResponsavel(r)">
                                        <span class="glyphicon-check-mark fa fa-check"></span>
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-sm-12 text-right margin-top-20">
                <button class="btn btn-primary" (click)="continuar()">Continuar</button>
            </div>
        </div>
    </div>
</div>