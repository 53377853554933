import { Component, OnInit } from '@angular/core';
import { Dashboard, FaturamentoDashboard } from 'src/app/domain/auxiliar/dashboard';
import { DashboardService } from 'src/app/service/auxiliar/dashboard.service';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { Chart } from 'angular-highcharts';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { TypeUser } from 'src/app/domain/usuario/Usuario';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  _dashboard = new Dashboard();
  _inicio = "";
  _fim = "";  
  _categorias = [];
  _data = [];
  chart: Chart;
  _faturamento_dashboard = new Array<FaturamentoDashboard>();
  date: any;
  public model: any
  constructor(private dashboardService: DashboardService, private router: Router, public userLoggedService: UserLoggedInService) { }

  ngOnInit() {    
    if(this.userLoggedService.type == TypeUser.client)
      this.router.navigateByUrl('/dashboard/comprador')
    var date = new Date();
    this._fim = UseFullService.formatDateUsForm(new Date(date.getFullYear(), date.getMonth() + 1, 0));    
    this._inicio = UseFullService.formatDateUsForm(new Date(date.getFullYear(), date.getMonth(), 1));
    this.GetResumo();
  }

  GetResumo() {
    this.dashboardService.Get(this._inicio, this._fim).subscribe(
      d => { this._dashboard = d; Dashboard.Formatar(this._dashboard); }
    );

    this.dashboardService.GetAll(this._inicio, this._fim).subscribe(
      d => {  this._faturamento_dashboard = d; 
      },
      e => { },
      () => { this.init(); }
    );
  }

  
  init() {
    this.SetCategorias();
    setTimeout(() => { this.StartChat() }, 50);
    setTimeout(() => { $("[class='highcharts-credits']").remove(); }, 150);

    $( ".chart-faturamento" ).on( "mousemove", function() {
      $(".valor-faturado").text(UseFullService.ToFixed(UseFullService.ReplaceFloat($('.valor-faturado').text())));
    });

    var _this = this;
    $('#atualizarChat').click(()=>{ _this.StartChat() });
  }

  StartChat(){
    let chart = new Chart(this.opcao as any); 
    this.chart = chart; 
  }


  SetCategorias() {
    this._faturamento_dashboard.forEach(c => {
      this._categorias.push(UseFullService.formatDateDiaMes(new Date(c.data)));
      this._data.push(c.total_diario);
    });
  }

  public opcao = {
    chart: {
      type: 'line'
    },
    title: {
      text: 'Faturamento por Período'
    },
    subtitle: {
      //text: 'Source: WorldClimate.com'
    },
    xAxis: {
      categories: this._categorias
    },
    yAxis: {
      title: {
        text: 'Vendas Realizadas'
      }
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true
        },
        enableMouseTracking: true
      }
    },
    tooltip: {
        headerFormat: '<b>{series.name}</b><br/>',
        pointFormat: "R$ <span class='valor-faturado'>{point.y}</span>"
    },
    series: [{
      name: 'Vendas Realizadas',
      data: this._data
    }]
  };

}
