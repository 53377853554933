import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from 'src/app/core/global-variable';
import { OrcamentoConfiguracao } from 'src/app/domain/orcamento/orcamento-configuracao';

@Injectable({
  providedIn: 'root'
})
export class OrcamentoConfiguracaoService {
  
  private url = GlobalVariable.BASE_API_URL + "orcamento/configuracao/";
  constructor(private http: HttpClient) { }

  GetFornecedor() {
    return this.http.get<OrcamentoConfiguracao>(this.url + "getfornecedor").pipe(
      map(res => {
        return res;
      })
    );
  }

  Get(id: number) {
    return this.http.get<OrcamentoConfiguracao>(this.url + "get?id="+id).pipe(
      map(res => {
        return res;
      })
    );
  }
  
  Create(data: OrcamentoConfiguracao): Observable<any>{
    return this.http.post<any[]>(this.url+"create", data).pipe(
      map(res=> { return res; })
    );
  }
  
  Edit(data: OrcamentoConfiguracao): Observable<any>{
    return this.http.put<any[]>(this.url+"edit", data).pipe(
      map(res=> { return res; })
    );
  }
  
  Delete(id: number): Observable<any> {    
    return this.http.delete(this.url+"delete?id="+id).pipe(
      map(res=> { return res; })
    );
  }
}
